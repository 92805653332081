import { useState } from 'react';
import { TextField, ListBox, FieldFeedback, classNames } from '@portal/ui';
import { USER_DROPDOWN_ROLES } from 'constants/users';
import { UserFieldsProps, User } from 'types/User';
import { useFormikContext } from 'formik';
import { Icons } from '@portal/ui';

export const UserFields = ({ role, onChange, index, remove, isInValidEmail }: UserFieldsProps) => {
  const { errors } = useFormikContext<{ invitees: User[] }>();
  const [isFocused, setIsFocused] = useState<boolean>(false);

  const error = errors?.invitees?.[index];
  const emailError = typeof error === 'object' ? error?.email : undefined;

  return (
    <>
      <div
        onBlur={() => setIsFocused(false)}
        className={classNames('flex items-center mb-2 border rounded-md', isFocused ? 'border-primary' : '')}
      >
        <div className="w-8/12">
          <TextField
            id={`invitees.${index}.email`}
            customStyling={{
              field:
                'text-steel-600 appearance-none block w-full px-3 py-2.5 border-none !border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
            }}
            onFocus={() => setIsFocused(true)}
          />
        </div>
        <div className="w-4/12">
          <ListBox
            options={Object.entries(USER_DROPDOWN_ROLES).map(([role, label]) => ({
              name: label,
              value: role,
            }))}
            customStyle="!border-l-0 rounded-l-none border-none"
            optionCustomStyle="max-h-30 h-28 right-0 text-steel"
            onChange={(value) => onChange(`invitees.${index}.role`, value)}
            value={{
              name: USER_DROPDOWN_ROLES[role],
              value: role,
            }}
            onFocus={() => setIsFocused(true)}
          />
        </div>
        {index > 0 && (
          <button className="w-1/12 ml-1" onClick={() => remove(index)}>
            <Icons.Cross className="w-5 h-5 text-red-600" />
          </button>
        )}
      </div>
      <FieldFeedback errorCondition={isInValidEmail} errorMessage={emailError} transitionClasses="mb-1" />
    </>
  );
};
