import { useCallback } from 'react';
import { FieldArray, useFormikContext, ErrorMessage } from 'formik';
import { Button, SubHeading, Icons } from '@portal/ui';
import { AddSite, FAQ } from 'types/Sites';
import FormField from 'components/formField';

const FAQs = () => {
  const {
    values: { FAQs, question, answer },
    errors,
    setFieldValue,
  } = useFormikContext<AddSite>();
  const AddFAQ = useCallback(
    async (push: (value: FAQ) => void) => {
      push({ question, answer });
      await setFieldValue('question', '');
      await setFieldValue('answer', '');
    },
    [answer, question, setFieldValue]
  );

  return (
    <FieldArray
      name="FAQs"
      render={({ push, remove }) => (
        <>
          <div>
            {FAQs?.map((FQA: FAQ, index: number) => (
              <div className="py-6 flex items-center justify-between border-b">
                <div className="flex flex-col">
                  <SubHeading title={FQA.question} description={FQA.answer} variant="medium" />
                </div>
                <Button title="trash" displayType="text" iconOnly onClick={() => remove(index)}>
                  <Icons.Trash />
                </Button>
              </div>
            ))}
            <div className="w-full py-5">
              <FormField
                id="question"
                type="text"
                placeholder="Question"
                value={question}
                forceHasError={!!errors.FAQs}
              />
            </div>
            <div className="w-full pb-5">
              <FormField
                id="answer"
                type="textarea"
                rows={7}
                placeholder="Answer"
                value={answer}
                forceHasError={!!errors.FAQs}
              />
            </div>
            <Button title="add" displayType="secondary" onClick={() => AddFAQ(push)} disabled={!question && !answer}>
              Add
            </Button>
          </div>
          <ErrorMessage name="FAQs" className="text-red-500 text-sm" component="a" />
        </>
      )}
    />
  );
};

export default FAQs;
