import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { GeneralSettings } from 'components/settings/GeneralSettings';
import { TeamSettings } from 'components/team';
import { ProductsPage } from 'components/products';
import { CalendarSettings } from 'components/settings/CalendarSettings';
import { BookingSettings } from 'components/settings/BookingSettings';
import { TagsPage } from 'components/tags';

const SettingsPage = () => {
  const { tab } = useParams();

  const Settings = useMemo(() => {
    switch (tab) {
      case 'calendar':
        return CalendarSettings;
      case 'booking':
        return BookingSettings;
      case 'team':
        return TeamSettings;
      case 'products':
        return ProductsPage;
      case 'tags':
        return TagsPage;
      default:
        return GeneralSettings;
    }
  }, [tab]);

  return <Settings />;
};

export default SettingsPage;
