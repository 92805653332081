import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { Avatar, DatePicker, FilePreview, FileUpload, ListBox, SearchableInput } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { getInitials } from '@portal/utils/string';
import CurrencyInput from 'components/currencyInput';
import FormField from 'components/formField';
import { PaymentMethods } from 'constants/payments';
import { useFormikContext } from 'formik';
import { useFindAllContactsLazyQuery } from 'graphql/query.generated';
import { find, map } from 'lodash';
import { useEffect, useMemo } from 'react';
import { DateValue } from 'react-aria';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import { RootState } from 'redux/store';
import { FormState } from '.';

export type Option = {
  value: string;
  label: string;
};
const LogPaymentForm = () => {
  const { setFieldValue, values, errors } = useFormikContext<FormState>();
  const { id: contactId } = useParams();
  const merchantAccountId = useSelector<RootState>((state) => state.user.attributes?.merchantAccount.id);
  const [fetchAllContacts, { data: customers }] = useFindAllContactsLazyQuery({
    fetchPolicy: 'network-only',
  });
  useEffect(() => {
    if (merchantAccountId) {
      fetchAllContacts({
        variables: {
          merchantAccountId: merchantAccountId as string,
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
  }, [merchantAccountId]);

  const customerOptions = useMemo(
    () =>
      map(customers?.findAllContacts?.contacts, (customer) => ({
        value: customer.id,
        label: customer?.name !== '' ? customer?.name : customer?.email,
        ...customer,
      })),
    [customers]
  );
  const customer = useMemo(() => {
    const customer = find(customerOptions, { id: values?.customer?.id || contactId });
    if (!values?.customer?.id) {
      setFieldValue('customer', customer);
    }
    return customer;
  }, [contactId, customerOptions, values?.customer?.id]);

  return (
    <div className="flex before:content flex-col gap-3 mb-10">
      <div>
        <label className="block mb-2 text-sm font-medium text-steel-700 " htmlFor="customer">
          Customer
        </label>
        <SearchableInput
          hasError={!!errors['customer']}
          value={customer}
          placeholder="Select a customer"
          options={customerOptions}
          customOptionNode={(props) => (
            <components.Option {...props} className="!flex flex-row gap-2 items-center">
              <Avatar imageUrl={props.data.avatar} size={'x_small'} initials={getInitials(props.data.name)} />
              <p className="text-steel-900">{props.data?.name}</p>
              <p className="text-steel-500 text-xs">{props.data?.email}</p>
            </components.Option>
          )}
          handleChange={(value) => {
            setFieldValue('customer', value);
          }}
        />
        {errors['customer'] && <div className="text-red-500 text-sm">Customer is required</div>}
      </div>
      <div className="w-full custom-date flex flex-col space-y-2">
        <label className="block text-sm font-medium text-steel-700" htmlFor="date">
          Date
        </label>
        <DatePicker
          onChange={(value: DateValue) => setFieldValue('date', value.toDate(getLocalTimeZone()))}
          value={
            values.date
              ? parseDate(
                  values.date?.toLocaleDateString('fr-CA', {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                  })
                )
              : today(getLocalTimeZone())
          }
        />
      </div>

      <div className="w-full">
        <label className="block text-sm font-medium text-steel-700 mb-2">Amount</label>
        <CurrencyInput id="amount" />
      </div>
      <div className="disabled-button">
        <label className="block mb-2 text-sm font-medium text-steel-700" htmlFor="paymentMethod">
          Payment method
        </label>
        <ListBox
          disabled={!customer}
          options={PaymentMethods}
          value={PaymentMethods.find((paymentMethod) => paymentMethod.value === values['paymentMethod'])}
          onChange={(value: string) => setFieldValue('paymentMethod', value)}
        />
      </div>
      <div>
        <label className="block mb-2 text-sm font-medium text-steel-700" htmlFor="attachments">
          Attachments
        </label>
        <FileUpload
          allowedFileTypes={[
            'image/png',
            'application/pdf',
            'application/msword',
            'application/doc',
            'application/docx',
          ]}
          multiple
          maxImageSize={10 * 1024 * 1024}
          uploadInfoText="10MB max"
          onChange={(files) => {
            setFieldValue('attachments', [...values.attachments, ...(files as File[])]);
          }}
        />
        <div className="my-2">
          {values.attachments.map((file, idx) => (
            <FilePreview
              key={file.lastModified}
              index={idx}
              handleRemove={(idx) => {
                const files = [...values.attachments.slice(0, idx), ...values.attachments.slice(idx + 1)];
                setFieldValue('attachments', files);
              }}
              file={file}
            />
          ))}
        </div>

        <div className="text-red-500 text-sm">{errors['attachments']?.toString()}</div>
      </div>
      <div>
        <FormField id="reference" label="Reference" maxLength={300} />
        <p className="text-sm font-normal text-steel-600 mt-1.5">{300 - values.reference?.length} characters left</p>
      </div>
    </div>
  );
};

export default LogPaymentForm;
