import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { HorizontalTabs } from '@portal/ui';
import { useMemo } from 'react';
import { Details, DetailsProps } from './Details';
import Invoice from './Invoices';
import Payment from './Payments';
import Appointments from './Appointments';

export const TabsView = ({
  contact,
  updateContactLoading,
  updateStatus,
  propertyName,
  onAvatarChange,
}: Partial<DetailsProps>) => {
  const isMobileView = useIsMobileView();

  const tabsData = [
    {
      id: 'payments',
      name: 'Payments',
      component: <Payment />,
    },
    {
      id: 'invoices',
      name: 'Invoices',
      component: <Invoice />,
    },
    {
      id: 'appointments',
      name: 'Appointments',
      component: <Appointments />,
    },
  ];
  const updatedTabsData = useMemo(
    () =>
      isMobileView && onAvatarChange && updateStatus
        ? [
            {
              id: 'details',
              name: 'Details',
              component: (
                <Details
                  updateContactLoading={updateContactLoading as boolean}
                  contact={contact}
                  onAvatarChange={onAvatarChange}
                  updateStatus={updateStatus}
                  propertyName={propertyName}
                />
              ),
            },
            ...tabsData,
          ]
        : tabsData,
    [isMobileView]
  );
  return (
    <div className="flex w-full bg-steel-50 min-h-full">
      <HorizontalTabs
        key={updatedTabsData.length}
        tabsData={updatedTabsData}
        customClasses={{
          list: ` horizontal-tabs-list-text ${
            isMobileView
              ? 'flex justify-between px-4 lg:px-8 sm:px-6  min-w-[375px] overflow-x-auto duration-150 ease-in-out'
              : ''
          }`,
        }}
      />
    </div>
  );
};
