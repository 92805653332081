export const formatTime = (date: string) => {
  const options = { hour: 'numeric', minute: 'numeric', hour12: true, timeZoneName: 'short' };
  return new Date(date).toLocaleTimeString('en-US', options as Intl.DateTimeFormatOptions);
};

export const getFormattedTime = (startTime: string, endTime: string) => {
  const [start, hourFromat1, timezone] = formatTime(startTime).split(' ');
  const [end, hourFromat2] = formatTime(endTime).split(' ');
  return `${start}${hourFromat1.toLowerCase()} - ${end}${hourFromat2.toLowerCase()} ${timezone}`;
};
