export enum CalendarNavigation {
  PREV = 'prev',
  TODAY = 'today',
  NEXT = 'next',
}

export type Direction = CalendarNavigation.PREV | CalendarNavigation.TODAY | CalendarNavigation.NEXT;

export enum CalendarDisplayModes {
  TIME_GRID_WEEK = 'timeGridWeek',
  DAY_GRID_MONTH = 'dayGridMonth',
}

export enum CalendarLabel {
  WEEK = 'Week',
  MONTH = 'Month',
}

export type FullCalendarEventType = {
  end: Date;
  endStr: string;
  start: Date;
  startStr: string;
};

export type Guests = {
  label: string;
  value: string;
};

export type Notifications = {
  label: string;
  value: string;
  selected: boolean;
};
