import { ApolloError } from '@apollo/client';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import {
  DataTable,
  DeleteModal,
  EmptyState,
  Icons,
  Modal,
  RowActionMenu,
  RowActionMenuProps,
  SubHeading,
} from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { DateFormats } from '@portal/utils/dates';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { TagForm, TagFormProps } from 'components/tags/TagForm';
import { useDeleteTagMutation, useUpdateTagMutation } from 'graphql/mutation.generated';
import { FindAllTagsDocument, FindAllTagsQuery, useFindAllTagsLazyQuery } from 'graphql/query.generated';
import { FindAllTagsFilters } from 'graphql/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'redux/hooks';

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllTagsFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

export type TagRow = FindAllTagsQuery['findAllTags']['tags'][number];

export const TagsPage = () => {
  const [fetchTags, { data, loading }] = useFindAllTagsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [deleteTag, { loading: deleteTagLoading }] = useDeleteTagMutation({
    onCompleted: () => {
      toast.success('Tag deleted');
      setSelectedTagToDelete(null);
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    refetchQueries: [FindAllTagsDocument],
  });

  const [updateTag, { loading: updateTagLoading }] = useUpdateTagMutation({
    onCompleted: () => {
      toast.success('Tag updated');
      setSelectedTag(null);
    },
    onError: (error) => {
      console.error(error);
    },
    refetchQueries: [FindAllTagsDocument],
  });

  const [selectedTag, setSelectedTag] = useState<TagRow | null>(null);
  const [selectedTagToDelete, setSelectedTagToDelete] = useState<TagRow | null>(null);
  const merchantAccountId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);

  useEffect(() => {
    if (merchantAccountId) {
      fetchTags({
        variables: {
          merchantAccountId: merchantAccountId as string,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
          },
        },
      });
    }
  }, [merchantAccountId, tableState.pageIndex, tableState.pageSize, tableState.filters, tableState.sorting]);

  const rowActions: RowActionMenuProps<TagRow>['rowActions'] = [
    {
      name: 'Edit',
      action: (row) => {
        setSelectedTag(row ?? null);
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedTagToDelete(row ?? null);
      },
    },
  ];

  const columns: ColumnType<TagRow>[] = [
    {
      name: 'Name',
      id: 'name',
      cell: (info) => {
        const tag = info.row.original;
        return (
          <div className="flex flex-col">
            <p className="text-steel-900 font-medium">{tag.name}</p>
            <p className="text-steel-600">{tag.contactsCount} contacts</p>
          </div>
        );
      },
    },
    {
      id: 'createdAt',
      name: 'Created',
      cell: (info: any) => (
        <p className="text-steel-600">{moment(info.getValue()).format(DateFormats.MONTH_DAY_WITH_YEAR)}</p>
      ),
    },
    {
      name: '',
      id: 'actions',
      cell: ({ row: { original } }) => <RowActionMenu row={original} rowActions={rowActions} />,
    },
  ];

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            nameLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = data?.findAllTags.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllTags.count]);

  const onTagDelete = () => {
    deleteTag({
      variables: {
        merchantTagId: selectedTagToDelete?.id as string,
      },
    });
  };

  const onTagUpdate: TagFormProps['onSubmit'] = (values, { setErrors }) => {
    updateTag({
      variables: {
        data: {
          ...values,
          merchantTagId: selectedTag?.id as string,
        },
      },
    }).then((res) => {
      const errors = res.errors as ApolloError | undefined;
      if (errors?.message) {
        setErrors({ name: errors.message });
      }
    });
  };

  return (
    <div className="px-8">
      <SubHeading
        title="Tags"
        description="Manage the tags that help you organize contacts on your account."
        useHelmetTitle
      />
      <div className="pb-3">
        <DataTable
          columns={columns}
          data={data?.findAllTags.tags || []}
          noDataElement={
            <EmptyState
              title={'No tags yet'}
              subTitle={'Any tags your team creates will appear here.'}
              icon={<Icons.Tag color="#7F56D9" />}
            />
          }
          tableState={tableState}
          updateTableState={updateTableState}
          loading={loading}
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
        />
      </div>

      <Modal open={selectedTag != null} title="Rename tag" onClose={() => setSelectedTag(null)} closeIcon>
        <TagForm
          onClose={() => setSelectedTag(null)}
          onSubmit={onTagUpdate}
          initialValues={{ name: selectedTag?.name ?? '' }}
          loading={updateTagLoading}
        />
      </Modal>

      <DeleteModal
        onClose={() => setSelectedTagToDelete(null)}
        title={'Delete tag'}
        open={selectedTagToDelete != null}
        onConfirm={onTagDelete}
        loading={deleteTagLoading}
        content={'Are you sure you want to delete this tag? This action cannot be undone.'}
      />
    </div>
  );
};
