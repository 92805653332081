import classNames from 'classnames';
import { Field } from 'formik';

interface RadioInputProps {
  name: string;
  value: string | boolean | number;
  label: string;
  info?: string;
  disabled?: boolean;
}

export const RadioInput = ({ name, label, value, info, disabled }: RadioInputProps) => {
  return (
    <div
      className={classNames('flex gap-2 items-start', {
        'opacity-50': disabled,
      })}
    >
      <Field
        disabled={disabled}
        type="radio"
        name={name}
        value={value}
        className="focus:ring-white mt-2 radio-checked"
      />
      <div className="flex gap-1 flex-col">
        <label className="text-sm font-medium text-steel-700">{label}</label>
        <p className="text-sm leading-none text-steel-700">{info}</p>
      </div>
    </div>
  );
};
