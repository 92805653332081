import { NavigationLink } from '@portal/ui';
import { Link } from 'react-router-dom';

export const ProfileNavigation = [
  {
    name: 'Details',
    link: <NavigationLink link={<Link to="/profile/details">Details</Link>} />,
    pathName: '/profile/details',
  },
  // {
  //   name: 'Notifications',
  //   link: <NavigationLink link={<Link to="/profile/notifications">Notifications</Link>} />,
  //   pathName: '/profile/notifications',
  // },
  {
    name: 'Security',
    link: <NavigationLink link={<Link to="/profile/security">Security</Link>} />,
    pathName: '/profile/security',
  },
];
