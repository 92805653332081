export const PageTitles: { [key: string]: string } = {
  '/': 'Dashboard',
  '/contacts': 'Contacts',
  '/settings': 'Settings',
  '/payments': 'Payments',
  '/listings': 'Listings',
  '/sites': 'Sites',
  '/calendar': 'Calendar',
  '/profile': 'Profile',
  '/inbox': 'Inbox',
};

export const getPageTitle = (pathname: string) => {
  if (pathname in PageTitles) {
    return PageTitles[pathname];
  } else if (/^\/contacts\/([^/]+)$/.test(pathname)) {
    return 'Contact details';
  } else if (/^\/settings\/([^/]+)$/.test(pathname)) {
    return 'Settings';
  } else if (/^\/profile\/([^/]+)$/.test(pathname)) {
    return 'Profile';
  }
  return 'Dashboard';
};
