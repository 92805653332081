import { Avatar, Button, Card, Icons, SearchField, Title } from '@portal/ui';
import { ReactNode, useState } from 'react';
import { EditBusinessDetails } from '../../components/Listings/EditBusinessDetails';

const Heading = ({ title, info }: { title: string; info: ReactNode }) => (
  <div>
    <h1 className="text-sm font-medium leading-5 text-steel-500 small-heading">{title}</h1>
    {typeof info === 'string' ? (
      <p className="text-sm font-normal text-steel-600 heading-info">{info}</p>
    ) : (
      <div>{info}</div>
    )}
  </div>
);

export const ListingsPage = () => {
  const [isEditBusinessDetailsOpen, setIsEditBusinessDetailsOpen] = useState(false);
  const hoursTable = [
    {
      day: 'Mon',
      hours: '8:00 AM - 5:00 PM',
    },
    {
      day: 'Tue',
      hours: '8:00 AM - 5:00 PM',
    },
    {
      day: 'Wed',
      hours: '8:00 AM - 5:00 PM',
    },
    {
      day: 'Thu',
      hours: '8:00 AM - 5:00 PM',
    },
    {
      day: 'Fri',
      hours: '8:00 AM - 5:00 PM',
    },
    {
      day: 'Sat',
      hours: 'Closed',
    },
    {
      day: 'Sun',
      hours: 'Closed',
    },
  ];

  return (
    <div className="flex flex-row min-h-screen p-8">
      <div className="flex flex-col space-y-4 w-1/4 pr-8">
        <div className="flex justify-between items-center">
          <Title>Business details</Title>
          <Button
            displayType="text"
            iconOnly
            title="edit business details"
            onClick={() => setIsEditBusinessDetailsOpen(true)}
          >
            <span className="p-1.5">
              <Icons.Pencil className="w-5 h-5" />
            </span>
          </Button>
        </div>
        <div className="border-t border-gray-200 my-8" />
        <div className="flex flex-col">
          <div className="mb-4">
            <Heading title="Name" info="Catalog Home Services" />
          </div>
          <div className="mb-4">
            <Heading
              title="Address"
              info={
                <>
                  <span>1841 Market Street</span>
                  <br />
                  <span>San Francisco, CA 94114</span>
                </>
              }
            />
          </div>

          <div className="mb-4">
            <Heading title="Phone" info="(713) 551-4114" />
          </div>

          <div className="mb-4">
            <Heading title="Website" info="https://www.cataloghomeserv.com" />
          </div>

          <div className="mb-4">
            <Heading
              title="Hours"
              info={
                <ul className="list-none">
                  {hoursTable.map((dayData) => (
                    <li key={dayData.day} className="text-sm font-normal text-gray-600">
                      <p className="w-1/6 inline-flex">{dayData.day}</p>
                      <p className="w-5/6 inline-flex">{dayData.hours}</p>
                    </li>
                  ))}
                </ul>
              }
            />
          </div>
        </div>
      </div>
      <div className="border-l border-gray-200 my-2" />
      <div className="flex flex-col space-y-4 w-3/4 pl-8">
        <div className="flex justify-between items-center">
          <Title>Listings</Title>
          <SearchField
            customClass="w-search-btn search-box p-0"
            id="search-listings"
            placeholder="Search listings"
            ariaLabel="Search listings"
            onChange={() => {
              return;
            }}
          />
        </div>
        <div className="border-t border-gray-200 my-8" />
        <div className="grid grid-cols-3 gap-6">
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
          <Card>
            <div className="flex flex-row gap-3">
              <Avatar initials="MK" />
              <div className="flex flex-col">
                <h3 className="leading-6 font-semibold text-steel-900">Catalog Home Services</h3>
                {/* status active with green text & green dot or inactive with red text & red cross */}
                <p className="text-xs text-green-600 font-normal flex gap-1 align-middle">
                  <Icons.CircleFilled width={8} color="green" /> Active
                </p>
              </div>
            </div>
          </Card>
        </div>
      </div>
      <EditBusinessDetails
        isOpen={isEditBusinessDetailsOpen}
        onClose={() => setIsEditBusinessDetailsOpen(false)}
        onSubmit={(values) => {
          console.log(values);
        }}
      />
    </div>
  );
};
