import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Drawer } from '@portal/ui';
import { Form, FormikValues } from 'formik';
import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Formik } from '../..';
import Icons from '../../assets/icons';
import InputBox from '../InputBox';
import EmailBoxInput from './EmailBoxInput';

export type EmailBoxProps = {
  open: boolean;
  onClose: () => void;
  value: EmailValues;
  handleSubmit: (value: FormikValues) => void;
};

const EmailBox = ({ value, open, onClose, handleSubmit }: EmailBoxProps) => {
  const [hideForm, setHideForm] = useState<boolean>(false);
  const isMobileView = useIsMobileView();
  const modalRef = useRef<HTMLDivElement | null>(null);
  const isClickOutsideModal = (event: MouseEvent) => {
    return modalRef.current && !modalRef.current.contains(event.target as Node);
  };
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (isClickOutsideModal(event)) {
        setHideForm(true);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  const submitBtn = useMemo(
    () => (
      <Button title="Save" displayType="primary" type="submit">
        Send
      </Button>
    ),
    []
  );
  useEffect(() => {
    if (!open) setHideForm(false);
  }, [open]);
  return (
    <>
      {open && (
        <div
          ref={modalRef}
          className={` ${
            isMobileView ? 'w-full h-screen overflow-y-auto' : 'fixed right-0 bottom-0 w-602'
          } flex flex-col items-center rounded-b-lg bg-white border-l border-gray-200 shadow-email-shadow rounded-t-lg z-10`}
        >
          {!isMobileView && (
            <div className="flex items-center justify-between px-6 gap-1.5 h-16 bg-gray-800 rounded-t-lg w-602">
              <div className="text-white text-lg font-semibold">New Email</div>
              <div className="flex gap-4">
                <Button title="remove" displayType="text" iconOnly type="button" onPress={() => setHideForm(!hideForm)}>
                  {hideForm ? (
                    <Icons.Plus className="w-5 h-5" aria-hidden="true" color="white" />
                  ) : (
                    <Icons.Minus className="w-5 h-5" aria-hidden="true" color="white" />
                  )}{' '}
                </Button>
                <Button title="close" displayType="text" iconOnly type="button" onPress={onClose}>
                  <Icons.Cross className="w-5 h-5 text-white" />
                </Button>
              </div>
            </div>
          )}

          {(!hideForm || isMobileView) && (
            <div className="w-full h-full flex flex-col justify-between gap-4">
              <Formik initialValues={value} onSubmit={handleSubmit}>
                {({ values, setFieldValue }) => (
                  <>
                    {isMobileView && <Drawer.Title title="New Email" onClose={onClose} submitBtn={submitBtn} />}

                    <Form className="px-4 sm:px-6 lg:px-8 w-full h-full flex flex-col justify-between gap-10 my-2">
                      <div>
                        <EmailBoxInput
                          value={values.to}
                          onChange={(value) => setFieldValue('to', value)}
                          label="To"
                          id="to"
                          type="email"
                        />

                        <EmailBoxInput
                          value={values.from}
                          onChange={(value) => setFieldValue('from', value)}
                          label="From"
                          id="from"
                          type="email"
                        />
                        <EmailBoxInput
                          value={values.subject}
                          onChange={(value) => setFieldValue('subject', value)}
                          label="Subject"
                          id="Subject"
                        />
                      </div>

                      <div className={`${!isMobileView && 'pb-20'} w-full email`}>
                        <div className="border-b border-b-gray-200">
                          <InputBox
                            displayType="email"
                            customClass={{ btnPosition: 'absolute -bottom-16 right-6 ' }}
                            showButton={!isMobileView}
                          />
                        </div>
                      </div>
                    </Form>
                  </>
                )}
              </Formik>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default EmailBox;
