import { isValidCreditCardNumber, isValidCreditCardExpiry, isValidCreditCardCVC } from '@portal/utils/payment';
import * as Yup from 'yup';
import { invoiceLineItemSchema } from './itemSchema';
import { customerSchema } from 'components/validation/customerSchmea';

export const creditCardSchema = Yup.object().shape({
  cardNumber: Yup.string().when('payment', {
    is: (value: string) => value === 'autocharge',
    then: Yup.string()
      .test('credit-card-number', 'Invalid credit card number', (value) => isValidCreditCardNumber(value))
      .required('Card number is required'),
  }),
  cardExpiry: Yup.string().when('payment', {
    is: (value: string) => value === 'autocharge',
    then: Yup.string()
      .test('credit-card-expiry', 'Invalid expiry date', (value) => isValidCreditCardExpiry(value))
      .required('Expiry is required'),
  }),
  cardCVC: Yup.string().when('payment', {
    is: (value: string) => value === 'autocharge',
    then: Yup.string()
      .test('credit-card-cvc', 'Invalid CVC', (value) => isValidCreditCardCVC(value))
      .required('CVC is required'),
  }),
});

export const invoiceSchema = Yup.object().shape({
  customer: customerSchema,
  addItem: Yup.bool(),
  addNewProduct: Yup.bool(),
  items: Yup.array().of(invoiceLineItemSchema).label('Items').required().min(1, 'Items selection is required'),
  payment: Yup.string().required('Payment selection is required'),
  delivery: Yup.string().required('Delivery selection is required'),
  subject: Yup.string(),
  memo: Yup.string().max(500, 'Memo is limited to 500 characters'),
  paymentDuration: Yup.string().when(['payment'], {
    is: (payment: string) => payment === 'REQUEST_PAYMENT',
    then: Yup.string(),
  }),
  card: Yup.object().when(['payment'], {
    is: (payment: string) => payment === 'AUTO',
    then: creditCardSchema,
  }),
  customDate: Yup.date().when(['paymentDuration'], {
    is: (paymentDuration: string) => paymentDuration === 'custom',
    then: Yup.date().required('Custom date is required'),
  }),
});
