import { useRef } from 'react';
import { DateSegment, useDateFieldState, DateFieldState } from 'react-stately';
import { useDateField, useDateSegment, useLocale, AriaDatePickerProps, DateValue } from 'react-aria';
import { createCalendar } from '@internationalized/date';
import { getMonthName } from '../../utils/helper';

interface DataSegmentProps {
  segment: DateSegment;
  state: DateFieldState;
}

export function DateField(props: AriaDatePickerProps<DateValue>) {
  const { locale } = useLocale();
  const state = useDateFieldState({
    ...props,
    isDisabled: true,
    isReadOnly: true,
    locale,
    createCalendar,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { fieldProps } = useDateField(props, state, ref);
  const segments = getDateFieldSegments(state.segments);
  return (
    <div {...fieldProps} ref={ref} className="flex py-[2.5px]">
      {segments.map((segment, i) => (
        <DateSegmentComponent key={i} segment={segment} state={state} />
      ))}
    </div>
  );
}

function DateSegmentComponent({ segment, state }: DataSegmentProps) {
  const ref = useRef<HTMLDivElement>(null);
  const { segmentProps } = useDateSegment(segment, state, ref);
  return (
    <div
      {...segmentProps}
      ref={ref}
      style={{
        ...segmentProps.style,
        minWidth: segment.maxValue != null ? String(segment.maxValue).length + 'ch' : undefined,
      }}
      className={`box-content tabular-nums text-sm text-right outline-none rounded-sm focus:bg-steel-50  group ${
        !segment.isEditable ? 'text-steel-700' : 'text-steel-900'
      } ${segment.type === 'literal' ? '' : 'pl-0.5'}`}
    >
      <span
        aria-hidden="true"
        className="block w-full text-center text-steel-500 group-focus:text-white"
        style={{
          visibility: segment.isPlaceholder ? 'visible' : 'hidden',
          height: segment.isPlaceholder ? '' : 0,
          pointerEvents: 'none',
        }}
      >
        {segment.placeholder}
      </span>
      {segment.isPlaceholder ? '' : segment.text}
    </div>
  );
}

function getDateFieldSegments(segments: DateSegment[]): DateSegment[] {
  const daySegment = segments.find((segment) => segment.type === 'day');
  const monthSegment = segments.find((segment) => segment.type === 'month');
  const yearSegment = segments.find((segment) => segment.type === 'year');
  const separatorSegment = segments.find((segment) => segment.type === 'literal');

  if (!daySegment || !monthSegment || !yearSegment || !separatorSegment) {
    throw new Error('Invalid date field segments');
  }

  const formattedDaySegment = {
    ...daySegment,
    text: daySegment.text.padStart(2, '0'),
  };
  const formattedMonthSegment = {
    ...monthSegment,
    text: getMonthName(monthSegment?.value as number),
  };
  const formattedSeparatorSegment = { ...separatorSegment, text: ',' };

  return [formattedMonthSegment, formattedDaySegment, formattedSeparatorSegment, yearSegment];
}
