import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Avatar, BackButton, Button, IconButton, Icons, Spinner } from '@portal/ui';
import { getInitials } from '@portal/utils/string';
import { TabsView } from 'components/contactDetails/TabsView';
import { Details } from 'components/contactDetails/TabsView/Details';
import ContactInfo from 'types/ContactInfo';

import { PaymentMethod } from 'types/PaymentMethod';

export interface ContactDetailsProps {
  loading: boolean;
  contact: ContactInfo | undefined;
  onAvatarChange: (file: File | null) => void;
  updateContactLoading: boolean;
  updateStatus: (status: string, contact: ContactInfo) => void;
  propertyName?: string;
  onEdit: () => void;
  paymentMethods?: PaymentMethod[] | undefined;
  fetchPaymentsMethodLoading?: boolean;
}

type ProfileCardProps = Partial<ContactDetailsProps>;

const ProfileCard = ({ contact, onAvatarChange, propertyName, updateContactLoading }: ProfileCardProps) => {
  const isMobileView = useIsMobileView();

  return contact ? (
    <>
      <div className={`${!isMobileView ? 'flex justify-center relative' : '[&>*>svg]:top-1 [&>*>svg]:left-[3px]'}`}>
        <Avatar
          editable
          onAvatarChange={onAvatarChange}
          size={isMobileView ? 'base' : 'xl_large'}
          imageUrl={contact.avatar}
          initials={getInitials(contact.name !== '' ? contact?.name : contact?.email)}
          loading={propertyName === 'avatar' && updateContactLoading}
        />
      </div>

      <div className={`flex flex-col ${isMobileView ? ' items-start justify-start' : 'items-center  '}`}>
        <p className={`text-start font-semibold text-steel-900 ${isMobileView ? ' text-sm' : 'text-2xl'}`}>
          {contact.name}
        </p>
        <p className="text-sm text-steel-500">{contact.email}</p>
      </div>
    </>
  ) : (
    <></>
  );
};

export const ContactDetails = ({
  contact,
  loading,
  onAvatarChange,
  updateContactLoading,
  updateStatus,
  propertyName,
  onEdit,
  paymentMethods,
  fetchPaymentsMethodLoading,
}: ContactDetailsProps) => {
  const IconsArray = [
    { title: 'Email', icon: <Icons.Email /> },
    { title: 'SMS', icon: <Icons.Message /> },
    { title: 'Request', icon: <Icons.Dollar /> },
  ];
  const isMobileView = useIsMobileView();

  return (
    <div className={`flex flex-col ${isMobileView && 'w-full [&>div]:w-full'}`}>
      <div
        className={`bg-white flex flex-col gap-6 max-h-fit border-r border-steel-200 relative w-[360px] ${
          isMobileView ? 'pt-4 px-0' : 'sm:px-6 px-4 lg:px-8 pt-8 pb-6'
        }`}
      >
        {contact && (
          <>
            {isMobileView ? (
              <div className="px-4 flex justify-between sm:px-2">
                <div className="flex items-center justify-start gap-x-4">
                  <ProfileCard
                    contact={contact}
                    propertyName={propertyName}
                    updateContactLoading={updateContactLoading}
                    onAvatarChange={onAvatarChange}
                  />
                </div>
                <Button title="edit contact" onClick={onEdit} displayType="text" iconOnly>
                  <Icons.Pencil className="h-5 w-5 cursor-pointer stroke-steel-500 hover:stroke-steel-700" color="" />
                </Button>
              </div>
            ) : (
              <>
                <div className="flex justify-between">
                  <BackButton backTo="/contacts" title={'Contacts'} />
                  <Button title="edit contact" onClick={onEdit} displayType="text" iconOnly>
                    <Icons.Pencil className="h-5 w-5 cursor-pointer stroke-steel-500 hover:stroke-steel-700" color="" />
                  </Button>
                </div>
                <ProfileCard
                  contact={contact}
                  propertyName={propertyName}
                  updateContactLoading={updateContactLoading}
                  onAvatarChange={onAvatarChange}
                />
              </>
            )}
            {!isMobileView ? (
              <div className="grid grid-cols-3 mx-auto gap-5">
                {IconsArray.map((item, idx) => (
                  <div key={idx}>
                    <IconButton
                      title={item.title}
                      icon={item.icon}
                      customClass="hover:bg-steel-50 !border-steel-200 cursor-pointer !shadow-sm"
                    />
                  </div>
                ))}
              </div>
            ) : (
              <div className={`${isMobileView && 'bg-steel-50 w-full h-[1130px] py-0 '}`}>
                <TabsView
                  updateContactLoading={updateContactLoading}
                  contact={contact}
                  onAvatarChange={onAvatarChange}
                  updateStatus={updateStatus}
                  propertyName={propertyName}
                />
              </div>
            )}
            {!isMobileView && (
              <Details
                updateContactLoading={updateContactLoading}
                contact={contact}
                onAvatarChange={onAvatarChange}
                updateStatus={updateStatus}
                propertyName={propertyName}
              />
            )}
          </>
        )}
        {loading && <Spinner />}
      </div>
    </div>
  );
};
