import { bytesToSize, getLogoUrl } from '@portal/utils/misc';
import Icons from '../../assets/icons';
import Button from '../Button';

interface FilePreviewProps {
  file: File;
  index: number;
  handleRemove: (index: number) => void;
}

const FilePreview: React.FC<FilePreviewProps> = ({ file, handleRemove, index }) => {
  return (
    <div className="flex border gap-2 border-steel-300 py-2 px-3 rounded-md my-2">
      {['img/jpeg', 'image/png', 'image/jpg', 'image/svg'].includes(file.type) ? (
        <img src={getLogoUrl(file)} className="w-9 h-9 rounded-md" />
      ) : (
        <Icons.File className="w-9 h-9 rounded-md" />
      )}
      <div className="flex flex-col text-steel-700 text-sm flex-grow">
        <p className="font-medium">{file.name}</p>
        <p className="text-steel-600">{bytesToSize(file.size)}</p>
      </div>
      <Button title="remove file" displayType="text" iconOnly onClick={() => handleRemove(index)}>
        <Icons.Trash />
      </Button>
    </div>
  );
};

export default FilePreview;
