import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import { toast } from 'react-hot-toast';
import Button from '../../base/Button';

interface StripeFormProps {
  handleCancel?: () => void;
  handleSubmit: (paymentMethod: any) => void;
  showCancel?: boolean;
}

const StripeForm = ({ handleCancel, handleSubmit, showCancel = true }: StripeFormProps) => {
  const stripe = useStripe();
  const elements = useElements();
  const [saveCardLoader, setSaveCardLoader] = useState(false);

  const onSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
    setSaveCardLoader(true);
    const cardElement = elements?.getElement(CardElement);

    if (!stripe || !elements || !cardElement) {
      return;
    }
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });
    if (paymentMethod && !error) {
      handleSubmit(paymentMethod);
    }
    if (error) {
      toast.error(error.message ?? 'Something went wrong');
    }

    setSaveCardLoader(false);
  };
  return (
    <form>
      <CardElement className="my-3 appearance-none block w-full px-3 py-3 border rounded-md shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:!ring-black focus:!border-black" />
      <div className="my-2 flex gap-2 justify-end">
        {showCancel && (
          <Button onClick={handleCancel} displayType="secondary">
            Cancel
          </Button>
        )}

        <Button loading={saveCardLoader} onClick={onSubmit} displayType="primary">
          Add card
        </Button>
      </div>
    </form>
  );
};

export default StripeForm;
