import React, { useState, useCallback, useEffect, useRef, useMemo, ChangeEvent } from 'react';
import { Combobox, Transition } from '@headlessui/react';
import moment from 'moment-timezone';
import cx from 'classnames';
import { getTimeOptions } from '../../utils/helper';

const styles = {
  field:
    'text-center appearance-none block w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-black focus:border-black',
  menu: 'absolute mt-2 w-full rounded-md shadow-lg bg-white overflow-auto max-h-56 z-10 border border-gray-200',
  option:
    'text-gray-700 font-medium cursor-default select-none relative py-2 px-4 text-sm hover:bg-gray-50 focus:bg-gray-500',
};

type Styling = {
  field?: string;
  menu?: string;
  option?: string;
};

interface TimeInputProps {
  value: string;
  customStyling?: Styling;
  placeholder?: string;
  onChange: (value: string) => void;
  disabled?: boolean;
}

const TimeInput: React.FC<TimeInputProps> = ({ value, customStyling, placeholder, onChange, disabled = false }) => {
  const [isOpen, setIsOpen] = useState(false);
  const comboboxRef = useRef<HTMLDivElement>(null);
  const timeOptions = useMemo(() => getTimeOptions(), []);

  const toggleDropdown = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  const clickOutside = useCallback(
    (event: MouseEvent) => {
      if (comboboxRef.current && !comboboxRef.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    },
    [comboboxRef]
  );

  useEffect(() => {
    document.addEventListener('mousedown', clickOutside);

    return () => {
      document.removeEventListener('mousedown', clickOutside);
    };
  }, [clickOutside]);

  const validateAndChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const input = event.target.value;
      if (moment(input, ['HH:mm', 'hh:mm A']).isValid()) {
        return onChange(moment(input, ['HH:mm', 'hh:mm A']).format('HH:mm'));
      }
      return onChange(input);
    },
    [onChange]
  );

  const formatValue = useCallback((value: string) => {
    if (value === '24:00') {
      return '24 hours';
    } else if (moment(value, ['HH:mm']).isValid()) {
      return moment(value, ['HH:mm']).format('hh:mm A');
    }
    return value;
  }, []);

  return (
    <div ref={comboboxRef}>
      <Combobox value={formatValue(value)} onChange={onChange} disabled={disabled}>
        {({ disabled }) => (
          <>
            <Combobox.Input
              className={cx(customStyling?.field ? customStyling?.field : styles.field, {
                'bg-gray-100': disabled,
              })}
              placeholder={placeholder || 'Select Time'}
              onClick={toggleDropdown}
              onChange={(event) => validateAndChange(event)}
              onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  event.stopPropagation();
                }
              }}
            />
            <Transition
              show={isOpen}
              enter="transition duration-100 ease-out"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition duration-75 ease-out"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Transition.Child
                as={React.Fragment}
                enter="transition ease-out duration-100"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="transition ease-in duration-75"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className={cx(customStyling?.menu ? customStyling?.menu : styles.menu)}>
                  <Combobox.Options static className="py-1" style={{ maxHeight: 'inherit' }}>
                    <Combobox.Option
                      key="24:00"
                      value="24:00"
                      onClick={toggleDropdown}
                      className={cx(
                        'border-b border-gray-200',
                        customStyling?.option ? customStyling?.option : styles.option
                      )}
                    >
                      24 hours
                    </Combobox.Option>
                    {timeOptions.map((time) => (
                      <Combobox.Option
                        key={time}
                        value={time}
                        className={cx(customStyling?.option ? customStyling?.option : styles.option)}
                        onClick={toggleDropdown}
                      >
                        {moment(time, 'HH:mm').format('hh:mm A')}
                      </Combobox.Option>
                    ))}
                  </Combobox.Options>
                </div>
              </Transition.Child>
            </Transition>
          </>
        )}
      </Combobox>
    </div>
  );
};

export default TimeInput;
