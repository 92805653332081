import React, { useState, useCallback, FC, useEffect } from 'react';
import { Button } from '@portal/ui';
import Icons from '../../assets/icons';
import classNames from 'classnames';

interface ModalBaseProps {
  isOpen: boolean;
  onToggle: () => void;
  footerContent?: React.ReactNode;
  children: React.ReactNode;
  title: string;
  onSave: () => void;
  loading?: boolean;
}

const ModalBase: FC<ModalBaseProps> = ({
  title,
  isOpen,
  onToggle,
  children,
  footerContent,
  onSave,
  loading = false,
}) => {
  const [minimize, setMinimize] = useState(false);

  const handleMinimizeToggle = useCallback(() => {
    setMinimize(!minimize);
  }, [minimize]);

  const handleClose = useCallback(() => {
    onToggle();
    setMinimize(true);
  }, [onToggle]);

  useEffect(() => {
    if (!isOpen) setMinimize(false);
  }, [isOpen]);

  return (
    <>
      {isOpen && (
        <div
          className={classNames('fixed z-50 bottom-0 right-20', {
            'w-72': minimize,
            'max-w-xl  w-full height-full': !minimize,
          })}
        >
          <div className="flex flex-col w-full shadow-md rounded-t-lg overflow-hidden transition-all transform bg-white">
            <div
              className={classNames('flex justify-between items-center bg-gray-800', {
                'py-2 px-3': minimize,
                'py-3.5 px-6': !minimize,
              })}
            >
              <span
                className={classNames('appointment-form text-white font-semibold', {
                  'truncate text-md': minimize,
                  'text-lg': !minimize,
                })}
              >
                {title}
              </span>
              <div className="flex">
                <button
                  type="button"
                  className="appointment-form p-1 mr-2 rounded-md focus:outline-none focus:ring-1 focus:ring-white"
                  onClick={() => handleMinimizeToggle()}
                >
                  {minimize ? (
                    <Icons.Plus className="w-5 h-5" aria-hidden="true" color="white" />
                  ) : (
                    <Icons.Minus className="w-5 h-5" aria-hidden="true" color="white" />
                  )}
                </button>
                <button
                  type="button"
                  className="p-1 rounded-md focus:outline-none focus:ring-1 focus:ring-white"
                  onClick={handleClose}
                >
                  <Icons.Cross className="w-5 h-5" aria-hidden="true" color="white" />
                </button>
              </div>
            </div>

            <div className={`${!minimize ? 'visible' : 'hidden'}`}>
              {children}
              <div
                className={classNames('appointment-form flex items-center px-6 py-4 border-t border-gray-200', {
                  'justify-between': footerContent,
                  'justify-end': !footerContent,
                })}
              >
                {footerContent}
                <Button loading={loading} title="save button" displayType="primary" type="submit" onPress={onSave}>
                  Save
                </Button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ModalBase;
