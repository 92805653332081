import FullCalendar from '@fullcalendar/react';
import { Button, Dropdown, Icons } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { DateFormats } from '@portal/utils/dates';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarDisplayModes, CalendarLabel, CalendarNavigation, Direction } from 'types/Calendar';
import { getFormattedDate } from 'utils/dates';

type Props = {
  calendar: FullCalendar | null;
  showBy: string;
  setShowBy: (value: CalendarDisplayModes) => void;
  bookingSlug: string;
  calendarEventsLoading: boolean;
};

export const CalendarHeader = ({ calendar, showBy, setShowBy, bookingSlug, calendarEventsLoading = false }: Props) => {
  const [currentDate, setCurrentDate] = useState<Date | null>(null);
  const calendarApi = calendar?.getApi();
  const navigate = useNavigate();

  const handleDateChange = useCallback(
    (direction: Direction): void => {
      if (calendarApi) {
        if (direction === CalendarNavigation.PREV) {
          calendarApi.prev();
        } else if (direction === CalendarNavigation.NEXT) {
          calendarApi.next();
        } else {
          calendarApi.today();
        }
        setCurrentDate(calendarApi?.getDate());
      }
    },
    [calendarApi]
  );

  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(`https://calendar.growthware.com/${bookingSlug}`);
    } catch {
      const el = document.createElement('textarea');
      el.value = `https://calendar.growthware.com/${bookingSlug}`;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    }
    toast.success('Link copied to clipboard');
  };

  useEffect(() => {
    if (calendarApi) {
      setCurrentDate(calendarApi?.getDate());
      calendarApi.changeView(showBy);
    }
  }, [calendarApi, showBy]);

  return (
    <div className="flex justify-between">
      <div className="flex items-center gap-2">
        <Button
          title="calendar go back"
          onPress={() => handleDateChange(CalendarNavigation.PREV)}
          displayType="text"
          iconOnly
        >
          <Icons.ChevronLeft />
        </Button>
        <label className="text-s-900 text-lg font-semibold mx-4">
          {getFormattedDate(currentDate, DateFormats.MONTH_NAME_WITH_YEAR)}
        </label>
        <Button
          title="calendar go forward"
          onPress={() => handleDateChange(CalendarNavigation.NEXT)}
          displayType="text"
          iconOnly
        >
          <Icons.ChevronRight />
        </Button>
        <Button
          title="calendar go to today"
          displayType="secondary"
          onPress={() => handleDateChange(CalendarNavigation.TODAY)}
        >
          Today
        </Button>
        {calendarEventsLoading && <Icons.Spinner className="!mx-4" />}
      </div>
      <div className="flex items-center gap-2">
        <Dropdown
          heading={showBy === CalendarDisplayModes.TIME_GRID_WEEK ? CalendarLabel.WEEK : CalendarLabel.MONTH}
          icon={<Icons.ChevronDown aria-hidden="true" className="mr-1" />}
          items={[
            { name: 'week', active: true, action: () => setShowBy(CalendarDisplayModes.TIME_GRID_WEEK) },
            { name: 'month', active: false, action: () => setShowBy(CalendarDisplayModes.DAY_GRID_MONTH) },
          ]}
          classNames="!bg-white !hover:bg-steel-100 !text-steel-700"
        />
        <Dropdown
          icon={<Icons.DotsHorizontal aria-hidden="true" width={30} height={30} />}
          items={[
            { name: 'Edit calendar', active: true, action: () => navigate('/settings/calendar') },
            { name: 'Edit event types', active: false, action: () => navigate('/settings/booking') },
            { name: 'Copy calendar link', active: false, action: () => copyToClipboard() },
          ]}
          classNames="!bg-white !hover:bg-steel-100 !text-steel-700"
        />
      </div>
    </div>
  );
};
