import { TextField } from '@portal/ui';
import logo from 'assets/growthware_logo.png';
import { loginSchema } from 'components/validation/loginSchema';
import { Form, Formik } from 'formik';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/hooks';
import { signInWithEmail } from 'redux/thunks/user';
import { NewUserSignIn } from 'types/User';

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const errorPresent = error && error !== '';

  const status = useSelector((state) => state.user.session.status);

  const signInClicked = async (username: string, password: string) => {
    try {
      const response = (await dispatch(signInWithEmail(username, password))) as NewUserSignIn;
      if (response?.newPasswordRequired) {
        navigate(`/onboarding?e=${username}&p=${password}`);
      } else navigate('/contacts');
    } catch (err: any) {
      if (err.code === 'UserNotConfirmedException') {
        navigate('verify');
      } else {
        setError(err.message);
      }
    }
  };

  useEffect(() => {
    if (status === 'signed-in') {
      navigate('/');
    }
  }, [status]);

  return (
    <div className="flex min-h-full flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img className="mx-auto h-12 w-auto" src={logo} alt="Your Company" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-steel-900">Log in to your account</h2>
        <p className="mt-2 text-center text-sm text-steel-600">Welcome back! Please enter your details.</p>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {errorPresent && (
            <div className="border-red-400 bg-red-50 p-4 my-4">
              <div className="flex">
                <div className="ml-3">
                  <p className="text-sm text-red-700">{error}</p>
                </div>
              </div>
            </div>
          )}
          <Formik
            initialValues={{
              email: '',
              password: '',
            }}
            validationSchema={loginSchema}
            onSubmit={(values) => {
              signInClicked(values.email, values.password);
            }}
          >
            <Form className="space-y-6">
              <TextField id="email" label="Email" type="email" />
              <TextField id="password" label="Password" type="password" />
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 rounded border-steel-300 bg-primary-background-200 text-purple-600 focus:ring-purple-500"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-steel-900">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-purple-600 hover:text-purple-500">
                    Forgot password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  title="log in"
                  className="cursor-pointer inline-flex items-center px-4 py-2 border text-sm font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 gap-1 hover:bg-primary-600 bg-primary primary-btn border-primary-600 hover:border-primary-700 focus:ring-primary-500 text-white w-full justify-center border-transparent"
                  type="submit"
                >
                  Log in
                </button>
              </div>
            </Form>
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;
