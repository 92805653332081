import { Formik, Spinner } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { invoiceSchema } from 'components/validation/invoiceSchema';
import { Form, FormikProps, FormikValues } from 'formik';
import { useCreateInvoiceMutation, useUpdateInvoiceMutation } from 'graphql/mutation.generated';
import { FetchAllInvoicesDocument } from 'graphql/query.generated';
import { useEffect, useMemo } from 'react';
import { CustomerType } from 'types/Customer';
import { Card } from 'types/Payment';
import InvoiceForm from './invoice_form/InvoiceForm';

export interface FormData {
  customer: CustomerType | null;
  items: ItemType[];
  payment: string;
  delivery: string;
  subject: string;
  memo: string;
  paymentDuration?: string;
  addItem?: boolean;
  addNewProduct?: boolean;
  card: Card;
  customDate?: Date;
}
export interface InvoiceProps {
  onClose: () => void;
  invoice?: any;
  edit?: boolean;
  loading?: boolean;
  formRef?: React.RefObject<FormikProps<FormikValues>>;
  handleLoading?: (value: boolean) => void;
  hidePreview: boolean;
  handleChange: (value: FormikValues) => void;
}
export interface ItemType {
  label: string;
  value: string;
  price?: number;
  quantity?: number;
  id?: number;
  index?: number;
}

const initialValues: FormData = {
  customer: null,
  items: [],
  payment: '',
  delivery: 'true',
  subject: '',
  memo: '',
  paymentDuration: '30',
  addItem: false,
  addNewProduct: false,
  customDate: new Date(),
  card: {
    number: '',
    expiry: '',
    CVC: '',
  },
};
const Invoice = ({
  onClose,
  invoice,
  edit,
  loading,
  formRef,
  handleLoading,
  hidePreview,
  handleChange,
}: InvoiceProps) => {
  const [createInvoice, { loading: loadingCreateInvoice }] = useCreateInvoiceMutation({
    onCompleted: () => {
      toast.success('Invoice created successfully');
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message ?? 'Invoice creation failed');
    },
    refetchQueries: [FetchAllInvoicesDocument],
  });
  const [updateInvoice, { loading: loadingUpdateInvoice }] = useUpdateInvoiceMutation({
    onCompleted: () => {
      toast.success('Invoice updated successfully');
      onClose();
    },
    onError: (err) => {
      toast.error(err?.message ?? 'Invoice creation failed');
      console.log(err);
    },
    refetchQueries: [FetchAllInvoicesDocument],
  });

  useEffect(() => {
    if (handleLoading) handleLoading(loadingCreateInvoice || loadingUpdateInvoice);
  }, [loadingCreateInvoice, loadingUpdateInvoice, handleLoading]);

  const selectedInvoice = useMemo(() => {
    return invoice
      ? {
          ...invoice,
          memo: invoice?.notesBody,
          subject: invoice?.notesSubject,
          items: invoice?.lineItems.map((item: any) => {
            return {
              ...item,
              label: item.title,
              value: item.price,
              id: item.productId,
            };
          }),
          payment: invoice?.paymentMode,
        }
      : null;
  }, [invoice]);

  const onSubmit = (values: FormikValues) => {
    if (edit) {
      updateInvoice({
        variables: {
          data: {
            invoiceId: selectedInvoice?.id as string,
            contactId: values.customer.id as string,
            notesBody: values.memo,
            notesSubject: values.subject,
            paymentMode: values.payment,
            lineItems: values.items.map((item: any, idx: number) => ({
              productId: item.id,
              price: item.price,
              quantity: item.quantity ?? 1,
              title: item.label,
              sortOrder: idx + 1,
            })),
            status: 'DRAFT',
          },
        },
      });
    } else
      createInvoice({
        variables: {
          data: {
            contactId: values.customer.id as string,
            notesBody: values.memo,
            notesSubject: values.subject,
            paymentMode: values.payment,
            sendEmailToContact: values.delivery === 'true',
            lineItems: values.items.map((item: any, idx: number) => ({
              productId: item.id,
              price: item.price,
              quantity: item.quantity ?? 1,
              title: item.label,
              sortOrder: idx + 1,
            })),
            status: 'DRAFT',
          },
        },
      });
  };

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <Formik
          innerRef={formRef}
          initialValues={{
            ...initialValues,
            ...(selectedInvoice || {}),
          }}
          validationSchema={invoiceSchema}
          onSubmit={onSubmit}
          validate={(values) => handleChange(values)}
          validateOnChange={true}
        >
          <Form>
            <InvoiceForm hidePreview={hidePreview} />
          </Form>
        </Formik>
      )}
    </>
  );
};

export default Invoice;
