interface TagsViewProps {
  tags: string[];
}

const TagsView: React.FC<TagsViewProps> = ({ tags }) => {
  return (
    <ul className="flex gap-1 mt-1 flex-wrap">
      {tags &&
        tags.map((tag) => (
          <li key={tag} className="text-primary-700 text-sm whitespace-nowrap bg-primary-200 rounded-full px-2 py-0.5">
            {tag}
          </li>
        ))}
    </ul>
  );
};

export default TagsView;
