import { useRef } from 'react';
import { CalendarState, useCalendarState } from 'react-stately';
import {
  useCalendar,
  useLocale,
  useCalendarGrid,
  mergeProps,
  useCalendarCell,
  useFocusRing,
  CalendarProps,
  DateValue,
} from 'react-aria';
import { createCalendar, getDayOfWeek, CalendarDate, getWeeksInMonth } from '@internationalized/date';
import Button from '../../base/Button';
import Icons from '../../assets/icons';

export function Calendar(props: CalendarProps<DateValue>) {
  const { locale } = useLocale();
  const state = useCalendarState({
    ...props,
    locale,
    createCalendar,
  });

  const ref = useRef<HTMLDivElement>(null);
  const { calendarProps, prevButtonProps, nextButtonProps, title } = useCalendar(props, state);

  return (
    <div {...calendarProps} ref={ref} className="inline-block text-gray-800">
      <div className="flex items-center pb-4 gap-2">
        <Button customClass="border-0 shadow-none focus:!ring-0 focus:!ring-offset-0" {...prevButtonProps}>
          <Icons.ChevronLeft />
        </Button>
        <h2 className="flex-1 text-center font-semibold text-base">{title}</h2>

        <Button customClass="border-0 shadow-none focus:!ring-0 focus:!ring-offset-0" {...nextButtonProps}>
          <Icons.ChevronRight />
        </Button>
      </div>
      <CalendarGrid state={state} />
    </div>
  );
}

export function CalendarGrid({ state, ...props }: { state: CalendarState }) {
  const { locale } = useLocale();
  const { gridProps, headerProps, weekDays } = useCalendarGrid(props, state);

  // Get the number of weeks in the month so we can render the proper number of rows.
  const weeksInMonth = getWeeksInMonth(state.visibleRange.start, locale);
  return (
    <table {...gridProps} cellPadding="0" className="flex-1">
      <thead {...headerProps} className="text-gray-600">
        <tr>
          {weekDays.map((day, index) => (
            <th className="text-center font-medium text-sm" key={index}>
              {day}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {[...new Array(weeksInMonth).keys()].map((weekIndex) => (
          <tr key={weekIndex}>
            {state
              .getDatesInWeek(weekIndex)
              .map((date, i) => (date ? <CalendarCell key={i} state={state} date={date} /> : <td key={i} />))}
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export function CalendarCell({ state, date }: { state: CalendarState; date: CalendarDate }) {
  const ref = useRef<HTMLDivElement>(null);

  const { cellProps, buttonProps, isSelected, isOutsideVisibleRange, isDisabled, formattedDate, isInvalid } =
    useCalendarCell({ date }, state, ref);

  const { locale } = useLocale();
  const dayOfWeek = getDayOfWeek(date, locale);
  const isRoundedLeft = isSelected && (isSelected || dayOfWeek === 0 || date.day === 1);
  const isRoundedRight =
    isSelected && (isSelected || dayOfWeek === 6 || date.day === date.calendar.getDaysInMonth(date));

  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <td {...cellProps} className={`py-0.5 cursor-pointer relative ${isFocusVisible ? 'z-10' : 'z-0'}`}>
      <div
        {...mergeProps(buttonProps, focusProps)}
        ref={ref}
        hidden={isOutsideVisibleRange}
        className={`w-10 h-10 text-sm outline-none group ${isRoundedLeft ? 'rounded-l-full' : ''} ${
          isRoundedRight ? 'rounded-r-full' : ''
        } ${isSelected ? (isInvalid ? 'bg-red-300' : 'bg-gray-50') : ''} ${isDisabled ? 'disabled' : ''}`}
      >
        <div
          className={`w-full h-full rounded-full flex items-center justify-center ${
            isDisabled && !isInvalid ? 'text-gray-400' : ''
          } ${isFocusVisible ? 'ring-2 group-focus:z-2 ring-primary-600 ring-offset-2' : ''} ${
            isSelected
              ? isInvalid
                ? 'bg-red-600 text-white hover:bg-red-700'
                : 'bg-primary-600 text-white hover:bg-primary-600'
              : ''
          } ${isSelected && !isDisabled ? (isInvalid ? 'hover:bg-red-400' : 'hover:bg-gray-50') : ''} ${
            !isSelected && !isDisabled ? 'hover:bg-gray-50' : ''
          } cursor-pointer`}
        >
          {formattedDate}
        </div>
      </div>
    </td>
  );
}
