import { Button, Formik, Label, checkFieldErrors } from '@portal/ui';
import { userSchema } from 'components/validation/userSchema';
import { USER_ROLES } from 'constants/users';
import { FieldArray, Form } from 'formik';
import { User, UsersFormProps } from 'types/User';
import { UserFields } from './UserFields';

export const defaultInitialValues: User = {
  email: '',
  role: USER_ROLES.ADMIN,
};

export const UserForm = ({ onSubmit, onClose, loading }: UsersFormProps) => {
  const addAnotherUser = (push: (value: User) => void) => {
    push({
      email: '',
      role: USER_ROLES.ADMIN,
    });
  };

  return (
    <Formik
      initialValues={{
        invitees: [{ ...defaultInitialValues }],
      }}
      validationSchema={userSchema}
      onSubmit={onSubmit}
    >
      {({ values: { invitees }, setFieldValue, touched, errors }) => {
        return (
          <Form className="h-full flex flex-col">
            <div className="relative mt-5 flex-1">
              <div className="flex flex-col">
                <Label htmlFor="email" label="Email" />
                <FieldArray
                  name="invitees"
                  render={({ push, remove }) => (
                    <>
                      {invitees.map((invitee, index) => (
                        <UserFields
                          key={`${invitee.id}-${index}`}
                          role={invitee.role}
                          onChange={setFieldValue}
                          index={index}
                          remove={remove}
                          isInValidEmail={checkFieldErrors(
                            touched as Record<string, boolean>,
                            errors as Record<string, string | undefined>,
                            `invitees.${index}`,
                            'email'
                          )}
                        />
                      ))}
                    </>
                  )}
                />
              </div>
            </div>
            <div className="flex justify-end items-center mt-8 gap-4">
              <Button title="cancel" displayType="secondary" type="button" onClick={onClose}>
                Cancel
              </Button>
              <Button title="send" displayType="primary" type="submit" loading={loading}>
                Send
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

export default UserForm;
