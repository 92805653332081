// Create a tailwind time picker component
// It should be a dropdown component with a list of time options
// First option will be 24 hours format
// Other options increment in 30 minutes interval
// It should have proper aria attributes for accessibility
import classNames from 'classnames';
import moment from 'moment';
import { useState } from 'react';
import { DropdownIndicatorProps, components } from 'react-select';
import Select from 'react-select/creatable';
import { Icons } from '../..';
import { options } from './constants';

type Option = {
  value: string;
  label: string;
};

type TimePickerProps = {
  id: string;
  label?: string;
  defaultValue?: string;
  onChange: (value: Option) => void;
  isDisabled?: boolean;
  isRequired?: boolean;
  isLoading?: boolean;
  disable24Hours?: boolean;
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ChevronDown />
    </components.DropdownIndicator>
  );
};

export const TimePicker = ({
  id,
  defaultValue,
  label,
  onChange,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  disable24Hours = true,
  ...rest
}: TimePickerProps) => {
  const [optionsToDisplay, setOptionsToDisplay] = useState(
    disable24Hours ? options.filter((item) => item.value !== '24 hours') : options
  );

  return (
    <>
      {label && (
        <label htmlFor={id} className="block text-sm font-medium text-steel-700 mb-2 label ">
          {label}
        </label>
      )}
      <Select
        id={id}
        isLoading={isLoading}
        isDisabled={isDisabled}
        required={isRequired}
        value={optionsToDisplay.find((option) => option.value === defaultValue)}
        classNames={{
          valueContainer: () => 'text-sm p-1',
          input: () => `[&>input]:text-red !text-red`,
          placeholder: () => 'text-black text-sm',
          menuList: () => 'bg-white border m-1 rounded-md ',
          noOptionsMessage: () => 'text-black text-sm p-2',
          indicatorSeparator: () => 'hidden',
          control: () =>
            `border bg-white border-steel-300 focus:border-primary-500 hover:border-steel-300 shadow-sm !text-base sm:text-sm p-2 rounded-md ${
              isDisabled ? 'cursor-not-allowed !bg-steel-100 !text-steel-400 !border-steel-100' : ''
            }`,
          option: ({ isSelected }) =>
            classNames(
              'active:bg-gray-50 hover:text-black hover:bg-gray-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-black text-black !cursor-pointer',
              isSelected ? 'bg-gray-50' : 'bg-white'
            ),
          container: () => `${isDisabled ? 'cursor-not-allowed' : ''}`,
        }}
        unstyled
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: DropdownIndicator,
          LoadingIndicator: () => <Icons.Spinner />,
        }}
        closeMenuOnSelect
        options={optionsToDisplay}
        isClearable={false}
        isMulti={false}
        hideSelectedOptions
        menuPlacement="auto"
        onChange={(value) => onChange(value as Option)}
        formatCreateLabel={(value: string) => <>Select: &Prime;{value}&Prime;</>}
        onCreateOption={(inputValue) => {
          if (
            moment(inputValue, 'h:mm A', true).isValid() &&
            !optionsToDisplay.find((item) => item.value === inputValue)
          ) {
            setOptionsToDisplay([...optionsToDisplay, { value: inputValue, label: inputValue }]);
            onChange({ value: inputValue, label: inputValue });
            return inputValue;
          } else {
            return;
          }
        }}
        {...rest}
      />
    </>
  );
};
