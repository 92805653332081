import { Button, Icons, checkFieldErrors } from '@portal/ui';
import { FieldArray, useFormikContext } from 'formik';
import { useCallback, useId } from 'react';
import { AddNewCalendarFunction, ConnectedCalendar } from 'types/CalendarSettings';
import { CalendarFields } from './CalendarField';

export const ConnectCalendars = () => {
  const id = useId();
  const {
    values: { calendars },
    errors,
    touched,
  } = useFormikContext<{ calendars: ConnectedCalendar[] }>();

  const addNewCalendar: AddNewCalendarFunction = useCallback((push) => {
    const payload = { id, name: '', link: '' };
    push(payload);
  }, []);

  return (
    <>
      <div className="flex mb-6">
        <div className="w-3/12">
          <p className="text-sm font-medium text-steel-700">Connected calendars</p>
          <p className="text-sm font-normal text-steel-600">
            {`Choose calendars that you’d like to display on your main calendar page.`}
          </p>
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <FieldArray
            name="calendars"
            render={({ push, remove }) => (
              <>
                {calendars?.map((calendar, index) => {
                  const isInValidName = checkFieldErrors(
                    touched as Record<string, boolean>,
                    errors as Record<string, string | undefined>,
                    `calendars.${index}`,
                    'name'
                  );
                  const isInValidLink = checkFieldErrors(
                    touched as Record<string, boolean>,
                    errors as Record<string, string | undefined>,
                    `calendars.${index}`,
                    'link'
                  );
                  const hasError = isInValidName || isInValidLink || !calendar.name || !calendar.link;
                  return (
                    <CalendarFields
                      key={`calendar-${calendar.link}-${index}`}
                      name={calendar.name}
                      path={`calendars.${index}`}
                      hasError={hasError}
                      showDivider={index !== calendars.length - 1}
                      onRemove={() => remove(index)}
                    />
                  );
                })}
                <div className="mb-4">
                  <Button title="add calendar" displayType="secondary" onClick={() => addNewCalendar(push)}>
                    <Icons.Plus className="w-5 h-5" /> Add Calendar
                  </Button>
                </div>
              </>
            )}
          />
        </div>
      </div>
    </>
  );
};
