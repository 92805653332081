/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type MeQueryVariables = Types.Exact<{ [key: string]: never }>;

export type MeQuery = {
  __typename?: 'Query';
  me: {
    __typename?: 'MerchantAccountUserWithRelated';
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
    role: Types.MerchantAccountUserRole;
    status: Types.MerchantAccountUserStatus;
    onboardingStatus: Types.MerchantAccountUserOnboardingStatus;
    tags: Array<string>;
    tzName: string;
    team: Array<{
      __typename?: 'MerchantTeamMember';
      id: string;
      name: string;
      email: string;
      displayName: string;
      avatar?: string | null;
      status: Types.MerchantAccountUserStatus;
    }>;
    merchantAccount: {
      __typename?: 'MerchantAccount';
      id: string;
      name: string;
      logo?: string | null;
      address?: {
        __typename?: 'Address';
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        countryCode: string;
        pincode: string;
      } | null;
    };
  };
};

export type FindAllUsersQueryVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllUsersFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllUsersOrderBy>;
}>;

export type FindAllUsersQuery = {
  __typename?: 'Query';
  findAllUsers: {
    __typename?: 'FindAllUsersPaginatedResponse';
    users: Array<{
      __typename?: 'MerchantAccountUser';
      avatar?: string | null;
      cognitoUsername: string;
      createdAt: any;
      email: string;
      id: string;
      name: string;
      role: Types.MerchantAccountUserRole;
      status: Types.MerchantAccountUserStatus;
      tzName: string;
      updatedAt: any;
    }>;
  };
};

export type FindAllProductsQueryVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllProductsFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllProductsOrderBy>;
}>;

export type FindAllProductsQuery = {
  __typename?: 'Query';
  findAllProducts: {
    __typename?: 'FindAllProductsPaginatedResponse';
    count: number;
    products: Array<{
      __typename?: 'MerchantProduct';
      createdAt: any;
      id: string;
      name: string;
      updatedAt: any;
      price: number;
    }>;
  };
};

export type FindAllContactsQueryVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllContactsFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllContactsOrderBy>;
}>;

export type FindAllContactsQuery = {
  __typename?: 'Query';
  findAllContacts: {
    __typename?: 'FindAllContactsPaginatedResponse';
    count: number;
    contacts: Array<{
      __typename?: 'MerchantContactWithOwner';
      id: string;
      email: string;
      createdAt: any;
      status: Types.MerchantContactStatus;
      phone: string;
      name: string;
      avatar?: string | null;
      companyName: string;
      tags: Array<string>;
      owner: { __typename?: 'MerchantAccountUser'; id: string; name: string; email: string; avatar?: string | null };
      address?: {
        __typename?: 'Address';
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        countryCode: string;
        pincode: string;
      } | null;
    }>;
  };
};

export type FindContactByIdQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
}>;

export type FindContactByIdQuery = {
  __typename?: 'Query';
  findContactById: {
    __typename?: 'MerchantContact';
    id: string;
    email: string;
    createdAt: any;
    status: Types.MerchantContactStatus;
    phone: string;
    name: string;
    avatar?: string | null;
    companyName: string;
    tags: Array<string>;
    address?: {
      __typename?: 'Address';
      addressLine1: string;
      addressLine2: string;
      city: string;
      state: string;
      countryCode: string;
      pincode: string;
    } | null;
  };
};

export type FindAllMerchantUsersQueryVariables = Types.Exact<{
  findAllUsersMerchantAccountId2: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllUsersFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllUsersOrderBy>;
}>;

export type FindAllMerchantUsersQuery = {
  __typename?: 'Query';
  findAllUsers: {
    __typename?: 'FindAllUsersPaginatedResponse';
    count: number;
    users: Array<{
      __typename?: 'MerchantAccountUser';
      id: string;
      name: string;
      email: string;
      cognitoUsername: string;
      tzName: string;
      status: Types.MerchantAccountUserStatus;
      role: Types.MerchantAccountUserRole;
      avatar?: string | null;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type FindAllTagsQueryVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllTagsFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllUsersOrderBy>;
}>;

export type FindAllTagsQuery = {
  __typename?: 'Query';
  findAllTags: {
    __typename?: 'FindAllTagsPaginatedResponse';
    count: number;
    tags: Array<{
      __typename?: 'MerchantTagWithAssociatedCount';
      id: string;
      contactsCount: number;
      name: string;
      createdAt: any;
    }>;
  };
};

export type FetchBookingConfigurationQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchBookingConfigurationQuery = {
  __typename?: 'Query';
  bookingConfiguration?: {
    __typename?: 'BookingConfiguration';
    id: string;
    title: string;
    slug: string;
    welcomeMessage: string;
    tzName: string;
    createdAt: any;
    updatedAt: any;
    availability: Array<{
      __typename?: 'BookingConfigurationAvailability';
      id: string;
      day: Types.BookingConfigurationAvailabilityDay;
      enabled: boolean;
      startTime: string;
      endTime: string;
    }>;
    eventTypes: Array<{
      __typename?: 'EventType';
      id: string;
      name: string;
      description: string;
      slug: string;
      color: string;
      duration: number;
      dayAvailabilityInFuture: number;
      location?: string | null;
      questions: Array<{ __typename?: 'EventTypeQuestion'; id: string; text: string; required: boolean }>;
      owners: Array<{
        __typename?: 'MerchantAccountUser';
        id: string;
        name: string;
        email: string;
        avatar?: string | null;
      }>;
    }>;
  } | null;
};

export type FetchBookingSlugQueryVariables = Types.Exact<{ [key: string]: never }>;

export type FetchBookingSlugQuery = {
  __typename?: 'Query';
  bookingConfiguration?: { __typename?: 'BookingConfiguration'; slug: string } | null;
};

export type FetchAllInvoicesQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllInvoicesOrderBy>;
}>;

export type FetchAllInvoicesQuery = {
  __typename?: 'Query';
  findAllInvoicesByContactId: {
    __typename?: 'FindAllInvoicesPaginatedResponse';
    count: number;
    invoices: Array<{
      __typename?: 'MerchantContactInvoice';
      id: string;
      slug: string;
      status: Types.MerchantContactInvoiceStatus;
      paymentMode: Types.MerchantContactInvoicePaymentMode;
      notesBody: string;
      notesSubject: string;
      createdAt: any;
      updatedAt: any;
      contact: { __typename?: 'MerchantContact'; id: string; name: string; email: string };
      lineItems: Array<{
        __typename?: 'MerchantContactInvoiceLineItem';
        id: string;
        title: string;
        price: number;
        quantity: number;
      }>;
    }>;
  };
};

export type FetchAllPaymentsQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllPaymentsOrderBy>;
}>;

export type FetchAllPaymentsQuery = {
  __typename?: 'Query';
  findAllPaymentsByContactId: {
    __typename?: 'FindAllPaymentsPaginatedResponse';
    count: number;
    payments: Array<{
      __typename?: 'MerchantContactPayment';
      id: string;
      paidAt: any;
      createdAt: any;
      status: Types.MerchantContactPaymentStatus;
      amount: number;
      paymentMethodId?: string | null;
      reference?: string | null;
      contact: { __typename?: 'MerchantContact'; id: string; name: string; email: string };
    }>;
  };
};

export type FindInvoiceByIdQueryVariables = Types.Exact<{
  invoiceId: Types.Scalars['String'];
}>;

export type FindInvoiceByIdQuery = {
  __typename?: 'Query';
  findInvoiceById: {
    __typename?: 'MerchantContactInvoice';
    id: string;
    slug: string;
    status: Types.MerchantContactInvoiceStatus;
    paymentMode: Types.MerchantContactInvoicePaymentMode;
    notesBody: string;
    notesSubject: string;
    createdAt: any;
    updatedAt: any;
    contact: { __typename?: 'MerchantContact'; id: string; name: string; email: string };
    lineItems: Array<{
      __typename?: 'MerchantContactInvoiceLineItem';
      id: string;
      productId: string;
      title: string;
      price: number;
      quantity: number;
    }>;
  };
};

export type FindPaymentStatsByIdQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
}>;

export type FindPaymentStatsByIdQuery = {
  __typename?: 'Query';
  findPaymentStatsByContactId: {
    __typename?: 'MerchantContactPaymentStats';
    open: string;
    paid: string;
    overdue: string;
  };
};

export type FindAllPaymentsByMerchantIdQueryVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  filters?: Types.InputMaybe<Types.FindAllPaymentsFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllPaymentsOrderBy>;
}>;

export type FindAllPaymentsByMerchantIdQuery = {
  __typename?: 'Query';
  findAllPaymentsByMerchantId: {
    __typename?: 'FindAllPaymentsPaginatedResponse';
    count: number;
    payments: Array<{
      __typename?: 'MerchantContactPayment';
      id: string;
      paidAt: any;
      createdAt: any;
      status: Types.MerchantContactPaymentStatus;
      amount: number;
      paymentMethodId?: string | null;
      reference?: string | null;
      contact: { __typename?: 'MerchantContact'; id: string; name: string; email: string; avatar?: string | null };
      invoice?: {
        __typename?: 'MerchantContactInvoice';
        id: string;
        status: Types.MerchantContactInvoiceStatus;
      } | null;
    }>;
  };
};

export type FetchAllEventsQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.FindAllEventsFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllEventsOrderBy>;
}>;

export type FetchAllEventsQuery = {
  __typename?: 'Query';
  findAllEvents: {
    __typename?: 'FindAllEventsPaginatedResponse';
    count: number;
    events: Array<{
      __typename?: 'Event';
      id: string;
      title: string;
      description?: string | null;
      startTime: any;
      endTime: any;
      locationType: Types.EventLocationType;
      phone?: string | null;
      location?: string | null;
      sendReminderAt?: any | null;
      sendSMSReminder: boolean;
      sendEmailReminder: boolean;
      externalCalendarEventId?: string | null;
      guests: Array<string>;
      createdAt: any;
      updatedAt: any;
    }>;
  };
};

export type FindAllPaymentMethodsByContactIdQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllPaymentMethodsOrderBy>;
}>;

export type FindAllPaymentMethodsByContactIdQuery = {
  __typename?: 'Query';
  findAllPaymentMethodsByContactId: {
    __typename?: 'FindAllPaymentMethodsPaginatedResponse';
    count: number;
    paymentMethods: Array<{
      __typename?: 'MerchantContactPaymentMethod';
      id: string;
      last4Digits: string;
      type: Types.MerchantContactPaymentMethodType;
      cardType?: string | null;
      expiration?: string | null;
      externalId: string;
      isPrimary: boolean;
      createdAt: any;
      updatedAt: any;
      contact: { __typename?: 'MerchantContact'; id: string; name: string; avatar?: string | null; email: string };
    }>;
  };
};

export type FetchContactAppointmentsQueryVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllEventsOrderBy>;
}>;

export type FetchContactAppointmentsQuery = {
  __typename?: 'Query';
  fetchContactAppointments: {
    __typename?: 'FindAllEventsPaginatedResponse';
    count: number;
    events: Array<{
      __typename?: 'Event';
      id: string;
      title: string;
      description?: string | null;
      startTime: any;
      endTime: any;
      location?: string | null;
      guests: Array<string>;
      sendReminderAt?: any | null;
      phone?: string | null;
      sendSMSReminder: boolean;
      sendEmailReminder: boolean;
    }>;
  };
};

export type FetchEventByIdQueryVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type FetchEventByIdQuery = {
  __typename?: 'Query';
  findEventById: {
    __typename?: 'Event';
    id: string;
    title: string;
    description?: string | null;
    startTime: any;
    endTime: any;
    location?: string | null;
    guests: Array<string>;
  };
};

export type FindAllSitesQueryVariables = Types.Exact<{
  filters?: Types.InputMaybe<Types.FindAllSitesFilters>;
  pagination?: Types.InputMaybe<Types.Pagination>;
  orderBy?: Types.InputMaybe<Types.FindAllSitesOrderBy>;
}>;

export type FindAllSitesQuery = {
  __typename?: 'Query';
  findAllSites: {
    __typename?: 'FindAllSitesPaginatedResponse';
    count: number;
    sites: Array<{
      __typename?: 'MerchantSite';
      id: string;
      businessName: string;
      businessLogo?: string | null;
      businessPhone: string;
      businessAddress: string;
      businessEmail: string;
      businessCategory: string;
      languagesSpoken: Array<string>;
      areasServices: Array<string>;
      businessAbout: string;
      businessMission: string;
      businessFoundedYear: number;
      facebookSlug?: string | null;
      yelpSlug?: string | null;
      instagramSlug?: string | null;
      linkedinSlug?: string | null;
      status: Types.MerchantSiteStatus;
      domain?: string | null;
      calendarLink: string;
      paymentLink: string;
      siteTitle: string;
      sitePromo: string;
      siteNotes: string;
      siteFavicon?: string | null;
      hours: Array<{
        __typename?: 'MerchantSiteHour';
        id: string;
        day: Types.Weekday;
        enabled: boolean;
        startTime: string;
        endTime: string;
      }>;
      faqs: Array<{ __typename?: 'MerchantSiteHourFaq'; id: string; question: string; answer: string }>;
    }>;
  };
};

export type FindSiteByIdQueryVariables = Types.Exact<{
  siteId: Types.Scalars['String'];
}>;

export type FindSiteByIdQuery = {
  __typename?: 'Query';
  findSiteById: {
    __typename?: 'MerchantSite';
    businessEmail: string;
    businessAddress: string;
    businessAbout: string;
    businessFoundedYear: number;
    areasServices: Array<string>;
    businessCategory: string;
    businessLogo?: string | null;
    businessName: string;
    businessPhone: string;
    calendarLink: string;
    domain?: string | null;
    businessMission: string;
    facebookSlug?: string | null;
    id: string;
    instagramSlug?: string | null;
    languagesSpoken: Array<string>;
    linkedinSlug?: string | null;
    paymentLink: string;
    siteFavicon?: string | null;
    siteNotes: string;
    sitePromo: string;
    siteTitle: string;
    status: Types.MerchantSiteStatus;
    yelpSlug?: string | null;
    createdAt: any;
    updatedAt: any;
    faqs: Array<{ __typename?: 'MerchantSiteHourFaq'; answer: string; id: string; question: string }>;
    hours: Array<{
      __typename?: 'MerchantSiteHour';
      day: Types.Weekday;
      enabled: boolean;
      endTime: string;
      id: string;
      startTime: string;
    }>;
  };
};

export const MeDocument = gql`
  query Me {
    me {
      id
      name
      email
      avatar
      role
      status
      onboardingStatus
      team {
        id
        name
        email
        displayName
        avatar
        status
      }
      merchantAccount {
        id
        name
        logo
        address {
          addressLine1
          addressLine2
          city
          state
          countryCode
          pincode
        }
      }
      tags
      tzName
    }
  }
`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
}
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const FindAllUsersDocument = gql`
  query FindAllUsers(
    $merchantAccountId: String!
    $filters: FindAllUsersFilters
    $pagination: Pagination
    $orderBy: FindAllUsersOrderBy
  ) {
    findAllUsers(merchantAccountId: $merchantAccountId, filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      users {
        avatar
        cognitoUsername
        createdAt
        email
        id
        name
        role
        status
        tzName
        updatedAt
      }
    }
  }
`;

/**
 * __useFindAllUsersQuery__
 *
 * To run a query within a React component, call `useFindAllUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllUsersQuery({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllUsersQuery(
  baseOptions: Apollo.QueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, options);
}
export function useFindAllUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllUsersQuery, FindAllUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllUsersQuery, FindAllUsersQueryVariables>(FindAllUsersDocument, options);
}
export type FindAllUsersQueryHookResult = ReturnType<typeof useFindAllUsersQuery>;
export type FindAllUsersLazyQueryHookResult = ReturnType<typeof useFindAllUsersLazyQuery>;
export type FindAllUsersQueryResult = Apollo.QueryResult<FindAllUsersQuery, FindAllUsersQueryVariables>;
export const FindAllProductsDocument = gql`
  query FindAllProducts(
    $merchantAccountId: String!
    $filters: FindAllProductsFilters
    $pagination: Pagination
    $orderBy: FindAllProductsOrderBy
  ) {
    findAllProducts(
      merchantAccountId: $merchantAccountId
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      products {
        createdAt
        id
        name
        updatedAt
        price
      }
    }
  }
`;

/**
 * __useFindAllProductsQuery__
 *
 * To run a query within a React component, call `useFindAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllProductsQuery({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllProductsQuery(
  baseOptions: Apollo.QueryHookOptions<FindAllProductsQuery, FindAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllProductsQuery, FindAllProductsQueryVariables>(FindAllProductsDocument, options);
}
export function useFindAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllProductsQuery, FindAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllProductsQuery, FindAllProductsQueryVariables>(FindAllProductsDocument, options);
}
export type FindAllProductsQueryHookResult = ReturnType<typeof useFindAllProductsQuery>;
export type FindAllProductsLazyQueryHookResult = ReturnType<typeof useFindAllProductsLazyQuery>;
export type FindAllProductsQueryResult = Apollo.QueryResult<FindAllProductsQuery, FindAllProductsQueryVariables>;
export const FindAllContactsDocument = gql`
  query FindAllContacts(
    $merchantAccountId: String!
    $filters: FindAllContactsFilters
    $pagination: Pagination
    $orderBy: FindAllContactsOrderBy
  ) {
    findAllContacts(
      merchantAccountId: $merchantAccountId
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      contacts {
        id
        email
        createdAt
        status
        phone
        name
        avatar
        companyName
        owner {
          id
          name
          email
          avatar
        }
        phone
        address {
          addressLine1
          addressLine2
          city
          state
          countryCode
          pincode
        }
        tags
      }
      count
    }
  }
`;

/**
 * __useFindAllContactsQuery__
 *
 * To run a query within a React component, call `useFindAllContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllContactsQuery({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllContactsQuery(
  baseOptions: Apollo.QueryHookOptions<FindAllContactsQuery, FindAllContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllContactsQuery, FindAllContactsQueryVariables>(FindAllContactsDocument, options);
}
export function useFindAllContactsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllContactsQuery, FindAllContactsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllContactsQuery, FindAllContactsQueryVariables>(FindAllContactsDocument, options);
}
export type FindAllContactsQueryHookResult = ReturnType<typeof useFindAllContactsQuery>;
export type FindAllContactsLazyQueryHookResult = ReturnType<typeof useFindAllContactsLazyQuery>;
export type FindAllContactsQueryResult = Apollo.QueryResult<FindAllContactsQuery, FindAllContactsQueryVariables>;
export const FindContactByIdDocument = gql`
  query FindContactById($merchantContactId: String!) {
    findContactById(merchantContactId: $merchantContactId) {
      id
      email
      createdAt
      status
      phone
      name
      avatar
      companyName
      phone
      address {
        addressLine1
        addressLine2
        city
        state
        countryCode
        pincode
      }
      tags
    }
  }
`;

/**
 * __useFindContactByIdQuery__
 *
 * To run a query within a React component, call `useFindContactByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindContactByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindContactByIdQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *   },
 * });
 */
export function useFindContactByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindContactByIdQuery, FindContactByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindContactByIdQuery, FindContactByIdQueryVariables>(FindContactByIdDocument, options);
}
export function useFindContactByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindContactByIdQuery, FindContactByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindContactByIdQuery, FindContactByIdQueryVariables>(FindContactByIdDocument, options);
}
export type FindContactByIdQueryHookResult = ReturnType<typeof useFindContactByIdQuery>;
export type FindContactByIdLazyQueryHookResult = ReturnType<typeof useFindContactByIdLazyQuery>;
export type FindContactByIdQueryResult = Apollo.QueryResult<FindContactByIdQuery, FindContactByIdQueryVariables>;
export const FindAllMerchantUsersDocument = gql`
  query FindAllMerchantUsers(
    $findAllUsersMerchantAccountId2: String!
    $filters: FindAllUsersFilters
    $pagination: Pagination
    $orderBy: FindAllUsersOrderBy
  ) {
    findAllUsers(
      merchantAccountId: $findAllUsersMerchantAccountId2
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      users {
        id
        name
        email
        cognitoUsername
        tzName
        status
        role
        avatar
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useFindAllMerchantUsersQuery__
 *
 * To run a query within a React component, call `useFindAllMerchantUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllMerchantUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllMerchantUsersQuery({
 *   variables: {
 *      findAllUsersMerchantAccountId2: // value for 'findAllUsersMerchantAccountId2'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllMerchantUsersQuery(
  baseOptions: Apollo.QueryHookOptions<FindAllMerchantUsersQuery, FindAllMerchantUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllMerchantUsersQuery, FindAllMerchantUsersQueryVariables>(
    FindAllMerchantUsersDocument,
    options
  );
}
export function useFindAllMerchantUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllMerchantUsersQuery, FindAllMerchantUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllMerchantUsersQuery, FindAllMerchantUsersQueryVariables>(
    FindAllMerchantUsersDocument,
    options
  );
}
export type FindAllMerchantUsersQueryHookResult = ReturnType<typeof useFindAllMerchantUsersQuery>;
export type FindAllMerchantUsersLazyQueryHookResult = ReturnType<typeof useFindAllMerchantUsersLazyQuery>;
export type FindAllMerchantUsersQueryResult = Apollo.QueryResult<
  FindAllMerchantUsersQuery,
  FindAllMerchantUsersQueryVariables
>;
export const FindAllTagsDocument = gql`
  query FindAllTags(
    $merchantAccountId: String!
    $filters: FindAllTagsFilters
    $pagination: Pagination
    $orderBy: FindAllUsersOrderBy
  ) {
    findAllTags(merchantAccountId: $merchantAccountId, filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      tags {
        id
        contactsCount
        name
        createdAt
      }
    }
  }
`;

/**
 * __useFindAllTagsQuery__
 *
 * To run a query within a React component, call `useFindAllTagsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllTagsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllTagsQuery({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllTagsQuery(baseOptions: Apollo.QueryHookOptions<FindAllTagsQuery, FindAllTagsQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllTagsQuery, FindAllTagsQueryVariables>(FindAllTagsDocument, options);
}
export function useFindAllTagsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllTagsQuery, FindAllTagsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllTagsQuery, FindAllTagsQueryVariables>(FindAllTagsDocument, options);
}
export type FindAllTagsQueryHookResult = ReturnType<typeof useFindAllTagsQuery>;
export type FindAllTagsLazyQueryHookResult = ReturnType<typeof useFindAllTagsLazyQuery>;
export type FindAllTagsQueryResult = Apollo.QueryResult<FindAllTagsQuery, FindAllTagsQueryVariables>;
export const FetchBookingConfigurationDocument = gql`
  query FetchBookingConfiguration {
    bookingConfiguration {
      id
      title
      slug
      welcomeMessage
      tzName
      createdAt
      updatedAt
      availability {
        id
        day
        enabled
        startTime
        endTime
      }
      eventTypes {
        id
        name
        description
        slug
        color
        duration
        dayAvailabilityInFuture
        location
        questions {
          id
          text
          required
        }
        owners {
          id
          name
          email
          avatar
        }
      }
    }
  }
`;

/**
 * __useFetchBookingConfigurationQuery__
 *
 * To run a query within a React component, call `useFetchBookingConfigurationQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBookingConfigurationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBookingConfigurationQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBookingConfigurationQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchBookingConfigurationQuery, FetchBookingConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchBookingConfigurationQuery, FetchBookingConfigurationQueryVariables>(
    FetchBookingConfigurationDocument,
    options
  );
}
export function useFetchBookingConfigurationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchBookingConfigurationQuery, FetchBookingConfigurationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchBookingConfigurationQuery, FetchBookingConfigurationQueryVariables>(
    FetchBookingConfigurationDocument,
    options
  );
}
export type FetchBookingConfigurationQueryHookResult = ReturnType<typeof useFetchBookingConfigurationQuery>;
export type FetchBookingConfigurationLazyQueryHookResult = ReturnType<typeof useFetchBookingConfigurationLazyQuery>;
export type FetchBookingConfigurationQueryResult = Apollo.QueryResult<
  FetchBookingConfigurationQuery,
  FetchBookingConfigurationQueryVariables
>;
export const FetchBookingSlugDocument = gql`
  query FetchBookingSlug {
    bookingConfiguration {
      slug
    }
  }
`;

/**
 * __useFetchBookingSlugQuery__
 *
 * To run a query within a React component, call `useFetchBookingSlugQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchBookingSlugQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchBookingSlugQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchBookingSlugQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchBookingSlugQuery, FetchBookingSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchBookingSlugQuery, FetchBookingSlugQueryVariables>(FetchBookingSlugDocument, options);
}
export function useFetchBookingSlugLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchBookingSlugQuery, FetchBookingSlugQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchBookingSlugQuery, FetchBookingSlugQueryVariables>(FetchBookingSlugDocument, options);
}
export type FetchBookingSlugQueryHookResult = ReturnType<typeof useFetchBookingSlugQuery>;
export type FetchBookingSlugLazyQueryHookResult = ReturnType<typeof useFetchBookingSlugLazyQuery>;
export type FetchBookingSlugQueryResult = Apollo.QueryResult<FetchBookingSlugQuery, FetchBookingSlugQueryVariables>;
export const FetchAllInvoicesDocument = gql`
  query fetchAllInvoices($merchantContactId: String!, $pagination: Pagination, $orderBy: FindAllInvoicesOrderBy) {
    findAllInvoicesByContactId(merchantContactId: $merchantContactId, pagination: $pagination, orderBy: $orderBy) {
      count
      invoices {
        id
        slug
        status
        paymentMode
        notesBody
        notesSubject
        createdAt
        updatedAt
        contact {
          id
          name
          email
        }
        lineItems {
          id
          title
          price
          quantity
        }
      }
    }
  }
`;

/**
 * __useFetchAllInvoicesQuery__
 *
 * To run a query within a React component, call `useFetchAllInvoicesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllInvoicesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllInvoicesQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFetchAllInvoicesQuery(
  baseOptions: Apollo.QueryHookOptions<FetchAllInvoicesQuery, FetchAllInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllInvoicesQuery, FetchAllInvoicesQueryVariables>(FetchAllInvoicesDocument, options);
}
export function useFetchAllInvoicesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllInvoicesQuery, FetchAllInvoicesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllInvoicesQuery, FetchAllInvoicesQueryVariables>(FetchAllInvoicesDocument, options);
}
export type FetchAllInvoicesQueryHookResult = ReturnType<typeof useFetchAllInvoicesQuery>;
export type FetchAllInvoicesLazyQueryHookResult = ReturnType<typeof useFetchAllInvoicesLazyQuery>;
export type FetchAllInvoicesQueryResult = Apollo.QueryResult<FetchAllInvoicesQuery, FetchAllInvoicesQueryVariables>;
export const FetchAllPaymentsDocument = gql`
  query fetchAllPayments($merchantContactId: String!, $pagination: Pagination, $orderBy: FindAllPaymentsOrderBy) {
    findAllPaymentsByContactId(merchantContactId: $merchantContactId, pagination: $pagination, orderBy: $orderBy) {
      count
      payments {
        id
        paidAt
        createdAt
        status
        contact {
          id
          name
          email
        }
        amount
        paymentMethodId
        reference
      }
    }
  }
`;

/**
 * __useFetchAllPaymentsQuery__
 *
 * To run a query within a React component, call `useFetchAllPaymentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllPaymentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllPaymentsQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFetchAllPaymentsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchAllPaymentsQuery, FetchAllPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllPaymentsQuery, FetchAllPaymentsQueryVariables>(FetchAllPaymentsDocument, options);
}
export function useFetchAllPaymentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllPaymentsQuery, FetchAllPaymentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllPaymentsQuery, FetchAllPaymentsQueryVariables>(FetchAllPaymentsDocument, options);
}
export type FetchAllPaymentsQueryHookResult = ReturnType<typeof useFetchAllPaymentsQuery>;
export type FetchAllPaymentsLazyQueryHookResult = ReturnType<typeof useFetchAllPaymentsLazyQuery>;
export type FetchAllPaymentsQueryResult = Apollo.QueryResult<FetchAllPaymentsQuery, FetchAllPaymentsQueryVariables>;
export const FindInvoiceByIdDocument = gql`
  query FindInvoiceById($invoiceId: String!) {
    findInvoiceById(invoiceId: $invoiceId) {
      id
      slug
      status
      paymentMode
      notesBody
      notesSubject
      createdAt
      updatedAt
      contact {
        id
        name
        email
      }
      lineItems {
        id
        productId
        title
        price
        quantity
      }
    }
  }
`;

/**
 * __useFindInvoiceByIdQuery__
 *
 * To run a query within a React component, call `useFindInvoiceByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindInvoiceByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindInvoiceByIdQuery({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useFindInvoiceByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindInvoiceByIdQuery, FindInvoiceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindInvoiceByIdQuery, FindInvoiceByIdQueryVariables>(FindInvoiceByIdDocument, options);
}
export function useFindInvoiceByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindInvoiceByIdQuery, FindInvoiceByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindInvoiceByIdQuery, FindInvoiceByIdQueryVariables>(FindInvoiceByIdDocument, options);
}
export type FindInvoiceByIdQueryHookResult = ReturnType<typeof useFindInvoiceByIdQuery>;
export type FindInvoiceByIdLazyQueryHookResult = ReturnType<typeof useFindInvoiceByIdLazyQuery>;
export type FindInvoiceByIdQueryResult = Apollo.QueryResult<FindInvoiceByIdQuery, FindInvoiceByIdQueryVariables>;
export const FindPaymentStatsByIdDocument = gql`
  query FindPaymentStatsById($merchantContactId: String!) {
    findPaymentStatsByContactId(merchantContactId: $merchantContactId) {
      open
      paid
      overdue
    }
  }
`;

/**
 * __useFindPaymentStatsByIdQuery__
 *
 * To run a query within a React component, call `useFindPaymentStatsByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindPaymentStatsByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindPaymentStatsByIdQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *   },
 * });
 */
export function useFindPaymentStatsByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindPaymentStatsByIdQuery, FindPaymentStatsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindPaymentStatsByIdQuery, FindPaymentStatsByIdQueryVariables>(
    FindPaymentStatsByIdDocument,
    options
  );
}
export function useFindPaymentStatsByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindPaymentStatsByIdQuery, FindPaymentStatsByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindPaymentStatsByIdQuery, FindPaymentStatsByIdQueryVariables>(
    FindPaymentStatsByIdDocument,
    options
  );
}
export type FindPaymentStatsByIdQueryHookResult = ReturnType<typeof useFindPaymentStatsByIdQuery>;
export type FindPaymentStatsByIdLazyQueryHookResult = ReturnType<typeof useFindPaymentStatsByIdLazyQuery>;
export type FindPaymentStatsByIdQueryResult = Apollo.QueryResult<
  FindPaymentStatsByIdQuery,
  FindPaymentStatsByIdQueryVariables
>;
export const FindAllPaymentsByMerchantIdDocument = gql`
  query FindAllPaymentsByMerchantId(
    $merchantAccountId: String!
    $filters: FindAllPaymentsFilters
    $pagination: Pagination
    $orderBy: FindAllPaymentsOrderBy
  ) {
    findAllPaymentsByMerchantId(
      merchantAccountId: $merchantAccountId
      filters: $filters
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      payments {
        id
        paidAt
        createdAt
        status
        contact {
          id
          name
          email
          avatar
        }
        invoice {
          id
          status
        }
        amount
        paymentMethodId
        reference
      }
    }
  }
`;

/**
 * __useFindAllPaymentsByMerchantIdQuery__
 *
 * To run a query within a React component, call `useFindAllPaymentsByMerchantIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPaymentsByMerchantIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPaymentsByMerchantIdQuery({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllPaymentsByMerchantIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindAllPaymentsByMerchantIdQuery, FindAllPaymentsByMerchantIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllPaymentsByMerchantIdQuery, FindAllPaymentsByMerchantIdQueryVariables>(
    FindAllPaymentsByMerchantIdDocument,
    options
  );
}
export function useFindAllPaymentsByMerchantIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllPaymentsByMerchantIdQuery, FindAllPaymentsByMerchantIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllPaymentsByMerchantIdQuery, FindAllPaymentsByMerchantIdQueryVariables>(
    FindAllPaymentsByMerchantIdDocument,
    options
  );
}
export type FindAllPaymentsByMerchantIdQueryHookResult = ReturnType<typeof useFindAllPaymentsByMerchantIdQuery>;
export type FindAllPaymentsByMerchantIdLazyQueryHookResult = ReturnType<typeof useFindAllPaymentsByMerchantIdLazyQuery>;
export type FindAllPaymentsByMerchantIdQueryResult = Apollo.QueryResult<
  FindAllPaymentsByMerchantIdQuery,
  FindAllPaymentsByMerchantIdQueryVariables
>;
export const FetchAllEventsDocument = gql`
  query FetchAllEvents($filters: FindAllEventsFilters, $pagination: Pagination, $orderBy: FindAllEventsOrderBy) {
    findAllEvents(filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      events {
        id
        title
        description
        startTime
        endTime
        locationType
        phone
        location
        sendReminderAt
        sendSMSReminder
        sendEmailReminder
        externalCalendarEventId
        guests
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useFetchAllEventsQuery__
 *
 * To run a query within a React component, call `useFetchAllEventsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAllEventsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAllEventsQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFetchAllEventsQuery(
  baseOptions?: Apollo.QueryHookOptions<FetchAllEventsQuery, FetchAllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchAllEventsQuery, FetchAllEventsQueryVariables>(FetchAllEventsDocument, options);
}
export function useFetchAllEventsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchAllEventsQuery, FetchAllEventsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchAllEventsQuery, FetchAllEventsQueryVariables>(FetchAllEventsDocument, options);
}
export type FetchAllEventsQueryHookResult = ReturnType<typeof useFetchAllEventsQuery>;
export type FetchAllEventsLazyQueryHookResult = ReturnType<typeof useFetchAllEventsLazyQuery>;
export type FetchAllEventsQueryResult = Apollo.QueryResult<FetchAllEventsQuery, FetchAllEventsQueryVariables>;
export const FindAllPaymentMethodsByContactIdDocument = gql`
  query FindAllPaymentMethodsByContactId(
    $merchantContactId: String!
    $pagination: Pagination
    $orderBy: FindAllPaymentMethodsOrderBy
  ) {
    findAllPaymentMethodsByContactId(
      merchantContactId: $merchantContactId
      pagination: $pagination
      orderBy: $orderBy
    ) {
      count
      paymentMethods {
        id
        contact {
          id
          name
          avatar
          email
        }
        last4Digits
        type
        cardType
        expiration
        externalId
        isPrimary
        createdAt
        updatedAt
      }
    }
  }
`;

/**
 * __useFindAllPaymentMethodsByContactIdQuery__
 *
 * To run a query within a React component, call `useFindAllPaymentMethodsByContactIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllPaymentMethodsByContactIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllPaymentMethodsByContactIdQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllPaymentMethodsByContactIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    FindAllPaymentMethodsByContactIdQuery,
    FindAllPaymentMethodsByContactIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllPaymentMethodsByContactIdQuery, FindAllPaymentMethodsByContactIdQueryVariables>(
    FindAllPaymentMethodsByContactIdDocument,
    options
  );
}
export function useFindAllPaymentMethodsByContactIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    FindAllPaymentMethodsByContactIdQuery,
    FindAllPaymentMethodsByContactIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllPaymentMethodsByContactIdQuery, FindAllPaymentMethodsByContactIdQueryVariables>(
    FindAllPaymentMethodsByContactIdDocument,
    options
  );
}
export type FindAllPaymentMethodsByContactIdQueryHookResult = ReturnType<
  typeof useFindAllPaymentMethodsByContactIdQuery
>;
export type FindAllPaymentMethodsByContactIdLazyQueryHookResult = ReturnType<
  typeof useFindAllPaymentMethodsByContactIdLazyQuery
>;
export type FindAllPaymentMethodsByContactIdQueryResult = Apollo.QueryResult<
  FindAllPaymentMethodsByContactIdQuery,
  FindAllPaymentMethodsByContactIdQueryVariables
>;
export const FetchContactAppointmentsDocument = gql`
  query FetchContactAppointments($merchantContactId: String!, $pagination: Pagination, $orderBy: FindAllEventsOrderBy) {
    fetchContactAppointments(merchantContactId: $merchantContactId, pagination: $pagination, orderBy: $orderBy) {
      events {
        id
        title
        description
        startTime
        endTime
        location
        guests
        sendReminderAt
        phone
        sendSMSReminder
        sendEmailReminder
      }
      count
    }
  }
`;

/**
 * __useFetchContactAppointmentsQuery__
 *
 * To run a query within a React component, call `useFetchContactAppointmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchContactAppointmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchContactAppointmentsQuery({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFetchContactAppointmentsQuery(
  baseOptions: Apollo.QueryHookOptions<FetchContactAppointmentsQuery, FetchContactAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchContactAppointmentsQuery, FetchContactAppointmentsQueryVariables>(
    FetchContactAppointmentsDocument,
    options
  );
}
export function useFetchContactAppointmentsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchContactAppointmentsQuery, FetchContactAppointmentsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchContactAppointmentsQuery, FetchContactAppointmentsQueryVariables>(
    FetchContactAppointmentsDocument,
    options
  );
}
export type FetchContactAppointmentsQueryHookResult = ReturnType<typeof useFetchContactAppointmentsQuery>;
export type FetchContactAppointmentsLazyQueryHookResult = ReturnType<typeof useFetchContactAppointmentsLazyQuery>;
export type FetchContactAppointmentsQueryResult = Apollo.QueryResult<
  FetchContactAppointmentsQuery,
  FetchContactAppointmentsQueryVariables
>;
export const FetchEventByIdDocument = gql`
  query FetchEventById($id: String!) {
    findEventById(eventId: $id) {
      id
      title
      description
      startTime
      endTime
      location
      guests
    }
  }
`;

/**
 * __useFetchEventByIdQuery__
 *
 * To run a query within a React component, call `useFetchEventByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchEventByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchEventByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFetchEventByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FetchEventByIdQuery, FetchEventByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FetchEventByIdQuery, FetchEventByIdQueryVariables>(FetchEventByIdDocument, options);
}
export function useFetchEventByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FetchEventByIdQuery, FetchEventByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FetchEventByIdQuery, FetchEventByIdQueryVariables>(FetchEventByIdDocument, options);
}
export type FetchEventByIdQueryHookResult = ReturnType<typeof useFetchEventByIdQuery>;
export type FetchEventByIdLazyQueryHookResult = ReturnType<typeof useFetchEventByIdLazyQuery>;
export type FetchEventByIdQueryResult = Apollo.QueryResult<FetchEventByIdQuery, FetchEventByIdQueryVariables>;
export const FindAllSitesDocument = gql`
  query FindAllSites($filters: FindAllSitesFilters, $pagination: Pagination, $orderBy: FindAllSitesOrderBy) {
    findAllSites(filters: $filters, pagination: $pagination, orderBy: $orderBy) {
      count
      sites {
        id
        businessName
        businessLogo
        businessPhone
        businessAddress
        businessEmail
        businessCategory
        languagesSpoken
        areasServices
        businessAbout
        businessMission
        businessFoundedYear
        facebookSlug
        yelpSlug
        instagramSlug
        linkedinSlug
        hours {
          id
          day
          enabled
          startTime
          endTime
        }
        status
        domain
        calendarLink
        paymentLink
        siteTitle
        sitePromo
        siteNotes
        siteFavicon
        faqs {
          id
          question
          answer
        }
      }
    }
  }
`;

/**
 * __useFindAllSitesQuery__
 *
 * To run a query within a React component, call `useFindAllSitesQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindAllSitesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindAllSitesQuery({
 *   variables: {
 *      filters: // value for 'filters'
 *      pagination: // value for 'pagination'
 *      orderBy: // value for 'orderBy'
 *   },
 * });
 */
export function useFindAllSitesQuery(
  baseOptions?: Apollo.QueryHookOptions<FindAllSitesQuery, FindAllSitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindAllSitesQuery, FindAllSitesQueryVariables>(FindAllSitesDocument, options);
}
export function useFindAllSitesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindAllSitesQuery, FindAllSitesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindAllSitesQuery, FindAllSitesQueryVariables>(FindAllSitesDocument, options);
}
export type FindAllSitesQueryHookResult = ReturnType<typeof useFindAllSitesQuery>;
export type FindAllSitesLazyQueryHookResult = ReturnType<typeof useFindAllSitesLazyQuery>;
export type FindAllSitesQueryResult = Apollo.QueryResult<FindAllSitesQuery, FindAllSitesQueryVariables>;
export const FindSiteByIdDocument = gql`
  query FindSiteById($siteId: String!) {
    findSiteById(siteId: $siteId) {
      businessEmail
      businessAddress
      businessAbout
      businessFoundedYear
      areasServices
      businessCategory
      businessLogo
      businessName
      businessPhone
      calendarLink
      domain
      businessMission
      facebookSlug
      faqs {
        answer
        id
        question
      }
      id
      hours {
        day
        enabled
        endTime
        id
        startTime
      }
      instagramSlug
      languagesSpoken
      linkedinSlug
      paymentLink
      siteFavicon
      siteNotes
      sitePromo
      siteTitle
      status
      yelpSlug
      createdAt
      updatedAt
    }
  }
`;

/**
 * __useFindSiteByIdQuery__
 *
 * To run a query within a React component, call `useFindSiteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useFindSiteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFindSiteByIdQuery({
 *   variables: {
 *      siteId: // value for 'siteId'
 *   },
 * });
 */
export function useFindSiteByIdQuery(
  baseOptions: Apollo.QueryHookOptions<FindSiteByIdQuery, FindSiteByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<FindSiteByIdQuery, FindSiteByIdQueryVariables>(FindSiteByIdDocument, options);
}
export function useFindSiteByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<FindSiteByIdQuery, FindSiteByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<FindSiteByIdQuery, FindSiteByIdQueryVariables>(FindSiteByIdDocument, options);
}
export type FindSiteByIdQueryHookResult = ReturnType<typeof useFindSiteByIdQuery>;
export type FindSiteByIdLazyQueryHookResult = ReturnType<typeof useFindSiteByIdLazyQuery>;
export type FindSiteByIdQueryResult = Apollo.QueryResult<FindSiteByIdQuery, FindSiteByIdQueryVariables>;
