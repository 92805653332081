import { Avatar, Button, FileUpload, Formik, HorizontalLabel, SearchableInput, SubHeading } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { FormDataState, getFormData } from '@portal/utils/forms';
import { getLogoUrl } from '@portal/utils/misc';
import { getInitials } from '@portal/utils/string';
import FormField from 'components/formField';
import { Form, FormikValues } from 'formik';
import { useRequestMerchantAvatarUpdateMutation, useUpdateMerchantMutation } from 'graphql/mutation.generated';
import { find } from 'lodash';
import { useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'redux/hooks';
import { fetchUserAttributes } from 'redux/thunks/user';
import states from 'states-us';

type FormState = {
  name: string;
  logo: string;
  textingNumber?: string;
  addressLine1?: string;
  addressLine2?: string;
  state?: string;
  city: string;
  pincode?: string;
  countryCode?: string;
};

const initialValues: FormState = {
  name: '',
  logo: '',
  textingNumber: '',
  addressLine1: '',
  addressLine2: '',
  state: '',
  city: '',
  pincode: '',
  countryCode: 'US',
};

export const GeneralSettings = () => {
  const merchantAccount = useSelector((state) => state.user.attributes?.merchantAccount);
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [requestMerchantLogoUpdateMutation] = useRequestMerchantAvatarUpdateMutation();

  const [updateMerchantUserMutation] = useUpdateMerchantMutation({
    onCompleted: () => {
      toast.success('Profile updated');
      dispatch(fetchUserAttributes());
    },
    onError: (error) => {
      console.error(error);
      toast.error(error.message);
    },
  });

  const requestAvatarUpdate = (values: FormikValues) => {
    requestMerchantLogoUpdateMutation({
      variables: {
        imgMimeType: values?.logo?.type || '',
        merchantAccountId: merchantAccount?.id as string,
      },
    }).then((res) => {
      const uploadDetails = res?.data?.requestMerchantLogoUpdate;
      const formData = getFormData(uploadDetails as FormDataState, values.logo as File);

      fetch(uploadDetails?.formPostUrl || '', {
        method: 'POST',
        body: formData,
      }).then(async (res) => {
        updateMerchantUserMutation({
          variables: {
            data: {
              acknowledgeMerchantLogoUpdate: true,
              merchantName: values.name,
              merchantAccountId: merchantAccount?.id as string,
              address: {
                addressLine1: values?.addressLine1,
                addressLine2: values?.addressLine2,
                city: values?.city,
                state: values?.state?.value ?? values?.state,
                pincode: values?.pincode,
                countryCode: values?.countryCode ?? 'US',
              },
            },
          },
        });
        setIsUpdating(false);
      });
    });
  };

  const updateUserProfile = async (values: FormikValues) => {
    if (values.logo && values.logo.type) {
      setIsUpdating(true);
      requestAvatarUpdate(values);
      return;
    }
    if (values.name) {
      setIsUpdating(true);
      await updateMerchantUserMutation({
        variables: {
          data: {
            merchantName: values.name,
            merchantAccountId: merchantAccount?.id as string,
            address: {
              addressLine1: values?.addressLine1,
              addressLine2: values?.addressLine2,
              city: values?.city,
              state: values?.state?.value ?? values?.state,
              pincode: values?.pincode,
              countryCode: values?.countryCode ?? 'US',
            },
          },
        },
      });
    }
    setIsUpdating(false);
  };

  const stateOptions = useMemo(() => {
    return states.map((state) => ({ label: state.name, value: state.abbreviation }));
  }, [states]);

  const defaultStateValue = useMemo(() => {
    const state = find(states, { abbreviation: merchantAccount?.address?.state });
    return { label: state?.name ?? '', value: state?.abbreviation ?? '' };
  }, [merchantAccount]);
  return (
    <div className="px-4 sm:px-6 lg:px-8 mb-24">
      <SubHeading
        title="General settings"
        description="Configure general settings for your account."
        showBorder
        useHelmetTitle
      />
      <Formik
        enableReinitialize
        initialValues={{
          ...initialValues,
          logo: merchantAccount?.logo,
          name: merchantAccount?.name,
          ...merchantAccount?.address,
        }}
        onSubmit={updateUserProfile}
      >
        {({ values, setFieldValue }) => (
          <Form className="my-[30px]">
            <div className="flex">
              <div className="w-3/12">
                <HorizontalLabel label="Company name" labelFor="company-name" />
              </div>
              <div className="w-9/12 pl-8 pr-16">
                <FormField id="name" type="text" />
              </div>
            </div>
            <hr className="bg-steel-200 my-[20px]" />
            <div className="flex">
              <div className="w-3/12">
                <HorizontalLabel label="Company logo" labelFor="company-logo" />
              </div>
              <div className="w-9/12 pl-8 pr-16 flex">
                <div className="flex-shrink-0 mb-1 mr-16">
                  <Avatar
                    id="company-logo"
                    size={'large'}
                    imageUrl={values.logo && typeof values.logo === 'object' ? getLogoUrl(values.logo) : values.logo}
                    initials={getInitials(values.name ?? '')}
                  />
                  {values.logo && (
                    <Button
                      title="remove logo"
                      displayType="text"
                      type="button"
                      onClick={() => setFieldValue('logo', '')}
                    >
                      Remove
                    </Button>
                  )}
                </div>
                <div className="flex">
                  <div className="w-96">
                    <FileUpload onChange={(value) => setFieldValue('logo', value)} />
                  </div>
                </div>
              </div>
            </div>
            <hr className="bg-steel-200 my-[20px]" />
            <div className="flex">
              <div className="w-3/12">
                <HorizontalLabel
                  label="Business address"
                  labelFor="activate-number"
                  info="This is the address that will appear on your invoices."
                />
              </div>
              <div className="w-9/12 pl-8 pr-16 flex flex-col space-y-3">
                <div className="flex items-center justify-between w-full gap-4">
                  <div className="w-3/5">
                    <HorizontalLabel label="Street address" labelFor="street-address" />
                    <FormField id="addressLine1" type="text" />
                  </div>
                  <div className="w-2/5">
                    <HorizontalLabel label="Street address 2" labelFor="street-address2" />
                    <FormField id="addressLine2" type="text" />
                  </div>
                </div>
                <div>
                  <HorizontalLabel label="City" labelFor="city" />
                  <FormField id="city" type="text" />
                </div>
                <div className="flex items-center justify-between w-full gap-4">
                  <div className="w-3/5">
                    <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="state">
                      State
                    </label>
                    <SearchableInput
                      defaultValue={defaultStateValue}
                      value={stateOptions.find((state) => state.value === values.state)}
                      options={stateOptions}
                      handleChange={(value) => setFieldValue('state', value)}
                      id="state"
                    />
                  </div>
                  <div className="w-2/5">
                    <HorizontalLabel label="Zip" labelFor="zip" />
                    <FormField id="pincode" type="text" />
                  </div>
                </div>
              </div>
            </div>
            {/* <hr className="bg-steel-200 my-[20px]" />
            <div className="flex">
              <div className="w-3/12">
                <HorizontalLabel
                  label="Texting number"
                  labelFor="activate-number"
                  info="The phone number used to send message and notification texts to customers."
                />
              </div>
              <div className="w-9/12 pl-8 pr-16">
                <Button title="activate number" id="activate-number" displayType="secondary">
                  Activate number
                </Button>
              </div>
            </div> */}
            <div className="py-5 border-t border-steel-200 fixed bottom-0 right-0 px-4 sm:px-6 lg:px-8 text-right bg-white w-full">
              <Button title="save settings" loading={isUpdating} displayType="primary" type="submit">
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
