import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Avatar, CheckboxButtons, CreatableSelect, DatePicker, Icons, Label, ListBox, TextField } from '@portal/ui';
import { getInitials } from '@portal/utils/string';
import { AddressInput } from 'components/AddressInput';
import { ErrorMessage, useFormikContext } from 'formik';
import { useFindAllContactsQuery } from 'graphql/query.generated';
import _ from 'lodash';
import { useMemo, useState, useEffect } from 'react';
import { useFindAllMerchantUsersQuery } from 'graphql/query.generated';
import { DateValue } from 'react-aria';
import { components } from 'react-select';
import { useSelector } from 'redux/hooks';
import { AppointmentFormValues as AppointmentFormState } from './ScheduleAppointmentModal';
import { string } from 'yup';

const locationOptions = [
  { name: 'Phone call', value: 'PHONE_CALL' },
  { name: 'In person', value: 'IN_PERSON' },
];

const reminderOptions = [
  { name: '10 minutes before event', value: '10' },
  { name: '15 minutes before event', value: '15' },
  { name: '30 minutes before event', value: '30' },
  { name: '1 hour before event', value: '60' },
];
export const durations = [
  { name: '1 hour', value: '60' },
  { name: '2 hour', value: '120' },
  { name: '3 hour', value: '180' },
  { name: '4 hour', value: '240' },
  { name: '5 hour', value: '300' },
];

const notificationOptions = [
  { label: 'Email', value: 'EMAIL', selected: true },
  { label: 'SMS', value: 'SMS', selected: false },
];

interface AppointmentFormProps {
  hasDescription?: boolean;
}

const createDropdownOptions = (data: any) =>
  _.map(data, (option) => ({
    value: option.email,
    label: option?.name !== '' ? option?.name : option?.email,
    ...option,
  }));

export const AppointmentForm = ({ hasDescription }: AppointmentFormProps) => {
  const { values, setFieldValue } = useFormikContext<AppointmentFormState>();
  const merchantAccountId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const [openDescriptionField, setOpenDescriptionField] = useState<boolean>(hasDescription ?? false);
  const [notificationTypes, setNotificationTypes] = useState(notificationOptions);

  const { data: guests, loading: findContactsLoading } = useFindAllContactsQuery({
    variables: { merchantAccountId: merchantAccountId as string },
  });
  const { data: teamMembers, loading: findTeamMembersLoading } = useFindAllMerchantUsersQuery({
    variables: {
      findAllUsersMerchantAccountId2: merchantAccountId as string,
    },
  });
  const isMobileView = useIsMobileView();

  // NOTE: We want a custom component in long term so reverting this back to default time for now
  // const formatTimeField = (event: ChangeEvent<HTMLInputElement>, field: 'startTime' | 'endTime') => {
  //   const minutes = Math.round(parseInt(event.target.value.split(':')[1]) / 15) * 15;
  //   const hours = parseInt(event.target.value.split(':')[0]);
  //   const time = `${hours < 10 ? `0${hours}` : hours}:${
  //     minutes === 60 ? 45 : minutes === 0 ? '00' : minutes < 10 ? `0${minutes}` : minutes
  //   }`;
  //   console.log(time);
  //   setFieldValue(field, time);
  // };
  const guestsOptions = useMemo(
    () => [
      {
        label: 'Team Members',
        options: createDropdownOptions(teamMembers?.findAllUsers.users ?? []),
      },
      {
        label: 'Contacts',
        options: createDropdownOptions(guests?.findAllContacts.contacts ?? []),
      },
    ],
    [guests, teamMembers]
  );

  return (
    <div className={`appointment-form flex flex-col ${isMobileView && 'space-y-3'}`}>
      <div className="lg:mb-3">
        <TextField id="title" label="Title" />
      </div>

      <div className="lg:mb-6">
        {!openDescriptionField && (
          <button
            className="flex align-center text-primary-700 font-semibold text-sm"
            type="button"
            onClick={() => setOpenDescriptionField(true)}
          >
            <Icons.Plus className="w-5 h-5 mr-1" /> Add description
          </button>
        )}
        {openDescriptionField && (
          <div className="mt-2">
            <TextField id="description" label="Description" />
          </div>
        )}
      </div>

      <div className={`flex gap-2.5 mb-6 ${isMobileView && 'flex-col'}`}>
        <div
          className={`flex flex-col [&>div]:w-full [&>div>div]:w-full [&_button]:w-full [&_button]:justify-between [&_button]:py-1.5 ${
            isMobileView ? 'w-full [&_button]:z-[1]' : 'w-1/2'
          }`}
        >
          <label className="block text-sm font-medium text-steel-700 mb-2" htmlFor="date">
            Date
          </label>
          <DatePicker
            onChange={(value: DateValue) => setFieldValue('date', value.toDate(getLocalTimeZone()))}
            value={
              values.date
                ? parseDate(
                    values.date?.toLocaleDateString('fr-CA', {
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit',
                    })
                  )
                : today(getLocalTimeZone())
            }
          />
        </div>
        <div className="flex gap-2.5 w-full">
          <div className="w-1/2">
            <TextField id="time" label="Start time" type="time" step="900" />
          </div>
          <div className="w-1/2 [&_button]:py-2">
            <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="duration">
              Duration
            </label>
            <ListBox
              defaultValue={durations.find((duration) => duration.value === values.duration) || durations[0]}
              options={durations}
              onChange={(value: string) => {
                setFieldValue('duration', value);
              }}
            />
            <ErrorMessage name="duration" component="a" className="!text-red-500 !text-sm" />
          </div>
        </div>
      </div>

      <div className={`flex gap-2.5 mb-6 ${isMobileView && 'flex-col'}`}>
        <div className={` [&_button]:py-2 ${isMobileView ? 'w-full [&_svg]:w-[23px] [&_svg]:h-[23px]' : 'w-1/4'}`}>
          <Label htmlFor="locationType" label="Location type" />
          <ListBox
            options={locationOptions}
            id="locationType"
            defaultValue={locationOptions.filter((el) => el.value === 'PHONE_CALL')[0]}
            onChange={(value) => {
              setFieldValue('locationType', value);
            }}
          />
        </div>
        <div className={isMobileView ? 'w-full' : 'w-3/4'}>
          {values.locationType === 'PHONE_CALL' ? (
            <TextField id="phone" label="Phone Number" type="tel" />
          ) : (
            <>
              <Label label="Location" htmlFor="location" />
              <AddressInput
                id="location"
                value={{ label: values.location || '', value: values.location }}
                onChange={(value) => {
                  setFieldValue('location', value.formattedAddress);
                }}
              />
            </>
          )}
        </div>
      </div>

      <div className={`mb-6 [&>div]:py-0 [&>div>div]:py-0 ${!isMobileView && '[&_svg]:w-4  [&_svg]:h-4 '}`}>
        <Label htmlFor="guests" label="Guests" />
        <CreatableSelect
          isMulti
          value={values.guests}
          isGroup={true}
          options={guestsOptions}
          isLoading={findContactsLoading || findTeamMembersLoading}
          onChange={(value) => {
            setFieldValue('guests', value);
          }}
          onCreateOption={(value) => {
            const schema = string().email();
            const result = schema.isValidSync(value);
            if (result && !values.guests.find((item) => item.value === value)) {
              setFieldValue('guests', [...values.guests, { label: value, value }]);
              return result;
            } else {
              return;
            }
          }}
          customOptionNode={(props: any) => {
            if (props.data.__isNew__) {
              return (
                <components.Option {...props}>
                  <p className="mx-2">+ Add new contact</p>
                </components.Option>
              );
            }
            return (
              <components.Option {...props} className="!flex flex-row gap-2 items-center">
                <Avatar imageUrl={props.data.avatar} size={'x_small'} initials={getInitials(props.data?.label)} />
                <p className="text-steel-900">{props.data?.label}</p>
                <p className="text-steel-500 text-sm">{props.data?.value}</p>
              </components.Option>
            );
          }}
        />
      </div>

      <div className={`flex gap-2.5 mb-6 ${isMobileView ? 'flex-col' : 'justify-start items-start'}`}>
        <div className="">
          <Label htmlFor="notifications" label="Notification" />
          <CheckboxButtons
            options={values.notifications || notificationTypes}
            onChange={(option: { label: string; value: string; selected: boolean }[]) => {
              setNotificationTypes(option);
              setFieldValue('notifications', option);
            }}
          />
        </div>
        <div className={isMobileView ? 'w-full [&_svg]:w-[23px] [&_svg]:h-[23px] [&_button]:py-2 mb-4' : 'w-5/12'}>
          <Label htmlFor="reminder" label="Reminder" />
          <ListBox
            id="reminder"
            options={reminderOptions}
            defaultValue={reminderOptions.filter((el) => el.value === values.sendReminderAt)[0] || reminderOptions[0]}
            onChange={(value) => {
              setFieldValue('sendReminderAt', value);
            }}
          />
        </div>
      </div>
    </div>
  );
};
