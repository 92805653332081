import { MerchantContactPaymentMethod } from 'graphql/types';
import { CardType, CardTypeIcons } from 'constants/CardType';

interface CreditCardDisplayProps {
  card: MerchantContactPaymentMethod;
}
export const CreditCardDisplay = ({ card }: CreditCardDisplayProps) => {
  return card ? (
    <>
      <div className="w-8 h-8">{CardTypeIcons[(card.cardType as CardType) || 'Default']}</div>
      <span className="text-sm text-steel-700 font-normal">
        {card?.cardType || 'Card'} ending in {card?.last4Digits}
      </span>
    </>
  ) : (
    <></>
  );
};
