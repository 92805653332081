import React from 'react';

export type SelectColorProps = {
  colors: string[];
  onChange: (value: string) => void;
  selectedColor: string;
};

const SelectColor = ({ colors, onChange, selectedColor }: SelectColorProps) => (
  <>
    {colors.map((color) => (
      <input
        key={color}
        id="color"
        name="color"
        type="checkbox"
        className="w-8 h-8 rounded-full mr-3 border-0 cursor-pointer"
        style={{ backgroundColor: color }}
        onChange={() => onChange(color)}
        checked={selectedColor === color}
      />
    ))}
  </>
);

export default SelectColor;
