import { customerSchema } from 'components/validation/customerSchmea';
import { PaymentMethods } from 'constants/payments';
import * as Yup from 'yup';

export const paymentSchema = Yup.object().shape({
  customer: customerSchema.required('Required'),
  amount: Yup.number().required('Required'),
  date: Yup.date().required('Required'),
  reference: Yup.string(),
  paymentMethod: Yup.string()
    .required('Required')
    .oneOf(PaymentMethods.map((status) => status.value)),

  attachments: Yup.mixed().test('fileSize', 'File size should be less than 10 MB', (files: FileList[]) => {
    return Array.from(files).every((file: FileList) => file[0].size < 10 * 1024 * 1024);
  }),
});
