import { useMemo } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, TextField, Dropdown, Icons } from '@portal/ui';
import { AddSite, socialProfile } from 'types/Sites';
import { socialProfilesOptions } from 'constants/sites';

const SocialProfiles = () => {
  const {
    values: { socialProfiles },
  } = useFormikContext<AddSite>();

  const filteredDropdownItems = useMemo(
    () => socialProfilesOptions.filter((item) => !socialProfiles.some((profile) => profile.platform === item.url)),
    [socialProfiles]
  );

  return (
    <FieldArray
      name="socialProfiles"
      render={({ push, remove }) => (
        <>
          {socialProfiles?.map((profile: socialProfile, index: number) => (
            <div className="flex mb-4">
              <div className="w-fit">
                <TextField
                  disabled={true}
                  id={`socialProfiles.${index}.platform`}
                  type="url"
                  customStyling={{
                    field:
                      'text-gray-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple-500 focus:border-purple-500',
                  }}
                />
              </div>
              <div className="w-full">
                <TextField
                  id={`socialProfiles.${index}.profile`}
                  type="text"
                  customStyling={{
                    field:
                      'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-purple-500 focus:border-purple-500',
                  }}
                />
              </div>
              <Button title="trash" displayType="text" iconOnly onClick={() => remove(index)}>
                <Icons.Trash />
              </Button>
            </div>
          ))}
          <div className="w-fit">
            <Dropdown
              heading="Add profile"
              icon={<Icons.Plus className="w-5 h-5 mr-1" />}
              customClassNames={{
                button: '!rounded-md !bg-white !hover:bg-gray-50 !text-gray-700 !focus:ring-primary-500',
                items: '!w-60 left-0',
                item: '!border-0',
              }}
              items={filteredDropdownItems.map((option) => ({
                name: option.name,
                action: () => {
                  push({ platform: option.url, profile: '' });
                },
              }))}
            />
          </div>
        </>
      )}
    />
  );
};

export default SocialProfiles;
