import { Fragment, useState } from 'react';
import { Listbox, Transition } from '@headlessui/react';
import Icons from '../../assets/icons';

interface ListBoxOption {
  name: string;
  value: string | number;
  [key: string]: any;
}

export type ListBoxProps = {
  id?: string;
  options: ListBoxOption[];
  onChange?: (value: string) => void;
  customStyle?: string;
  optionCustomStyle?: string;
  selectedNode?: React.ReactNode;
  value?: ListBoxOption;
  optionNode?: (option: ListBoxOption) => React.ReactNode;
  defaultValue?: ListBoxOption;
  disabled?: boolean;
  onFocus?: () => void;
};

const ListBox = ({
  id,
  options,
  optionNode,
  value,
  onChange,
  customStyle,
  optionCustomStyle,
  selectedNode,
  defaultValue,
  disabled,
  onFocus,
}: ListBoxProps) => {
  const [selected, setSelected] = useState(value ? value : defaultValue);
  const handleChange = (option: ListBoxOption) => {
    if (onChange) onChange(`${option.value}`);
    setSelected(option);
  };

  return (
    <div>
      <Listbox value={selected} onChange={handleChange} disabled={disabled}>
        <div className="relative">
          <Listbox.Button id={id} onFocus={onFocus} className={`listbox-button border-steel-300 ${customStyle}`}>
            {selectedNode ? selectedNode : <span className="block truncate text-md">{selected?.name || 'Select'}</span>}
            <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center p-2">
              <Icons.ChevronDown aria-hidden="true" className="arrow-icon w-4 h-4" />
            </span>
          </Listbox.Button>
          <Transition as={Fragment} leave="transition ease-in duration-100" leaveFrom="opacity-100" leaveTo="opacity-0">
            <Listbox.Options
              className={`absolute mt-1 overflow-x-hidden overflow-y-auto max-h-40 min-w-full overflow-auto rounded-md bg-white p-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm z-10 ${optionCustomStyle}`}
            >
              {options.map((option, idx) => (
                <Listbox.Option
                  key={idx}
                  className={({ active }) =>
                    `relative hover:text-black cursor-default rounded-md select-none px-3 py-2 hover:bg-gray-50 pr-4 ${
                      active ? 'bg-gray-50 text-black' : 'text-gray-900'
                    } option`
                  }
                  value={option}
                >
                  {({ selected }) => (
                    <>
                      <span className={`block truncate ${selected ? 'font-medium' : 'font-normal'}`}>
                        {optionNode ? optionNode(option) : option.name}
                      </span>
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default ListBox;
