import { Tab } from '@headlessui/react';
import cx from 'classnames';

type CustomStyling = {
  list?: string;
  tab?: string;
  tabActive?: string;
  tabInActive?: string;
};

type Tabs = {
  id: string;
  name: string | React.ReactNode;
  component?: React.ReactNode;
};

export type TabsProps = {
  tabsData: Tabs[];
  customClasses?: CustomStyling;
};

const HorizontalTabs = ({ tabsData, customClasses }: TabsProps) => {
  const listClass = customClasses?.list ?? 'horizontal-tabs-list';
  const tabClass = customClasses?.tab ?? 'horizontal-tabs-tab';
  const tabActiveClass = customClasses?.tabActive ?? 'horizontal-tabs-tabActive';
  const tabInActiveClass = customClasses?.tabInActive ?? 'horizontal-tabs-tabInActive';

  return (
    <div className="w-full pb-6 sm:py-0">
      <Tab.Group>
        <Tab.List className={`${listClass}`}>
          {tabsData.map((tabTitle) => (
            <Tab
              key={tabTitle.id}
              className={({ selected }) =>
                cx(
                  `${tabClass}`,
                  selected ? `${tabActiveClass}` : `${tabInActiveClass}`,
                  'text-sm font-medium text-steel-900 ring-0 focus-visible:outline-none'
                )
              }
            >
              {tabTitle.name}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="md:mt-2 sm:mt-0 overflow-x-auto h-full min-h-full">
          {tabsData.map((posts, idx: number) => (
            <Tab.Panel key={posts.id} className={cx('p-5 sm:px-5 md:px-4 lg:px-8 md:py-3.5 sm:py-5')}>
              {posts.component}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};

export default HorizontalTabs;
