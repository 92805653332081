import Icons from '../../assets/icons';

interface ErrorBoxProps {
  onClose: () => void;
  title: string;
  detail?: string;
}

const ErrorBox = ({ onClose, detail, title }: ErrorBoxProps) => {
  return (
    <div className="text-sm	flex gap-3 items-start justify-between pb-4 border rounded-lg bg-error-25 border-error-300 p-5 m-10 ">
      <Icons.Error color="#D92D20" />
      <div>
        <span className="text-error-700 font-semibold">{title}</span>
        <p className="text-error-700">{detail}</p>
      </div>
      <div className="cursor-pointer" onClick={() => onClose()}>
        <Icons.Close color="#F04438" />
      </div>
    </div>
  );
};

export default ErrorBox;
