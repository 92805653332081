import { EmptyState, Spinner } from '@portal/ui';
import CardInput from 'components/invoices/invoice_form/CardInput';
import { useCreatePaymentMethodMutation } from 'graphql/mutation.generated';
import { Icons } from '@portal/ui';
import { useState } from 'react';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { useUpdatePaymentMethodMutation } from 'graphql/mutation.generated';
import { FindAllPaymentMethodsByContactIdDocument } from 'graphql/query.generated';
import { PaymentMethod } from 'types/PaymentMethod';
import { CardType, CardTypeIcons } from 'constants/CardType';

export interface PaymentMethodFormProps {
  contactId: string;
  paymentMethods: PaymentMethod[] | undefined;
  loading?: boolean;
}
interface PaymentTabProps {
  data: PaymentMethod;
  setCardAsPrimary: () => void;
}
const PaymentIcon = ({ type }: { type: string | null | undefined }) => {
  return CardTypeIcons[(type as CardType) || 'Default'] || null;
};
function PaymentTab({ data, setCardAsPrimary }: PaymentTabProps) {
  if (!data) return <></>;
  return (
    <div className="mt-6 mb-2 flex-1 px-4 sm:px-6 space-y-6">
      <div
        className={`flex pt-3 px-3 rounded-xl space-x-3 pb-1 ${
          data.isPrimary
            ? 'border-2 border-primary-600 bg-primary-50 justify-between'
            : 'bg-white border border-steel-200'
        }`}
      >
        <div className="flex space-x-3 items-start">
          <PaymentIcon type={data?.cardType} />
          <div className="flex flex-col items-start relative top-[-5px]">
            <span className="font-semibold text-sm text-primary-800">
              {data.cardType} ending in {data.last4Digits}
            </span>
            <span className="font-normal text-sm text-steel-600">Expiry {data.expiration}</span>
            <div className="flex space-x-2 pt-1 font-semibold">
              {!data.isPrimary && (
                <span
                  className="text-primary-600 text-xs cursor-pointer hover:text-primary-700"
                  onClick={setCardAsPrimary}
                >
                  Set as default
                </span>
              )}

              <span className="text-xs text-primary-800 font-medium cursor-pointer">Edit</span>
            </div>
          </div>
        </div>
        {data.isPrimary && (
          <div>
            <span className="rounded-full text-primary-700 bg-primary-200 px-2 inline-flex text-xs py-1">Primary</span>
          </div>
        )}
      </div>
    </div>
  );
}
function PaymentMethodsForm({
  paymentMethods,
  contactId,
  loading: fetchPaymentMethodsLoading,
}: PaymentMethodFormProps) {
  const [addPaymentMethod, setAddPaymentMethod] = useState<boolean>(false);
  const [createPaymentMethod, { loading: createPaymentMethodLoading }] = useCreatePaymentMethodMutation({
    onCompleted: (res) => {
      toast.success('Card added');
      setAddPaymentMethod(false);
      if (!paymentMethods?.length) {
        setCardAsPrimary(res.createPaymentMethod);
      }
    },
    onError: (err) => {
      console.log(err);
      toast.error('something went wrong');
    },

    refetchQueries: [FindAllPaymentMethodsByContactIdDocument],
  });
  const [updatePaymentMethod, { loading: updatePaymentMethodLoading }] = useUpdatePaymentMethodMutation({
    onCompleted: () => {
      toast.success('Card added as primary');
    },
    onError: (err) => {
      console.log(err);
      toast.error('something went wrong');
    },
    refetchQueries: [FindAllPaymentMethodsByContactIdDocument],
  });

  const handleSubmit = (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      createPaymentMethod({
        variables: {
          data: {
            contactId: contactId as string,
            stripeToken: paymentMethod.id,
          },
        },
      });
    }
  };
  const setCardAsPrimary = (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      updatePaymentMethod({
        variables: {
          data: {
            paymentMethodId: paymentMethod.id as string,
            isPrimary: true,
          },
        },
      });
    }
  };
  return (
    <>
      {createPaymentMethodLoading || updatePaymentMethodLoading || fetchPaymentMethodsLoading ? (
        <div className="flex  justify-center">
          <Spinner />
        </div>
      ) : (
        <div className="h-full">
          {paymentMethods &&
            paymentMethods?.length > 0 &&
            paymentMethods?.map((paymentMethod: PaymentMethod) => (
              <PaymentTab
                data={paymentMethod}
                setCardAsPrimary={() => {
                  setCardAsPrimary(paymentMethod);
                }}
              />
            ))}

          {!paymentMethods?.length && !addPaymentMethod && (
            <EmptyState
              icon={<Icons.CreditCard />}
              title="No payment methods on file"
              subTitle="Adding a payment method will allow you to easily charge customers without having to re-enter their information each time."
              btnText="Add payment method"
              onClick={() => {
                setAddPaymentMethod(true);
              }}
            />
          )}
          {!addPaymentMethod ? (
            paymentMethods && paymentMethods?.length > 0 ? (
              <div
                className=" my-2 px-4 sm:px-6 space-y-6 cursor-pointer"
                onClick={() => {
                  setAddPaymentMethod(true);
                }}
              >
                <span className="text-primary-700 inline-flex font-semibold">+ Add payment method</span>
              </div>
            ) : undefined
          ) : (
            <div className=" my-2 px-4 sm:px-6 space-y-6">
              <CardInput
                handleCancel={() => {
                  setAddPaymentMethod(false);
                }}
                handleSubmit={handleSubmit}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
}

export default PaymentMethodsForm;
