import { Formik, Form, FormikHelpers } from 'formik';
import { Button, TextField } from '@portal/ui';
import { tagSchema } from 'components/validation/tagSchema';

type Tag = {
  name: string;
};

export type TagFormProps = {
  onSubmit: (values: Tag, helpers: FormikHelpers<Tag>) => void;
  onClose: () => void;
  loading: boolean;
  initialValues: Tag;
};

export const defaultInitialValues: Tag = {
  name: '',
};

export const TagForm = ({ onSubmit, onClose, loading, initialValues }: TagFormProps) => {
  return (
    <Formik
      initialValues={{
        ...defaultInitialValues,
        ...initialValues,
      }}
      validationSchema={tagSchema}
      onSubmit={onSubmit}
    >
      <Form className="h-full flex flex-col">
        <div className="relative mt-5 flex-1">
          <TextField id="name" label="Title" />
        </div>
        <div className="flex justify-end items-center mt-8">
          <Button title="save tag" displayType="primary" type="submit" loading={loading}>
            Save
          </Button>
        </div>
      </Form>
    </Formik>
  );
};
