import { Helmet } from 'react-helmet';
import { Header } from '.';
import TopBar from 'components/topBar';
import { useLocation } from 'react-router-dom';
import { getPageTitle } from 'constants/pageTitle';
import { useState, useEffect } from 'react';
import { Spinner, ResponsiveSidebar } from '@portal/ui';
import { useSelector } from 'redux/hooks';

const Layout = ({ children }: { children: JSX.Element }) => {
  const location = useLocation();
  const [isSidebarOpen, setIsOpenSidebar] = useState(false);

  const toggleSidebar = (isSidebarOpen: boolean) => {
    setIsOpenSidebar(isSidebarOpen);
  };

  useEffect(() => {
    toggleSidebar(false);
  }, [location.pathname]);
  const loadingMerchant = useSelector((state) => state.user.loading);

  return loadingMerchant ? (
    <Spinner />
  ) : (
    <>
      <Helmet titleTemplate="%s | Growthware">
        <title></title>
      </Helmet>
      <div className="flex">
        <div className="hidden md:flex">
          <Header />
        </div>
        <div className="min-h-full w-full overflow-hidden">
          <main>
            <TopBar title={getPageTitle(location.pathname)} toggleSidebar={() => toggleSidebar(true)} />
            <div className="relative">{children}</div>
          </main>
        </div>
      </div>
      <ResponsiveSidebar open={isSidebarOpen} onClose={() => toggleSidebar(false)}>
        <Header />
      </ResponsiveSidebar>
    </>
  );
};

export { Layout };
