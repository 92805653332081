import classNames from 'classnames';
import Select, { DropdownIndicatorProps, OptionProps, components, MultiValue, SingleValue } from 'react-select';
import Icons from '../../assets/icons';

type Option = {
  value: string;
  label: string;
};

export type MultiSelectProps = {
  id?: string;
  loading?: boolean;
  options: Option[];
  onChange?: (selectedOptions: MultiValue<Option> | SingleValue<Option>) => void;
  customOptionNode?: React.FC<OptionProps>;
  noOptionNode?: React.FC;
  values?: Option[];
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ChevronDown />
    </components.DropdownIndicator>
  );
};

const MultiSelect = ({ options, onChange, customOptionNode, noOptionNode, id, loading, values }: MultiSelectProps) => {
  const handleSelectChange = (selectedOptions: MultiValue<Option> | SingleValue<Option>) => {
    if (onChange) {
      onChange(selectedOptions);
    }
  };

  return (
    <Select
      id={id}
      isLoading={loading}
      value={values}
      classNames={{
        multiValue: () => 'bg-gray-50 px-1 mx-1 flex gap-1 items-center',
        multiValueRemove: () => 'text-gray-500',
        valueContainer: () => 'text-sm p-1',
        input: () => '[&>input]:text-red !text-red',
        placeholder: () => 'text-black text-sm ',
        menuList: () => 'bg-white border m-1 rounded-md ',
        noOptionsMessage: () => 'text-black text-sm p-2',
        indicatorSeparator: () => 'hidden',
        control: () =>
          'border bg-white border-gray-300 focus:border-gray-300 hover:border-gray-300 shadow-sm !text-base sm:text-sm p-2 rounded-md',
        option: ({ isSelected }) =>
          classNames(
            'active:bg-gray-50 hover:text-black hover:bg-gray-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-black text-black !cursor-pointer',
            isSelected ? 'bg-gray-50' : 'bg-white'
          ),
      }}
      unstyled
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator,
        ...(customOptionNode && { Option: customOptionNode }),
        ...(noOptionNode && { NoOptionsMessage: noOptionNode }),
      }}
      closeMenuOnSelect
      isMulti
      options={options}
      isClearable={false}
      menuPlacement="auto"
      onChange={handleSelectChange}
    />
  );
};

export default MultiSelect;
