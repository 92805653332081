import { StripeForm } from '@portal/ui';
import { PaymentMethod } from 'types/PaymentMethod';

type Props = {
  showCancel?: boolean;
  handleCancel?: () => void;
  handleSubmit?: (paymentMethod: PaymentMethod) => void;
};

const CardInput = ({ showCancel, handleCancel, handleSubmit }: Props) => {
  const handlePayment = (paymentMethod: PaymentMethod) => {
    if (handleSubmit) {
      handleSubmit(paymentMethod);
    }
  };

  return (
    <div>
      <StripeForm showCancel={showCancel} handleSubmit={handlePayment} handleCancel={handleCancel} />
    </div>
  );
};

export default CardInput;
