import { Badge, BadgeType, DeleteModal, EmptyState, Price, RowActionMenu, Drawer, DataTable, Icons } from '@portal/ui';
import { capitalizeText } from '@portal/utils/string';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ColumnType } from '@portal/ui/components/base/Table';
import {
  FetchAllPaymentsQuery,
  useFetchAllPaymentsLazyQuery,
  useFindPaymentStatsByIdLazyQuery,
  FindPaymentStatsByIdDocument,
} from 'graphql/query.generated';
import { useDeletePaymentMutation } from 'graphql/mutation.generated';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import LogPayment from 'components/payment';
import { useParams } from 'react-router-dom';
import { ternary } from '@portal/utils/conditional';
import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { FindAllUsersFilters } from 'graphql/types';
import { currencyFormatter } from '@portal/utils/misc';

const badgeMappings: Record<string, BadgeType> = {
  success: BadgeType.Success,
  pending: BadgeType.Warning,
  failed: BadgeType.Cancelled,
};

export type PaymentRow = FetchAllPaymentsQuery['findAllPaymentsByContactId']['payments'][0];

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllUsersFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: {},
};
const Payments = () => {
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState<PaymentRow | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [openLogPaymentDrawer, setOpenLogPaymentDrawer] = useState<boolean>(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState<boolean>(false);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const isMobileView = useIsMobileView();
  const onClose = () => {
    setShowDeleteModal(false);
    setShowConfirmationModal(false);

    setOpenLogPaymentDrawer(false);
    setSelectedRow(null);
  };

  const [fetchPayments, { data: payments, loading: loadingPayments, refetch }] = useFetchAllPaymentsLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [fetchPaymentStats, { data: stats, loading: loadingStats }] = useFindPaymentStatsByIdLazyQuery();
  const [deletePaymentMutation, { loading: deletePaymentLoading }] = useDeletePaymentMutation({
    onCompleted: () => {
      toast.success('Payment deleted');
      onClose();
      resetTableState();
      refetch();
    },
    onError: (error) => {
      console.error(error);
    },
    refetchQueries: [FindPaymentStatsByIdDocument],
  });

  const onPaymentDelete = () => {
    deletePaymentMutation({
      variables: { paymentId: selectedRow?.id ?? '' },
    }).then((res) => {
      if (res.errors) {
        toast.error('Something went wrong');
      }
    });
  };
  useEffect(() => {
    fetchPayments({
      variables: {
        merchantContactId: id as string,
        pagination: {
          offset: tableState.pageIndex * tableState.pageSize,
          limit: tableState.pageSize,
        },
        orderBy: {
          createdAt: ternary(tableState.sorting.find((col) => col.id === 'createdAt')?.desc, 'desc', 'asc', undefined),
        },
      },
    });
  }, [id, tableState.pageIndex, tableState.pageSize, tableState.sorting]);
  useEffect(() => {
    fetchPaymentStats({
      variables: {
        merchantContactId: id as string,
      },
    });
  }, [id]);
  const copyPaymentLink = (row: any) => {
    navigator.clipboard.writeText(row.link);
    toast.success('Payment link copied to clipboard');
  };

  const rowActions: RowActionMenuProps<PaymentRow>['rowActions'] = [
    { name: 'View', action: () => console.log('view') },
    {
      name: 'Edit',
      action: (row) => {
        setSelectedRow(row ?? null);
        setOpenLogPaymentDrawer(true);
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedRow(row ?? null);
        setShowDeleteModal(true);
      },
    },
  ];

  const tableActions = useMemo(() => [{ name: 'Log payment', action: () => setOpenLogPaymentDrawer(true) }], []);
  const columns: ColumnType<PaymentRow>[] = useMemo(
    () => [
      {
        name: 'Amount',
        id: 'amount',
        cell: (info) => (
          <div className="font-medium text-sm text-steel-600">{currencyFormatter(info.row.original.amount)}</div>
        ),
      },
      {
        id: 'status',
        name: 'Status',
        cell: (info) => (
          <Badge
            text={capitalizeText(info.row.original.status)}
            type={badgeMappings[info.row.original.status.toLowerCase()]}
          />
        ),
      },
      {
        name: 'Payment ID',
        id: 'id',
        cell: (info) => {
          return <div>{info.row.original.id}</div>;
        },
      },
      {
        id: 'paidAt',
        name: 'Date',
        cell: (info) => moment(info.row.original.paidAt).format('MMM D, YYYY'),
      },

      {
        id: 'action',
        name: '',
        cell: ({ row: { original } }) => {
          if (original.status !== 'SUCCESS') {
            return <RowActionMenu row={original} rowActions={rowActions.filter((row) => row.name !== 'Delete')} />;
          }
          return <RowActionMenu row={original} rowActions={rowActions} />;
        },
      },
    ],
    []
  );

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = payments?.findAllPaymentsByContactId.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [payments?.findAllPaymentsByContactId.count]);

  const paymentData = payments?.findAllPaymentsByContactId.payments || [];
  const hasPayments = paymentData.length > 0 && Object.keys(stats?.findPaymentStatsByContactId || {}).length > 0;
  const { open, overdue, paid } = useMemo(() => {
    return stats?.findPaymentStatsByContactId || { paid: '', open: '', overdue: '' };
  }, [stats?.findPaymentStatsByContactId]);
  return (
    <>
      <DeleteModal
        onClose={onClose}
        title={'Delete payment'}
        open={showDeleteModal}
        onConfirm={onPaymentDelete}
        loading={deletePaymentLoading}
        content={'Are you sure you want to delete this payment? This action cannot be undone.'}
      />

      {hasPayments && !isMobileView && (
        <div className="flex justify-evenly items-center py-9">
          <div>
            <Price title={'Open'} amount={open} />
          </div>
          <div>
            <Price title={'Overdue'} amount={overdue} />
          </div>
          <div>
            <Price title={'Paid'} amount={paid} />
          </div>
        </div>
      )}
      <div className="payment-table w-full">
        <DataTable
          updateTableState={updateTableState}
          tableState={tableState}
          columns={columns}
          loading={loadingPayments}
          data={paymentData}
          pagination
          showSearch={false}
          noDataElement={
            <EmptyState
              title="No payments yet"
              subTitle="Payments collected from this contact will appear here"
              icon={<Icons.CreditCard />}
            />
          }
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
        />
      </div>

      <Drawer open={openLogPaymentDrawer} onClose={onClose}>
        <LogPayment data={selectedRow} closePaymentForm={onClose} edit={!!selectedRow} />
      </Drawer>
    </>
  );
};

export default Payments;
