import { useState, useEffect, useMemo } from 'react';
import { EmptyState, Spinner, Icons } from '@portal/ui';
import AppointmentCard from './AppointmentCard';
import { useParams } from 'react-router-dom';
import { useFetchContactAppointmentsLazyQuery } from 'graphql/query.generated';
import { Event } from 'types/Event';
import toast from '@portal/ui/components/widgets/Toast/notify';
import ViewAppointmentModal from 'components/appointment/ViewAppointmentModal';
import _ from 'lodash';
type CategorizedEvents = {
  pastEvents: Event[];
  upcomingEvents: Event[];
};

const Appointments = () => {
  const { id } = useParams();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [visibleAppointmentsCount, setVisibleAppointmentsCount] = useState<number>(10);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined);
  const [appointmentsData, setAppointmentsData] = useState<Event[]>([]);
  const [categorizedEvents, setCategorizedEvents] = useState<CategorizedEvents>({
    pastEvents: [],
    upcomingEvents: [],
  });
  const [fetchContactAppointment, { data: appointments, loading: fetchAppointmentsLoading }] =
    useFetchContactAppointmentsLazyQuery({
      fetchPolicy: 'network-only',
      onError: (err) => {
        toast.error(err.message ?? 'Something went wrong');
      },
    });

  useEffect(() => {
    if (id)
      fetchContactAppointment({
        variables: {
          merchantContactId: id,
          orderBy: {
            startTime: 'desc',
          },
          pagination: {
            offset: visibleAppointmentsCount - 10,
            limit: visibleAppointmentsCount,
          },
        },
      });
  }, [id, visibleAppointmentsCount]);

  useEffect(() => {
    if (appointments?.fetchContactAppointments.events) {
      setAppointmentsData((data) => {
        const newEvents = appointments.fetchContactAppointments.events as Event[];
        const uniqueEvents = newEvents.filter((newEvent) => !data.some((event) => event.id === newEvent.id));
        return [...data, ...uniqueEvents];
      });
    }
  }, [appointments?.fetchContactAppointments.events]);

  const currentDate = new Date();
  const createCategorizedEvents = useMemo(
    () =>
      appointments?.fetchContactAppointments.events?.reduce(
        (res: { pastEvents: Event[]; upcomingEvents: Event[] }, event) => {
          const eventDate = new Date(event.startTime);
          if (currentDate > eventDate) {
            res.pastEvents = [{ ...event } as Event, ...res.pastEvents].sort((a, b) =>
              a.startTime > b.startTime ? -1 : 1
            );
          } else {
            res.upcomingEvents.unshift(event as Event);
          }
          return res;
        },
        {
          pastEvents: [],
          upcomingEvents: [],
        }
      ),
    [appointments?.fetchContactAppointments.events, appointments?.fetchContactAppointments.count]
  );

  useEffect(() => {
    if (createCategorizedEvents) {
      setCategorizedEvents((events) => ({
        pastEvents: [...events.pastEvents, ...createCategorizedEvents.pastEvents],
        upcomingEvents: [...events.upcomingEvents, ...createCategorizedEvents.upcomingEvents],
      }));
    }
  }, [createCategorizedEvents]);

  useEffect(() => {
    const handleScroll = () => {
      const windowHeight = window.innerHeight;
      const documentHeight = document.documentElement.scrollHeight;
      const scrollTop = window.scrollY;
      const threshold = 100;
      const isNearBottom = scrollTop + windowHeight >= documentHeight - threshold;
      if (
        !fetchAppointmentsLoading &&
        isNearBottom &&
        appointments &&
        appointments?.fetchContactAppointments?.count >= visibleAppointmentsCount
      ) {
        debouncedIncreaseVisibleCount();
      }
    };
    if (handleScroll) window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [appointments?.fetchContactAppointments.count]);

  const debouncedIncreaseVisibleCount = _.debounce(() => {
    setVisibleAppointmentsCount((count) => count + 10);
  }, 2000);
  return (
    <>
      <ViewAppointmentModal isModalOpen={isModalOpen} closeModal={() => setIsModalOpen(false)} event={selectedEvent} />
      {fetchAppointmentsLoading && appointmentsData.length === 0 ? (
        <div className="relative">
          <Spinner />
        </div>
      ) : appointments?.fetchContactAppointments.count === 0 ? (
        <EmptyState title={'No appointments yet'} icon={<Icons.Calendar />} />
      ) : (
        <>
          {categorizedEvents?.upcomingEvents.map((event) => (
            <AppointmentCard
              key={event.id}
              event={event as Event}
              onClick={() => {
                setIsModalOpen(true);
                setSelectedEvent(event);
              }}
            />
          ))}
          {categorizedEvents?.pastEvents ? (
            <div>
              <Divider />
              {categorizedEvents?.pastEvents.map((event) => (
                <AppointmentCard
                  key={event.id}
                  event={event as Event}
                  onClick={() => {
                    setIsModalOpen(true);
                    setSelectedEvent(event);
                  }}
                />
              ))}
            </div>
          ) : null}

          {fetchAppointmentsLoading && appointmentsData.length > 0 && (
            <div className="relative h-24 flex items-center justify-center w-full">
              <Spinner />
            </div>
          )}
        </>
      )}
    </>
  );
};

const Divider = () => {
  return (
    <div className="flex gap-2 justify-center items-center my-6">
      <div className="bg-steel-200 h-px w-full" />
      <p className="text-sm font-normal text-steel-700 whitespace-nowrap">Past appointments</p>
      <div className="bg-steel-200 h-px w-full" />
    </div>
  );
};

export default Appointments;
