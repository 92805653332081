import { FormikValues } from 'formik';
import { CreateContactInput } from 'graphql/types';

export const toCompleteAdress = (
  address:
    | {
        addressLine1: string;
        addressLine2: string;
        city: string;
        state: string;
        countryCode: string;
        pincode: string;
      }
    | undefined
    | null
) => {
  return (
    address?.addressLine1 +
    ' ' +
    address?.addressLine2 +
    ' ' +
    address?.city +
    ', ' +
    address?.state +
    ' ' +
    address?.pincode +
    ', ' +
    address?.countryCode
  );
};

export const formatContactFormikData = (values: FormikValues): CreateContactInput => {
  return {
    name: `${values['firstName']} ${values['lastName']}`.trim(),
    email: values.email,
    phone: values.phone,
    addressLine1: values.addressLine1,
    addressLine2: values.addressLine2,
    city: values.city,
    state: values.state,
    pincode: values.pincode,
    countryCode: values.countryCode,
    companyName: values.companyName,
    status: values.status as CreateContactInput['status'],
    merchantAccountId: values.merchantAccountId,
    tags: values.tags,
    ownerId: values.ownerId,
  };
};

export const onBoardingStatusPath: { [key: string]: string } = {
  SET_PASSWORD: '/onboarding/credentials',
  RUN_BUSINESS_SCAN: '/onboarding/scan',
  SET_BUSINESS_INFO: '/onboarding/details',
};
