import React from 'react';
import cx from 'classnames';
import { To, Link } from 'react-router-dom';
import Icons from '../../assets/icons';
import Button from '../Button';

export interface PageHeaderProps {
  title: string;
  className?: string | undefined;
  backTo?: To;
}

export const BackButton: React.FC<PageHeaderProps> = ({ title, className, backTo }) => {
  const Component = backTo ? ({ ...rest }) => <Link to={backTo} {...rest} /> : 'div';

  return (
    <>
      <Component
        className={cx(className, 'relative inline-flex items-start text-gray-600 transition-colors', {
          'cursor-pointer hover:text-gray-700 space-x-2': backTo,
        })}
      >
        <Button title={title} displayType="text" type="button">
          {backTo && <Icons.NarrowLeftArrow color="" className="w-4 h-4 stroke-gray-600 hover:stroke-gray-700 mr-2" />}
          {title}
        </Button>
      </Component>
    </>
  );
};

export default BackButton;
