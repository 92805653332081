export const PaymentStatuses = [
  {
    name: 'Success',
    value: 'SUCCESS',
  },
  {
    name: 'Failed',
    value: 'FAILED',
  },
  {
    name: 'Pending',
    value: 'PENDING',
  },
];
export const PaymentMethods = [
  { name: 'Cash', value: 'cash' },
  { name: 'Check', value: 'check' },
  { name: 'Credit Card', value: 'credit_card' },
  { name: 'Debit Card', value: 'debit_card' },
  { name: 'Direct Debit', value: 'direct_debit' },
  { name: 'EFT', value: 'eft' },
  { name: 'PayPal', value: 'paypal' },
  { name: 'Other', value: 'other' },
];

export const PAYMENT_DURATIONS = [
  { name: 'Due today', value: 'today' },
  { name: 'Due tomorrow', value: '1' },
  { name: 'Due in 7 days', value: '7' },
  { name: 'Due in 14 days', value: '14' },
  { name: 'Due in 30 days', value: '30' },
  { name: 'Due in 45 days', value: '45' },
  { name: 'Due in 60 days', value: '60' },
  { name: 'Due in 90 days', value: '90' },
  { name: 'Custom date', value: 'custom' },
];

export const ITEMS = [
  // { label: '+ Add one-time item', value: 'addItem', __isNew__: true },
  { label: '+ Create new product', value: 'addNewProduct', __isNew__: true },
];
