import { Button, Drawer, Dropdown, Icons, Title } from '@portal/ui';
import { DropdownItem } from '@portal/ui/components/base/Dropdown';
import InvoiceFormHOC from 'components/invoices/invoice_form/InvoiceFormHOC';
import { ScheduleAppointmentModal } from 'components/calendar/appointments/ScheduleAppointmentModal';
import { ContactForm } from 'components/contacts/ContactForm';
import EmailForm from 'components/emailForm';
import LogPaymentForm from 'components/payment';
import moment from 'moment';
import { useMemo, useState } from 'react';

type TopBarProps = {
  title: string;
  toggleSidebar: () => void;
};

const TopBar = ({ title, toggleSidebar }: TopBarProps) => {
  const [form, setForm] = useState<'contact' | 'invoice' | 'email' | 'appointment' | 'payment' | null>(null);

  const closeDrawer = () => {
    setForm(null);
  };

  const items: DropdownItem[] = [
    {
      name: 'Contact',
      action: () => setForm('contact'),
    },
    // {
    //   name: 'Email',
    //   action: () => setForm('email'),
    // },
    {
      name: 'Appointment',
      action: () => setForm('appointment'),
    },
    {
      name: 'Invoice',
      action: () => setForm('invoice'),
    },
    {
      name: 'Payment',
      action: () => setForm('payment'),
    },
  ];

  const selectedForm = useMemo(() => {
    return form === 'contact' ? (
      <ContactForm mode={'create'} />
    ) : form === 'invoice' ? (
      <InvoiceFormHOC onClose={closeDrawer} />
    ) : form === 'payment' ? (
      <LogPaymentForm closePaymentForm={closeDrawer} />
    ) : undefined;
  }, [form]);

  return (
    <div className="flex py-4 justify-between items-center px-4 sm:px-6 lg:px-8 border-b">
      <div className="flex items-center gap-4">
        <div className="md:hidden">
          <Button title="open menu" displayType="text" iconOnly onClick={toggleSidebar}>
            <Icons.Menu />
          </Button>
        </div>
        <Title>{title}</Title>
      </div>
      <div className="flex gap-4 items-center global-buttons">
        <Button title="search" displayType="text" iconOnly>
          <Icons.Search />
        </Button>

        <Button title="notifications" displayType="text" iconOnly>
          <Icons.Bell />
        </Button>

        <Dropdown
          items={items}
          icon={<Icons.Plus width={28} height={28} color="white" />}
          classNames="!bg-primary !hover:bg-primary-700"
        />
      </div>

      <Drawer open={['contact', 'payment'].includes(form ?? '')} onClose={closeDrawer}>
        {selectedForm || <>{form}</>}
      </Drawer>

      {form === 'invoice' && selectedForm}

      <EmailForm open={form === 'email'} onClose={closeDrawer} />

      <ScheduleAppointmentModal
        isOpen={form === 'appointment'}
        onToggle={closeDrawer}
        data={{
          startTime: moment()
            .add(30 - (moment().minute() % 30), 'minutes')
            .toISOString(),
          endTime: moment()
            .add(60 - (moment().minute() % 30), 'minutes')
            .toISOString(),
        }}
      />
    </div>
  );
};

export default TopBar;
