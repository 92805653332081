/* eslint-disable @next/next/no-img-element */
import { FieldArray, useFormikContext } from 'formik';
import { Button, FileUpload, Icons, SubHeading } from '@portal/ui';
import { AddSite } from 'types/Sites';

const Images = () => {
  const {
    values: { images },
  } = useFormikContext<AddSite>();

  return (
    <FieldArray
      name="images"
      render={({ push, remove }) => (
        <>
          <div className="text-gray-600 flex text-sm font-semibold cursor-pointer gap-8">
            <div className="w-full">
              <FileUpload
                onChange={(file) => push({ file })}
                uploadInfoText="SVG, PNG, JPG or GIF (10MB max)"
                name="images"
                multiple
              />
            </div>
          </div>
          {images?.map((image: any, index: number) => {
            return image.file.map((file: any, index: number) => {
              return (
                <div key={file.name}>
                  <div className="flex my-3 items-center rounded-xl border p-4">
                    <div className="h-9 w-9 flex-shrink-0">
                      <img className="h-9 w-9" src={URL.createObjectURL(file)} alt={file.name} />
                    </div>
                    <div className="flex w-full  flex-col mx-4">
                      <SubHeading title={file.name} info={`${Math.round(file.size / 1024)} KB`} variant="medium" />
                    </div>
                    <Button title="trash" displayType="text" iconOnly onClick={() => remove(index)}>
                      <Icons.Trash />
                    </Button>
                  </div>
                </div>
              );
            });
          })}
        </>
      )}
    />
  );
};

export default Images;
