import { FetchResult } from '@apollo/client';
import FullCalendar from '@fullcalendar/react';
import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Button, Drawer } from '@portal/ui';
import { ModalBase } from '@portal/ui/components';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { DateFormats } from '@portal/utils/dates';
import { Form, Formik, FormikProps } from 'formik';
import {
  CreateEventMutation,
  UpdateEventMutation,
  useCreateEventMutation,
  useUpdateEventMutation,
} from 'graphql/mutation.generated';
import { FetchAllEventsDocument, FetchContactAppointmentsDocument } from 'graphql/query.generated';
import { CreateEventInput, UpdateEventInput } from 'graphql/types';
import moment from 'moment';
import { useCallback, useMemo, useRef } from 'react';
import { Guests, Notifications } from 'types/Calendar';
import { getFormattedDate } from '../../../utils/dates';
import { newAppointmentSchema } from '../../validation/appointmentSchema';
import { AppointmentForm, durations } from './AppointmentForm';
import { useSelector } from 'redux/hooks';

type ScheduleAppointmentModalProps = {
  isOpen: boolean;
  onToggle: () => void;
  calendar?: FullCalendar | null;
  data: Partial<UpdateEventInput>;
  edit?: boolean;
};
type ScheduleAppointmentProps = Omit<ScheduleAppointmentModalProps, 'isOpen'> & {
  formRef: React.RefObject<FormikProps<AppointmentFormValues>>;
  createEvent: (args: any) => Promise<FetchResult<CreateEventMutation>>;
  loading?: boolean;
  edit?: boolean;
  updateEvent: (args: any) => Promise<FetchResult<UpdateEventMutation>>;
};
export type AppointmentFormValues = Omit<
  CreateEventInput,
  'guests' | 'startTime' | 'endTime' | 'addToExternalCalendar'
> & {
  date: Date;
  guests: Guests[];
  time: string;
  duration: string;
  notifications: Notifications[];
};

const getTimeDifference = (startTime: string, endTime: string) => moment(endTime).diff(moment(startTime), 'minutes');

const notificationOptions = [
  { label: 'Email', value: 'EMAIL', selected: true },
  { label: 'SMS', value: 'SMS', selected: false },
];

export const ScheduleAppointment = ({
  createEvent,
  loading,
  formRef,
  data,
  calendar,
  onToggle,
  edit,
  updateEvent,
}: ScheduleAppointmentProps) => {
  const getDateFromDifferentTimestamps = (startTime: string | undefined | null, endTime: string | undefined | null) => {
    if (startTime !== undefined && startTime !== null && endTime !== undefined && endTime !== null) {
      return new Date(startTime);
    } else {
      return new Date();
    }
  };
  const { email: merchantEmail, name: merchantName } = useSelector(
    (state) => state.user.attributes ?? { name: '', email: '' }
  );

  const getNotificationData = () =>
    notificationOptions.map((notification) => ({
      ...notification,
      selected:
        notification.value === 'EMAIL'
          ? data.sendEmailReminder ?? true
          : notification.value === 'SMS'
          ? data.sendSMSReminder ?? false
          : false,
    }));

  const getTime = useCallback(
    (dateStr?: string) => {
      const date = moment(dateStr || data.startTime).toDate();
      return getFormattedDate(date, DateFormats.HOURS_MINS);
    },
    [data]
  );
  const isMobileView = useIsMobileView();
  const initialValues: AppointmentFormValues = useMemo(
    () => ({
      title: (data.title as string) ?? '',
      description: data.description ? (data.description !== data.title ? data.description : '') : '',
      date: getDateFromDifferentTimestamps(data.startTime, data.endTime),
      time: getTime(),
      duration: data.eventId
        ? getTimeDifference(data.startTime as string, data.endTime as string).toString()
        : durations[0].value,
      location: data.location ?? '',
      locationType: data?.location ? 'IN_PERSON' : 'PHONE_CALL',
      phone: data?.phone ?? '',
      guests: data.guests
        ? data.guests.map((guest) => ({
            value: guest,
            label: guest,
          }))
        : ([{ value: merchantEmail, label: `${merchantName} (you)` }] as Guests[]),
      sendEmailReminder: data?.sendEmailReminder ?? true,
      sendSMSReminder: data?.sendSMSReminder ?? false,
      sendReminderAt: data.sendReminderAt
        ? getTimeDifference(data.sendReminderAt as string, data.startTime as string).toString()
        : '',
      addToExternalCalendar: true,
      notifications: getNotificationData() as Notifications[],
    }),
    [data]
  );

  const handleOnsubmit = async (values: AppointmentFormValues) => {
    const formattedDate = values.date?.toLocaleDateString('fr-CA', {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
    const payload = {
      ...values,
      startTime: moment(`${formattedDate} ${values.time}`).toISOString(),
      endTime: moment(`${formattedDate} ${values.time}`).add(values.duration, 'minutes').toISOString(),
      sendReminderAt: moment(`${formattedDate} ${values.time}`)
        .subtract(values.sendReminderAt, 'minutes')
        .toISOString(),
      guests: values.guests.map((guest) => guest.value),
      sendEmailReminder:
        values.notifications?.filter((notification) => notification.value === 'EMAIL')[0]?.selected || false,
      sendSMSReminder:
        values.notifications?.filter((notification) => notification.value === 'SMS')[0]?.selected || false,
    };
    const { date, notifications, time, duration, ...dataToSubmit } = payload;

    if (dataToSubmit.locationType === 'PHONE_CALL') {
      dataToSubmit.location = '';
    } else {
      dataToSubmit.phone = '';
    }

    if (data.eventId) {
      if (calendar) {
        const eventObject = calendar.getApi().getEventById(data.eventId);
        if (!eventObject) {
          return;
        }
        eventObject.setStart(dataToSubmit.startTime);
        eventObject.setEnd(dataToSubmit.endTime);
        eventObject.setDates(dataToSubmit.startTime, dataToSubmit.endTime);
        Object.keys(dataToSubmit).forEach((key) => eventObject.setProp(key, payload[key as keyof typeof payload]));
      }

      if ('addToExternalCalendar' in dataToSubmit) delete dataToSubmit['addToExternalCalendar'];

      updateEvent({
        variables: {
          data: {
            eventId: data.eventId,
            ...dataToSubmit,
          },
          onCompleted: () => {
            toast.success('Event updated');

            onToggle();
          },
        },
      });
    } else {
      const event = {
        ...dataToSubmit,
        start: dataToSubmit.startTime,
        end: dataToSubmit.endTime,
        classNames: 'bg-purple-50',
        textColor: 'text-purple-700',
        extendedProps: {
          listColor: 'bg-purple-400',
          timeColor: 'text-purple-500',
        },
      };

      createEvent({
        variables: {
          data: {
            ...dataToSubmit,
          },
        },
        onCompleted: () => {
          toast.success('Event saved');
          if (calendar) {
            calendar.getApi().addEvent(event);
          }
          onToggle();
        },
      });
    }
  };
  const submitBtn = useMemo(
    () => (
      <Button title="Save" loading={loading} displayType="primary" type="submit">
        Save
      </Button>
    ),
    [loading]
  );

  const hasDescription = useMemo(
    () => (data.description ? (data.description !== data.title ? !!data.description : false) : false),
    [data.description]
  );

  return (
    <div className={`overflow-y-auto ${isMobileView ? 'h-screen' : 'max-h-[80vh]'}`}>
      <Formik
        innerRef={formRef}
        initialValues={{
          ...initialValues,
          title: initialValues.title || '',
        }}
        validationSchema={newAppointmentSchema}
        onSubmit={handleOnsubmit}
      >
        {({ handleSubmit }) => {
          return (
            <Form className="h-full flex flex-col" onSubmit={handleSubmit}>
              {isMobileView ? (
                <>
                  <Drawer.Title
                    title={`${edit ? 'Edit' : 'New'} Appointment`}
                    onClose={onToggle}
                    submitBtn={submitBtn}
                  />
                  <div className="relative mt-3 lg:mt-6 flex-1 px-4 sm:px-6 lg:px-8 space-y-6">
                    <AppointmentForm hasDescription={hasDescription} />
                  </div>
                </>
              ) : (
                <div className="px-6 pt-6 pb-4 flex-1">
                  <AppointmentForm hasDescription={hasDescription} />
                </div>
              )}
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const ScheduleAppointmentModal = ({ isOpen, onToggle, calendar, data, edit }: ScheduleAppointmentModalProps) => {
  const formRef = useRef<FormikProps<AppointmentFormValues>>(null);

  const [createEvent, { loading: createEventLoading }] = useCreateEventMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Event saved');
      onToggle();
    },
    refetchQueries: [FetchAllEventsDocument, FetchContactAppointmentsDocument],
  });

  const [updateEvent, { loading: updateEventLoading }] = useUpdateEventMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Event updated');
      onToggle();
    },
    refetchQueries: [FetchAllEventsDocument, FetchContactAppointmentsDocument],
  });

  const handleSave = useCallback(() => {
    formRef?.current?.handleSubmit();
  }, []);
  const commonProps = {
    data,
    calendar,
    onToggle,
    formRef,
    createEvent,
    loading: createEventLoading || updateEventLoading,
    edit,
    updateEvent,
  };

  const isMobileView = useIsMobileView();
  return isMobileView ? (
    <Drawer open={isOpen} onClose={onToggle}>
      <ScheduleAppointment {...commonProps} />
    </Drawer>
  ) : (
    <ModalBase
      title={edit ? 'Edit Schedule Appointment' : 'Schedule Appointment'}
      isOpen={isOpen}
      onToggle={onToggle}
      onSave={handleSave}
      loading={createEventLoading || updateEventLoading}
    >
      <div>
        <ScheduleAppointment {...commonProps} />
      </div>
    </ModalBase>
  );
};
