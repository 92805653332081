import { Avatar, Button, CreatableSelect, FileUpload, ListBox, data } from '@portal/ui';
import { getLogoUrl } from '@portal/utils/misc';
import { getInitials } from '@portal/utils/string';
import { AddressInput } from 'components/AddressInput';
import FormField from 'components/formField';
import { ErrorMessage, useFormikContext } from 'formik';
import { filter, find, isEmpty, map } from 'lodash';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { FormState } from './ContactForm';

const ContactFields = () => {
  const { setFieldValue, values, setValues } = useFormikContext<FormState>();
  const merchantId = useSelector((state: RootState) => state.user.attributes?.id);
  const tags = useSelector((state: RootState) => state.tags.data);
  const team = useSelector((state: RootState) => state.user.attributes?.team) || [];

  const addTag = (value: string) => {
    setFieldValue('tags', [...(values.tags ? values.tags : []), value]);
  };

  const defaultOwner = useMemo(() => {
    const owner = find(team, ({ id }) => id === values.ownerId || id === merchantId);
    if (owner) {
      setFieldValue('ownerId', owner.id);
    }
    return { name: owner?.displayName || '', value: owner?.id || '' };
  }, [team]);

  const tagsValue = useMemo(() => {
    const newTags = map(
      filter(values.tags, (tag) => isEmpty(tags.filter((t) => t.id === tag))),
      (tag) => ({
        label: tag,
        value: tag,
      })
    );

    return [
      ...tags
        ?.filter((tag) => values.tags && values.tags.includes(tag.id))
        .map((tag) => ({ value: tag.name, label: tag.name })),
      ...newTags,
    ];
  }, [tags, values.tags]);

  const tagOptions = useMemo(() => {
    return tags.map((tag) => ({ value: tag.name, label: tag.name })) || [];
  }, [tags]);

  const teamOptions = useMemo(() => {
    const filteredTeam = filter(team, ({ status }) => ['INVITED', 'ACTIVE'].includes(status));

    return filteredTeam.map((teammate) => ({ ...teammate, value: teammate.id, name: teammate.displayName }));
  }, [team]);

  return (
    <div className="flex flex-col">
      <div className="contact-form-field">
        <FormField id="firstName" label="First name" />
        <FormField id="lastName" label="Last name" />
      </div>
      <div className="contact-form-field">
        <FormField id="email" label="Email address" />
        <FormField id="phone" label="Phone number" />
      </div>
      <div className="contact-form-field">
        <FormField id="companyName" label="Company name" />
        <div className="w-full">
          <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="status">
            Status
          </label>
          <ListBox
            defaultValue={data.statusItems.find((status) => status.value === values.status)}
            options={data.statusItems}
            onChange={(value: string) => setFieldValue('status', value)}
          />
          <ErrorMessage name="status" component="a" className="!text-red-500 !text-sm" />
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="ContactOwner">
          Contact owner
        </label>
        <ListBox
          optionNode={(option) => (
            <div className="flex gap-1 items-center">
              <Avatar
                size={'x_small'}
                initials={getInitials(option?.name !== '' ? option?.name : option?.email)}
                imageUrl={option['avatar']}
              />
              <span className="ml-2">{option?.displayName}</span>
            </div>
          )}
          defaultValue={defaultOwner}
          onChange={(value: string) => setFieldValue('ownerId', value)}
          options={teamOptions}
        />
        <ErrorMessage component="a" name="ownerId" className="!text-red-500 !text-sm" />
      </div>
      <div className="contact-form-field">
        <div className="flex flex-col gap-2 w-3/4">
          <label className="block text-sm font-medium text-steel-700" htmlFor="ProfileImage">
            Address
          </label>

          <AddressInput
            value={{ label: values.addressLine1, value: values.addressLine1 }}
            onChange={(value) => {
              setValues({
                ...values,
                addressLine1: value.formattedAddress ?? '',
                city: value.city ?? '',
                state: value.state ?? '',
                countryCode: value.country ?? '',
                pincode: value.postalCode ?? '',
              });
            }}
          />
        </div>
        <div className="w-1/4">
          <FormField id="addressLine2" label="Address line 2" />
        </div>
      </div>
      <div>
        <label className="block text-sm whitespace-nowrap font-medium text-steel-700 mb-2" htmlFor="profile-image">
          Profile image
        </label>
        <div className="flex flex-col md:flex-row mb-4">
          <div className="w-1/5 flex-col items-center">
            {values.avatar && (
              <Avatar
                id="profile-image"
                size="large"
                imageUrl={typeof values.avatar === 'object' ? getLogoUrl(values.avatar) : `${values.avatar}}`}
              />
            )}
            {values.avatar && (
              <Button displayType="text" title="remove avatar" onClick={() => setFieldValue('avatar', '')}>
                Remove
              </Button>
            )}
          </div>
          <div className="w-full md:w-4/5">
            <FileUpload
              onChange={(file) => {
                setFieldValue('avatar', file);
              }}
            />
          </div>
        </div>
      </div>
      <div className="mb-4">
        <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="Tags">
          Tags
        </label>
        <CreatableSelect
          onCreateOption={(value: string) => addTag(value)}
          isMulti
          value={tagsValue}
          onChange={(value) => {
            if ('length' in value)
              setFieldValue(
                'tags',
                value?.map((tag) => tag.label)
              );
          }}
          options={tagOptions}
          createOptionNode={(value: string) => <div>+ Add &Prime;{value}&Prime;</div>}
        />
      </div>
    </div>
  );
};

export default ContactFields;
