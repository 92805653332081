import * as Yup from 'yup';

export const productSchema = Yup.object().shape({
  name: Yup.string().required('Required').max(250, 'Must not exceed 250 characters'),
  price: Yup.number().required('Required'),
});

export const ProductsSchema = Yup.object().shape({
  products: Yup.array().of(productSchema),
});
