import { Field } from 'formik';

export type Props = {
  name: string;
  value: string;
  label: string;
  info?: string;
  id: string;
  disabled?: boolean;
  handleChange: (event: React.ChangeEvent<Record<string, unknown>>) => void;
  checked: boolean;
};

export const RadioButton = ({ name, label, value, info, id, disabled, handleChange, checked }: Props) => {
  return (
    <div className="flex gap-2 items-start">
      <Field
        type="radio"
        name={name}
        value={value}
        checked={checked}
        id={id}
        disabled={disabled}
        className="focus:ring-white mt-1.5 radio-checked"
        onChange={handleChange}
      />
      <div>
        <label htmlFor={id} className="text-sm font-medium cursor-pointer text-gray-800">
          {label}
        </label>
        <p className="text-sm font-light leading-none text-gray-700 ">{info}</p>
      </div>
    </div>
  );
};
