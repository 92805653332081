import FullCalendar from '@fullcalendar/react';
import ViewAppointmentModal from 'components/appointment/ViewAppointmentModal';
import {
  useFetchAllEventsLazyQuery,
  useFetchBookingSlugLazyQuery,
  useFetchEventByIdLazyQuery,
} from 'graphql/query.generated';
import { UpdateEventInput } from 'graphql/types';
import moment from 'moment';
import { useEffect, useState, useCallback } from 'react';
import { Event } from 'types/Event';
import { CalendarHeader } from '../../components/calendar/CalendarHeader';
import { CalendarView } from '../../components/calendar/CalendarView';
import { ScheduleAppointmentModal } from '../../components/calendar/appointments/ScheduleAppointmentModal';
import '../../css/calendar.css';
import { CalendarDisplayModes, FullCalendarEventType } from '../../types/Calendar';

export const CalendarPage = () => {
  const [bookingSlug, setBookingSlug] = useState<string>('');
  const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState<Event | undefined>(undefined);

  const [fetchEventById, { loading: fetchEventLoading }] = useFetchEventByIdLazyQuery({
    fetchPolicy: 'network-only',
    onCompleted: (res) => {
      setSelectedEvent(res?.findEventById as Event);
    },
  });

  const [eventData, setEventData] = useState<Partial<UpdateEventInput>>({
    startTime: new Date().toISOString(),
    endTime: new Date(new Date().getTime() + 30 * 60 * 1000).toISOString(),
  });
  const [showBy, setShowBy] = useState<CalendarDisplayModes>(CalendarDisplayModes.TIME_GRID_WEEK);
  const [calendar, setCalendar] = useState<FullCalendar | null>(null);

  const [fetchBookingSlug] = useFetchBookingSlugLazyQuery({
    onCompleted: (data) => {
      setBookingSlug(data?.bookingConfiguration?.slug || '');
    },
  });

  const [fetchAllEvents, { data, loading: fetchAllEventsLoading }] = useFetchAllEventsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const handleToggle = () => {
    setAddNewOpen(!addNewOpen);
    if (!addNewOpen) {
      setAddNewOpen(false);
    }
  };

  const handleSelect = (info: FullCalendarEventType) => {
    setEventData({
      startTime: info.startStr,
      endTime: info.endStr,
    });
    setAddNewOpen(true);
  };

  useEffect(() => {
    fetchBookingSlug();
    fetchAllEvents();
  }, []);

  const measuredRef = useCallback((node: FullCalendar) => {
    if (node !== null) {
      setCalendar(node);
    }
  }, []);

  return (
    <div className="p-8">
      <div className="pb-6 calendar">
        <CalendarHeader
          calendar={calendar}
          showBy={showBy}
          setShowBy={setShowBy}
          bookingSlug={bookingSlug}
          calendarEventsLoading={fetchAllEventsLoading}
        />
        <hr className="mt-5 mb-8" />
        <CalendarView
          ref={measuredRef}
          handleSelect={handleSelect}
          events={
            data?.findAllEvents?.events.map((event) => ({
              ...event,
              start: moment(event.startTime).toDate().toISOString(),
              end: moment(event.endTime).toDate().toISOString(),
            })) || []
          }
          handleEventClick={(event) => {
            fetchEventById({
              variables: {
                id: event.event.id,
              },
            });
            setIsModalOpen(true);
          }}
        />
      </div>
      <ViewAppointmentModal
        isModalOpen={isModalOpen}
        closeModal={() => {
          setIsModalOpen(false);
        }}
        calendar={calendar}
        event={selectedEvent}
        loading={fetchEventLoading}
      />

      {addNewOpen && (
        <ScheduleAppointmentModal isOpen={addNewOpen} onToggle={handleToggle} calendar={calendar} data={eventData} />
      )}
    </div>
  );
};
