import * as Yup from 'yup';
import { hasValidTimeFormat } from '../../utils/calendar';

export const bookingSettingsSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  slug: Yup.string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Please use only letters, numbers, hyphens, and underscores only.')
    .required('Required'),
  tzName: Yup.string().required('Required'),
  availabilities: Yup.array(
    Yup.object().shape({
      startTime: Yup.string().test('startTime', 'startTime time in Valid format', (value): boolean =>
        hasValidTimeFormat(value)
      ),
      endTime: Yup.string()
        .test('endTime', 'endTime time in Valid format', (value): boolean => hasValidTimeFormat(value))
        .when('startTime', {
          is: (startTime: string): string => startTime,
          then: Yup.string().test(
            'endTime',
            'endTime time should be greater than startTime time',
            (value, context): boolean => {
              const { startTime } = context.parent as { startTime: string };
              const [startHour, startMinute] = startTime.split(':');
              const [endHour, endMinute] = (value ?? '').split(':');
              return parseInt(endHour) > parseInt(startHour) || parseInt(endMinute) > parseInt(startMinute);
            }
          ),
        }),
    })
  ),
});

export const eventTypeSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  slug: Yup.string()
    .matches(/^[a-zA-Z0-9-_]+$/, 'Please use only letters, numbers, hyphens, and underscores only.')
    .matches(/^[^\s-].*[^\s-]$/, 'Should not end with a dash')
    .required('Required'),
  duration: Yup.string().required('Required'),
  dayAvailabilityInFuture: Yup.string().required('Required'),
  color: Yup.string().required('Required'),
  eventOwners: Yup.array()
    .of(
      Yup.object().shape({
        label: Yup.string(),
        value: Yup.string(),
      })
    )
    .min(1, 'Required'),
  questions: Yup.array().of(
    Yup.object().shape({
      text: Yup.string().required('Required'),
      required: Yup.boolean().required('Required'),
    })
  ),
});
