import { Button, Drawer, Formik, Icons, Label, ListBox, SelectColor, Avatar, SearchableInput } from '@portal/ui';
import FormField from 'components/formField';
import { components } from 'react-select';
import { eventTypeSchema } from 'components/validation/bookingSchema';
import { availabilityOptions, colors, durationOptions } from 'constants/booking';
import { FieldArray, Form, useFormikContext, ErrorMessage } from 'formik';
import { getInitials } from '@portal/utils/string';
import { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'redux/hooks';
import _ from 'lodash';
import { AddQuestionFunction, AddTypeProps } from 'types/BookingSettings';
import { v4 as uuidv4 } from 'uuid';
import { AddQuestion } from './AddQuestion';
import { EventType } from 'types/Event';
import { MerchantAccountUser } from 'graphql/types';

type Option = {
  label: string;
  value: string;
};

type AddEventTypeProps = {
  onClose: () => void;
  handleAddQuestion: AddQuestionFunction;
  eventType: EventType | Record<string, never>;
  eventOwnersOptions: Option[];
  createEventTypeloading?: boolean;
};

type EventTypeFormState = Omit<EventType, 'eventOwners' | 'owners'> & {
  eventOwners: Option[];
};
const AddEventTypeForm = ({
  onClose,
  handleAddQuestion,
  eventType,
  eventOwnersOptions,

  createEventTypeloading,
}: AddEventTypeProps) => {
  const { values, setFieldValue, errors, handleSubmit } = useFormikContext<EventTypeFormState>();
  const [slugModifiedByUser, setSlugModifiedByUser] = useState(false);
  useEffect(() => {
    if (!values.name || slugModifiedByUser || eventType?.id) return;
    const slug = values.name
      .replace(/[^\w\s]/gi, '')
      .replace(/\s+/g, '-')
      .toLowerCase();

    setFieldValue('slug', slug);
  }, [values.name]);
  return (
    <Form className="h-full flex flex-col">
      <Drawer.Title title={`${values.id ? 'Edit' : 'Add'} an event type`} onClose={onClose} />
      <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
        <div className="flex flex-col">
          <div className="mb-4">
            <FormField id="name" label="Event name" />
          </div>
          <div className="mb-4">
            <FormField id="description" label="Description" type="textarea" />
            <p className="text-sm font-normal text-steel-600 mt-1.5">
              {300 - values.description?.length} characters left
            </p>
          </div>
          <div className=" mb-4">
            <FormField id="location" label="Location" />
          </div>
          <div className="mb-4">
            <FormField
              id="slug"
              label="Event link"
              onChange={(e) => {
                setSlugModifiedByUser(true);
                setFieldValue('slug', e.target.value);
              }}
            />
            <p className="text-sm font-normal text-steel-600 mt-1.5">
              Customize the URL of your event type&apos;s booking page
            </p>
          </div>
          <div className="flex gap-2.5 mb-4">
            <div className="w-1/2">
              <Label htmlFor="duration" label="Duration" />
              <ListBox
                value={durationOptions.filter((option) => option.value === values.duration)[0]}
                options={durationOptions}
                onChange={(value) => setFieldValue('duration', value)}
              />
            </div>
            <div className="w-1/2">
              <Label htmlFor="dayAvailabilityInFuture" label="Availability Window" />
              <ListBox
                value={availabilityOptions.filter((option) => option.value === values.dayAvailabilityInFuture)[0]}
                options={availabilityOptions}
                defaultValue={availabilityOptions.filter((option) => option.value === 30)[0]}
                onChange={(value) => setFieldValue('dayAvailabilityInFuture', value)}
              />
            </div>
          </div>
          <div className="[&>div>div]:p-1 [&_svg]:w-[18px] [&_svg]:h-[18px] mb-4">
            <Label htmlFor="eventOwners" label="Event owners" />
            <SearchableInput
              value={values.eventOwners.map((owner) => ({ ...owner, value: owner.value }))}
              options={eventOwnersOptions}
              isMulti
              handleChange={(value) => {
                setFieldValue('eventOwners', value);
              }}
              customOptionNode={(props: any) => {
                if (props.data.__isNew__) {
                  return (
                    <components.Option {...props}>
                      <p>+ Add new owner</p>
                    </components.Option>
                  );
                }
                return (
                  <components.Option {...props} className="!flex flex-row gap-2 items-center">
                    <Avatar imageUrl={props.data.avatar} size={'x_small'} initials={getInitials(props.data.name)} />
                    <p className="text-steel-900">{props.data?.name}</p>
                    <p className="text-steel-500 text-sm">{props.data?.email}</p>
                  </components.Option>
                );
              }}
            />
            {errors.eventOwners && <ErrorMessage component="a" className="!text-red-500 text-sm" name="eventOwners" />}
          </div>
          <div className="mb-4">
            <Label htmlFor="color" label="Color" />
            <SelectColor
              colors={colors}
              selectedColor={values.color}
              onChange={(color) => setFieldValue('color', color)}
            />
          </div>

          <div className="mb-4">
            <p className="text-lg font-semibold text-steel-900 mb-1">Invitee questions</p>
            <p className="text-sm font-normal text-steel-600">
              Name, email, and optional notes are requested by default
            </p>
          </div>

          <FieldArray
            name="questions"
            render={({ push, remove }) => (
              <>
                {values.questions?.map((question, index) => (
                  <AddQuestion question={question} index={index} remove={remove} key={`question-${index}`} />
                ))}
                <div className="w-1/2 mb-4">
                  <Button title="add event question" displayType="secondary" onClick={() => handleAddQuestion(push)}>
                    <Icons.Plus color="#4A5578" className="w-5 h-5 mr-1" /> Add question
                  </Button>
                </div>
              </>
            )}
          />
        </div>
      </div>
      <Drawer.Footer>
        <Button
          title="update or create event type"
          displayType="primary"
          type="submit"
          onClick={handleSubmit}
          loading={createEventTypeloading}
        >
          {eventType?.id ? 'Update' : 'Create'}
        </Button>
      </Drawer.Footer>
    </Form>
  );
};

export const AddEventType = ({ open, onClose, eventType, onSubmit, loading }: AddTypeProps) => {
  const handleAddQuestion: AddQuestionFunction = (push) => {
    push({ id: uuidv4(), text: '', required: false });
  };
  const merchantAccountId = useSelector((state) => state.user.attributes?.id);
  const merchantAccountName = useSelector((state) => state.user.attributes?.name);
  const users = useSelector((state) => state.users.data);

  const eventOwnersOptions = useMemo(
    () =>
      _.map(users, (user) => ({
        value: user.id,
        label: user.name || user.email,
        ...user,
      })),
    [users]
  );

  const initialValues: EventTypeFormState = {
    id: eventType?.id || '',
    name: eventType?.name || '',
    description: eventType?.description || '',
    location: eventType?.location || '',
    slug: eventType?.slug || '',
    duration: eventType?.duration || 30,
    dayAvailabilityInFuture: eventType?.dayAvailabilityInFuture || 30,
    eventOwners: eventType.owners?.length
      ? (eventType.owners as MerchantAccountUser[]).map((owner) => ({
          value: owner?.id,
          label: owner?.name || owner.email,
        }))
      : ([{ label: merchantAccountName, value: merchantAccountId }] as Option[]),
    color: eventType?.color || '#7A5AF8',
    questions: eventType?.questions || [],
  };

  return (
    <Drawer open={open} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        validationSchema={eventTypeSchema}
        onSubmit={(values) => {
          onSubmit({
            ...values,
            duration: Number(values.duration),
            dayAvailabilityInFuture: Number(values.dayAvailabilityInFuture),
            owners: values.eventOwners.map((eventOwner) => eventOwner.value),
          });
        }}
      >
        <AddEventTypeForm
          createEventTypeloading={loading}
          onClose={onClose}
          handleAddQuestion={handleAddQuestion}
          eventType={eventType}
          eventOwnersOptions={eventOwnersOptions}
        />
      </Formik>
    </Drawer>
  );
};
