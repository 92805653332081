import { Button, FieldFeedback, Icons, Label, TimePicker, checkFieldErrors } from '@portal/ui';
import { FieldArray, useFormikContext } from 'formik';
import toUpper from 'lodash/toUpper';
import moment from 'moment';
import { Fragment, useState } from 'react';

const defaultDates = [
  {
    day: 'Sunday',
    label: 'SUN',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Monday',
    label: 'MON',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Tuesday',
    label: 'TUE',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Wednesday',
    label: 'WED',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Thursday',
    label: 'THU',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Friday',
    label: 'FRI',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
  {
    day: 'Saturday',
    label: 'SAT',
    enabled: false,
    slots: [
      {
        startTime: '9:00 AM',
        endTime: '6:00 PM',
      },
    ],
  },
];

type TimeSlots = {
  startTime: string;
  endTime: string;
};

type Days = {
  day: string;
  enabled: boolean;
  slots: Array<TimeSlots>;
};

type Props = {
  id: string;
  label: string;
  days?: Array<Days>;
  values?: Array<Days>;
  onChange: (data: Array<Days>) => void;
};

export const TimeSlotPicker = ({ id, label, days = defaultDates, values, onChange }: Props) => {
  const { touched, errors } = useFormikContext<{ timeSlots: Array<Days> }>();
  const [selectedDays, setSelectedDays] = useState<Array<Days>>(values || days);

  const handleDayChange = (day: Pick<Days, 'day' | 'enabled'>) => {
    const updatedDays = selectedDays.map((d) => {
      if (d.day === day.day) {
        return {
          ...d,
          enabled: !d.enabled,
        };
      }

      return d;
    });

    console.log(updatedDays);

    setSelectedDays(updatedDays);
    onChange(updatedDays);
  };

  const handleTimeSlotChange = (day: string, index?: number) => {
    const updatedDays = selectedDays.map((d) => {
      if (d.day === day && index) {
        d.slots.splice(index, 1);
      }

      if (d.day === day && index == null) {
        if (d.slots[d.slots.length - 1].endTime === '11:30 PM') {
          return d;
        }

        d.slots.push({
          startTime: moment(d.slots[d.slots.length - 1].endTime, 'h:mm A').format('h:mm A'),
          endTime: moment(d.slots[d.slots.length - 1].endTime, 'h:mm A')
            .add(1, 'hours')
            .format('h:mm A'),
        });
      }

      return d;
    });

    setSelectedDays(updatedDays);
    onChange(updatedDays);
  };

  const handleTimeChange = (day: string, index: number, value: string, type: 'startTime' | 'endTime') => {
    if (type === 'startTime' && index > 0) {
      const previousSlot = selectedDays.find((d) => d.day === day)?.slots[index - 1];
      if (previousSlot && moment(value, 'h:mm A').isSameOrBefore(moment(previousSlot.startTime, 'h:mm A'))) {
        return;
      }
    }
    if (type === 'endTime') {
      const startTime = selectedDays.find((d) => d.day === day)?.slots[index].startTime;
      if (startTime && moment(value, 'h:mm A').isSameOrBefore(moment(startTime, 'h:mm A'))) {
        return;
      }
    }
    const updatedDays = selectedDays.map((d) => {
      if (d.day === day) {
        d.slots[index][type] = value;
      }

      return d;
    });

    const selectedDaySlots = updatedDays.find((d) => d.day === day)?.slots;

    if (selectedDaySlots && selectedDaySlots[index].startTime === '24 hours') {
      selectedDaySlots.splice(index + 1);
      selectedDaySlots[index].endTime = '2:00 AM';
    }

    setSelectedDays(updatedDays);
    onChange(updatedDays);
  };

  return (
    <div className="flex flex-col">
      <Label htmlFor={id} label={label} />
      <FieldArray
        name={id}
        render={() =>
          selectedDays.map(({ day, enabled, slots }, index) => {
            const error = errors?.timeSlots?.[index];
            const timeSlotError = typeof error === 'object' ? error?.slots : undefined;
            return (
              <Fragment key={day}>
                <div className="flex flex-row justify-between items-baseline">
                  <div className="w-1/6 mt-1">
                    <div className="flex items-center">
                      <input
                        aria-label="enable or disable day"
                        type="checkbox"
                        className="text-primary-600 border-primary-600 bg-primary-50 rounded-md cursor-pointer mr-8 focus:ring-0 w-5 h-5"
                        checked={enabled}
                        name={`timeSlots.${index}.enabled`}
                        id={`timeSlots.${index}.enabled`}
                        onChange={() => handleDayChange({ day, enabled })}
                      />
                      <label className="text-steel-700 text-sm font-medium">{toUpper(day.substring(0, 3))}</label>
                    </div>
                  </div>
                  <div className="w-5/6 mt-1">
                    {slots.map((slot, slotsIdx) => (
                      <div className="flex-row mb-3" key={`${slot.toString()}-${slotsIdx}`}>
                        <div className="flex flex-col">
                          <div className="flex w-auto pl-8">
                            <div className="flex flex-col w-5/12">
                              <TimePicker
                                id={`timeSlots[${index}].slot[${slotsIdx}].startTime`}
                                defaultValue={slot.startTime}
                                isDisabled={!enabled}
                                disable24Hours={slotsIdx !== 0}
                                onChange={({ value }) => handleTimeChange(day, slotsIdx, value, 'startTime')}
                              />
                            </div>
                            <Icons.Minus className="w-6 h-6 mx-2 mt-2.5" />
                            <div className="flex flex-col w-5/12">
                              <TimePicker
                                id={`timeSlots[${index}].slot[${slotsIdx}].endTime`}
                                defaultValue={slot.startTime === '24 hours' ? '' : slot.endTime}
                                isDisabled={!enabled || slot.startTime === '24 hours'}
                                disable24Hours
                                onChange={({ value }) => handleTimeChange(day, slotsIdx, value, 'endTime')}
                              />
                            </div>
                            {slotsIdx === 0 && (
                              <Button
                                title="add more slots"
                                displayType="text"
                                iconOnly
                                disabled={!enabled || slot.startTime === '24 hours'}
                                onClick={() => handleTimeSlotChange(day)}
                              >
                                <Icons.Plus />
                              </Button>
                            )}
                            {slotsIdx > 0 && (
                              <Button
                                title="remove slot"
                                displayType="text"
                                iconOnly
                                disabled={!enabled}
                                onClick={() => handleTimeSlotChange(day, slotsIdx)}
                              >
                                <Icons.Trash />
                              </Button>
                            )}
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <FieldFeedback
                  errorCondition={checkFieldErrors(
                    touched as Record<string, boolean>,
                    errors as Record<string, string | undefined>,
                    `timeSlots.${index}`,
                    'timeSlots'
                  )}
                  errorMessage={timeSlotError?.toLocaleString()}
                  transitionClasses="mt-1"
                />
                <hr className="bg-steel-200 my-[20px]" />
              </Fragment>
            );
          })
        }
      />
    </div>
  );
};
