import { Button, FullScreenModal } from '@portal/ui';
import { DateFormats } from '@portal/utils/dates';
import InvoiceForm from 'components/invoices/Invoice';
import { FormikProps, FormikValues } from 'formik';
import moment from 'moment';
import { useCallback, useRef, useState } from 'react';

type InvoiceFormHOCProps = {
  onClose: () => void;
  edit?: boolean;
  fetchInvoiceLoading?: boolean;
  invoice?: any;
  showInvoiceModal?: boolean;
};

const InvoiceFormHOC = ({
  onClose,
  fetchInvoiceLoading = false,
  invoice,
  showInvoiceModal = true,
}: InvoiceFormHOCProps) => {
  const [hidePreview, setHidePreview] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(false);
  const [values, setValues] = useState<FormikValues>({});
  const formRef = useRef<FormikProps<FormikValues>>(null);

  const currentDate = moment().format(DateFormats.DAY_MONTH_YEAR + ' ' + DateFormats.HOURS_MINS_WITH_AMPM);

  const submitInvoice = useCallback(() => {
    formRef?.current?.handleSubmit();
  }, []);

  return (
    <FullScreenModal
      isOpen={showInvoiceModal}
      onClose={onClose}
      title={invoice?.id ? 'Edit invoice' : 'New invoice'}
      closeIcon
      actions={[
        <div className="flex items-center gap-2">
          <p className="hidden lg:block text-sm mr-4">Last saved: {currentDate}</p>
          <div className="hidden lg:block">
            <Button title="show or hide preview" onClick={() => setHidePreview(!hidePreview)} displayType="secondary">
              {!hidePreview ? 'Show preview' : 'Hide preview'}
            </Button>
          </div>
          <Button
            title="update, send or confirm invoice"
            loading={loading}
            type="submit"
            displayType="primary"
            onClick={submitInvoice}
          >
            <span className="hidden lg:inline">
              {values?.delivery === 'true' ? 'Send ' : 'Confirm '}
              invoice
            </span>
            <span className="lg:hidden">{values.delivery === 'true' ? 'Send ' : 'Confirm'}</span>
          </Button>
        </div>,
      ]}
    >
      <InvoiceForm
        hidePreview={hidePreview}
        formRef={formRef}
        onClose={onClose}
        loading={fetchInvoiceLoading}
        invoice={invoice}
        edit={invoice?.id}
        handleChange={(data) => setValues(data)}
        handleLoading={(value) => setLoading(value)}
      />
    </FullScreenModal>
  );
};

export default InvoiceFormHOC;
