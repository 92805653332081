import { Button, Formik, SubHeading } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { bookingSettingsSchema } from 'components/validation/bookingSchema';
import { defaultAvailabilities } from 'constants/booking';
import { Form } from 'formik';
import { useUpdateBookingConfigurationMutation } from 'graphql/mutation.generated';
import { useFetchBookingConfigurationLazyQuery } from 'graphql/query.generated';
import { BookingConfiguration, EventType } from 'graphql/types';
import { useEffect, useState } from 'react';
import { AvailabilitySettings } from './AvailabilitySettings';
import { EventTypesSettings } from './EventTypesSettings';
import { GeneralSettings } from './GeneralSettings';
import { MerchantAccountUser } from 'graphql/types';

interface BookingSettingsType extends Omit<BookingConfiguration, 'eventTypes' | 'id' | 'createdAt' | 'updatedAt'> {
  eventTypes:
    | Omit<EventType, 'owners'>[]
    | {
        owners?: Partial<MerchantAccountUser>[];
      };
}

export const BookingSettings = () => {
  const [bookingSettingValues, setBookingSettingValues] = useState<
    Omit<BookingSettingsType, 'id' | 'createdAt' | 'updatedAt'>
  >({
    title: '',
    slug: '',
    tzName: '',
    availability: defaultAvailabilities,
    welcomeMessage: '',
    eventTypes: [],
  });

  const [fetchBookingConfiguration, { data, refetch, loading }] = useFetchBookingConfigurationLazyQuery({
    onCompleted: (data) => {
      setBookingSettingValues({
        title: data?.bookingConfiguration?.title || '',
        slug: data?.bookingConfiguration?.slug || '',
        tzName: data?.bookingConfiguration?.tzName || '',
        availability: data?.bookingConfiguration?.availability.length
          ? data?.bookingConfiguration?.availability
          : defaultAvailabilities,
        welcomeMessage: data?.bookingConfiguration?.welcomeMessage || '',
        eventTypes: data?.bookingConfiguration?.eventTypes || [],
      });
    },
  });

  const [updateBookingConfiguration] = useUpdateBookingConfigurationMutation({
    onCompleted: () => {
      toast.success('Settings updated');
      refetch();
    },
    onError: (error) => {
      toast.error('Something went wrong');
    },
  });

  const handleOnsubmit = (values: Omit<BookingConfiguration, 'id' | 'createdAt' | 'updatedAt' | 'eventTypes'>) => {
    updateBookingConfiguration({
      variables: {
        data: {
          title: values.title,
          welcomeMessage: values.welcomeMessage,
          tzName: values.tzName,
          availability: values.availability.map(({ __typename, ...rest }) => rest),
          slug: values.slug,
        },
      },
    });
  };

  useEffect(() => {
    fetchBookingConfiguration();
  }, []);

  return (
    <div className="px-8 mb-24">
      <SubHeading
        title="Booking tool settings"
        description="Manage the types of appointments that customers can book with your company."
        showBorder
        useHelmetTitle
      />
      <Formik<BookingSettingsType>
        initialValues={bookingSettingValues}
        validationSchema={bookingSettingsSchema}
        enableReinitialize
        onSubmit={handleOnsubmit}
      >
        {({ dirty }) => (
          <Form className="my-[20px]">
            <GeneralSettings />
            <hr className="bg-steel-200 my-[20px]" />
            <AvailabilitySettings />
            <hr className="bg-steel-200 my-[20px]" />
            <EventTypesSettings refetch={refetch} disabled={data?.bookingConfiguration?.id == null} />

            <div className="py-5 border-t border-steel-200 fixed bottom-0 right-0 px-4 sm:px-6 lg:px-8 text-right bg-white w-full">
              <Button
                title="save booking"
                displayType="primary"
                type="submit"
                disabled={loading || !dirty}
                loading={loading}
              >
                Save
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};
