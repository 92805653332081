import { statusItems } from '@portal/ui/components/data/statusItems';
import * as Yup from 'yup';

export const contactSchema = Yup.object().shape({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email().label('Email address').required('Required'),
  companyName: Yup.string(),
  phone: Yup.string(),
  pincode: Yup.string(),
  state: Yup.string(),
  status: Yup.string().oneOf(statusItems.map((status) => status.value)),
  city: Yup.string(),
  addressLine1: Yup.string(),
  addressLine2: Yup.string(),
  countryCode: Yup.string(),
  ownerId: Yup.string().required('Required'),
  tags: Yup.array(Yup.string()),
});
