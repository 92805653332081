import { useFormikContext } from 'formik';
import { SubHeading, FileUpload, Avatar, HorizontalLabel } from '@portal/ui';
import FAQs from './FAQs';
import { AddSite } from 'types/Sites';
import Images from './Images';
import { subHeadingsWrapperClasses } from './AddSiteModal';
import FormField from 'components/formField';

const DesignAndContent = () => {
  const { values, setFieldValue } = useFormikContext<AddSite>();

  return (
    <>
      <div className="flex-grow pb-5 border-b border-gray-200 mt-16">
        <SubHeading
          title="Design and content"
          description="Outline additional visual and written information that will appear on your site."
        />
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Site title"
            info="This is the title of your site, which will appear in the tab of the browser and search engine results. "
          />
        </div>
        <div className="w-8/12">
          <FormField id="title" type="text" />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Favicon"
            info="This is the small icon that appears next to your site's title in the tab of the browser."
          />
        </div>
        <div className="w-8/12">
          <div className="text-gray-600 flex text-sm font-semibold cursor-pointer gap-8 w-full">
            <div className="flex-shrink-0 mb-1 items-start justify-start">
              <Avatar size={'large'} imageUrl={values.favicon} />
            </div>
            <div className="w-full">
              <FileUpload
                onChange={(favicon) => setFieldValue('favicon', URL.createObjectURL(favicon as File))}
                uploadInfoText="SVG, PNG, JPG or GIF (10MB max)"
                name="favicon"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Images" info="These are additional images that showcase your business and team." />
        </div>
        <div className="w-8/12">
          <Images />
        </div>
      </div>
      <div className="py-5 border-gray-200 border-b flex gap-8">
        <div className="w-4/12">
          <HorizontalLabel
            label="FAQs"
            info="These are frequently asked questions and answers that can help visitors better understand your business."
          />
        </div>
        <div className="w-8/12">
          <FAQs />
        </div>
      </div>
      <div className="py-5 border-gray-200 border-b flex gap-8">
        <div className="w-4/12">
          <HorizontalLabel
            label="Promo"
            info="Use this section to highlight special promotions or discounts that can capture the interest of your website visitors. Promos create a sense of urgency and encourage visitors to explore your site."
          />
        </div>
        <div className="w-8/12">
          <FormField id="promo" type="textarea" rows={7} />
        </div>
      </div>
      <div className="py-5 border-gray-200 flex gap-8">
        <div className="w-4/12">
          <HorizontalLabel
            label="Notes"
            info="Use this field to provide any information or requests you have for the site designer, including details on your preferred colors, fonts, or other specific design elements. You can also include testimonials or other content you would like to feature on your site. Your input will help us create a site tailored to your needs and preferences."
          />
        </div>
        <div className="w-8/12">
          <FormField id="notes" type="textarea" rows={7} />
        </div>
      </div>
    </>
  );
};

export default DesignAndContent;
