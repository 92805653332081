import { TagsView } from '@portal/ui';
import { DateFormats } from '@portal/utils/dates';
import moment from 'moment';
import ContactInfo from 'types/ContactInfo';
import { getFirstAndLastName } from '@portal/utils/string';
import { useMemo } from 'react';

type ContactInfoViewProps = {
  contact: ContactInfo;
};

const InfoElement = ({ label, value }: { label: string; value: string }) => (
  <div className="flex flex-col gap-1">
    <label className="text-sm font-medium leading-5 text-steel-500">{label}</label>
    <span className="text-steel-700 text-sm">{value}</span>
  </div>
);

const ContactInfoView = ({ contact }: ContactInfoViewProps) => {
  const [firstName, lastName] = getFirstAndLastName(contact.name);

  const addressLine1 = useMemo(() => contact.address?.addressLine1.split(',')?.[0] ?? '', [contact.address]);
  return (
    <div className="flex flex-col gap-5">
      <InfoElement label="First Name" value={firstName} />
      <InfoElement label="Last Name" value={lastName} />
      <InfoElement label="Phone" value={contact.phone} />
      <div className="flex flex-col gap-1">
        <label className="text-sm font-medium leading-5 text-steel-500">Address</label>
        <span className="text-steel-700 text-sm">
          {addressLine1}, {contact.address?.addressLine2}
          <br />
          {contact.address?.city}, {contact.address?.state} {contact.address?.pincode}
        </span>
      </div>
      <InfoElement label="Company" value={contact.companyName} />
      <InfoElement label="Created at" value={moment(contact.createdAt).format(DateFormats.DAY_OF_MONTH_WITH_YEAR)} />
      <div>
        <label className="text-sm font-medium leading-5 text-steel-500">Tags</label>
        <TagsView tags={contact.tags ?? []} />
      </div>
    </div>
  );
};

export default ContactInfoView;
