import { Routes, Route } from 'react-router-dom';

import { RequireAuth } from 'components/routing';

import { ContactsPage } from 'views/Contacts/ContactsPage';
import { ContactDetailsPage } from 'views/ContactDetails/ContactDetailsPage';
import { CalendarPage } from 'views/Calendar/CalendarPage';
import SettingsPage from 'views/Settings/SettingsPage';
import LoginPage from 'views/Login/LoginPage';
import OnboardingPage from 'views/Onboarding/OnboardingPage';
import GrowthBooksPage from 'views/Growthbooks/GrowthBooksPage';
import { ListingsPage } from 'views/Listings/ListingsPage';
import PaymentsPage from 'views/Payments/PaymentsPage';
import SitesPage from 'views/Sites/SitesPage';
import ProfilePage from 'views/Profile/ProfilePage';
import InboxPage from 'views/Inbox/InboxPage';
import { UnauthorizedPage } from 'views/Errors/UnauthorizedPage';

const AppRoutes = () => {
  return (
    <Routes>
      <Route
        exact
        path="/"
        element={
          <RequireAuth>
            <ContactsPage />
          </RequireAuth>
        }
      />
      <Route path="/login" element={<LoginPage />} />
      <Route path="/onboarding/:step?" element={<OnboardingPage />} />
      <Route
        path="/contacts"
        element={
          <RequireAuth>
            <ContactsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/calendar"
        element={
          <RequireAuth>
            <CalendarPage />
          </RequireAuth>
        }
      />
      <Route
        path="/contacts/:id"
        element={
          <RequireAuth>
            <ContactDetailsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/sites"
        element={
          <RequireAuth>
            <SitesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/settings/:tab"
        element={
          <RequireAuth>
            <SettingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/growthbooks"
        element={
          <RequireAuth>
            <GrowthBooksPage />
          </RequireAuth>
        }
      />
      <Route
        path="/payments"
        element={
          <RequireAuth>
            <PaymentsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/sites"
        element={
          <RequireAuth>
            <SitesPage />
          </RequireAuth>
        }
      />
      <Route
        path="/listings"
        element={
          <RequireAuth>
            <ListingsPage />
          </RequireAuth>
        }
      />
      <Route
        path="/profile/:tab"
        element={
          <RequireAuth>
            <ProfilePage />
          </RequireAuth>
        }
      />
      <Route
        path="/inbox"
        element={
          <RequireAuth>
            <InboxPage />
          </RequireAuth>
        }
      />
      <Route path="/unauthorized" element={<UnauthorizedPage />} />
    </Routes>
  );
};

export default AppRoutes;
