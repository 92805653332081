import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Details } from 'components/profile/Details';
import { Security } from 'components/profile/Security';

const ProfilePage = () => {
  const { tab } = useParams();

  const Sections = useMemo(() => {
    switch (tab) {
      case 'details':
        return Details;
      case 'security':
        return Security;
      default:
        return Details;
    }
  }, [tab]);

  return <Sections />;
};

export default ProfilePage;
