import { Formik, Form } from 'formik';
import { SubHeading } from '@portal/ui';
import { ConnectCalendars } from './ConnectCalendars';
import { calendarSettingsSchema } from 'components/validation/calendarSettingsSchema';
import { CalendarSettingsValues } from 'types/CalendarSettings';

export const CalendarSettings = () => {
  const handleOnsubmit = (values: CalendarSettingsValues) => {
    //TODO: Remove this console when make API call
    console.log('submitted', values);
  };

  const initialValues = {
    connectedCalendars: [
      {
        name: '',
        link: '',
      },
    ],
  };

  return (
    <div className="px-8">
      <SubHeading
        title="Calendar settings"
        description="Manage details related to your account's calendar tool."
        showBorder
        useHelmetTitle
      />
      <Formik initialValues={initialValues} validationSchema={calendarSettingsSchema} onSubmit={handleOnsubmit}>
        <Form className="h-full flex flex-col">
          <div className="calendar-settings-form py-8">
            <ConnectCalendars />
          </div>
        </Form>
      </Formik>
    </div>
  );
};
