import * as Yup from 'yup';

export const userSchema = Yup.object().shape({
  invitees: Yup.array().of(
    Yup.object().shape({
      email: Yup.string().email('Invalid email format').required('Email is required'),
      role: Yup.string().required('Role is required'),
    })
  ),
});
