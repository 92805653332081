import { useCallback, forwardRef, ForwardedRef } from 'react';
import { EventContentArg, DayHeaderContentArg, EventClickArg, EventSourceInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import { CalendarDisplayModes, FullCalendarEventType } from 'types/Calendar';
import { getFormattedDate } from 'utils/dates';
import { DateFormats } from '@portal/utils/dates';

type Props = {
  events: EventSourceInput;
  handleEventClick: (event: EventClickArg) => void;
  handleSelect: (event: FullCalendarEventType) => void;
};

export const CalendarView = forwardRef(function CalendarView(
  { events, handleEventClick, handleSelect }: Props,
  ref: ForwardedRef<FullCalendar>
) {
  const injectDayHeaderContent = useCallback((args: DayHeaderContentArg) => {
    const {
      date,
      view: { type },
    } = args;
    const day = getFormattedDate(date, DateFormats.WEEKDAY_NAME_SHORTER);
    const dateNumber = getFormattedDate(date, DateFormats.DAY_OF_MONTH);

    return type === CalendarDisplayModes.TIME_GRID_WEEK ? (
      <>
        <span className="text-xs text-steel-400 mr-1">{day}</span>
        <span className="text-xs">{dateNumber}</span>
      </>
    ) : (
      <span className="text-sm text-steel-800 font-medium">
        {getFormattedDate(date, DateFormats.SHORT_ABBREVIATED_WEEKDAY)}
      </span>
    );
  }, []);

  const renderEventContent = useCallback((eventInfo: EventContentArg) => {
    const {
      event: {
        title,
        start,
        end,
        textColor,
        extendedProps: { listColor, timeColor },
      },
      view: { type: viewType },
    } = eventInfo;

    return viewType === CalendarDisplayModes.TIME_GRID_WEEK ? (
      <span className="flex align-item-center flex-col mx-2 my-1">
        <span className={`mr-1 text-xs ${timeColor}`}>
          {getFormattedDate(start, DateFormats.HOURS_MINS_WITH_AMPM)} -{' '}
          {getFormattedDate(end, DateFormats.HOURS_MINS_WITH_AMPM)}
        </span>
        <span className={`truncate text-xs font-medium ${textColor}`}>{title}</span>
      </span>
    ) : (
      <span className="flex align-item-center flex-row truncate">
        <span className={`w-2 h-2 rounded-full self-center mr-1 ${listColor}`}></span>
        <span className="mr-1 text-xs text-black font-normal">
          {getFormattedDate(start, DateFormats.HOURS_MINS_WITH_AMPM)} -{' '}
          {getFormattedDate(end, DateFormats.HOURS_MINS_WITH_AMPM)}
        </span>
        <span className="text-xs text-black font-medium">{title}</span>
      </span>
    );
  }, []);

  return (
    <FullCalendar
      ref={ref}
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView={CalendarDisplayModes.TIME_GRID_WEEK}
      headerToolbar={false}
      editable
      selectable
      selectMirror
      unselectCancel=".appointment-form"
      snapDuration="00:30:00"
      nowIndicator
      allDaySlot={false}
      dragRevertDuration={60}
      dayHeaderContent={injectDayHeaderContent}
      showNonCurrentDates={false}
      height={821}
      select={handleSelect}
      events={events}
      eventClick={handleEventClick}
      eventContent={renderEventContent}
      views={{
        dayGrid: {
          // options apply to dayGridMonth, dayGridWeek, and dayGridDay view
          eventTimeFormat: {
            hour: 'numeric',
            meridiem: 'short',
          },
        },
      }}
    />
  );
});
