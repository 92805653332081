import { TextField, TextFieldProps } from '@portal/ui';

const FormField = (props: TextFieldProps) => (
  <div className="flex flex-col">
    <TextField
      {...props}
      customStyling={{
        field: 'form-field',
      }}
    />
  </div>
);

export default FormField;
