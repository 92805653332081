import { Menu, Transition } from '@headlessui/react';
import { getLocalTimeZone, parseDate, today } from '@internationalized/date';
import {
  Avatar,
  Button,
  CreatableSelect,
  DatePicker,
  Drawer,
  Icons,
  ListBox,
  RadioInput,
  SearchableInput,
  classNames,
} from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { currencyFormatter } from '@portal/utils/misc';
import { getInitials } from '@portal/utils/string';
import { ContactForm } from 'components/contacts/ContactForm';
import FormField from 'components/formField';
import { CardType, CardTypeIcons } from 'constants/CardType';
import { ITEMS, PAYMENT_DURATIONS } from 'constants/payments';
import { ErrorMessage, useFormikContext } from 'formik';
import {
  useCreatePaymentMethodMutation,
  useCreateProductMutation,
  useUpdatePaymentMethodMutation,
} from 'graphql/mutation.generated';
import {
  FindAllPaymentMethodsByContactIdDocument,
  useFindAllContactsQuery,
  useFindAllPaymentMethodsByContactIdLazyQuery,
  useFindAllProductsLazyQuery,
} from 'graphql/query.generated';
import { MerchantContactPaymentMethod } from 'graphql/types';
import _, { find } from 'lodash';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { DateValue } from 'react-aria';
import { useParams } from 'react-router-dom';
import { components } from 'react-select';
import { useSelector } from 'redux/hooks';
import { PaymentMethod } from 'types/PaymentMethod';
import InvoicePDF from 'views/InvoicePDF';
import { FormData, ItemType } from '../Invoice';
import AddNewItem from './AddNewItem';
import CardInput from './CardInput';

interface InvoiceFormProps {
  hidePreview: boolean;
}
type PaymentMethodType = MerchantContactPaymentMethod & {
  value: string;
  name: string;
};
const InvoiceForm = ({ hidePreview }: InvoiceFormProps) => {
  const merchantAccountId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const { data: customers } = useFindAllContactsQuery({
    variables: { merchantAccountId: merchantAccountId as string },
    skip: !merchantAccountId,
  });
  const { id: contactId } = useParams();

  const { values, setFieldValue, errors } = useFormikContext<FormData>();
  const [showCardInput, setShowCardInput] = useState<boolean>(false);
  const [showContactForm, setShowContactForm] = useState<boolean>(false);
  const [editItem, setEditItem] = useState<ItemType | null>(null);
  const [itemToBeAdded, setItemToBeAdded] = useState<ItemType | null>(null);
  const [findAllProducts, { data: products, loading: loadingProducts, refetch }] = useFindAllProductsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [createPaymentMethod] = useCreatePaymentMethodMutation({
    onCompleted: (res) => {
      toast.success('Card Added');
      if (!paymentMethods?.findAllPaymentMethodsByContactId?.paymentMethods?.length) {
        setCardAsPrimary(res.createPaymentMethod);
      }
    },
    onError: (err) => {
      console.log(err);
      toast.error('something went wrong');
    },
    refetchQueries: [FindAllPaymentMethodsByContactIdDocument],
  });
  const [updatePaymentMethod, { loading: updatePaymentMethodLoading }] = useUpdatePaymentMethodMutation({
    onCompleted: () => {
      toast.success('Card added as primary');
    },
    onError: (err) => {
      console.log(err);
      toast.error('something went wrong');
    },
    refetchQueries: [FindAllPaymentMethodsByContactIdDocument],
  });
  const [createProduct, { loading: loadingCreateProduct }] = useCreateProductMutation({
    onCompleted: (res) => {
      refetch();
    },
  });
  const [findAllPaymentsMethodByContactId, { data: paymentMethods }] = useFindAllPaymentMethodsByContactIdLazyQuery();
  useEffect(() => {
    if (merchantAccountId) findAllProducts({ variables: { merchantAccountId: merchantAccountId as string } });
  }, [merchantAccountId]);

  const handleCreateProduct = (item: ItemType) => {
    createProduct({
      variables: {
        merchantAccountId: merchantAccountId as string,
        data: {
          name: item.label,
          price: item.price as number,
        },
      },
    }).then((res) => {
      setFieldValue('items', [...values.items, { ...item, id: res?.data?.createProducts[0].id }]);
    });
  };

  const addItem = (item: ItemType) => {
    if (editItem) {
      values.items[editItem?.index ?? values.items.findIndex((item) => item.id === editItem.id)] = item;
      setFieldValue('items', [...values.items]);
    } else if (values.addItem) {
      setFieldValue('items', [...values.items, item]);
    } else if (values.addNewProduct) {
      handleCreateProduct(item);
    }

    setFieldValue('addItem', false);
    setFieldValue('addNewProduct', false);
    setEditItem(null);
    setItemToBeAdded(null);
  };
  const handleAddCardSubmit = (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      createPaymentMethod({
        variables: {
          data: {
            contactId: (contactId as string) || (values.customer?.id as string),
            stripeToken: paymentMethod.id,
          },
        },
      }).then((res) => {
        setFieldValue('paymentDuration', res.data?.createPaymentMethod.id);
        setShowCardInput(false);
      });
    }
  };
  const productOptions = useMemo(() => {
    return [
      ..._.map(products?.findAllProducts?.products, (product) => ({
        ...product,
        value: product.price,
        label: product.name,
        quantity: 1,
      })),
      ...ITEMS,
    ];
  }, [products]);
  const customerOptions = useMemo(
    () =>
      _.map(customers?.findAllContacts?.contacts, (customer) => ({
        value: customer.id,
        label: customer?.name !== '' ? customer?.name : customer?.email,
        ...customer,
      })),
    [customers]
  );

  const customer = useMemo(() => {
    const customer = find(customerOptions, { id: values?.customer?.id || contactId });
    if (!values?.customer?.id && customer) {
      setFieldValue('customer', customer);
      if (!values.payment) {
        setFieldValue('payment', 'REQUEST_PAYMENT');
      }
    }
    return customer;
  }, [contactId, customerOptions, values?.customer?.id]);
  useEffect(() => {
    if (customer?.id) {
      findAllPaymentsMethodByContactId({
        variables: {
          merchantContactId: customer?.id as string,
        },
      });
    }
  }, [customer, customer?.id]);

  const paymentMethodsOptions = useMemo(
    () => [
      ..._.map(paymentMethods?.findAllPaymentMethodsByContactId.paymentMethods, (paymentMethod) => ({
        value: paymentMethod.id,
        name: `${paymentMethod.cardType} ending in ${paymentMethod.last4Digits}`,
        ...paymentMethod,
      })),
      {
        value: 'add',
        id: 'add',
        name: '+ Add new payment method',
        isPrimary: null,
      },
    ],
    [paymentMethods, paymentMethods?.findAllPaymentMethodsByContactId.paymentMethods]
  );

  const primaryPaymentMethod = useMemo(() => {
    return paymentMethodsOptions.find((paymentMethod) => paymentMethod.isPrimary);
  }, [paymentMethodsOptions]);
  const paymentMethodNode = (paymentMethod: PaymentMethodType): React.ReactNode => (
    <div className="flex space-x-3 items-center justify-start px-0">
      {paymentMethod.id !== 'add' && (
        <div className="w-6 h-6">{CardTypeIcons[(paymentMethod.cardType as CardType) || 'Default']}</div>
      )}
      <span className="ml-2">{paymentMethod?.name}</span>
    </div>
  );

  const setCardAsPrimary = (paymentMethod: PaymentMethod) => {
    if (paymentMethod) {
      updatePaymentMethod({
        variables: {
          data: {
            paymentMethodId: paymentMethod.id as string,
            isPrimary: true,
          },
        },
      });
    }
  };
  return (
    <>
      <Drawer
        open={showContactForm}
        onClose={() => {
          setShowContactForm((prev) => !prev);
        }}
      >
        <ContactForm mode={'create'} />
      </Drawer>
      <div className="flex">
        <div
          className={classNames(
            'px-4 sm:px-6 py-5 lg:px-8 mb-10 overflow-y-auto h-[calc(100vh-70px)] w-full lg:w-1/2',
            'w-1/2 border-r border-r-steel-200'
          )}
        >
          <div
            className={classNames(
              `flex before:content flex-col pb-[80px] gap-6 `,
              !hidePreview ? 'w-full  m-auto' : ''
            )}
          >
            <div>
              <label className="block mb-2 text-lg font-semibold text-steel-900" htmlFor="customer">
                Customer
              </label>
              <CreatableSelect
                value={customer}
                options={customerOptions}
                isValidNewOption={() => true}
                customOptionNode={(props: any) => {
                  if (props.data.__isNew__) {
                    return (
                      <components.Option {...props}>
                        <p>+ Add new contact</p>
                      </components.Option>
                    );
                  }
                  return (
                    <components.Option {...props} className="!flex flex-row gap-2 items-center">
                      <Avatar imageUrl={props.data.avatar} size={'x_small'} initials={getInitials(props.data.name)} />
                      <p className="text-steel-900">{props.data?.name}</p>
                      <p className="text-steel-500 text-sm">{props.data?.email}</p>
                    </components.Option>
                  );
                }}
                onChange={(value: any) => {
                  if (value.__isNew__) {
                    setShowContactForm(true);
                  } else {
                    setFieldValue('customer', value);
                    if (!values.payment) {
                      setFieldValue('payment', 'REQUEST_PAYMENT');
                    }
                  }
                }}
              />
              {errors.customer && typeof errors.customer === 'string' ? (
                <ErrorMessage name="customer" component="div" className="text-red-500 text-sm" />
              ) : (
                errors.customer &&
                Object.keys(errors.customer).map((key) => (
                  <ErrorMessage key={key} name={`customer.${key}`} component="div" className="text-red-500 text-sm" />
                ))
              )}
            </div>
            <div>
              <label className="block mb-2 text-lg font-semibold text-steel-900" htmlFor="customer">
                Items
              </label>
              {errors.items && typeof errors.items === 'string' ? (
                <ErrorMessage name="items" component="div" className="text-red-500 text-sm" />
              ) : (
                errors.items &&
                Object.keys(errors.items).map((key) => (
                  <ErrorMessage key={key} name={`items.${key}`} component="div" className="text-red-500 text-sm" />
                ))
              )}
              <div className="my-2">
                {values.items.map((item, index) => (
                  <div
                    key={item.id}
                    className="bg-steel-50 p-5 text-steel-900 flex justify-between items-center border-b border-b-steel-200"
                  >
                    <div className="flex gap-2">
                      <p className="text-sm">{item?.label}</p>
                      <p className="text-sm">x {item?.quantity ?? 1}</p>
                    </div>
                    <div className="flex gap-5 items-center">
                      <p className="text-sm">
                        {currencyFormatter(
                          item?.price ? item?.price * (item?.quantity ?? 1) : 1 * (item?.quantity ?? 1)
                        )}
                      </p>
                      <Menu as="div" className="relative inline-block text-left">
                        <div className="flex items-center">
                          <Menu.Button className="pl-5 pr-5">
                            <Icons.DotsHorizontal className="w-5 h-5" aria-hidden="true" />
                          </Menu.Button>
                        </div>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="z-10 absolute right-0 mt-2 w-56 origin-top-right  rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none ">
                            <div
                              className="px-2 py-2 "
                              onClick={() => {
                                setEditItem({ ...item, index });
                              }}
                            >
                              <Menu.Item>
                                <Button title="edit invoice item" displayType="text" iconOnly>
                                  Edit
                                </Button>
                              </Menu.Item>
                            </div>
                            <div
                              className="px-2 py-2"
                              onClick={() =>
                                setFieldValue(
                                  'items',
                                  values.items.filter((i) => i.value !== item.value)
                                )
                              }
                            >
                              <Menu.Item>
                                <Button title="delete invoice item" displayType="text" iconOnly>
                                  Delete
                                </Button>
                              </Menu.Item>
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                ))}
                {(values.addItem || values.addNewProduct || !_.isEmpty(editItem)) && (
                  <AddNewItem
                    addItem={addItem}
                    handleCancel={() => {
                      setFieldValue('addItem', false);
                      setFieldValue('addNewProduct', false);
                      setEditItem(null);
                      setItemToBeAdded(null);
                    }}
                    item={itemToBeAdded ?? editItem}
                  />
                )}
              </div>
              <SearchableInput
                placeholder="Find or add an item"
                hideSelectedOptions={false}
                options={productOptions}
                controlShouldRenderValue={false}
                isMulti
                onInputChange={(value) => setItemToBeAdded({ label: value, price: 0, quantity: 1, value: '0' })}
                isLoading={loadingCreateProduct}
                value={values.items}
                customOptionNode={(props: any) => {
                  if (props.value === 'addItem' || props.value === 'addNewProduct') {
                    return (
                      <div
                        className="listOption"
                        onClick={() => {
                          setItemToBeAdded({ label: props.selectProps.inputValue, price: 0, quantity: 1, value: '0' });
                          setFieldValue(props.value, !values[props.value as keyof FormData]);
                        }}
                      >
                        {props.selectProps.inputValue !== ''
                          ? props.value === 'addItem'
                            ? `+ Add '${props.selectProps.inputValue}' as one time item`
                            : `+ Create '${props.selectProps.inputValue}' as new product`
                          : props.label}
                      </div>
                    );
                  }
                  return (
                    <components.Option className="cursor-pointer" {...props}>
                      <div className="text-sm">{props.label}</div>
                      <div className="text-xs text-steel-600">${props.data.value}</div>
                    </components.Option>
                  );
                }}
                handleChange={(value, action) => {
                  if (action.action === 'deselect-option') {
                    setFieldValue('items', [...values.items, action.option]);
                  } else if (
                    action.action === 'select-option' &&
                    action?.option &&
                    action?.option?.value === 'addNewProduct'
                  ) {
                    setItemToBeAdded({ price: 0, quantity: 1, value: '0', label: '', ...itemToBeAdded });
                    setFieldValue('addNewProduct', !values['addItem']);
                  } else setFieldValue('items', value);
                }}
              />
            </div>
            <div>
              <label className="block mb-2 text-lg font-semibold text-steel-900" htmlFor="customer">
                Payment
              </label>
              {errors.payment && <ErrorMessage name="payment" component="div" className="text-red-500 text-sm" />}
              <div className="flex flex-col gap-2 " role="group" aria-labelledby="my-radio-group">
                <RadioInput
                  disabled={!values.customer}
                  name="payment"
                  label="Request payment"
                  value="REQUEST_PAYMENT"
                  info="Create an invoice requesting payment on a specific date"
                />

                {values.payment === 'REQUEST_PAYMENT' && (
                  <div className="flex gap-3 [&>*]:w-1/2 items-center pt-2">
                    <div className="pl-6">
                      <ListBox
                        options={PAYMENT_DURATIONS}
                        value={find(PAYMENT_DURATIONS, { value: values.paymentDuration })}
                        onChange={(value: string) => setFieldValue('paymentDuration', value)}
                      />
                    </div>
                    {values.paymentDuration === 'custom' && (
                      <>
                        <DatePicker
                          onChange={(value: DateValue) => setFieldValue('customDate', value.toDate(getLocalTimeZone()))}
                          minValue={today(getLocalTimeZone())}
                          value={
                            values.customDate
                              ? parseDate(
                                  values.customDate?.toLocaleDateString('fr-CA', {
                                    year: 'numeric',
                                    month: '2-digit',
                                    day: '2-digit',
                                  })
                                )
                              : today(getLocalTimeZone())
                          }
                        />
                      </>
                    )}
                  </div>
                )}

                <hr className="bg-steel-200 my-2" />
                <RadioInput
                  disabled={!values.customer}
                  name="payment"
                  label="Autocharge"
                  value="AUTO"
                  info="Automatically charge a payment method on file "
                />
                {values.payment === 'AUTO' && paymentMethodsOptions.length > 1 && !showCardInput && (
                  <div className="payments-dropdown">
                    <ListBox
                      options={paymentMethodsOptions}
                      optionNode={(option) => paymentMethodNode(option as PaymentMethodType)}
                      selectedNode={paymentMethodNode(
                        (paymentMethodsOptions.find(
                          (paymentMethod) => paymentMethod.id === values.paymentDuration
                        ) as PaymentMethodType) ||
                          primaryPaymentMethod ||
                          paymentMethodsOptions[0]
                      )}
                      onChange={(value: string) => {
                        if (value === 'add') {
                          setShowCardInput(true);
                        } else {
                          setFieldValue('paymentDuration', value);
                        }
                      }}
                    />
                  </div>
                )}
                {values.payment === 'AUTO' && (paymentMethodsOptions.length <= 1 || showCardInput) && (
                  <CardInput
                    showCancel={!!paymentMethods?.findAllPaymentMethodsByContactId?.paymentMethods?.length}
                    handleSubmit={handleAddCardSubmit}
                    handleCancel={() => {
                      setShowCardInput(false);
                    }}
                  />
                )}
              </div>
            </div>

            <div>
              <label className="block mb-2 text-lg font-semibold text-steel-900" htmlFor="customer">
                Delivery
              </label>
              {errors.delivery && <ErrorMessage name="delivery" component="div" className="text-red-500 text-sm" />}
              <div className="flex flex-col gap-2" role="group" aria-labelledby="my-radio-group">
                <RadioInput
                  name="delivery"
                  label="Email invoice to customer"
                  value={'true'}
                  info="Customer will receive an email with a PDF of the invoice and a link to a payment page."
                />
                <hr className="bg-steel-200 my-2" />
                <RadioInput
                  name="delivery"
                  label="Don’t email invoice to customer"
                  value={'false'}
                  info="A link to an online payment page will still be created after finalizing this invoice for you to send manually"
                />
              </div>
            </div>

            <div>
              <label className="block mb-2 text-lg font-semibold text-steel-900" htmlFor="customer">
                Notes
              </label>
              <FormField id="subject" type="text" label="Subject" />
            </div>

            <div>
              <FormField id="memo" type="textarea" label="Memo" maxLength={500} />
              <p className="text-sm font-normal text-steel-600 mt-1.5">{500 - values.memo?.length} characters left</p>
            </div>
          </div>
          <div></div>
        </div>

        {hidePreview && (
          <div className="hidden lg:block w-1/2 px-10 mb-10 bg-steel-50 overflow-y-auto h-screen">
            <h1 className="text-lg text-steel-900 my-5 font-semibold">Preview</h1>
            {values && <InvoicePDF values={values} />}
          </div>
        )}
      </div>
    </>
  );
};

export default InvoiceForm;
