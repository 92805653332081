/* eslint-disable */
import * as Types from './types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type CreateProductMutationVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  data: Array<Types.CreateProductInput> | Types.CreateProductInput;
}>;

export type CreateProductMutation = {
  __typename?: 'Mutation';
  createProducts: Array<{
    __typename?: 'MerchantProduct';
    id: string;
    name: string;
    price: number;
    createdAt: any;
    updatedAt: any;
  }>;
};

export type DeleteProductMutationVariables = Types.Exact<{
  merchantProductId: Types.Scalars['String'];
}>;

export type DeleteProductMutation = { __typename?: 'Mutation'; deleteProduct?: boolean | null };

export type UpdateProductMutationVariables = Types.Exact<{
  data: Types.UpdateProductInput;
}>;

export type UpdateProductMutation = {
  __typename?: 'Mutation';
  updateProduct: {
    __typename?: 'MerchantProduct';
    id: string;
    name: string;
    price: number;
    createdAt: any;
    updatedAt: any;
  };
};

export type CreateContactMutationVariables = Types.Exact<{
  data: Types.CreateContactInput;
}>;

export type CreateContactMutation = {
  __typename?: 'Mutation';
  createContact: {
    __typename?: 'CreateContactResponse';
    contact: { __typename?: 'MerchantContact'; id: string };
    avatarUploadForm?: {
      __typename?: 'UploadForm';
      formPostUrl: string;
      key?: string | null;
      bucket?: string | null;
      X__Amz__Algorithm?: string | null;
      X__Amz__Credential?: string | null;
      X__Amz__Date?: string | null;
      Policy?: string | null;
      X__Amz__Signature?: string | null;
      X__Amz__Security__Token?: string | null;
    } | null;
  };
};

export type UpdateContactMutationVariables = Types.Exact<{
  data: Types.UpdateContactInput;
}>;

export type UpdateContactMutation = {
  __typename?: 'Mutation';
  updateContact: { __typename?: 'MerchantContact'; id: string };
};

export type UpdateContactAvatarMutationVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
  imgMimeType: Types.Scalars['String'];
}>;

export type UpdateContactAvatarMutation = {
  __typename?: 'Mutation';
  requestContactAvatarUpdate: {
    __typename?: 'UploadForm';
    formPostUrl: string;
    key?: string | null;
    bucket?: string | null;
    X__Amz__Algorithm?: string | null;
    X__Amz__Credential?: string | null;
    X__Amz__Date?: string | null;
    Policy?: string | null;
    X__Amz__Signature?: string | null;
    X__Amz__Security__Token?: string | null;
  };
};

export type DeleteContactMutationVariables = Types.Exact<{
  merchantContactId: Types.Scalars['String'];
}>;

export type DeleteContactMutation = { __typename?: 'Mutation'; deleteContact?: boolean | null };

export type CreateTagMutationVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  name: Types.Scalars['String'];
}>;

export type CreateTagMutation = {
  __typename?: 'Mutation';
  createTag: { __typename?: 'MerchantTag'; id: string; name: string };
};

export type InviteUserMutationVariables = Types.Exact<{
  data: Types.InviteMerchantUserInput;
}>;

export type InviteUserMutation = {
  __typename?: 'Mutation';
  inviteMerchantUser: {
    __typename?: 'MerchantAccountUser';
    id: string;
    name: string;
    email: string;
    cognitoUsername: string;
    tzName: string;
    status: Types.MerchantAccountUserStatus;
    role: Types.MerchantAccountUserRole;
    avatar?: string | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type ToggleMerchantUserDisabledMutationVariables = Types.Exact<{
  merchantAccountUserId: Types.Scalars['String'];
  disabled: Types.Scalars['Boolean'];
}>;

export type ToggleMerchantUserDisabledMutation = {
  __typename?: 'Mutation';
  toggleMerchantUserDisabled?: boolean | null;
};

export type UpdateMerchantUserRoleMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantUserRoleInput;
}>;

export type UpdateMerchantUserRoleMutation = {
  __typename?: 'Mutation';
  updateMerchantUserRole: {
    __typename?: 'MerchantAccountUser';
    id: string;
    name: string;
    email: string;
    cognitoUsername: string;
    tzName: string;
    status: Types.MerchantAccountUserStatus;
    role: Types.MerchantAccountUserRole;
    avatar?: string | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type DeleteTagMutationVariables = Types.Exact<{
  merchantTagId: Types.Scalars['String'];
}>;

export type DeleteTagMutation = { __typename?: 'Mutation'; deleteTag?: boolean | null };

export type UpdateTagMutationVariables = Types.Exact<{
  data: Types.UpdateTagInput;
}>;

export type UpdateTagMutation = {
  __typename?: 'Mutation';
  updateTag: { __typename?: 'MerchantTag'; id: string; name: string; createdAt: any; updatedAt: any };
};

export type UpdateMerchantUserMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantUserInput;
}>;

export type UpdateMerchantUserMutation = {
  __typename?: 'Mutation';
  updateMerchantUser: {
    __typename?: 'MerchantAccountUser';
    id: string;
    name: string;
    email: string;
    cognitoUsername: string;
    tzName: string;
    avatar?: string | null;
    createdAt: any;
    updatedAt: any;
  };
};

export type RequestMerchantrUserAvatarUpdateMutationVariables = Types.Exact<{
  imgMimeType: Types.Scalars['String'];
}>;

export type RequestMerchantrUserAvatarUpdateMutation = {
  __typename?: 'Mutation';
  requestMerchantUserAvatarUpdate: {
    __typename?: 'UploadForm';
    formPostUrl: string;
    key?: string | null;
    bucket?: string | null;
    X__Amz__Algorithm?: string | null;
    X__Amz__Credential?: string | null;
    X__Amz__Date?: string | null;
    Policy?: string | null;
    X__Amz__Signature?: string | null;
    X__Amz__Security__Token?: string | null;
  };
};

export type UpdateBookingConfigurationMutationVariables = Types.Exact<{
  data: Types.UpdateBookingConfigurationInput;
}>;

export type UpdateBookingConfigurationMutation = {
  __typename?: 'Mutation';
  updateBookingConfiguration: {
    __typename?: 'BookingConfiguration';
    title: string;
    slug: string;
    welcomeMessage: string;
    tzName: string;
    availability: Array<{
      __typename?: 'BookingConfigurationAvailability';
      id: string;
      day: Types.BookingConfigurationAvailabilityDay;
      enabled: boolean;
      startTime: string;
      endTime: string;
    }>;
  };
};

export type CreateEventTypeMutationVariables = Types.Exact<{
  data: Types.CreateEventTypeInput;
}>;

export type CreateEventTypeMutation = {
  __typename?: 'Mutation';
  createEventType: {
    __typename?: 'EventType';
    color: string;
    dayAvailabilityInFuture: number;
    description: string;
    duration: number;
    location?: string | null;
    name: string;
    slug: string;
    questions: Array<{ __typename?: 'EventTypeQuestion'; text: string; required: boolean }>;
  };
};

export type UpdateEventTypeMutationVariables = Types.Exact<{
  data: Types.UpdateEventTypeInput;
}>;

export type UpdateEventTypeMutation = {
  __typename?: 'Mutation';
  updateEventType: {
    __typename?: 'EventType';
    color: string;
    dayAvailabilityInFuture: number;
    description: string;
    duration: number;
    id: string;
    location?: string | null;
    name: string;
    slug: string;
    questions: Array<{ __typename?: 'EventTypeQuestion'; text: string; required: boolean }>;
  };
};

export type DeleteEventTypeMutationVariables = Types.Exact<{
  eventTypeId: Types.Scalars['String'];
}>;

export type DeleteEventTypeMutation = { __typename?: 'Mutation'; deleteEventType?: boolean | null };

export type DeleteMerchantUserMutationVariables = Types.Exact<{
  merchantAccountUserId: Types.Scalars['String'];
}>;

export type DeleteMerchantUserMutation = { __typename?: 'Mutation'; deleteMerchantUser?: boolean | null };

export type CreateInvoiceMutationVariables = Types.Exact<{
  data: Types.CreateMerchantContactInvoiceInput;
}>;

export type CreateInvoiceMutation = {
  __typename?: 'Mutation';
  createInvoice: { __typename?: 'MerchantContactInvoice'; id: string };
};

export type CreatePaymentMutationVariables = Types.Exact<{
  data: Types.CreateMerchantContactPaymentInput;
}>;

export type CreatePaymentMutation = {
  __typename?: 'Mutation';
  createPayment: {
    __typename?: 'MerchantContactPayment';
    id: string;
    contact: { __typename?: 'MerchantContact'; id: string; name: string };
  };
};

export type RequestMerchantAvatarUpdateMutationVariables = Types.Exact<{
  merchantAccountId: Types.Scalars['String'];
  imgMimeType: Types.Scalars['String'];
}>;

export type RequestMerchantAvatarUpdateMutation = {
  __typename?: 'Mutation';
  requestMerchantLogoUpdate: {
    __typename?: 'UploadForm';
    formPostUrl: string;
    key?: string | null;
    bucket?: string | null;
    X__Amz__Algorithm?: string | null;
    X__Amz__Credential?: string | null;
    X__Amz__Date?: string | null;
    Policy?: string | null;
    X__Amz__Signature?: string | null;
    X__Amz__Security__Token?: string | null;
  };
};

export type UpdateMerchantMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantInput;
}>;

export type UpdateMerchantMutation = {
  __typename?: 'Mutation';
  updateMerchant: { __typename?: 'MerchantAccount'; id: string; name: string; logo?: string | null };
};

export type DeleteInvoiceMutationVariables = Types.Exact<{
  invoiceId: Types.Scalars['String'];
}>;

export type DeleteInvoiceMutation = { __typename?: 'Mutation'; deleteInvoice?: boolean | null };

export type DeletePaymentMutationVariables = Types.Exact<{
  paymentId: Types.Scalars['String'];
}>;

export type DeletePaymentMutation = { __typename?: 'Mutation'; deletePayment?: boolean | null };

export type UpdatePaymentMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantContactPaymentInput;
}>;

export type UpdatePaymentMutation = {
  __typename?: 'Mutation';
  updatePayment: {
    __typename?: 'MerchantContactPayment';
    id: string;
    contact: { __typename?: 'MerchantContact'; id: string; name: string };
  };
};

export type UpdateInvoiceMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantContactInvoiceInput;
}>;

export type UpdateInvoiceMutation = {
  __typename?: 'Mutation';
  updateInvoice: { __typename?: 'MerchantContactInvoice'; id: string };
};

export type CreateEventMutationVariables = Types.Exact<{
  data: Types.CreateEventInput;
}>;

export type CreateEventMutation = {
  __typename?: 'Mutation';
  createEvent: {
    __typename?: 'Event';
    title: string;
    description?: string | null;
    startTime: any;
    endTime: any;
    locationType: Types.EventLocationType;
    phone?: string | null;
    location?: string | null;
    sendReminderAt?: any | null;
    sendSMSReminder: boolean;
    sendEmailReminder: boolean;
    guests: Array<string>;
  };
};

export type UpdateEventMutationVariables = Types.Exact<{
  data: Types.UpdateEventInput;
}>;

export type UpdateEventMutation = {
  __typename?: 'Mutation';
  updateEvent: {
    __typename?: 'Event';
    title: string;
    description?: string | null;
    startTime: any;
    endTime: any;
    locationType: Types.EventLocationType;
    phone?: string | null;
    location?: string | null;
    sendReminderAt?: any | null;
    sendSMSReminder: boolean;
    sendEmailReminder: boolean;
    guests: Array<string>;
  };
};

export type CreatePaymentMethodMutationVariables = Types.Exact<{
  data: Types.CreateMerchantContactPaymentMethodInput;
}>;

export type CreatePaymentMethodMutation = {
  __typename?: 'Mutation';
  createPaymentMethod: {
    __typename?: 'MerchantContactPaymentMethod';
    id: string;
    last4Digits: string;
    type: Types.MerchantContactPaymentMethodType;
    cardType?: string | null;
    expiration?: string | null;
    isPrimary: boolean;
    contact: { __typename?: 'MerchantContact'; id: string; email: string };
  };
};

export type UpdatePaymentMethodMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantContactPaymentMethodInput;
}>;

export type UpdatePaymentMethodMutation = {
  __typename?: 'Mutation';
  updatePaymentMethod: {
    __typename?: 'MerchantContactPaymentMethod';
    id: string;
    last4Digits: string;
    type: Types.MerchantContactPaymentMethodType;
    cardType?: string | null;
    expiration?: string | null;
    isPrimary: boolean;
    contact: { __typename?: 'MerchantContact'; id: string; email: string };
  };
};

export type CreateSiteMutationVariables = Types.Exact<{
  data: Types.CreateMerchantSiteInput;
}>;

export type CreateSiteMutation = {
  __typename?: 'Mutation';
  createSite: {
    __typename?: 'MerchantSite';
    businessName: string;
    id: string;
    businessPhone: string;
    businessAddress: string;
    businessEmail: string;
    businessCategory: string;
    languagesSpoken: Array<string>;
    areasServices: Array<string>;
    businessAbout: string;
    businessMission: string;
    businessFoundedYear: number;
    facebookSlug?: string | null;
    hours: Array<{ __typename?: 'MerchantSiteHour'; day: Types.Weekday; startTime: string; endTime: string }>;
  };
};

export type DeleteSiteMutationVariables = Types.Exact<{
  id: Types.Scalars['String'];
}>;

export type DeleteSiteMutation = { __typename?: 'Mutation'; deleteSite?: boolean | null };

export type UpdateSiteMutationVariables = Types.Exact<{
  data: Types.UpdateMerchantSiteInput;
}>;

export type UpdateSiteMutation = {
  __typename?: 'Mutation';
  updateSite: {
    __typename?: 'MerchantSite';
    businessName: string;
    businessPhone: string;
    id: string;
    businessAddress: string;
    businessEmail: string;
    businessCategory: string;
    languagesSpoken: Array<string>;
    areasServices: Array<string>;
    businessAbout: string;
    businessMission: string;
    businessFoundedYear: number;
    facebookSlug?: string | null;
    hours: Array<{ __typename?: 'MerchantSiteHour'; day: Types.Weekday; startTime: string; endTime: string }>;
  };
};

export const CreateProductDocument = gql`
  mutation CreateProduct($merchantAccountId: String!, $data: [CreateProductInput!]!) {
    createProducts(merchantAccountId: $merchantAccountId, data: $data) {
      id
      name
      price
      createdAt
      updatedAt
    }
  }
`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
}
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<
  CreateProductMutation,
  CreateProductMutationVariables
>;
export const DeleteProductDocument = gql`
  mutation DeleteProduct($merchantProductId: String!) {
    deleteProduct(merchantProductId: $merchantProductId)
  }
`;
export type DeleteProductMutationFn = Apollo.MutationFunction<DeleteProductMutation, DeleteProductMutationVariables>;

/**
 * __useDeleteProductMutation__
 *
 * To run a mutation, you first call `useDeleteProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteProductMutation, { data, loading, error }] = useDeleteProductMutation({
 *   variables: {
 *      merchantProductId: // value for 'merchantProductId'
 *   },
 * });
 */
export function useDeleteProductMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteProductMutation, DeleteProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteProductMutation, DeleteProductMutationVariables>(DeleteProductDocument, options);
}
export type DeleteProductMutationHookResult = ReturnType<typeof useDeleteProductMutation>;
export type DeleteProductMutationResult = Apollo.MutationResult<DeleteProductMutation>;
export type DeleteProductMutationOptions = Apollo.BaseMutationOptions<
  DeleteProductMutation,
  DeleteProductMutationVariables
>;
export const UpdateProductDocument = gql`
  mutation UpdateProduct($data: UpdateProductInput!) {
    updateProduct(data: $data) {
      id
      name
      price
      createdAt
      updatedAt
    }
  }
`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateProductMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
}
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<
  UpdateProductMutation,
  UpdateProductMutationVariables
>;
export const CreateContactDocument = gql`
  mutation CreateContact($data: CreateContactInput!) {
    createContact(data: $data) {
      contact {
        id
      }
      avatarUploadForm {
        formPostUrl
        key
        bucket
        X__Amz__Algorithm
        X__Amz__Credential
        X__Amz__Date
        Policy
        X__Amz__Signature
        X__Amz__Security__Token
      }
    }
  }
`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
}
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<
  CreateContactMutation,
  CreateContactMutationVariables
>;
export const UpdateContactDocument = gql`
  mutation UpdateContact($data: UpdateContactInput!) {
    updateContact(data: $data) {
      id
    }
  }
`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateContactMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
}
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactMutation,
  UpdateContactMutationVariables
>;
export const UpdateContactAvatarDocument = gql`
  mutation UpdateContactAvatar($merchantContactId: String!, $imgMimeType: String!) {
    requestContactAvatarUpdate(merchantContactId: $merchantContactId, imgMimeType: $imgMimeType) {
      formPostUrl
      key
      bucket
      X__Amz__Algorithm
      X__Amz__Credential
      X__Amz__Date
      Policy
      X__Amz__Signature
      X__Amz__Security__Token
    }
  }
`;
export type UpdateContactAvatarMutationFn = Apollo.MutationFunction<
  UpdateContactAvatarMutation,
  UpdateContactAvatarMutationVariables
>;

/**
 * __useUpdateContactAvatarMutation__
 *
 * To run a mutation, you first call `useUpdateContactAvatarMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactAvatarMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactAvatarMutation, { data, loading, error }] = useUpdateContactAvatarMutation({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *      imgMimeType: // value for 'imgMimeType'
 *   },
 * });
 */
export function useUpdateContactAvatarMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateContactAvatarMutation, UpdateContactAvatarMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateContactAvatarMutation, UpdateContactAvatarMutationVariables>(
    UpdateContactAvatarDocument,
    options
  );
}
export type UpdateContactAvatarMutationHookResult = ReturnType<typeof useUpdateContactAvatarMutation>;
export type UpdateContactAvatarMutationResult = Apollo.MutationResult<UpdateContactAvatarMutation>;
export type UpdateContactAvatarMutationOptions = Apollo.BaseMutationOptions<
  UpdateContactAvatarMutation,
  UpdateContactAvatarMutationVariables
>;
export const DeleteContactDocument = gql`
  mutation DeleteContact($merchantContactId: String!) {
    deleteContact(merchantContactId: $merchantContactId)
  }
`;
export type DeleteContactMutationFn = Apollo.MutationFunction<DeleteContactMutation, DeleteContactMutationVariables>;

/**
 * __useDeleteContactMutation__
 *
 * To run a mutation, you first call `useDeleteContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteContactMutation, { data, loading, error }] = useDeleteContactMutation({
 *   variables: {
 *      merchantContactId: // value for 'merchantContactId'
 *   },
 * });
 */
export function useDeleteContactMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteContactMutation, DeleteContactMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteContactMutation, DeleteContactMutationVariables>(DeleteContactDocument, options);
}
export type DeleteContactMutationHookResult = ReturnType<typeof useDeleteContactMutation>;
export type DeleteContactMutationResult = Apollo.MutationResult<DeleteContactMutation>;
export type DeleteContactMutationOptions = Apollo.BaseMutationOptions<
  DeleteContactMutation,
  DeleteContactMutationVariables
>;
export const CreateTagDocument = gql`
  mutation CreateTag($merchantAccountId: String!, $name: String!) {
    createTag(merchantAccountId: $merchantAccountId, name: $name) {
      id
      name
    }
  }
`;
export type CreateTagMutationFn = Apollo.MutationFunction<CreateTagMutation, CreateTagMutationVariables>;

/**
 * __useCreateTagMutation__
 *
 * To run a mutation, you first call `useCreateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createTagMutation, { data, loading, error }] = useCreateTagMutation({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useCreateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateTagMutation, CreateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateTagMutation, CreateTagMutationVariables>(CreateTagDocument, options);
}
export type CreateTagMutationHookResult = ReturnType<typeof useCreateTagMutation>;
export type CreateTagMutationResult = Apollo.MutationResult<CreateTagMutation>;
export type CreateTagMutationOptions = Apollo.BaseMutationOptions<CreateTagMutation, CreateTagMutationVariables>;
export const InviteUserDocument = gql`
  mutation InviteUser($data: InviteMerchantUserInput!) {
    inviteMerchantUser(data: $data) {
      id
      name
      email
      cognitoUsername
      tzName
      status
      role
      avatar
      createdAt
      updatedAt
    }
  }
`;
export type InviteUserMutationFn = Apollo.MutationFunction<InviteUserMutation, InviteUserMutationVariables>;

/**
 * __useInviteUserMutation__
 *
 * To run a mutation, you first call `useInviteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInviteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [inviteUserMutation, { data, loading, error }] = useInviteUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useInviteUserMutation(
  baseOptions?: Apollo.MutationHookOptions<InviteUserMutation, InviteUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InviteUserMutation, InviteUserMutationVariables>(InviteUserDocument, options);
}
export type InviteUserMutationHookResult = ReturnType<typeof useInviteUserMutation>;
export type InviteUserMutationResult = Apollo.MutationResult<InviteUserMutation>;
export type InviteUserMutationOptions = Apollo.BaseMutationOptions<InviteUserMutation, InviteUserMutationVariables>;
export const ToggleMerchantUserDisabledDocument = gql`
  mutation ToggleMerchantUserDisabled($merchantAccountUserId: String!, $disabled: Boolean!) {
    toggleMerchantUserDisabled(merchantAccountUserId: $merchantAccountUserId, disabled: $disabled)
  }
`;
export type ToggleMerchantUserDisabledMutationFn = Apollo.MutationFunction<
  ToggleMerchantUserDisabledMutation,
  ToggleMerchantUserDisabledMutationVariables
>;

/**
 * __useToggleMerchantUserDisabledMutation__
 *
 * To run a mutation, you first call `useToggleMerchantUserDisabledMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useToggleMerchantUserDisabledMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [toggleMerchantUserDisabledMutation, { data, loading, error }] = useToggleMerchantUserDisabledMutation({
 *   variables: {
 *      merchantAccountUserId: // value for 'merchantAccountUserId'
 *      disabled: // value for 'disabled'
 *   },
 * });
 */
export function useToggleMerchantUserDisabledMutation(
  baseOptions?: Apollo.MutationHookOptions<
    ToggleMerchantUserDisabledMutation,
    ToggleMerchantUserDisabledMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<ToggleMerchantUserDisabledMutation, ToggleMerchantUserDisabledMutationVariables>(
    ToggleMerchantUserDisabledDocument,
    options
  );
}
export type ToggleMerchantUserDisabledMutationHookResult = ReturnType<typeof useToggleMerchantUserDisabledMutation>;
export type ToggleMerchantUserDisabledMutationResult = Apollo.MutationResult<ToggleMerchantUserDisabledMutation>;
export type ToggleMerchantUserDisabledMutationOptions = Apollo.BaseMutationOptions<
  ToggleMerchantUserDisabledMutation,
  ToggleMerchantUserDisabledMutationVariables
>;
export const UpdateMerchantUserRoleDocument = gql`
  mutation UpdateMerchantUserRole($data: UpdateMerchantUserRoleInput!) {
    updateMerchantUserRole(data: $data) {
      id
      name
      email
      cognitoUsername
      tzName
      status
      role
      avatar
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMerchantUserRoleMutationFn = Apollo.MutationFunction<
  UpdateMerchantUserRoleMutation,
  UpdateMerchantUserRoleMutationVariables
>;

/**
 * __useUpdateMerchantUserRoleMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantUserRoleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantUserRoleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantUserRoleMutation, { data, loading, error }] = useUpdateMerchantUserRoleMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMerchantUserRoleMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMerchantUserRoleMutation, UpdateMerchantUserRoleMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMerchantUserRoleMutation, UpdateMerchantUserRoleMutationVariables>(
    UpdateMerchantUserRoleDocument,
    options
  );
}
export type UpdateMerchantUserRoleMutationHookResult = ReturnType<typeof useUpdateMerchantUserRoleMutation>;
export type UpdateMerchantUserRoleMutationResult = Apollo.MutationResult<UpdateMerchantUserRoleMutation>;
export type UpdateMerchantUserRoleMutationOptions = Apollo.BaseMutationOptions<
  UpdateMerchantUserRoleMutation,
  UpdateMerchantUserRoleMutationVariables
>;
export const DeleteTagDocument = gql`
  mutation DeleteTag($merchantTagId: String!) {
    deleteTag(merchantTagId: $merchantTagId)
  }
`;
export type DeleteTagMutationFn = Apollo.MutationFunction<DeleteTagMutation, DeleteTagMutationVariables>;

/**
 * __useDeleteTagMutation__
 *
 * To run a mutation, you first call `useDeleteTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTagMutation, { data, loading, error }] = useDeleteTagMutation({
 *   variables: {
 *      merchantTagId: // value for 'merchantTagId'
 *   },
 * });
 */
export function useDeleteTagMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteTagMutation, DeleteTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteTagMutation, DeleteTagMutationVariables>(DeleteTagDocument, options);
}
export type DeleteTagMutationHookResult = ReturnType<typeof useDeleteTagMutation>;
export type DeleteTagMutationResult = Apollo.MutationResult<DeleteTagMutation>;
export type DeleteTagMutationOptions = Apollo.BaseMutationOptions<DeleteTagMutation, DeleteTagMutationVariables>;
export const UpdateTagDocument = gql`
  mutation UpdateTag($data: UpdateTagInput!) {
    updateTag(data: $data) {
      id
      name
      createdAt
      updatedAt
    }
  }
`;
export type UpdateTagMutationFn = Apollo.MutationFunction<UpdateTagMutation, UpdateTagMutationVariables>;

/**
 * __useUpdateTagMutation__
 *
 * To run a mutation, you first call `useUpdateTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateTagMutation, { data, loading, error }] = useUpdateTagMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateTagMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateTagMutation, UpdateTagMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateTagMutation, UpdateTagMutationVariables>(UpdateTagDocument, options);
}
export type UpdateTagMutationHookResult = ReturnType<typeof useUpdateTagMutation>;
export type UpdateTagMutationResult = Apollo.MutationResult<UpdateTagMutation>;
export type UpdateTagMutationOptions = Apollo.BaseMutationOptions<UpdateTagMutation, UpdateTagMutationVariables>;
export const UpdateMerchantUserDocument = gql`
  mutation UpdateMerchantUser($data: UpdateMerchantUserInput!) {
    updateMerchantUser(data: $data) {
      id
      name
      email
      cognitoUsername
      tzName
      avatar
      createdAt
      updatedAt
    }
  }
`;
export type UpdateMerchantUserMutationFn = Apollo.MutationFunction<
  UpdateMerchantUserMutation,
  UpdateMerchantUserMutationVariables
>;

/**
 * __useUpdateMerchantUserMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantUserMutation, { data, loading, error }] = useUpdateMerchantUserMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMerchantUserMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMerchantUserMutation, UpdateMerchantUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMerchantUserMutation, UpdateMerchantUserMutationVariables>(
    UpdateMerchantUserDocument,
    options
  );
}
export type UpdateMerchantUserMutationHookResult = ReturnType<typeof useUpdateMerchantUserMutation>;
export type UpdateMerchantUserMutationResult = Apollo.MutationResult<UpdateMerchantUserMutation>;
export type UpdateMerchantUserMutationOptions = Apollo.BaseMutationOptions<
  UpdateMerchantUserMutation,
  UpdateMerchantUserMutationVariables
>;
export const RequestMerchantrUserAvatarUpdateDocument = gql`
  mutation RequestMerchantrUserAvatarUpdate($imgMimeType: String!) {
    requestMerchantUserAvatarUpdate(imgMimeType: $imgMimeType) {
      formPostUrl
      key
      bucket
      X__Amz__Algorithm
      X__Amz__Credential
      X__Amz__Date
      Policy
      X__Amz__Signature
      X__Amz__Security__Token
    }
  }
`;
export type RequestMerchantrUserAvatarUpdateMutationFn = Apollo.MutationFunction<
  RequestMerchantrUserAvatarUpdateMutation,
  RequestMerchantrUserAvatarUpdateMutationVariables
>;

/**
 * __useRequestMerchantrUserAvatarUpdateMutation__
 *
 * To run a mutation, you first call `useRequestMerchantrUserAvatarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMerchantrUserAvatarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMerchantrUserAvatarUpdateMutation, { data, loading, error }] = useRequestMerchantrUserAvatarUpdateMutation({
 *   variables: {
 *      imgMimeType: // value for 'imgMimeType'
 *   },
 * });
 */
export function useRequestMerchantrUserAvatarUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMerchantrUserAvatarUpdateMutation,
    RequestMerchantrUserAvatarUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    RequestMerchantrUserAvatarUpdateMutation,
    RequestMerchantrUserAvatarUpdateMutationVariables
  >(RequestMerchantrUserAvatarUpdateDocument, options);
}
export type RequestMerchantrUserAvatarUpdateMutationHookResult = ReturnType<
  typeof useRequestMerchantrUserAvatarUpdateMutation
>;
export type RequestMerchantrUserAvatarUpdateMutationResult =
  Apollo.MutationResult<RequestMerchantrUserAvatarUpdateMutation>;
export type RequestMerchantrUserAvatarUpdateMutationOptions = Apollo.BaseMutationOptions<
  RequestMerchantrUserAvatarUpdateMutation,
  RequestMerchantrUserAvatarUpdateMutationVariables
>;
export const UpdateBookingConfigurationDocument = gql`
  mutation UpdateBookingConfiguration($data: UpdateBookingConfigurationInput!) {
    updateBookingConfiguration(data: $data) {
      title
      slug
      welcomeMessage
      tzName
      availability {
        id
        day
        enabled
        startTime
        endTime
      }
    }
  }
`;
export type UpdateBookingConfigurationMutationFn = Apollo.MutationFunction<
  UpdateBookingConfigurationMutation,
  UpdateBookingConfigurationMutationVariables
>;

/**
 * __useUpdateBookingConfigurationMutation__
 *
 * To run a mutation, you first call `useUpdateBookingConfigurationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateBookingConfigurationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateBookingConfigurationMutation, { data, loading, error }] = useUpdateBookingConfigurationMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateBookingConfigurationMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateBookingConfigurationMutation,
    UpdateBookingConfigurationMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateBookingConfigurationMutation, UpdateBookingConfigurationMutationVariables>(
    UpdateBookingConfigurationDocument,
    options
  );
}
export type UpdateBookingConfigurationMutationHookResult = ReturnType<typeof useUpdateBookingConfigurationMutation>;
export type UpdateBookingConfigurationMutationResult = Apollo.MutationResult<UpdateBookingConfigurationMutation>;
export type UpdateBookingConfigurationMutationOptions = Apollo.BaseMutationOptions<
  UpdateBookingConfigurationMutation,
  UpdateBookingConfigurationMutationVariables
>;
export const CreateEventTypeDocument = gql`
  mutation CreateEventType($data: CreateEventTypeInput!) {
    createEventType(data: $data) {
      color
      dayAvailabilityInFuture
      description
      duration
      location
      name
      questions {
        text
        required
      }
      slug
    }
  }
`;
export type CreateEventTypeMutationFn = Apollo.MutationFunction<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;

/**
 * __useCreateEventTypeMutation__
 *
 * To run a mutation, you first call `useCreateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventTypeMutation, { data, loading, error }] = useCreateEventTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventTypeMutation, CreateEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventTypeMutation, CreateEventTypeMutationVariables>(
    CreateEventTypeDocument,
    options
  );
}
export type CreateEventTypeMutationHookResult = ReturnType<typeof useCreateEventTypeMutation>;
export type CreateEventTypeMutationResult = Apollo.MutationResult<CreateEventTypeMutation>;
export type CreateEventTypeMutationOptions = Apollo.BaseMutationOptions<
  CreateEventTypeMutation,
  CreateEventTypeMutationVariables
>;
export const UpdateEventTypeDocument = gql`
  mutation UpdateEventType($data: UpdateEventTypeInput!) {
    updateEventType(data: $data) {
      color
      dayAvailabilityInFuture
      description
      duration
      id
      location
      name
      questions {
        text
        required
      }
      slug
    }
  }
`;
export type UpdateEventTypeMutationFn = Apollo.MutationFunction<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;

/**
 * __useUpdateEventTypeMutation__
 *
 * To run a mutation, you first call `useUpdateEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventTypeMutation, { data, loading, error }] = useUpdateEventTypeMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventTypeMutation, UpdateEventTypeMutationVariables>(
    UpdateEventTypeDocument,
    options
  );
}
export type UpdateEventTypeMutationHookResult = ReturnType<typeof useUpdateEventTypeMutation>;
export type UpdateEventTypeMutationResult = Apollo.MutationResult<UpdateEventTypeMutation>;
export type UpdateEventTypeMutationOptions = Apollo.BaseMutationOptions<
  UpdateEventTypeMutation,
  UpdateEventTypeMutationVariables
>;
export const DeleteEventTypeDocument = gql`
  mutation DeleteEventType($eventTypeId: String!) {
    deleteEventType(eventTypeId: $eventTypeId)
  }
`;
export type DeleteEventTypeMutationFn = Apollo.MutationFunction<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;

/**
 * __useDeleteEventTypeMutation__
 *
 * To run a mutation, you first call `useDeleteEventTypeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteEventTypeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteEventTypeMutation, { data, loading, error }] = useDeleteEventTypeMutation({
 *   variables: {
 *      eventTypeId: // value for 'eventTypeId'
 *   },
 * });
 */
export function useDeleteEventTypeMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteEventTypeMutation, DeleteEventTypeMutationVariables>(
    DeleteEventTypeDocument,
    options
  );
}
export type DeleteEventTypeMutationHookResult = ReturnType<typeof useDeleteEventTypeMutation>;
export type DeleteEventTypeMutationResult = Apollo.MutationResult<DeleteEventTypeMutation>;
export type DeleteEventTypeMutationOptions = Apollo.BaseMutationOptions<
  DeleteEventTypeMutation,
  DeleteEventTypeMutationVariables
>;
export const DeleteMerchantUserDocument = gql`
  mutation DeleteMerchantUser($merchantAccountUserId: String!) {
    deleteMerchantUser(merchantAccountUserId: $merchantAccountUserId)
  }
`;
export type DeleteMerchantUserMutationFn = Apollo.MutationFunction<
  DeleteMerchantUserMutation,
  DeleteMerchantUserMutationVariables
>;

/**
 * __useDeleteMerchantUserMutation__
 *
 * To run a mutation, you first call `useDeleteMerchantUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteMerchantUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteMerchantUserMutation, { data, loading, error }] = useDeleteMerchantUserMutation({
 *   variables: {
 *      merchantAccountUserId: // value for 'merchantAccountUserId'
 *   },
 * });
 */
export function useDeleteMerchantUserMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteMerchantUserMutation, DeleteMerchantUserMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteMerchantUserMutation, DeleteMerchantUserMutationVariables>(
    DeleteMerchantUserDocument,
    options
  );
}
export type DeleteMerchantUserMutationHookResult = ReturnType<typeof useDeleteMerchantUserMutation>;
export type DeleteMerchantUserMutationResult = Apollo.MutationResult<DeleteMerchantUserMutation>;
export type DeleteMerchantUserMutationOptions = Apollo.BaseMutationOptions<
  DeleteMerchantUserMutation,
  DeleteMerchantUserMutationVariables
>;
export const CreateInvoiceDocument = gql`
  mutation CreateInvoice($data: CreateMerchantContactInvoiceInput!) {
    createInvoice(data: $data) {
      id
    }
  }
`;
export type CreateInvoiceMutationFn = Apollo.MutationFunction<CreateInvoiceMutation, CreateInvoiceMutationVariables>;

/**
 * __useCreateInvoiceMutation__
 *
 * To run a mutation, you first call `useCreateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createInvoiceMutation, { data, loading, error }] = useCreateInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateInvoiceMutation, CreateInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateInvoiceMutation, CreateInvoiceMutationVariables>(CreateInvoiceDocument, options);
}
export type CreateInvoiceMutationHookResult = ReturnType<typeof useCreateInvoiceMutation>;
export type CreateInvoiceMutationResult = Apollo.MutationResult<CreateInvoiceMutation>;
export type CreateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  CreateInvoiceMutation,
  CreateInvoiceMutationVariables
>;
export const CreatePaymentDocument = gql`
  mutation CreatePayment($data: CreateMerchantContactPaymentInput!) {
    createPayment(data: $data) {
      id
      contact {
        id
        name
      }
    }
  }
`;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
}
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMutation,
  CreatePaymentMutationVariables
>;
export const RequestMerchantAvatarUpdateDocument = gql`
  mutation RequestMerchantAvatarUpdate($merchantAccountId: String!, $imgMimeType: String!) {
    requestMerchantLogoUpdate(merchantAccountId: $merchantAccountId, imgMimeType: $imgMimeType) {
      formPostUrl
      key
      bucket
      X__Amz__Algorithm
      X__Amz__Credential
      X__Amz__Date
      Policy
      X__Amz__Signature
      X__Amz__Security__Token
    }
  }
`;
export type RequestMerchantAvatarUpdateMutationFn = Apollo.MutationFunction<
  RequestMerchantAvatarUpdateMutation,
  RequestMerchantAvatarUpdateMutationVariables
>;

/**
 * __useRequestMerchantAvatarUpdateMutation__
 *
 * To run a mutation, you first call `useRequestMerchantAvatarUpdateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestMerchantAvatarUpdateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestMerchantAvatarUpdateMutation, { data, loading, error }] = useRequestMerchantAvatarUpdateMutation({
 *   variables: {
 *      merchantAccountId: // value for 'merchantAccountId'
 *      imgMimeType: // value for 'imgMimeType'
 *   },
 * });
 */
export function useRequestMerchantAvatarUpdateMutation(
  baseOptions?: Apollo.MutationHookOptions<
    RequestMerchantAvatarUpdateMutation,
    RequestMerchantAvatarUpdateMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RequestMerchantAvatarUpdateMutation, RequestMerchantAvatarUpdateMutationVariables>(
    RequestMerchantAvatarUpdateDocument,
    options
  );
}
export type RequestMerchantAvatarUpdateMutationHookResult = ReturnType<typeof useRequestMerchantAvatarUpdateMutation>;
export type RequestMerchantAvatarUpdateMutationResult = Apollo.MutationResult<RequestMerchantAvatarUpdateMutation>;
export type RequestMerchantAvatarUpdateMutationOptions = Apollo.BaseMutationOptions<
  RequestMerchantAvatarUpdateMutation,
  RequestMerchantAvatarUpdateMutationVariables
>;
export const UpdateMerchantDocument = gql`
  mutation UpdateMerchant($data: UpdateMerchantInput!) {
    updateMerchant(data: $data) {
      id
      name
      logo
    }
  }
`;
export type UpdateMerchantMutationFn = Apollo.MutationFunction<UpdateMerchantMutation, UpdateMerchantMutationVariables>;

/**
 * __useUpdateMerchantMutation__
 *
 * To run a mutation, you first call `useUpdateMerchantMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMerchantMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMerchantMutation, { data, loading, error }] = useUpdateMerchantMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateMerchantMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateMerchantMutation, UpdateMerchantMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateMerchantMutation, UpdateMerchantMutationVariables>(UpdateMerchantDocument, options);
}
export type UpdateMerchantMutationHookResult = ReturnType<typeof useUpdateMerchantMutation>;
export type UpdateMerchantMutationResult = Apollo.MutationResult<UpdateMerchantMutation>;
export type UpdateMerchantMutationOptions = Apollo.BaseMutationOptions<
  UpdateMerchantMutation,
  UpdateMerchantMutationVariables
>;
export const DeleteInvoiceDocument = gql`
  mutation DeleteInvoice($invoiceId: String!) {
    deleteInvoice(invoiceId: $invoiceId)
  }
`;
export type DeleteInvoiceMutationFn = Apollo.MutationFunction<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>;

/**
 * __useDeleteInvoiceMutation__
 *
 * To run a mutation, you first call `useDeleteInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteInvoiceMutation, { data, loading, error }] = useDeleteInvoiceMutation({
 *   variables: {
 *      invoiceId: // value for 'invoiceId'
 *   },
 * });
 */
export function useDeleteInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteInvoiceMutation, DeleteInvoiceMutationVariables>(DeleteInvoiceDocument, options);
}
export type DeleteInvoiceMutationHookResult = ReturnType<typeof useDeleteInvoiceMutation>;
export type DeleteInvoiceMutationResult = Apollo.MutationResult<DeleteInvoiceMutation>;
export type DeleteInvoiceMutationOptions = Apollo.BaseMutationOptions<
  DeleteInvoiceMutation,
  DeleteInvoiceMutationVariables
>;
export const DeletePaymentDocument = gql`
  mutation DeletePayment($paymentId: String!) {
    deletePayment(paymentId: $paymentId)
  }
`;
export type DeletePaymentMutationFn = Apollo.MutationFunction<DeletePaymentMutation, DeletePaymentMutationVariables>;

/**
 * __useDeletePaymentMutation__
 *
 * To run a mutation, you first call `useDeletePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePaymentMutation, { data, loading, error }] = useDeletePaymentMutation({
 *   variables: {
 *      paymentId: // value for 'paymentId'
 *   },
 * });
 */
export function useDeletePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<DeletePaymentMutation, DeletePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeletePaymentMutation, DeletePaymentMutationVariables>(DeletePaymentDocument, options);
}
export type DeletePaymentMutationHookResult = ReturnType<typeof useDeletePaymentMutation>;
export type DeletePaymentMutationResult = Apollo.MutationResult<DeletePaymentMutation>;
export type DeletePaymentMutationOptions = Apollo.BaseMutationOptions<
  DeletePaymentMutation,
  DeletePaymentMutationVariables
>;
export const UpdatePaymentDocument = gql`
  mutation UpdatePayment($data: UpdateMerchantContactPaymentInput!) {
    updatePayment(data: $data) {
      id
      contact {
        id
        name
      }
    }
  }
`;
export type UpdatePaymentMutationFn = Apollo.MutationFunction<UpdatePaymentMutation, UpdatePaymentMutationVariables>;

/**
 * __useUpdatePaymentMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMutation, { data, loading, error }] = useUpdatePaymentMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMutation, UpdatePaymentMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentMutation, UpdatePaymentMutationVariables>(UpdatePaymentDocument, options);
}
export type UpdatePaymentMutationHookResult = ReturnType<typeof useUpdatePaymentMutation>;
export type UpdatePaymentMutationResult = Apollo.MutationResult<UpdatePaymentMutation>;
export type UpdatePaymentMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMutation,
  UpdatePaymentMutationVariables
>;
export const UpdateInvoiceDocument = gql`
  mutation UpdateInvoice($data: UpdateMerchantContactInvoiceInput!) {
    updateInvoice(data: $data) {
      id
    }
  }
`;
export type UpdateInvoiceMutationFn = Apollo.MutationFunction<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>;

/**
 * __useUpdateInvoiceMutation__
 *
 * To run a mutation, you first call `useUpdateInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateInvoiceMutation, { data, loading, error }] = useUpdateInvoiceMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateInvoiceMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateInvoiceMutation, UpdateInvoiceMutationVariables>(UpdateInvoiceDocument, options);
}
export type UpdateInvoiceMutationHookResult = ReturnType<typeof useUpdateInvoiceMutation>;
export type UpdateInvoiceMutationResult = Apollo.MutationResult<UpdateInvoiceMutation>;
export type UpdateInvoiceMutationOptions = Apollo.BaseMutationOptions<
  UpdateInvoiceMutation,
  UpdateInvoiceMutationVariables
>;
export const CreateEventDocument = gql`
  mutation CreateEvent($data: CreateEventInput!) {
    createEvent(data: $data) {
      title
      description
      startTime
      endTime
      locationType
      phone
      location
      sendReminderAt
      sendSMSReminder
      sendEmailReminder
      guests
    }
  }
`;
export type CreateEventMutationFn = Apollo.MutationFunction<CreateEventMutation, CreateEventMutationVariables>;

/**
 * __useCreateEventMutation__
 *
 * To run a mutation, you first call `useCreateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createEventMutation, { data, loading, error }] = useCreateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateEventMutation, CreateEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateEventMutation, CreateEventMutationVariables>(CreateEventDocument, options);
}
export type CreateEventMutationHookResult = ReturnType<typeof useCreateEventMutation>;
export type CreateEventMutationResult = Apollo.MutationResult<CreateEventMutation>;
export type CreateEventMutationOptions = Apollo.BaseMutationOptions<CreateEventMutation, CreateEventMutationVariables>;
export const UpdateEventDocument = gql`
  mutation UpdateEvent($data: UpdateEventInput!) {
    updateEvent(data: $data) {
      title
      description
      startTime
      endTime
      locationType
      phone
      location
      sendReminderAt
      sendSMSReminder
      sendEmailReminder
      guests
    }
  }
`;
export type UpdateEventMutationFn = Apollo.MutationFunction<UpdateEventMutation, UpdateEventMutationVariables>;

/**
 * __useUpdateEventMutation__
 *
 * To run a mutation, you first call `useUpdateEventMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEventMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEventMutation, { data, loading, error }] = useUpdateEventMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateEventMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateEventMutation, UpdateEventMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateEventMutation, UpdateEventMutationVariables>(UpdateEventDocument, options);
}
export type UpdateEventMutationHookResult = ReturnType<typeof useUpdateEventMutation>;
export type UpdateEventMutationResult = Apollo.MutationResult<UpdateEventMutation>;
export type UpdateEventMutationOptions = Apollo.BaseMutationOptions<UpdateEventMutation, UpdateEventMutationVariables>;
export const CreatePaymentMethodDocument = gql`
  mutation CreatePaymentMethod($data: CreateMerchantContactPaymentMethodInput!) {
    createPaymentMethod(data: $data) {
      id
      contact {
        id
        email
      }
      last4Digits
      type
      cardType
      expiration
      isPrimary
    }
  }
`;
export type CreatePaymentMethodMutationFn = Apollo.MutationFunction<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;

/**
 * __useCreatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMethodMutation, { data, loading, error }] = useCreatePaymentMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreatePaymentMethodMutation, CreatePaymentMethodMutationVariables>(
    CreatePaymentMethodDocument,
    options
  );
}
export type CreatePaymentMethodMutationHookResult = ReturnType<typeof useCreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationResult = Apollo.MutationResult<CreatePaymentMethodMutation>;
export type CreatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  CreatePaymentMethodMutation,
  CreatePaymentMethodMutationVariables
>;
export const UpdatePaymentMethodDocument = gql`
  mutation UpdatePaymentMethod($data: UpdateMerchantContactPaymentMethodInput!) {
    updatePaymentMethod(data: $data) {
      id
      contact {
        id
        email
      }
      last4Digits
      type
      cardType
      expiration
      isPrimary
    }
  }
`;
export type UpdatePaymentMethodMutationFn = Apollo.MutationFunction<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;

/**
 * __useUpdatePaymentMethodMutation__
 *
 * To run a mutation, you first call `useUpdatePaymentMethodMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePaymentMethodMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePaymentMethodMutation, { data, loading, error }] = useUpdatePaymentMethodMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdatePaymentMethodMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePaymentMethodMutation, UpdatePaymentMethodMutationVariables>(
    UpdatePaymentMethodDocument,
    options
  );
}
export type UpdatePaymentMethodMutationHookResult = ReturnType<typeof useUpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationResult = Apollo.MutationResult<UpdatePaymentMethodMutation>;
export type UpdatePaymentMethodMutationOptions = Apollo.BaseMutationOptions<
  UpdatePaymentMethodMutation,
  UpdatePaymentMethodMutationVariables
>;
export const CreateSiteDocument = gql`
  mutation CreateSite($data: CreateMerchantSiteInput!) {
    createSite(data: $data) {
      businessName
      id
      businessPhone
      businessAddress
      businessEmail
      businessCategory
      languagesSpoken
      areasServices
      businessAbout
      businessMission
      businessFoundedYear
      hours {
        day
        startTime
        endTime
      }
      facebookSlug
    }
  }
`;
export type CreateSiteMutationFn = Apollo.MutationFunction<CreateSiteMutation, CreateSiteMutationVariables>;

/**
 * __useCreateSiteMutation__
 *
 * To run a mutation, you first call `useCreateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSiteMutation, { data, loading, error }] = useCreateSiteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useCreateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateSiteMutation, CreateSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateSiteMutation, CreateSiteMutationVariables>(CreateSiteDocument, options);
}
export type CreateSiteMutationHookResult = ReturnType<typeof useCreateSiteMutation>;
export type CreateSiteMutationResult = Apollo.MutationResult<CreateSiteMutation>;
export type CreateSiteMutationOptions = Apollo.BaseMutationOptions<CreateSiteMutation, CreateSiteMutationVariables>;
export const DeleteSiteDocument = gql`
  mutation DeleteSite($id: String!) {
    deleteSite(siteId: $id)
  }
`;
export type DeleteSiteMutationFn = Apollo.MutationFunction<DeleteSiteMutation, DeleteSiteMutationVariables>;

/**
 * __useDeleteSiteMutation__
 *
 * To run a mutation, you first call `useDeleteSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSiteMutation, { data, loading, error }] = useDeleteSiteMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<DeleteSiteMutation, DeleteSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<DeleteSiteMutation, DeleteSiteMutationVariables>(DeleteSiteDocument, options);
}
export type DeleteSiteMutationHookResult = ReturnType<typeof useDeleteSiteMutation>;
export type DeleteSiteMutationResult = Apollo.MutationResult<DeleteSiteMutation>;
export type DeleteSiteMutationOptions = Apollo.BaseMutationOptions<DeleteSiteMutation, DeleteSiteMutationVariables>;
export const UpdateSiteDocument = gql`
  mutation UpdateSite($data: UpdateMerchantSiteInput!) {
    updateSite(data: $data) {
      businessName
      businessPhone
      id
      businessAddress
      businessEmail
      businessCategory
      languagesSpoken
      areasServices
      businessAbout
      businessMission
      businessFoundedYear
      hours {
        day
        startTime
        endTime
      }
      facebookSlug
    }
  }
`;
export type UpdateSiteMutationFn = Apollo.MutationFunction<UpdateSiteMutation, UpdateSiteMutationVariables>;

/**
 * __useUpdateSiteMutation__
 *
 * To run a mutation, you first call `useUpdateSiteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSiteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSiteMutation, { data, loading, error }] = useUpdateSiteMutation({
 *   variables: {
 *      data: // value for 'data'
 *   },
 * });
 */
export function useUpdateSiteMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSiteMutation, UpdateSiteMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSiteMutation, UpdateSiteMutationVariables>(UpdateSiteDocument, options);
}
export type UpdateSiteMutationHookResult = ReturnType<typeof useUpdateSiteMutation>;
export type UpdateSiteMutationResult = Apollo.MutationResult<UpdateSiteMutation>;
export type UpdateSiteMutationOptions = Apollo.BaseMutationOptions<UpdateSiteMutation, UpdateSiteMutationVariables>;
