import React from 'react';

const CircleProgressBar = ({ progress, children }: { progress: number; children: React.ReactNode }) => {
  const radius = 15;
  const circumference = 2 * Math.PI * radius;
  const offset = circumference - (progress / 100) * circumference;

  return (
    <div className="relative w-14 h-14">
      <svg className="absolute top-0 left-0 w-full h-full" viewBox={`0 0 ${radius * 2} ${radius * 2}`}>
        <circle
          r={radius - 2}
          cx={radius}
          cy={radius}
          fill="transparent"
          className="stroke-primary-50"
          strokeWidth="1"
        />
        <circle
          r={radius - 2}
          cx={radius}
          cy={radius}
          className="stroke-primary-600 fill-primary-100"
          strokeWidth="1"
          strokeDasharray={`${circumference} ${circumference}`}
          strokeDashoffset={offset}
          transform={`rotate(-90 ${radius} ${radius})`}
        />
      </svg>
      <div className="absolute top-1/4 left-1/4 w-full h-full">{children}</div>
    </div>
  );
};

export default CircleProgressBar;
