import { useMergeState } from '@portal/react-hooks/use-merge-state';
import {
  Avatar,
  Badge,
  DataTable,
  DeleteModal,
  Drawer,
  EmptyState,
  Icons,
  RowActionMenu,
  RowActionMenuProps,
} from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { currencyFormatter } from '@portal/utils/misc';
import { capitalizeText, getInitials } from '@portal/utils/string';
import { PaginationState, SortingState } from '@tanstack/react-table';
import LogPayment from 'components/payment';
import { badgeMappings } from 'constants/users';
import { useDeletePaymentMutation } from 'graphql/mutation.generated';
import { FindAllPaymentsByMerchantIdQuery, useFindAllPaymentsByMerchantIdLazyQuery } from 'graphql/query.generated';
import { FindAllPaymentsFilters, ViewMode } from 'graphql/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'redux/hooks';

type PaymentRow = FindAllPaymentsByMerchantIdQuery['findAllPaymentsByMerchantId']['payments'][number];

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllPaymentsFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'paidAt',
      desc: true,
    },
  ],
  filters: {
    viewMode: 'viewAll',
  },
};

const PaymentsPage = () => {
  const merchantId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const role = useSelector((state) => state.user.attributes?.role);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [selectedRow, setSelectedRow] = useState<PaymentRow | null>(null);
  const [openLogPaymentDrawer, setOpenLogPaymentDrawer] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  const onClose = () => {
    setShowDeleteModal(false);
    setOpenLogPaymentDrawer(false);
    setSelectedRow(null);
  };

  const [fetchPayments, { data: payments, loading, refetch }] = useFindAllPaymentsByMerchantIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    fetchPayments({
      variables: {
        merchantAccountId: merchantId as string,
        filters: tableState.filters,
        pagination: {
          offset: tableState.pageIndex * tableState.pageSize,
          limit: tableState.pageSize,
        },
        orderBy: {
          createdAt: ternary(tableState.sorting.find((col) => col.id === 'paidAt')?.desc, 'desc', 'asc', undefined),
          amount: ternary(tableState.sorting.find((col) => col.id === 'amount')?.desc, 'desc', 'asc', undefined),
          contactName: ternary(tableState.sorting.find((col) => col.id === 'contact')?.desc, 'desc', 'asc', undefined),
        },
      },
    });
  }, [
    merchantId,
    tableState.pageIndex,
    tableState.pageSize,
    tableState.sorting,
    tableState.filters?.contactNameLike,
    tableState.filters?.idLike,
    tableState.filters?.viewMode,
    tableState.sorting,
  ]);

  const [deletePaymentMutation, { loading: deletePaymentLoading }] = useDeletePaymentMutation({
    onCompleted: () => {
      toast.success('Payment deleted');
      onClose();
      resetTableState();
      refetch();
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const rowActions: RowActionMenuProps<PaymentRow>['rowActions'] = [
    { name: 'View', action: () => console.log('view') },
    {
      name: 'Edit',
      action: (row) => {
        setSelectedRow(row ?? null);
        setOpenLogPaymentDrawer(true);
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedRow(row ?? null);
        setShowDeleteModal(true);
      },
    },
  ];

  const columns: ColumnType<PaymentRow>[] = useMemo(
    () => [
      {
        name: 'Amount',
        id: 'amount',
        cell: (info) => <div className="text-sm text-steel-600">{currencyFormatter(info.row.original.amount)}</div>,
      },
      {
        id: 'status',
        name: 'Status',
        cell: (info) => (
          <Badge
            text={capitalizeText(info.row.original.status)}
            type={badgeMappings[info.row.original.status.toLowerCase()]}
          />
        ),
      },
      {
        name: 'Payment ID',
        id: 'id',
        cell: (info) => {
          return <div className="font-medium text-sm text-steel-600">{info.row.original.id}</div>;
        },
      },

      {
        id: 'contact',
        name: 'Contact',
        cell: ({
          row: {
            original: { contact },
          },
        }) => {
          return (
            <div className="flex gap-2">
              <Avatar size="small" initials={getInitials(contact.name)} imageUrl={contact.avatar} />
              <div className="flex flex-col ">
                <p className="text-steel-900">{contact.name}</p>
                <p className="text-steel-500">{contact.email}</p>
              </div>
            </div>
          );
        },
      },
      {
        id: 'paidAt',
        name: 'Issue date',
        cell: (info) => moment(info.row.original.paidAt).format('MMM D, YYYY'),
      },

      {
        id: 'action',
        name: '',
        cell: ({ row: { original } }) => {
          if (original.status !== 'SUCCESS') {
            return <RowActionMenu row={original} rowActions={rowActions.filter((row) => row.name !== 'Delete')} />;
          }
          return <RowActionMenu row={original} rowActions={rowActions} />;
        },
      },
    ],
    []
  );

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            contactNameLike: value,
            idLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );
  const selectViewMode = (viewMode: ViewMode) => {
    updateTableState({
      filters: {
        ...tableState.filters,
        viewMode,
      },
    });
  };

  const pageCount = useMemo(() => {
    const total = payments?.findAllPaymentsByMerchantId.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [payments?.findAllPaymentsByMerchantId.count]);

  const onPaymentDelete = () => {
    deletePaymentMutation({
      variables: { paymentId: selectedRow?.id ?? '' },
    }).then((res) => {
      if (res.errors) {
        toast.error('Something went wrong');
      }
    });
  };
  return (
    <div className="px-0 md:px-8">
      <DeleteModal
        onClose={onClose}
        title={'Delete payment'}
        open={showDeleteModal}
        onConfirm={onPaymentDelete}
        loading={deletePaymentLoading}
        content={'Are you sure you want to delete this payment? This action cannot be undone.'}
      />
      <DataTable
        columns={columns}
        pageCount={pageCount}
        data={payments?.findAllPaymentsByMerchantId?.payments ?? []}
        loading={loading}
        tableState={tableState}
        updateTableState={updateTableState}
        pagination
        showSearch
        canAccessAll={role === 'ADMIN'}
        showViewModeFilter
        selectViewMode={selectViewMode}
        noDataElement={
          <EmptyState
            icon={<Icons.CreditCard />}
            title="No payments yet"
            subTitle="Payments collected will appear here"
          />
        }
        onGlobalFilterChange={onGlobalFilterChange}
      />
      <Drawer open={openLogPaymentDrawer} onClose={onClose}>
        <LogPayment data={selectedRow} closePaymentForm={onClose} edit={!!selectedRow} />
      </Drawer>
    </div>
  );
};

export default PaymentsPage;
