import { useMergeState } from '@portal/react-hooks/use-merge-state';
import {
  Avatar,
  Badge,
  Button,
  ConfirmationModal,
  DataTable,
  Drawer,
  EmptyState,
  Icons,
  Modal,
  RowActionMenu,
  SubHeading,
} from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { capitalizeText, getInitials } from '@portal/utils/string';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { USER_ROLES, USER_ROLES_LABELS, USER_VISIBILITY, badgeMappings } from 'constants/users';
import { FormikValues } from 'formik';
import {
  useDeleteMerchantUserMutation,
  useInviteUserMutation,
  useToggleMerchantUserDisabledMutation,
  useUpdateMerchantUserRoleMutation,
} from 'graphql/mutation.generated';
import { FindAllMerchantUsersQuery, useFindAllMerchantUsersLazyQuery } from 'graphql/query.generated';
import { FindAllUsersFilters } from 'graphql/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from 'redux/store';
import EditPermissionsForm from './EditPermissionsForm';
import UsersForm from './UserForm';

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllUsersFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

export type UserRow = FindAllMerchantUsersQuery['findAllUsers']['users'][number];

export const TeamSettings = () => {
  const merchantAccountId = useSelector<RootState>((state) => state.user.attributes?.merchantAccount.id);
  const merchantAccountRole = useSelector<RootState>((state) => state.user.attributes?.role);
  const [userFormMode, setUserFormMode] = useState<'create' | 'edit' | null>(null);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [selectedUser, setSelectedUser] = useState<UserRow | null>();
  const [confirmationModal, setConfirmationModal] = useState<boolean>(false);
  const [selectedUserToDelete, setSelectedUserToDelete] = useState<UserRow | null>();
  const [userDeletionAction, setUserDeletionAction] = useState<boolean>(false);
  const [confirmationModalRevokeInvite, setConfirmationModalRevokeInvite] = useState<boolean>(false);
  const openUserForm = (mode: typeof userFormMode) => {
    setUserFormMode(mode);
  };

  const closeUserForm = () => {
    setSelectedUser(null);
    setUserFormMode(null);
  };

  const [findAllUsers, { data, loading, refetch }] = useFindAllMerchantUsersLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [inviteUser, { loading: inviteUserLoading }] = useInviteUserMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('User invited');
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      setUserFormMode(null);
    },
  });

  const [toggleMerchantUserDisabledMutation, { loading: toggleMerchantUserDisabledLoading }] =
    useToggleMerchantUserDisabledMutation({
      onError: (error) => {
        console.error(error);
        toast.error('Something went wrong');
      },
      onCompleted: () => {
        toast.success(userDeletionAction ? 'Member suspended' : 'Member reactivated');
        refetch({
          pagination: {
            offset: 0,
            limit: PAGE_SIZE,
          },
          filters: undefined, // reset any filters
        });
        setUserFormMode(null);
        setSelectedUserToDelete(null);
        setUserDeletionAction(false);
        setConfirmationModal(false);
      },
    });

  const [revokeInvite, { loading: revokeInviteLoading }] = useDeleteMerchantUserMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Invite revoked');
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      setSelectedUser(null);
      setConfirmationModalRevokeInvite(false);
    },
  });

  const [updateUser, { loading: updateUserLoading }] = useUpdateMerchantUserRoleMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Member updated');
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      setUserFormMode(null);
    },
  });

  useEffect(() => {
    if (merchantAccountId) {
      findAllUsers({
        variables: {
          findAllUsersMerchantAccountId2: merchantAccountId as string,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
          },
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantAccountId, tableState.pageIndex, tableState.pageSize, tableState.filters, tableState.sorting]);

  const updateUserStatus = () => {
    toggleMerchantUserDisabledMutation({
      variables: {
        merchantAccountUserId: selectedUserToDelete?.id as string,
        disabled: userDeletionAction,
      },
    });
  };

  const handleUserDeletion = (user: UserRow, disable: boolean) => {
    setConfirmationModal(true);
    setSelectedUserToDelete(user);
    setUserDeletionAction(disable);
  };

  const rowActions = (row: UserRow) => {
    let actions: RowActionMenuProps<UserRow>['rowActions'] = [
      {
        name: 'Edit',
        action: (row) => {
          setSelectedUser(row);
          setUserFormMode('edit');
        },
      },
    ];

    if (row.status === 'ACTIVE') {
      actions = [
        ...actions,
        {
          name: 'Suspend',
          action: (row) => {
            if (row) handleUserDeletion(row, true);
          },
        },
      ];
    } else if (row.status === 'INACTIVE') {
      actions = [
        ...actions,
        {
          name: 'Reactivate',
          action: (row) => {
            if (row) handleUserDeletion(row, false);
          },
        },
      ];
    } else if (row.status === 'INVITED' && merchantAccountRole === 'ADMIN') {
      actions = [
        ...actions,
        {
          name: 'Revoke invite',
          action: (row) => {
            if (row) {
              setSelectedUser(row);
              setConfirmationModalRevokeInvite(true);
            }
          },
        },
      ];
    }
    return actions;
  };

  const columns: ColumnType<UserRow>[] = [
    {
      name: 'Name',
      id: 'name',
      cell: (info) => {
        const user = info.row.original;
        return (
          <div className="flex items-center">
            <div className="h-10 w-10 flex-shrink-0">
              <Avatar imageUrl={user.avatar} initials={getInitials(user.name !== '' ? user.name : user.email)} />
            </div>
            <div className="ml-4">
              <div className="font-medium text-steel-900">{user.name && capitalizeText(user.name)}</div>
              <div className="text-steel-500">{user.email}</div>
            </div>
          </div>
        );
      },
    },
    {
      name: 'Role',
      id: 'role',
      cell: (info: any) => {
        const user = info.row.original;
        return (
          <div className="flex items-center">
            <div className="font-medium text-steel-600">{USER_ROLES_LABELS[user.role]}</div>
          </div>
        );
      },
    },
    {
      name: 'Visibility',
      id: 'visibility',
      cell: (info: any) => {
        const user = info.row.original;
        return (
          <div className="flex items-center">
            <div className="font-medium text-steel-600">
              {user.role === USER_ROLES.SALES_REPRESENTATIVE ? 'View owned' : 'View all'}
            </div>
          </div>
        );
      },
    },
    {
      id: 'status',
      name: 'Status',
      cell: (info: any) => {
        const status = info.getValue() === 'INACTIVE' ? 'Suspended' : info.getValue();
        return (
          <span className="">
            <Badge text={capitalizeText(status)} type={badgeMappings[info.getValue()]} />
          </span>
        );
      },
    },
    {
      name: '',
      id: 'actions',
      cell: ({ row: { original } }) => <RowActionMenu row={original} rowActions={rowActions(original)} />,
    },
  ];

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            emailLike: value,
            nameLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = data?.findAllUsers.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllUsers.count]);

  const handleInviteUser = (values: FormikValues) => {
    inviteUser({
      variables: {
        data: { ...values.invitees[0], merchantAccountId: merchantAccountId as string },
      },
    });
  };
  const handleRevokeInvite = () => {
    revokeInvite({
      variables: { merchantAccountUserId: selectedUser?.id as string },
    });
  };

  const handleUpdateUser = (values: FormikValues) => {
    updateUser({
      variables: {
        data: {
          merchantAccountUserId: selectedUser?.id as string,
          role: values.role,
        },
      },
    });
  };

  const onSubmit = (values: FormikValues) => {
    if (selectedUser) {
      const data =
        values.role !== 'Sales'
          ? values
          : {
              ...values,
              role:
                values.visibility === USER_VISIBILITY.VIEW_ALL
                  ? USER_ROLES.SALES_MANAGER
                  : USER_ROLES.SALES_REPRESENTATIVE,
            };
      handleUpdateUser(data);
    } else {
      handleInviteUser(values);
    }
  };

  const title = useMemo(() => {
    if (selectedUserToDelete?.name && selectedUserToDelete?.name !== '') {
      return selectedUserToDelete?.name;
    } else if (selectedUser?.name && selectedUser?.name !== '') {
      return selectedUser?.name;
    }
    return 'member';
  }, [selectedUserToDelete, selectedUser]);

  return (
    <div className="px-8">
      <SubHeading
        title="Team"
        description="Manage your team members and their account roles."
        actions={[
          <Button title="add team member" onPress={() => openUserForm('create')} displayType="primary">
            <Icons.Plus className="w-5 h-5 mr-1" aria-hidden="true" color="white" />
            Add member
          </Button>,
        ]}
        useHelmetTitle
      />
      <div className="pb-3">
        <DataTable
          columns={columns}
          data={data?.findAllUsers.users || []}
          noDataElement={
            <EmptyState
              title="No users yet"
              subTitle="Get started by adding a new user"
              btnText="Add user"
              onClick={() => openUserForm('create')}
              icon={<Icons.Users />}
            />
          }
          tableState={tableState}
          updateTableState={updateTableState}
          loading={loading}
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
          showSearch={false}
        />
      </div>
      <Modal
        open={userFormMode === 'create'}
        title="Invite team member"
        subTitle={
          <span>
            Add a new user to your account by entering their email address below.
            <Link to="/#" className="ml-1 text-primary-600 outline-none focus-visible:outline-none">
              Learn more about user roles.
            </Link>
          </span>
        }
        onClose={closeUserForm}
        closeIcon
      >
        <UsersForm onSubmit={onSubmit} onClose={closeUserForm} loading={inviteUserLoading} />
      </Modal>
      <ConfirmationModal
        open={confirmationModal}
        isLoading={toggleMerchantUserDisabledLoading}
        onClose={() => setConfirmationModal(false)}
        onConfirm={updateUserStatus}
        title={`${userDeletionAction ? 'Suspend' : 'Reactivate'} ${title}`}
        content={`Are you sure you want to ${userDeletionAction ? 'suspend' : 'reactivate'} this member?`}
      >
        <p className="text-sm text-steel-500">
          {userDeletionAction
            ? 'They will no longer able to access their account.'
            : 'They will be able to access their account again.'}
        </p>
      </ConfirmationModal>
      <ConfirmationModal
        title={`Revoke invite`}
        open={confirmationModalRevokeInvite}
        isLoading={revokeInviteLoading}
        onClose={() => setConfirmationModalRevokeInvite(false)}
        onConfirm={handleRevokeInvite}
        content="Are you sure you want to revoke this invite?"
      />
      <Drawer open={userFormMode === 'edit'} onClose={closeUserForm}>
        <>
          <Drawer.Title title={`Edit ${title}`} onClose={closeUserForm} />
          <EditPermissionsForm
            onSubmit={onSubmit}
            initialValues={{
              ...selectedUser,
              role:
                selectedUser?.role &&
                [USER_ROLES.SALES_MANAGER, USER_ROLES.SALES_REPRESENTATIVE].includes(selectedUser.role)
                  ? 'Sales'
                  : selectedUser?.role,
              visibility:
                selectedUser?.role === USER_ROLES.SALES_REPRESENTATIVE
                  ? USER_VISIBILITY.VIEW_OWNED
                  : USER_VISIBILITY.VIEW_ALL,
            }}
            loading={updateUserLoading}
          />
        </>
      </Drawer>
    </div>
  );
};
