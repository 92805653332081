import { Button, Icons } from '@portal/ui';
import { Link } from 'react-router-dom';

export const UnauthorizedPage = () => (
  <div className="flex flex-col items-center justify-center h-screen">
    <div className="font-bold text-primary mb-3">403</div>
    <div className="text-4xl font-bold text-gray-700 mb-3">Access restricted</div>
    <div className="text-gray-700 sm:w-full md:w-1/3  text-center">
      The page you&apos;re trying to access is restricted to authorized users only. If you believe you should have
      access, please contact the account administrator.
    </div>
    <div className="flex mt-4 gap-3">
      <Link to="..">
        <Button title="go back" displayType="secondary" prefixIcon={<Icons.ArrowLeft className="w-4 h-4 mr-2" />}>
          Go back
        </Button>
      </Link>
      <Button title="go to home" displayType="primary">
        Take me home
      </Button>
    </div>
  </div>
);
