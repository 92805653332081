import { FieldFeedback, HorizontalLabel, Icons, TextField, TimeZone, checkFieldErrors } from '@portal/ui';
import { formatOffset } from '@portal/utils/misc';
import FormField from 'components/formField';
import { useFormikContext } from 'formik';
import { BookingConfiguration } from 'graphql/types';
import moment from 'moment';
import { components } from 'react-select';

export const GeneralSettings = () => {
  const {
    values: { title, slug, welcomeMessage, tzName },
    setFieldValue,
    errors,
    touched,
  } = useFormikContext<BookingConfiguration>();

  return (
    <div className="mb-6">
      <div className="flex">
        <div className="w-3/12">
          <HorizontalLabel labelFor="title" label="Title" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <FormField id="title" type="text" value={title} />
        </div>
      </div>
      <hr className="bg-steel-200 my-[20px]" />
      <div className="flex">
        <div className="w-3/12">
          <HorizontalLabel labelFor="slug" label="URL" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <div className="flex">
            <div className="w-1/2">
              <TextField
                disabled
                id="url"
                value="https://calendar.growthware.com/"
                customStyling={{
                  field:
                    'text-steel-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                }}
              />
            </div>
            <div className="w-1/2 url-field">
              <FormField
                id="slug"
                value={slug}
                type="text"
                customStyling={{
                  field:
                    'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <hr className="bg-steel-200 my-[20px]" />
      <div className="flex">
        <div className="w-3/12">
          <HorizontalLabel
            labelFor="welcomeMessage"
            label="Welcome message"
            info="Write a short introduction. This will appear on your booking page."
          />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <FormField id="welcomeMessage" type="textarea" rows={6} value={welcomeMessage} />
        </div>
      </div>
      <hr className="bg-steel-200 my-[20px]" />
      <div className="flex">
        <div className="w-3/12">
          <HorizontalLabel labelFor="tzName" label="Time zone" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TimeZone
            id="tzName"
            timezone={tzName}
            onChange={(value: string) => setFieldValue('tzName', value)}
            selectedComponent={({ children, ...props }) => {
              const timezone = props.getValue();
              let value: number | null = null;
              if (timezone && timezone.length) {
                value = moment.tz(timezone[0].value).utcOffset();
              }

              return (
                <components.Control {...props} className="flex gap-2 !justify-start">
                  <Icons.Clock />
                  <div className="flex">{children}</div>
                  <p className="text-sm text-steel-500">{value ? `UTC ${formatOffset(value)}` : ''}</p>
                </components.Control>
              );
            }}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'tzName'
            )}
            errorMessage={errors?.tzName}
            transitionClasses="mt-1"
          />
        </div>
      </div>
    </div>
  );
};
