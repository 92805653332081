import React, { useEffect, useState } from 'react';
import { ErrorBox, Icons, classNames } from '@portal/ui';
import OnboardingLogo from 'assets/growthware_onboarding.png';
import BusinessDetails from 'components/onboardingSteps/components/BusinessDetails';
import BusinessScan from 'components/onboardingSteps/components/BusinessScan';
import Credentials from 'components/onboardingSteps/components/Credentials';
import { useDispatch, useSelector } from 'redux/hooks';
import OnboardingLayout from 'components/onboardingSteps/layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { StepProps } from 'components/onboardingSteps/onboardingTypes';
import { signInWithEmail, setNewPassword } from 'redux/thunks/user';
import { NewUserSignIn } from 'types/User';
import { FormikValues } from 'formik';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { onBoardingStatusPath } from 'utils/misc';
import { RootState } from 'redux/store';
import { logout } from 'redux/reducers/user';

type OnboardingState = {
  email: string | null;
  password: string | null;
  userType: string | null;
};

const Onboarding = () => {
  const [onBoardingState, setOnBoardingState] = useState<OnboardingState>({
    email: null,
    password: null,
    userType: null,
  });
  const user = useSelector((state: RootState) => state.user);
  const userStatus = user?.session?.status;
  const { onboardingStatus, role } = user?.attributes ?? { onboardingStatus: '', role: '' };

  useEffect(() => {
    if (onboardingStatus === 'COMPLETED') {
      navigate('/', { replace: true });
    } else if (onboardingStatus) navigate(onBoardingStatusPath[onboardingStatus as string], { replace: true });
  }, [onboardingStatus]);

  const dispatch = useDispatch();

  const [error, setError] = React.useState(null);
  const errorPresent = error && error !== '';

  const navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    setOnBoardingState({
      email: searchParams.get('e') as string,
      password: searchParams.get('p') as string,
      userType: searchParams.get('t') as string,
    });
  }, [location]);

  const step = location.pathname.split('/')[2] ?? 'credentials';

  const fetchUserSession = async () => {
    try {
      const response = (await dispatch(
        signInWithEmail(onBoardingState.email, onBoardingState.password)
      )) as NewUserSignIn;
      return response;
    } catch (err: any) {
      setError(err?.message ?? 'something went wrong');
      throw new Error(err);
    }
  };

  const handleNext = (path: string) => {
    navigate(`/onboarding/${path}`, { replace: true });
  };

  const steps: StepProps = {
    credentials: {
      title: 'Set a password',
      subtitle: 'Must be at least 8 characters',
      component: (
        <Credentials
          handleNext={async (values?: FormikValues) => {
            if (values?.password) {
              try {
                setError(null);
                await fetchUserSession();
                await dispatch(setNewPassword(values.password));
              } catch (err: any) {
                setError(err?.message ?? 'something went wrong');
                toast.error(err.message);
              }
            }
          }}
        />
      ),
    },
    scan: {
      title: 'Run a scan',
      subtitle: 'Generate your Growth Index Score',
      component: <BusinessScan handleNext={() => handleNext('details')} />,
    },
    details: {
      title: 'About your business',
      subtitle: 'Confirm your business details',
      component: <BusinessDetails />,
    },
  };

  const userTypeOwner = onBoardingState.userType === 'owner' || role === 'ADMIN';

  const logoutUser = () => {
    dispatch(logout());
    navigate('/login');
  };

  return (
    <OnboardingLayout>
      <div className={classNames('grid h-screen', userTypeOwner ? ' grid-cols-3' : ' grid-cols-1')}>
        {userTypeOwner && (
          <OnboardingLayout.Overview>
            <div className="flex flex-col">
              <img src={OnboardingLogo} alt="Growthware Onboarding" className="p-10 w-1/2" />
              <div className="pl-5">
                {Object.keys(steps).map((key, index) => {
                  return (
                    <div key={index} className="flex gap-4 items-start p-5">
                      <Icons.Check
                        color={
                          key === step ? '#7F56D9' : index < Object.keys(steps).indexOf(step) ? '#D6BBFB' : '#B9C0D4'
                        }
                      />
                      <div className="flex-col gap-2">
                        <h1 className={`${key !== step && 'text-[#B9C0D4]'} font-bold`}>{steps[key].title}</h1>
                        <p className="text-[#B9C0D4]">{steps[key].subtitle}</p>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="pl-14 pb-8">
              {userStatus === 'signed-in' && (
                <button className="text-sm font-bold text-steel-600" onClick={logoutUser}>
                  Log out
                </button>
              )}
            </div>
          </OnboardingLayout.Overview>
        )}
        <OnboardingLayout.Details>
          <>
            {errorPresent && <ErrorBox title={error} onClose={() => setError(null)} />}
            {step ? steps[step].component : steps.credentials.component}
            {userTypeOwner && (
              <div className="relative">
                <ul className="flex gap-1 absolute left-0 right-0 text-center justify-center bottom-5 ">
                  {Object.keys(steps).map((key, index) =>
                    key === step ? (
                      <li key={index} className="bg-primary w-6 rounded h-2"></li>
                    ) : (
                      <li key={index} className="bg-gray-300 w-2 rounded h-2"></li>
                    )
                  )}
                </ul>
              </div>
            )}
          </>
        </OnboardingLayout.Details>
      </div>
    </OnboardingLayout>
  );
};

export default Onboarding;
