import classNames from 'classnames';
import { Fragment } from 'react';

type Option = {
  label: string;
  value: string;
  selected: boolean;
};

type CheckBoxProps = {
  options: Option[];
  onChange: (options: Option[]) => void;
};

export const CheckboxButtons = ({ options, onChange }: CheckBoxProps) => {
  return (
    <div className="flex gap-2">
      {options.map((option) => (
        <Fragment key={option.value}>
          <input
            type="checkbox"
            id={option.value}
            value={option.value}
            className="hidden peer"
            onChange={() =>
              onChange(
                [...options].map((curr) => (curr.value === option.value ? { ...curr, selected: !curr.selected } : curr))
              )
            }
            checked={option.selected}
          />
          <label
            htmlFor={option.value}
            className={classNames('px-4 py-2 border text-sm font-medium rounded-md hover:cursor-pointer', {
              'bg-primary-50 hover:bg-primary-100 border-2 border-primary-600 text-primary-500': option.selected,
              'text-gray-600 border border-gray-200 hover:bg-gray-50': !option.selected,
            })}
          >
            {option.label}
          </label>
        </Fragment>
      ))}
    </div>
  );
};
