import { client } from 'libs/cognito';
import { setData } from '../../reducers/tags';
import { ThunkFn } from '../definitions/types';
import { FindAllTagsDocument, FindAllTagsQuery } from 'graphql/query.generated';

const fetchTags: ThunkFn = (id: string) => {
  return async (dispatch) => {
    try {
      const res = await client.query<FindAllTagsQuery>({
        query: FindAllTagsDocument,
        variables: {
          merchantAccountId: id,
        },
        fetchPolicy: 'network-only',
      });

      dispatch(setData(res.data.findAllTags.tags));
    } catch (err) {
      console.error(err);
    }
  };
};

export { fetchTags };
