import { HorizontalLabel, SubHeading } from '@portal/ui';
import Services from './Services';
import { subHeadingsWrapperClasses } from './AddSiteModal';
import FormField from 'components/formField';

const BusinessOverview = () => {
  return (
    <>
      <div className="flex-grow pb-5 border-b border-gray-200 mt-16">
        <SubHeading
          title="Business overview"
          description="Provide details about your business and what sets you apart from the competition."
        />
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="About your business"
            info="This is a section of your site where you describe your business, team, or story. It should be engaging and informative, and help visitors connect with your brand."
          />
        </div>
        <div className="w-8/12">
          <FormField id="about" type="textarea" rows={7} />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Mission statement"
            info="This is a statement that communicates the values and goals of your business. It should be brief and memorable, and ideally reflect the unique perspective or approach of your business."
          />
        </div>
        <div className="w-8/12">
          <FormField id="statement" type="textarea" rows={7} />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Products and services"
            labelFor="These are the specific products or services your business provides."
          />
        </div>
        <div className="w-8/12">
          <Services />
        </div>
      </div>
      <div className="py-5 border-gray-200 flex gap-8">
        <div className="w-4/12">
          <HorizontalLabel label="Year founded" />
        </div>
        <div className="w-8/12">
          <FormField id="year" type="number" />
        </div>
      </div>
    </>
  );
};

export default BusinessOverview;
