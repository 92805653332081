import FormField from 'components/formField';

const PasswordFields = () => {
  return (
    <>
      <div className="flex [&>div]:w-full gap-2 mb-2">
        <FormField type="password" label="Current password" id="currentPassword" />
      </div>
      <div className="flex [&>div]:w-full gap-2 mb-2">
        <FormField type="password" label="New password" id="newPassword" />
      </div>
      <div className="flex [&>div]:w-full gap-2 mb-2">
        <FormField type="password" label="Confirm password" id="confirmPassword" />
      </div>
    </>
  );
};

export default PasswordFields;
