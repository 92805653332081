import React, { useState, useCallback, useEffect, useMemo } from 'react';
import moment from 'moment';
import {
  Badge,
  BadgeType,
  EmptyState,
  Icons,
  RowActionMenu,
  DataTable,
  RowActionMenuProps,
  DeleteModal,
} from '@portal/ui';
import { capitalizeText } from '@portal/utils/string';
import ActionModal from 'components/sites/ActionModal';
import AddSiteModal from 'components/sites/AddSiteModal';
import { useFindAllSitesLazyQuery } from 'graphql/query.generated';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { FindAllSitesFilters } from 'graphql/types';
import { SiteRow } from 'types/Sites';
import { ternary } from '@portal/utils/conditional';
import { ColumnType } from '@portal/ui/components/base/Table';
import { useDeleteSiteMutation } from 'graphql/mutation.generated';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { SiteActions } from 'constants/sites';

const badgeMappings: Record<string, BadgeType> = {
  PENDING: BadgeType.Primary,
  INACTIVE: BadgeType.Draft,
  ACTIVE: BadgeType.Success,
  DRAFT: BadgeType.Draft,
};

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllSitesFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'paidAt',
      desc: true,
    },
  ],
  filters: {
    siteTitleLike: '',
  },
};

const SitesPage = () => {
  const [actionModalType, setActionModalType] = useState<string>('');
  const [addNewOpen, setAddNewOpen] = useState<boolean>(false);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [selectedRow, setSelectedRow] = useState<SiteRow | null>(null);
  const [isActionModalOpen, setIsActionModalOpen] = useState<boolean>(false);

  const [findAllSites, { data: sitesData, loading: sitesLoading, refetch: refetchAllSites }] = useFindAllSitesLazyQuery(
    {
      fetchPolicy: 'no-cache',
    }
  );

  const [deleteSite, { loading: deleteSiteLoading }] = useDeleteSiteMutation({
    onCompleted: () => {
      refetchAllSites();
      setDeleteModal(false);
      setSelectedRow(null);
      toast.success('Site removed');
    },

    onError: (error) => {
      toast.error(error.message);
    },
  });

  useEffect(() => {
    findAllSites({
      variables: {
        filters: tableState.filters,
        pagination: {
          offset: tableState.pageIndex * tableState.pageSize,
          limit: tableState.pageSize,
        },
        orderBy: {
          createdAt: ternary(tableState.sorting.find((col) => col.id === 'createdAt')?.desc, 'desc', 'asc', undefined),
          siteTitle: ternary(tableState.sorting.find((col) => col.id === 'title')?.desc, 'desc', 'asc', undefined),
        },
      },
    });
  }, [
    tableState.pageIndex,
    tableState.pageSize,
    tableState.sorting,
    tableState.filters?.siteTitleLike,
    tableState.sorting,
  ]);

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            siteTitleLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = sitesData?.findAllSites.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [sitesData?.findAllSites.count]);

  const updateSiteStatus = useCallback(
    (site: any) => () => {
      //TODO: We need to publish and unpublish site here
    },
    []
  );

  const openActionModal = useCallback(
    (actionType: string) => {
      setActionModalType(actionType);
      setIsActionModalOpen(true);
    },
    [setActionModalType]
  );

  const closeActionModal = useCallback(() => {
    setIsActionModalOpen(false);
  }, []);

  const confirmActionModal = useCallback(() => {
    closeActionModal();
  }, [closeActionModal]);

  const actionTypeMapping: Record<string, () => void> = {
    view: confirmActionModal,
    customizeDomain: closeActionModal,
    credits: closeActionModal,
    edit: closeActionModal,
    remove: closeActionModal,
  };

  // const creditsBar = (
  //   <span className="inline-flex items-center rounded-full px-3 py-1.5 ml-2 text-xs font-medium bg-primary-50 text-primary-700">
  //     {data.credits} site credits available
  //     <Button
  //       displayType="secondary"
  //       onClick={() => openActionModal('credits')}
  //       title="buy credits"
  //       suffixIcon={<Icons.ChevronRight />}
  //     >
  //       Buy credits
  //     </Button>
  //   </span>
  // );

  const rowActions: (site: SiteRow) => RowActionMenuProps<SiteRow>['rowActions'] = (site) => [
    { name: 'View', action: () => openActionModal('view') },

    {
      name: 'Edit',
      action: () => {
        return site.status === 'DRAFT' ? editSite(site) : openActionModal('edit');
      },
    },
    {
      name: `${site.status === 'ACTIVE' ? 'Unpublish' : 'Publish'}`,
      action: () => updateSiteStatus(site),
    },
    { name: 'Customize domain', action: () => openActionModal('customizeDomain') },
    {
      name: 'Remove',
      action: () => (site.status === 'DRAFT' ? handleRemoveSite(site) : openActionModal('remove')),
    },
  ];

  const editSite = (site: SiteRow) => {
    setSelectedRow(site);
    setAddNewOpen(true);
  };

  const columns: ColumnType<SiteRow>[] = [
    {
      id: 'title',
      name: 'Site',
      cell: (info: any) => {
        const { siteTitle, domain } = info.row.original;
        return (
          <div className="flex items-center">
            <div className="font-medium text-gray-900">{siteTitle ? capitalizeText(siteTitle) : 'New Site'}</div>
            {domain && <div className="text-gray-500">{domain}</div>}
          </div>
        );
      },
    },
    {
      id: 'status',
      name: 'Status',
      cell: (info: any) => {
        return <Badge text={capitalizeText(info.getValue())} type={badgeMappings[info.getValue()]} />;
      },
    },
    {
      id: 'createdAt',
      name: 'Created',
      cell: (info: any) => moment(info.getValue()).format('MMM D, YYYY'),
    },
    {
      id: 'action',
      name: '',
      cell: (info) => {
        const site: SiteRow = info.row.original;

        return (
          <RowActionMenu
            rowActions={rowActions(site).filter((rowAction) => SiteActions[site.status].includes(rowAction.name))}
            row={site}
          />
        );
      },
    },
  ];

  const handleRemoveSite = (site: SiteRow) => {
    setDeleteModal(true);
    setSelectedRow(site);
  };

  const removeSite = () => {
    deleteSite({
      variables: {
        id: selectedRow?.id as string,
      },
    });
  };

  return (
    <div className="px-0 md:px-8">
      <DeleteModal
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
          setSelectedRow(null);
        }}
        content="Are you sure you want to remove this site?"
        title="Remove site"
        onConfirm={removeSite}
        loading={deleteSiteLoading}
      />
      <DataTable
        columns={columns}
        tableActions={[
          {
            icon: <Icons.Plus color="#fff" />,
            name: 'Add site',
            action: () => setAddNewOpen(true),
          },
        ]}
        data={sitesData?.findAllSites.sites || []}
        showSearch={true}
        pagination={true}
        onGlobalFilterChange={onGlobalFilterChange}
        pageCount={pageCount}
        loading={sitesLoading}
        tableState={tableState}
        updateTableState={updateTableState}
        noDataElement={
          <EmptyState
            title={'No sites yet'}
            subTitle={'Get started by adding a new site.'}
            btnText={'Add site'}
            icon={<Icons.Desktop />}
            onClick={() => setAddNewOpen(true)}
          />
        }
      />

      <AddSiteModal
        isOpen={addNewOpen}
        onClose={() => {
          setAddNewOpen(false);
          refetchAllSites();
          setSelectedRow(null);
        }}
        selectedRow={selectedRow}
        setSelectedRow={setSelectedRow}
      />
      <ActionModal
        isOpen={isActionModalOpen}
        action={actionTypeMapping[actionModalType]}
        actionType={actionModalType}
        onClose={closeActionModal}
      />
    </div>
  );
};

export default React.memo(SitesPage);
