import { useFormikContext } from 'formik';
import { FieldFeedback, checkFieldErrors } from '@portal/ui';
import toUpper from 'lodash/toUpper';
import { AvailabilitySlotProps } from 'types/BookingSettings';
import { BookingConfigurationAvailability } from 'graphql/types';
import { Icons } from '@portal/ui';
import FormField from 'components/formField';

export const AvailabilitySlot = ({ availability, index, showDivider }: AvailabilitySlotProps) => {
  const { handleChange, touched, errors } = useFormikContext<{ availability: BookingConfigurationAvailability[] }>();
  const { day, startTime, endTime, enabled } = availability;
  const error = errors?.availability?.[index];
  const startTimeError = typeof error === 'object' ? error?.startTime : undefined;
  const endTimeError = typeof error === 'object' ? error?.endTime : undefined;

  return (
    <>
      <div className="flex justify-between">
        <div className="mt-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="text-primary-600 border-primary-600 bg-primary-50 rounded-md cursor-pointer mr-8 focus:ring-0 w-5 h-5"
              checked={enabled}
              name={`availability.${index}.enabled`}
              id={`availability.${index}.enabled`}
              onChange={handleChange}
            />
            <label className="text-steel-700 text-sm font-medium">{toUpper(day.substring(0, 3))}</label>
          </div>
        </div>
        <div className="flex w-auto pl-8">
          <div className="flex flex-col w-5/12">
            <FormField id={`availability.${index}.startTime`} type="time" value={startTime} disabled={!enabled} />
            <FieldFeedback
              errorCondition={checkFieldErrors(
                touched as Record<string, boolean>,
                errors as Record<string, string | undefined>,
                `availability.${index}`,
                'startTime'
              )}
              errorMessage={startTimeError}
              transitionClasses="mt-1"
            />
          </div>
          <Icons.Minus className="w-5 h-5 mx-3 mt-2.5" />
          <div className="flex flex-col w-5/12">
            <FormField id={`availability.${index}.endTime`} type="time" value={endTime} disabled={!enabled} />
            <FieldFeedback
              errorCondition={checkFieldErrors(
                touched as Record<string, boolean>,
                errors as Record<string, string | undefined>,
                `availability.${index}`,
                'endTime'
              )}
              errorMessage={endTimeError}
              transitionClasses="mt-1"
            />
          </div>
          <div className="flex items-center justify-end w-full">
            <Icons.Plus width={20} height={20} />
          </div>
        </div>
      </div>
      {showDivider && <hr className="bg-steel-200 my-[20px]" />}
    </>
  );
};
