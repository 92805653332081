export function isValidCreditCardNumber(value: string | undefined): boolean {
  if (!value) {
    return false;
  }

  // Remove any non-digit characters
  const cardNumber = value.replace(/\D/g, "");

  // Perform Luhn algorithm check
  let sum = 0;
  let doubleDigit = false;

  for (let i = cardNumber.length - 1; i >= 0; i--) {
    let digit = parseInt(cardNumber.charAt(i), 10);

    if (doubleDigit) {
      digit *= 2;
      if (digit > 9) {
        digit -= 9;
      }
    }

    sum += digit;
    doubleDigit = !doubleDigit;
  }

  return sum % 10 === 0;
}

export function isValidCreditCardExpiry(value: string | undefined): boolean {
  if (!value) {
    return false;
  }

  // Extract month and year from the input
  const [enteredMonth, enteredYear] = value
    .split("/")
    .map((part) => parseInt(part.trim(), 10));

  if (isNaN(enteredMonth) || isNaN(enteredYear)) {
    return false;
  }

  const currentYear = new Date().getFullYear();
  const currentMonth = new Date().getMonth() + 1; // Months are zero-based

  // Check if the expiry date is in the future
  if (
    enteredYear > currentYear ||
    (enteredYear === currentYear && enteredMonth >= currentMonth)
  ) {
    return true;
  }

  return false;
}

export function isValidCreditCardCVC(value: string | undefined): boolean {
  if (!value) {
    return false;
  }

  const cvcRegex = /^[0-9]{3,4}$/;
  return cvcRegex.test(value);
}
