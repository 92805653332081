import { Button, Formik } from '@portal/ui';
import { FormikValues, Form } from 'formik';
import { useDispatch, useSelector } from 'redux/hooks';
import { useUpdateMerchantUserMutation, useRequestMerchantrUserAvatarUpdateMutation } from 'graphql/mutation.generated';
import { useState } from 'react';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { fetchUserAttributes } from 'redux/thunks/user';
import { FormDataState, getFormData } from '@portal/utils/forms';
import ProfileFields from 'components/profile/ProfileFields';
import { profileSchema } from 'components/validation/profileSchema';

export type FormState = {
  name: string;
  tzName: string;
  profileAvatar: string;
};

const initialData: FormState = {
  name: '',
  tzName: '',
  profileAvatar: '',
};

export const Details = () => {
  const user = useSelector((state) => state.user.attributes);
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState<boolean>(false);

  const [requestMerchantLogoUpdateMutation] = useRequestMerchantrUserAvatarUpdateMutation();

  const [updateMerchantUserMutation] = useUpdateMerchantUserMutation({
    onCompleted: () => {
      toast.success('Profile updated');
      dispatch(fetchUserAttributes());
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const requestAvatarUpdate = (values: FormikValues) => {
    requestMerchantLogoUpdateMutation({
      variables: {
        imgMimeType: values?.profileAvatar?.type || '',
      },
    }).then((res) => {
      const uploadDetails = res?.data?.requestMerchantUserAvatarUpdate;
      const formData = getFormData(uploadDetails as FormDataState, values.profileAvatar as File);

      fetch(uploadDetails?.formPostUrl || '', {
        method: 'POST',
        body: formData,
      }).then(async (res) => {
        updateMerchantUserMutation({
          variables: {
            data: {
              acknowledgeMerchantUserAvatarUpdate: true,
              name: values.name,
              tzName: values.tzName,
            },
          },
        });
        setIsUpdating(false);
      });
    });
  };

  const updateUserProfile = async (values: FormikValues) => {
    if (values.profileAvatar && values.profileAvatar.type) {
      setIsUpdating(true);
      requestAvatarUpdate(values);
      return;
    }
    if (values.name || values.tzName) {
      setIsUpdating(true);
      await updateMerchantUserMutation({
        variables: {
          data: {
            name: values.name,
            tzName: values.tzName,
          },
        },
      });
    }
    setIsUpdating(false);
  };

  return (
    <div className="overflow-y-auto w-full relative">
      <Formik
        enableReinitialize
        initialValues={{
          ...initialData,
          email: user?.email ?? '',
          tzName: user?.tzName ?? '',
          name: user?.name ?? '',
          profileAvatar: user?.avatar ?? '',
        }}
        onSubmit={updateUserProfile}
        validationSchema={profileSchema}
      >
        <Form>
          <div className="px-4 sm:px-6 lg:px-8 mb-24">
            <ProfileFields />
          </div>
          <div className="py-5 border-t border-steel-200 fixed bottom-0 right-0 px-4 sm:px-6 lg:px-8 text-right bg-white w-full">
            <Button title="save details" loading={isUpdating} displayType="primary" type="submit">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};
