const ProgressBar = ({ progress }: { progress: number }) => {
  return (
    <div className="relative w-full h-2 bg-gray-300">
      <div
        className="absolute top-0 left-0 h-full bg-primary-600 transition-all"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default ProgressBar;
