import AppRoutes from './AppRoutes';
import { Toast } from '@portal/ui';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PKEY || '');

function App() {
  return (
    <>
      <Elements stripe={stripePromise}>
        <Toast />
        <AppRoutes />
      </Elements>
    </>
  );
}

export default App;
