import { Button, Drawer, HorizontalLabel, SubHeading } from '@portal/ui';
import PasswordForm from 'components/password/PasswordForm';
import { useCallback, useState } from 'react';

export const PasswordSection = () => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);

  const onClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);

  return (
    <>
      <SubHeading title="Password" description="Manage how you securely access your account." showBorder />
      <div className="py-6 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel
            label="Change password"
            labelFor="change-password"
            info="Enter your current password to change your password"
          />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <Button
            title="change password"
            displayType="secondary"
            onClick={() => {
              setOpenDrawer(true);
            }}
          >
            Change password
          </Button>
        </div>
      </div>

      <Drawer open={openDrawer} onClose={onClose}>
        <PasswordForm onClose={onClose} />
      </Drawer>
    </>
  );
};
