import { Button, DeleteModal, EmptyState, Icons, SubHeading } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { FieldArray, useFormikContext } from 'formik';
import {
  useCreateEventTypeMutation,
  useDeleteEventTypeMutation,
  useUpdateEventTypeMutation,
} from 'graphql/mutation.generated';
import { useState } from 'react';
import { AddEventType } from './AddEventType';
import { EventType } from 'types/Event';

type EventTypesSettingsProps = {
  disabled: boolean;
  refetch: () => void;
};

export const EventTypesSettings = ({ disabled, refetch }: EventTypesSettingsProps) => {
  const [addNewType, setAddNewType] = useState<boolean>(false);
  const [selectedEventType, setSelectedEventType] = useState<
    | (EventType & {
        eventOwners?: string[];
      })
    | Record<string, never>
  >({});
  const [deleteEventTypeModal, setDeleteEventTypeModal] = useState<boolean>(false);
  const [selectedForDelete, setSelectedForDelete] = useState<EventType | null>();
  const {
    values: { eventTypes },
    setFieldValue,
  } = useFormikContext<{ eventTypes: EventType[] }>();

  const [updateEventTypeMutation, { loading: updateEventLoading }] = useUpdateEventTypeMutation({
    onCompleted: () => {
      toast.success('Event type updated');
      refetch();
      handleOnClose();
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
  });

  const [createEventTypeMutation, { loading: createEventLoading }] = useCreateEventTypeMutation({
    onCompleted: () => {
      toast.success('Event type created');
      refetch();
      handleOnClose();
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
  });

  const [deleteEventType, { loading: deleteEventTypeLoading }] = useDeleteEventTypeMutation({
    onCompleted: () => {
      toast.success('Event type deleted');
      refetch();
      handleOnClose();
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
  });

  const closeDeleteProductModal = () => {
    setSelectedForDelete(null);
    setDeleteEventTypeModal(false);
  };

  const handleEventTypeRemove = () => {
    if (!selectedForDelete) return;
    deleteEventType({
      variables: {
        eventTypeId: selectedForDelete.id,
      },
    });
    closeDeleteProductModal();
    refetch();
  };

  const handleEdit = (event: EventType) => {
    setSelectedEventType(event);
    setAddNewType(true);
  };

  const handleCreate = (event: EventType) => {
    if (selectedEventType?.id) {
      updateEventTypeMutation({
        variables: {
          data: {
            color: event.color,
            duration: event.duration,
            name: event.name,
            dayAvailabilityInFuture: event.dayAvailabilityInFuture,
            description: event.description,
            id: event.id,
            questions:
              event?.questions?.map(({ id, ...rest }) => ({
                id,
                required: rest.required,
                text: rest.text,
              })) ?? [],
            slug: event.slug,
            location: event.location || '',
            owners: event.owners as string[],
          },
        },
      });
    } else {
      createEventTypeMutation({
        variables: {
          data: {
            color: event?.color ?? '',
            duration: event.duration ?? 30,
            name: event?.name ?? '',
            dayAvailabilityInFuture: event?.dayAvailabilityInFuture ?? 30,
            description: event?.description ?? '',
            questions: event?.questions?.map(({ ...rest }) => rest) ?? [],
            slug: event?.slug ?? '',
            location: event.location || '',
            owners: event.owners as string[],
          },
        },
      });
    }
    setFieldValue('eventTypes', eventTypes);
  };

  const handleOnClose = () => {
    setAddNewType(false);
    setSelectedEventType({});
  };

  return (
    <div className="mb-20">
      <SubHeading
        title="Event types"
        description="Manage the types of appointments that customers can book with your company."
        actions={[
          <Button
            title="add new event type"
            displayType="secondary"
            onClick={() => setAddNewType(true)}
            disabled={disabled}
          >
            <Icons.Plus className="w-5 h-5 mr-1" aria-hidden="true" />
            Add new type
          </Button>,
        ]}
        showBorder
      />
      <div className="py-8">
        {eventTypes.length ? (
          <FieldArray
            name="eventTypes"
            render={() =>
              eventTypes.map((eventType, index) => (
                <div key={eventType.id} className="pr-16 px-2">
                  <div className="flex justify-between items-center">
                    <div className="flex">
                      <span className="w-5 h-5 rounded-full mr-8" style={{ backgroundColor: eventType.color }}></span>
                      <p className="text-sm font-normal text-steel-600">{eventType.name}</p>
                    </div>
                    <div className="mr-4">
                      <button
                        type="button"
                        onClick={() => {
                          setSelectedForDelete(eventType);
                          setDeleteEventTypeModal(true);
                        }}
                        className="px-2 py-2.5 border-0 shadow-0 mr-1"
                      >
                        <Icons.Trash color="#4A5578" className="w-5 h-5 mr-1" />
                      </button>
                      <button type="button" onClick={() => handleEdit(eventType)} className="px-2 py-2.5">
                        <Icons.Pencil color="#4A5578" className="w-5 h-5" />
                      </button>
                    </div>
                  </div>
                  {index !== eventTypes.length - 1 && <hr className="bg-steel-200 mt-5 mb-6" />}
                </div>
              ))
            }
          />
        ) : (
          <EmptyState
            title="No event types yet"
            subTitle="Get started by adding a new event type."
            icon={<Icons.Calendar color="#7F56D9" />}
            customClassNames="!py-2"
          />
        )}
      </div>
      <AddEventType
        eventType={selectedEventType}
        open={addNewType}
        onClose={handleOnClose}
        onSubmit={handleCreate}
        loading={createEventLoading || updateEventLoading}
      />
      <DeleteModal
        onClose={closeDeleteProductModal}
        loading={deleteEventTypeLoading}
        title={'Delete event type'}
        open={deleteEventTypeModal}
        onConfirm={handleEventTypeRemove}
        content={'Are you sure you want to delete this event type? This action cannot be undone.'}
      />
    </div>
  );
};
