import { Avatar } from '@portal/ui';
import { DateFormats } from '@portal/utils/dates';
import { currencyFormatter } from '@portal/utils/misc';
import { getInitials } from '@portal/utils/string';
import { FormikValues } from 'formik';
import { uniqueId } from 'lodash';
import moment from 'moment';
import React from 'react';
import { useSelector } from 'redux/hooks';

type InvoicePDFProps = {
  values: FormikValues;
};

const InvoicePDF: React.FC<InvoicePDFProps> = ({ values }) => {
  const total = values?.items.reduce((amount = 0, item: any) => amount + item.price * item.quantity, 0);
  const merchant = useSelector((state) => state.user.attributes?.merchantAccount);
  return (
    <>
      <div className="to-primary-500 bg-gradient-to-r from-primary w-full h-3 rounded-t-md"></div>
      <div className="flex flex-col gap-4 bg-white p-5 mb-3 relative rounded-b-md h-screen shadow-sm">
        <div className="flex justify-between">
          <h1 className="text-2xl text-steel-900 font-semibold mb-4">Invoice</h1>
          <Avatar size="x_large" imageUrl={merchant?.logo} initials={getInitials(merchant?.name ?? '')} />
        </div>
        <div>
          <h2 className="text-steel-600 text-xs my-2">To</h2>
          <div className="flex justify-between">
            <div className="flex flex-col text-xs text-steel-900 flex-grow">
              <p className="font-medium">{values?.customer?.name}</p>
              <div>
                <p>{values?.customer?.address?.addressLine1}</p>
                <p>
                  {values?.customer?.address?.city}, {values?.customer?.address?.state}{' '}
                  {values?.customer?.address?.pincode}
                </p>
                <hr className="mt-8 h-0.5 w-1/2 bg-steel-900" />
              </div>
            </div>
            <div className="flex flex-col text-right text-xs text-steel-600">
              <p className="font-medium">{merchant?.name}</p>
              <div>
                <p>{merchant?.address?.addressLine1}</p>
                <p>
                  {merchant?.address?.city}, {merchant?.address?.state} {merchant?.address?.pincode}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-12 text-xs text-steel-900">
          <div>
            <p>Invoice number</p>
            <p>Date of issue</p>
            <p>Due date</p>
          </div>
          <div className="font-medium">
            <p>{values?.slug ?? uniqueId('invoice-')}</p>
            <p>{moment(values?.createdAt).format(DateFormats.MONTH_DAY_WITH_YEAR)}</p>
            <p>
              {values?.customDate && values?.paymentDuration === 'custom'
                ? moment(values.customDate).format(DateFormats.MONTH_DAY_WITH_YEAR)
                : values?.paymentDuration &&
                  moment(values?.createdAt)
                    .add(Number(values?.paymentDuration), 'days')
                    .format(DateFormats.MONTH_DAY_WITH_YEAR)}
            </p>
          </div>
        </div>

        <div>
          <table className="w-full my-3 text-xs">
            <thead>
              <tr className="border-b border-steel-200 [&>th]:px-4 [&>th]:py-4 [&>th]:font-medium [&>th]:text-xs [&>th]:text-steel-600 bg-steel-50 text-left">
                <th className="w-1/2">Item</th>
                <th>Quantity</th>

                <th>Unit price</th>

                <th>Amount</th>
              </tr>
            </thead>
            <tbody>
              {values?.items?.map((item: any) => (
                <tr className="border-b [&>td]:px-4 [&>td]:py-2 [&>td]:text-xs [&>td]:text-steel-600">
                  <td className="!font-medium !text-xs !text-steel-900">{item.label}</td>
                  <td>{item.quantity}</td>
                  <td>{currencyFormatter(item.price)}</td>
                  <td>{currencyFormatter(item.price * item.quantity)}</td>
                </tr>
              ))}
              <tr className="[&>td]:px-4 [&>td]:text-xs [&>td]:py-2 [&>*:not(:first-child)]:border-b">
                <td></td>
                <td className="text-steel-700">Subtotal</td>
                <td></td>
                <td>{currencyFormatter(Number(total ?? 0))}</td>
              </tr>
              <tr className="[&>td]:px-4 [&>td]:text-xs [&>td]:py-2 [&>*:not(:first-child)]:border-b ">
                <td></td>
                <td className="text-steel-700">Total</td>
                <td></td>
                <td>{currencyFormatter(Number(total ?? 0))}</td>
              </tr>
              <tr className="[&>td]:px-4 [&>td]:text-xs [&>td]:py-2 font-medium">
                <td></td>
                <td className="text-steel-900">Amount due</td>
                <td></td>
                <td>{currencyFormatter(Number(total ?? 0))}</td>
              </tr>
            </tbody>
          </table>

          <footer className="text-xs flex flex-col gap-2">
            <span className="text-steel-900 font-medium">Memo</span>
            <span className="text-steel-600">{values?.memo}</span>
          </footer>
        </div>
      </div>
    </>
  );
};

export default InvoicePDF;
