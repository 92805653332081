import { Button, Formik } from '@portal/ui';
import CurrencyInput from 'components/currencyInput';
import FormField from 'components/formField';
import { invoiceLineItemSchema } from 'components/validation/itemSchema';
import { Form, FormikHelpers, FormikProps, FormikValues } from 'formik';
import { useRef } from 'react';
import { ItemType } from '../Invoice';

export interface ItemFormData {
  label: string;
  price: number;
  quantity: number;
}

const initialValues: ItemFormData = {
  label: '',
  price: 0.0,
  quantity: 0,
};

interface AddNewItemProps {
  addItem: (values: ItemType) => void;
  handleCancel: () => void;
  item?: ItemType | null;
}

const AddNewItem = ({ addItem, handleCancel, item }: AddNewItemProps) => {
  const formikRef = useRef<FormikProps<ItemFormData>>(null);
  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    e.preventDefault();
    formikRef.current?.submitForm();
  };

  const handleSubmit = (values: FormikValues, helpers: FormikHelpers<ItemFormData>) => {
    addItem({ ...values, label: values.label, value: values.price });

    helpers.resetForm();
  };

  return (
    <div className="pt-5 px-5 mb-4 bg-steel-50 border-b-steel-200">
      <Formik
        innerRef={formikRef}
        validationSchema={invoiceLineItemSchema}
        initialValues={{ ...initialValues, ...(item ? item : '') }}
        onSubmit={handleSubmit}
      >
        <Form>
          <label className="block mb-2 text-md font-bold text-steel-700" htmlFor="customer">
            New Item
          </label>
          <div className="block lg:grid lg:grid-cols-4 gap-4">
            <div className="col-span-2">
              <label className="block text-sm font-medium text-steel-700 mb-2">Item</label>
              <FormField id="label" type="text" />
            </div>
            <div className="grid grid-cols-2 lg:col-span-2 gap-4 mt-4 lg:mt-0">
              <div>
                <label className="block text-sm font-medium text-steel-700 mb-2">Quantity</label>
                <FormField min="0" id="quantity" type="number" />
              </div>
              <div>
                <label className="block text-sm font-medium text-steel-700 mb-2">Price</label>
                <CurrencyInput id="price" />
              </div>
            </div>
          </div>
          <div className="flex justify-end py-4 gap-2">
            <Button title="cancel invoice item" onClick={handleCancel} displayType="secondary">
              Cancel
            </Button>
            <Button title="save invoice item" onClick={handleClick} displayType="primary">
              Save
            </Button>
          </div>
        </Form>
      </Formik>
    </div>
  );
};

export default AddNewItem;
