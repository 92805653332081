import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { Action } from 'constants/action';
import { MerchantAccountStatus, MerchantAccountUserRole, MerchantAccountUserOnboardingStatus } from 'graphql/types';
import { Keys, localStorage } from 'libs/local-storage';

type UserState = {
  attributes: {
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
    role: MerchantAccountUserRole;
    tzName?: string;
    status?: string;
    onboardingStatus?: MerchantAccountUserOnboardingStatus;
    team: {
      id: string;
      name: string;
      avatar?: string | null;
      email: string;
      displayName: string;
      status: MerchantAccountStatus;
    }[];
    merchantAccount: {
      id: string;
      name: string;
      logo?: string | null;
      address?:
        | {
            addressLine1: string;
            addressLine2: string;
            city: string;
            state: string;
            pincode: string;
            countryCode: string;
          }
        | undefined
        | null;
    };
  } | null;
  session: {
    status: 'loading' | 'signed-in' | 'signed-out';
    accessToken: string | null;
    refreshToken: string | null;
  };
  loading: boolean;
  changePasswordLoading?: boolean;
  action?: string;
};

const initialState: UserState = {
  attributes: null,
  session: {
    status: 'signed-out',
    accessToken: null,
    refreshToken: null,
  },
  loading: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setAttributes: (state, action: PayloadAction<UserState['attributes']>) => {
      state.attributes = action.payload;
    },
    setStatus: (state, action: PayloadAction<UserState['session']['status']>) => {
      state.session.status = action.payload;
    },
    updateSession: (state, action: PayloadAction<Partial<UserState['session']>>) => {
      state.session = {
        ...state.session,
        ...action.payload,
      };
      localStorage.set(Keys.accessToken, state.session.accessToken);
      localStorage.set(Keys.refreshToken, state.session.refreshToken);
    },
    logout: () => {
      localStorage.remove(Keys.accessToken);
      localStorage.remove(Keys.refreshToken);
      return { ...initialState };
    },
    reset: () => {
      return { ...initialState };
    },

    setLoader: (state) => {
      return { ...state, action: Action.IN_PROGRESS, loading: true };
    },
    setChangePasswordLoader: (state) => {
      return { ...state, action: Action.IN_PROGRESS, changePasswordLoading: true };
    },
    resetChangePasswordLoader: (state) => {
      return { ...state, action: Action.SUCCESS, changePasswordLoading: false };
    },
    resetLoader: (state) => {
      return { ...state, action: '', loading: false };
    },

    resetAction: (state) => {
      return { ...state, action: '', loading: false };
    },
  },
});

export const userReducer = slice.reducer;
export const {
  setAttributes,
  setStatus,
  updateSession,
  logout,
  reset,
  setLoader,
  resetLoader,
  resetAction,
  setChangePasswordLoader,
  resetChangePasswordLoader,
} = slice.actions;
export type { UserState };
