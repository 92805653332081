import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { EmailBox, Drawer } from '@portal/ui';
import { FormikValues } from 'formik';

interface EmailFormProps {
  open: boolean;
  onClose: () => void;
}

type EmailValues = {
  to: string;
  from: string;
  subject: string;
};

const initailValues: EmailValues = {
  to: '',
  from: '',
  subject: '',
};

const EmailForm = ({ open, onClose }: EmailFormProps) => {
  const isMobileView = useIsMobileView();
  const handleSubmit = (values: FormikValues) => {
    console.log('email submitted');
  };
  const commonProps = {
    open,
    onClose,
    handleSubmit,
    value: initailValues,
  };
  return isMobileView ? (
    <Drawer onClose={onClose} open={open}>
      <EmailBox {...commonProps} />
    </Drawer>
  ) : (
    <EmailBox open={open} onClose={onClose} handleSubmit={handleSubmit} value={initailValues} />
  );
};

export default EmailForm;
