import React from 'react';
import { FieldArray, useFormikContext } from 'formik';
import AvailabilitySlot from './AvailabilitySlots';
import { weeklyAvailability } from '../../types/WeeklyAvailabilityType';

const WeeklyAvailability: React.FC = () => {
  const {
    values: { weeklyAvailability },
  } = useFormikContext<{ weeklyAvailability: weeklyAvailability[] }>();

  return (
    <FieldArray
      name="weeklyAvailability"
      render={() =>
        weeklyAvailability?.map((availability: weeklyAvailability, index: number) => (
          <AvailabilitySlot
            key={`calendar-${availability.day}`}
            basePath={`weeklyAvailability.${index}`}
            slots={availability.slots}
            active={availability.active}
            day={availability.day}
            showDivider={index !== weeklyAvailability.length - 1}
          />
        ))
      }
    />
  );
};

export default WeeklyAvailability;
