export type IconButtonProps = {
  icon?: React.ReactNode;
  title?: string;
  customClass?: string;
  onClick?: () => void;
};

export const IconButton = ({ icon, title, customClass, onClick }: IconButtonProps) => {
  return (
    <button type="button" className="flex flex-col items-center justify-center gap-2.5" onClick={onClick} title={title}>
      <div
        className={`flex justify-center items-center p-2 gap-2 w-9 h-9  bg-white border border-gray-300 shadow-custom-shadow rounded-lg ${customClass}`}
      >
        {icon}
      </div>
      {title && <p className="text-xs font-normal text-gray-700">{title}</p>}
    </button>
  );
};
