import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type TagsState = {
  data: {
    id: string;
    name: string;
    createdAt: string;
    updatedAt?: string;
  }[];
};

const initialState: TagsState = {
  data: [],
};

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<TagsState['data'] | []>) => {
      state.data = action.payload;
    },
  },
});

export const tagsReducer = slice.reducer;
export const { setData } = slice.actions;
export type { TagsState };
