import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { useMergeState } from '@portal/react-hooks/use-merge-state';
import { Avatar, DataTable, DeleteModal, Drawer, EmptyState, Icons, RowActionMenu } from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import { statusItems } from '@portal/ui/components/data/statusItems';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { getFirstAndLastName, getInitials } from '@portal/utils/string';
import { PaginationState, SortingState } from '@tanstack/react-table';
import BadgeDropdown from 'components/badgeDropdown';
import { ContactForm, ContactFormProps } from 'components/contacts/ContactForm';
import { useDeleteContactMutation, useUpdateContactMutation } from 'graphql/mutation.generated';
import { FindAllContactsQuery, useFindAllContactsLazyQuery } from 'graphql/query.generated';
import { FindAllContactsFilters, MerchantContactStatus, ViewMode } from 'graphql/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/hooks';
import { fetchTags } from 'redux/thunks/tags';

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllContactsFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: {
    viewMode: 'viewAll',
  },
};

export type ContactRow = FindAllContactsQuery['findAllContacts']['contacts'][number];

export const ContactsPage = () => {
  const merchantAccountId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const merchantRole = useSelector((state) => state.user.attributes?.role);
  const [contactFormMode, setContactFormMode] = useState<ContactFormProps['mode'] | null>(null);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [selectedContact, setSelectedContact] = useState<ContactRow | null>();
  const [selectedContactId, setSelectedContactId] = useState<string>('');
  const openContactFormDrawer = useCallback((mode: typeof contactFormMode) => setContactFormMode(mode), []);
  const dispatch = useDispatch();
  const closeContactFormDrawer = useCallback(() => {
    setContactFormMode(null);
    setSelectedContact(null);
  }, []);

  const [findAllContacts, { data, loading, refetch }] = useFindAllContactsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const isMobileView = useIsMobileView();
  const [selectedContactToDelete, setSelectedContactToDelete] = useState<ContactRow | null>(null);
  const closeDeleteContactModal = useCallback(() => setSelectedContactToDelete(null), []);
  const navigate = useNavigate();
  const canAccessAllContacts = merchantRole !== 'SALES_REPRESENTATIVE';

  const [updateContact, { loading: updateContactLoading }] = useUpdateContactMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Contact updated');
      dispatch(fetchTags(merchantAccountId));
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      closeContactFormDrawer();
    },
  });

  const [deleteContactMutation, { loading: deleteContactLoading }] = useDeleteContactMutation({
    onCompleted: () => {
      toast.success('Contact deleted');
      closeDeleteContactModal();
      resetTableState();
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const updateStatus = (status: string, contact: ContactRow) => {
    setSelectedContactId(contact.id);
    updateContact({
      variables: {
        data: {
          merchantContactId: contact.id,
          status: status as MerchantContactStatus,
        },
      },
    }).then(() => {
      setSelectedContactId('');
    });
  };

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  useEffect(() => {
    if (merchantAccountId) {
      findAllContacts({
        variables: {
          merchantAccountId: merchantAccountId as string,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            phone: ternary(tableState.sorting.find((col) => col.id === 'phone')?.desc, 'desc', 'asc', undefined),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
            status: ternary(tableState.sorting.find((col) => col.id === 'status')?.desc, 'desc', 'asc', undefined),
          },
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
  }, [
    merchantAccountId,
    tableState.pageIndex,
    tableState.pageSize,
    tableState.filters?.nameLike,
    tableState.filters?.emailLike,
    tableState.filters?.phoneLike,
    tableState.filters?.viewMode,
    tableState.sorting,
  ]);

  const rowActions: RowActionMenuProps<ContactRow>['rowActions'] = [
    {
      name: 'Edit',
      action: (row) => {
        setSelectedContact(row);
        openContactFormDrawer('edit');
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedContactToDelete(row ?? null);
      },
    },
  ];

  const columns: ColumnType<ContactRow>[] = [
    {
      name: 'Name',
      cell: (info: any) => {
        const contact = info.row.original;

        return (
          <div
            onClick={() => navigate(`/contacts/${contact.id}`)}
            className="flex items-center justify-between cursor-pointer"
          >
            <div className="flex items-center">
              <div className="h-10 w-10 flex-shrink-0">
                <Avatar
                  imageUrl={contact.avatar}
                  initials={getInitials(contact.name !== '' ? contact?.name : contact?.email)}
                />
              </div>
              <div className="ml-4">
                <div className="font-medium text-steel-900">{contact.name}</div>
                <div className="text-steel-500">{contact.email}</div>
              </div>
            </div>
            <div>{isMobileView && <Icons.ChevronRight />}</div>
          </div>
        );
      },
      id: 'name',
    },
    {
      id: 'status',
      name: 'Status',
      cell: (info: any) => (
        <BadgeDropdown
          options={statusItems}
          disabled={selectedContactId === info.row.original.id && updateContactLoading}
          loading={selectedContactId === info.row.original.id && updateContactLoading}
          selected={info.getValue()}
          onChange={(value: string) => updateStatus(value, info.row.original)}
        />
      ),
      display: 'desktop',
    },
    {
      id: 'createdAt',
      name: 'Created',
      cell: (info: any) => moment(info.getValue()).format('MMM D, YYYY'),
      display: 'desktop',
    },
    {
      name: 'Phone',
      id: 'phone',
      display: 'desktop',
    },
    {
      name: 'Owner',
      id: 'owner',
      display: 'desktop',
      cell: ({ row: { original } }) => {
        return (
          <div className="flex items-center">
            <div className="h-6 w-6 flex-shrink-0 ">
              <Avatar
                imageUrl={original.owner.avatar}
                size="x_small"
                initials={getInitials(original.owner.name !== '' ? original.owner.name : original.owner.email)}
              />
            </div>
            <div className="ml-4">
              <div className="text-steel-900">
                {original.owner.name !== '' ? original.owner.name : original.owner.email}
              </div>
            </div>
          </div>
        );
      },
    },
    {
      name: '',
      id: 'actions',
      cell: ({ row: { original } }) => <RowActionMenu row={original} rowActions={rowActions} />,
      display: 'desktop',
    },
  ];

  const onContactDelete = () => {
    deleteContactMutation({
      variables: { merchantContactId: selectedContactToDelete?.id ?? '' },
    }).then((res) => {
      if (res.errors) {
        toast.error('Something went wrong');
      }
    });
  };

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: {
            nameLike: value,
            phoneLike: value,
            emailLike: value,
          },
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = data?.findAllContacts.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllContacts.count]);

  const selectViewMode = (viewMode: ViewMode) => {
    updateTableState({
      filters: {
        ...tableState.filters,
        viewMode,
      },
    });
  };

  const [firstName, lastName] = getFirstAndLastName(selectedContact?.name ?? '');

  return (
    <>
      <div className="px-0 md:px-8">
        <div className="py-0 md:py-3">
          <div className="flex md:hidden justify-between bg-steel-50 border-steel-200 border-b py-3 px-4 sm:px-6  items-center">
            <div className="text-steel-600 text-xs">{data?.findAllContacts.count} contacts</div>
          </div>
          <DataTable
            columns={columns}
            data={data?.findAllContacts.contacts || []}
            hideColumnHeadersOnMobile={true}
            noDataElement={
              <EmptyState
                title={'No contacts yet'}
                subTitle={'Get started by adding a new contact'}
                btnText={'Add contact'}
                onClick={() => openContactFormDrawer('create')}
                icon={<Icons.AddContact />}
              />
            }
            tableState={tableState}
            loading={loading}
            updateTableState={updateTableState}
            onGlobalFilterChange={onGlobalFilterChange}
            pageCount={pageCount}
            showSearch
            showViewModeFilter
            selectViewMode={selectViewMode}
            canAccessAll={canAccessAllContacts}
          />
        </div>

        <Drawer open={contactFormMode != null} onClose={closeContactFormDrawer}>
          <ContactForm
            mode={contactFormMode ?? 'create'}
            initialValues={
              selectedContact
                ? {
                    ...selectedContact,
                    avatar: null,
                    firstName: firstName,
                    lastName: lastName,
                    ...selectedContact.address,
                    ownerId: selectedContact.owner?.id ?? '',
                  }
                : undefined
            }
          />
        </Drawer>

        <DeleteModal
          onClose={closeDeleteContactModal}
          title={'Delete contact'}
          open={selectedContactToDelete != null}
          onConfirm={onContactDelete}
          loading={deleteContactLoading}
          content={'Are you sure you want to delete this contact? This action cannot be undone.'}
        />
      </div>
    </>
  );
};
