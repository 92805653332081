import { FieldArray, useFormikContext } from 'formik';
import { AvailabilitySlot } from './AvailabilitySlot';
import { BookingConfigurationAvailability } from 'graphql/types';
import { HorizontalLabel } from '@portal/ui';
import { Icons } from '@portal/ui';
export interface AvailabilitySettingsProps {
  customFieldClass?: string;
  showLabel?: boolean;
}
export const AvailabilitySettings = ({ customFieldClass, showLabel = true }: AvailabilitySettingsProps) => {
  const {
    values: { availability },
  } = useFormikContext<{ availability: BookingConfigurationAvailability[] }>();
  return (
    <>
      <div className="mb-6 flex w-full">
        {showLabel && (
          <div className="flex flex-col flex-shrink w-3/12">
            <HorizontalLabel
              labelFor="availability"
              label="Availability"
              info="Set the hours that you want to allow people to book time with your business."
            />
          </div>
        )}
        <div className="flex flex-col flex-grow">
          <FieldArray
            name="availability"
            render={() => (
              <div>
                {availability?.map((slot, index) => (
                  <AvailabilitySlot
                    key={`calendar-${slot.day}`}
                    index={index}
                    availability={slot}
                    showDivider={index !== availability.length - 1}
                  />
                ))}
              </div>
            )}
          />
        </div>
      </div>
    </>
  );
};
