import { Checkbox, CircleProgressBar, Icons, ProgressBar } from '@portal/ui';
import { useState } from 'react';

const GrowthBooks = () => {
  const [mainSteps, setMainSteps] = useState([
    {
      id: 1,
      name: 'Account Activation',
      subtitle: 'Unleash the potential of your business by activating the core modules of the platform',
      icon: <Icons.Power />,
      subSteps: [
        { name: 'Run a business scan', isComplete: true },
        { name: 'Upload your logo', isComplete: true },
        { name: 'Invite your team', isComplete: true },
        { name: 'Enable payments', isComplete: false },
        { name: 'Set up business texting', isComplete: false },
        { name: 'Connect your calendar', isComplete: false },
        { name: 'Publish your listings', isComplete: false },
        { name: 'Integrate Google and Facebook', isComplete: false },
      ],
    },
    {
      id: 2,
      name: 'Liftoff',
      subtitle: 'Maximize your business potential by launching your products and scaling your operations',
      icon: <Icons.Rocket />,
      subSteps: [
        { name: 'Publish your site', isComplete: false },
        { name: 'Add a customer', isComplete: false },
        { name: 'Book an appointment', isComplete: false },
        { name: 'Request a payment', isComplete: false },
      ],
    },
  ]);

  // Calculate progress based on number of completed sub-steps
  const numCompletedSubSteps = mainSteps.reduce(
    (total, mainStep) => total + mainStep.subSteps.filter((subStep) => subStep.isComplete).length,
    0
  );
  const totalSubStep = mainSteps.reduce((total, mainStep) => total + mainStep.subSteps.length, 0);
  const progress = (numCompletedSubSteps / (mainSteps.length * totalSubStep)) * 100;

  const calculateProgressPerMainStep = () => {
    return mainSteps.map((mainStep) => {
      const numCompletedSubSteps = mainStep.subSteps.filter((subStep) => subStep.isComplete).length;
      const progress = (numCompletedSubSteps / mainStep.subSteps.length) * 100;
      return { name: mainStep.name, progress };
    });
  };

  return (
    <div className="container">
      <div className="p-4">
        <h1 className="text-3xl font-semibold mb-2">GrowthBooks</h1>
        <h2 className="text-sm mb-4 text-secondary-500">Your step-by-step guide to growing your business</h2>
      </div>
      <ProgressBar progress={progress} />
      <div className="flex w-1/4 h-full border-b border-r p-5 items-center">
        <h3 className="text-sm font-bold flex-grow text-center text-secondary-600">Unlock your level</h3>
        <Icons.ChevronRight />
      </div>

      <div className="flex h-full sticky top-0">
        <div className="w-1/4 h-full border-r">
          {mainSteps.map((mainStep) => (
            <div className="border-b p-4" key={mainStep.name}>
              <div className="flex gap-3 my-2 items-center">
                <CircleProgressBar
                  progress={calculateProgressPerMainStep().find((step) => step.name === mainStep.name)?.progress || 0}
                >
                  {mainStep.icon}
                </CircleProgressBar>
                <h3 className="text-sm font-semibold text-secondary-600">{mainStep.name}</h3>
              </div>
              <p className="text-sm text-secondary-500">{mainStep.subtitle}</p>

              <div className="my-4">
                {mainStep.subSteps.map((subStep, index) => (
                  <div key={index} className="flex items-center gap-4 py-2">
                    <Checkbox
                      id="is-complete"
                      className="rounded-lg checked:bg-primary-600"
                      checked={subStep.isComplete}
                    />
                    <p
                      className={`${
                        subStep.isComplete ? 'line-through italic text-secondary-400' : 'text-secondary-600'
                      } text-sm`}
                    >
                      {subStep.name}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>

        {/* Add more content here */}
      </div>
    </div>
  );
};

export default GrowthBooks;
