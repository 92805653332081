import { NavigationLink } from '@portal/ui';
import { Link } from 'react-router-dom';

export const SettingsNavigation = [
  {
    name: 'General',
    link: <NavigationLink link={<Link to="/settings/general">General</Link>} />,
    pathName: '/settings/general',
  },
  {
    name: 'Team',
    link: <NavigationLink link={<Link to="/settings/team">Team</Link>} />,
    pathName: '/settings/team',
  },
  // {
  //   name: 'Calendar',
  //   link: <NavigationLink link={<Link to="/settings/calendar">Calendar</Link>} />,
  //   pathName: '/settings/calendar',
  // },
  {
    name: 'Booking',
    link: <NavigationLink link={<Link to="/settings/booking">Booking</Link>} />,
    pathName: '/settings/booking',
  },
  {
    name: 'Products',
    link: <NavigationLink link={<Link to="/settings/products">Products</Link>} />,
    pathName: '/settings/products',
  },
  {
    name: 'Tags',
    link: <NavigationLink link={<Link to="/settings/tags">Tags</Link>} />,
    pathName: '/settings/tags',
  },
  // {
  //   name: 'Payouts',
  //   link: <NavigationLink link={<Link to="/settings/payouts">Payouts</Link>} />,
  //   pathName: '/settings/payouts',
  // },
  // {
  //   name: 'Integrations',
  //   link: <NavigationLink link={<Link to="/settings/integrations">Integrations</Link>} />,
  //   pathName: '/settings/integrations',
  // },
];
