import { useState, useEffect } from 'react';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Animate } from 'react-move';
import { easeQuadOut } from 'd3-ease';

export interface SemiCircleProgressBarProps {
  data: number;
}

const SemiCircleProgressBar = ({ data }: SemiCircleProgressBarProps) => {
  const [pathValue, setPathValue] = useState(0);

  useEffect(() => {
    const interval = setTimeout(() => {
      setPathValue(data);
    }, 100);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Animate
      start={() => ({
        value: pathValue,
      })}
      update={() => ({
        value: data,
        timing: { duration: 1000, ease: easeQuadOut },
      })}
      enter={[
        {
          value: pathValue,
          timing: { duration: 1000 },
        },
      ]}
    >
      {({ value: currentValue }) => (
        <div style={{ width: 200, height: 200, margin: 'auto', marginTop: 30 }}>
          <CircularProgressbar
            value={currentValue}
            text={`${currentValue}%`}
            circleRatio={0.55}
            styles={{
              trail: {
                transform: 'rotate(-100deg)',
                transformOrigin: 'center center',
              },
              path: {
                transform: 'rotate(-100deg)',
                transformOrigin: 'center center',
                transition: 'stroke-dashoffset 0.5s ease 0s',
                stroke: '#7F56D9',
              },
              text: {
                fill: '#000',
                fontWeight: 600,
              },
            }}
            strokeWidth={8}
          />
        </div>
      )}
    </Animate>
  );
};

export default SemiCircleProgressBar;
