import { configureStore } from '@reduxjs/toolkit';
import { Keys, localStorage } from 'libs/local-storage';
import { ThunkDispatch } from 'redux-thunk';
import { tagsReducer } from './reducers/tags';
import { userReducer } from './reducers/user';
import { usersReducer } from './reducers/users';
import { signInWithTokens } from './thunks/user';

const store = configureStore({
  reducer: {
    user: userReducer,
    users: usersReducer,
    tags: tagsReducer,
  },
});

const preloadStore = () => {
  const accessToken = localStorage.get(Keys.accessToken);
  const refreshToken = localStorage.get(Keys.refreshToken);
  if (accessToken && refreshToken) {
    store.dispatch(signInWithTokens(accessToken, refreshToken));
  }
};

type RootState = ReturnType<typeof store.getState>;
type AppDispatch = ThunkDispatch<RootState, any, any>;

export { preloadStore, store };
export type { AppDispatch, RootState };
