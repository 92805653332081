import React, { useLayoutEffect, useCallback, useRef, useMemo, useEffect, useState } from 'react';
import { Button, Icons, Modal, FullScreenModal, Formik, Spinner } from '@portal/ui';
import classNames from 'classnames';
import { Form, FormikProps, FormikValues } from 'formik';
import BasicInfo from './BasicInfo';
import { useCreateSiteMutation, useUpdateSiteMutation } from 'graphql/mutation.generated';
import BusinessOverview from './BusinessOverview';
import DesignAndContent from './DesignAndContent';
import { weeklyAvailability, socialProfile, AddSiteModalProps, SiteType } from 'types/Sites';
import { weeklyAvailability as weeklyAvailabilityData } from 'constants/sites';
import { languages } from 'utils/language';
import { addSiteSchema } from 'components/validation/siteSchema';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { useFindSiteByIdLazyQuery } from 'graphql/query.generated';
import { getUrl, getLink } from 'utils/site';
import { debounce, isEqual } from 'lodash';
import { DateFormats } from '@portal/utils/dates';
import moment from 'moment';
import { MerchantSite } from 'graphql/types';

const siteTypes: SiteType[] = [
  {
    title: 'Company site',
    description: 'Build a professional website to showcase your business.',
    icon: <Icons.BuildingStore color="#7F56D9" width={24} height={23} />,
    type: 'company',
  },
  {
    title: 'Landing page',
    description: 'Create a simple one-page website to promote a location, event, or offer.',
    icon: <Icons.Target color="#7F56D9" width={26} height={27} />,
    type: 'landing',
  },
];

type Option = {
  value: string;
  label: string;
};
interface Availability extends weeklyAvailability {
  id?: string;
}

const createSocialLinks = (socialLinks: socialProfile[]) =>
  socialLinks.reduce<Record<string, string | null>>((res, socialLink: socialProfile) => {
    const key = socialLink.platform.split('.', 1) + 'Slug';
    res[key] = socialLink.profile.length > 0 ? socialLink.profile : null;
    return res;
  }, {});
const calenderLink = {
  protocol: 'http://',
  url: '',
};
const paymentLink = {
  protocol: 'http://',
  url: '',
};
type PayloadType = 'create' | 'update';
const createSitePayload = (values: FormikValues, payloadType: PayloadType = 'create') => ({
  businessName: values.name,
  businessAddress: values.address,
  businessAbout: values.about,
  businessCategory: values.industry,
  businessEmail: values.email,
  businessFoundedYear: values.year,
  areasServices: values.areas.map((areaService: Option) => areaService.value),
  businessPhone: values.phone,
  businessMission: values.statement,
  languagesSpoken: values.languages.map((language: Option) => language.value),
  calendarLink: getLink(values.calenderLink),
  paymentLink: getLink(values.paymentLink),
  hours: values.weeklyAvailability.flatMap((availability: Availability) =>
    availability.slots.map((slot) => ({
      day: availability.day,
      enabled: availability.active,
      startTime: slot.start,
      endTime: slot.end,
      ...(payloadType === 'update' ? { id: availability?.id } : {}),
    }))
  ),
  sitePromo: values.promo,
  siteTitle: values.title,
  siteNotes: values.notes,
  faqs: values.FAQs,
  ...createSocialLinks(values.socialProfiles),
});
export const subHeadingsWrapperClasses = 'py-5 border-b border-gray-200 flex gap-8';

const AddSiteModal = ({ isOpen, onClose, selectedRow }: AddSiteModalProps) => {
  const formRef = useRef<FormikProps<FormikValues> | null>(null);
  const [selectedSiteType, setSiteType] = useState<string>('');
  const [openFullModal, setOpenFullModal] = useState<boolean>(false);
  const [site, setSite] = useState<MerchantSite | null>(null);
  const [isSubmitModalOpen, setIsSubmitModalOpen] = useState<boolean>(false);
  const [createSite, { loading: createSiteLoading }] = useCreateSiteMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const [updateSite, { loading: updateSiteLoading }] = useUpdateSiteMutation({
    onError: (err) => {
      toast.error(err.message);
    },
  });

  const [fetchSite, { loading: loadingFetchSite }] = useFindSiteByIdLazyQuery({
    fetchPolicy: 'no-cache',
    onCompleted: (res) => {
      setSite(res.findSiteById);
    },
  });

  useLayoutEffect(() => {
    if (!isOpen) {
      setSiteType('');
    }
  }, [isOpen]);

  useEffect(() => {
    if (selectedRow && isOpen) setOpenFullModal(true);
  }, [selectedRow, isOpen]);

  const onContinue = useCallback(() => {
    onClose();
    setOpenFullModal(true);
  }, [onClose]);

  const closeOpenModal = () => {
    onClose();
    setOpenFullModal(() => false);
  };

  const submitSite = useCallback(() => {
    formRef?.current?.handleSubmit();
  }, [formRef]);

  const capatalizeFirstLetter = (word: string) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  };

  useEffect(() => {
    if (selectedRow?.id) {
      fetchSite({
        variables: {
          siteId: selectedRow?.id,
        },
      });
    }
  }, [selectedRow]);

  const initalValues = useMemo(() => {
    return {
      weeklyAvailability: site?.hours
        ? Object.values(
            site.hours.reduce((acc: Record<string, weeklyAvailability>, item) => {
              if (!acc[item.day as string]) {
                acc[item.day] = { ...item, slots: [], active: item.enabled };
              }
              acc[item.day].slots.push({ start: item.startTime, end: item.endTime });

              return acc;
            }, {})
          )
        : weeklyAvailabilityData,
      areas: site?.areasServices
        ? site?.areasServices.map((area) => {
            return { value: area, label: capatalizeFirstLetter(area) };
          })
        : [],
      name: site?.businessName ?? '',
      address: site?.businessAddress ?? '',
      about: site?.businessAbout ?? '',
      industry: site?.businessCategory ?? '',
      email: site?.businessEmail ?? '',
      year: site?.businessFoundedYear ?? new Date().getFullYear(),
      phone: site?.businessPhone ?? '',
      statement: site?.businessMission ?? '',
      languages: site?.languagesSpoken
        ? languages
            .filter((language) => site?.languagesSpoken.includes(language))
            .map((language) => ({ value: language, label: language }))
        : [],
      calenderLink: site?.calendarLink ? getUrl(site.calendarLink) : calenderLink,
      paymentLink: site?.paymentLink ? getUrl(site?.paymentLink) : paymentLink,
      promo: site?.sitePromo ?? '',
      title: site?.siteTitle ?? '',
      notes: site?.siteNotes ?? '',
      serviceName: '',
      services: [],
      images: [],
      question: '',
      answer: '',
      FAQs: site?.faqs
        ? site?.faqs.map((faq) => ({
            id: faq.id,
            question: faq.question,
            answer: faq.answer,
          }))
        : [],
      logo: site?.businessLogo ?? '',
      favicon: site?.siteFavicon ?? '',
      socialProfiles: [
        { platform: 'facebook.com/', profile: site?.facebookSlug ?? '' },
        { platform: 'yelp.com/', profile: site?.yelpSlug ?? '' },
        { platform: 'instagram.com/', profile: site?.instagramSlug ?? '' },
        { platform: 'linkedin.com/company/', profile: site?.linkedinSlug ?? '' },
      ],
    };
  }, [site]);

  const onSubmit = (values: FormikValues) => {
    if (selectedRow) {
      updateSite({
        variables: {
          data: {
            siteId: site?.id as string,
            status: 'PENDING',
            ...createSitePayload(values, 'update'),
          },
        },
      }).then(() => {
        closeOpenModal();
        setIsSubmitModalOpen(true);
      });
    } else {
      createSite({
        variables: {
          data: createSitePayload(values),
        },
      }).then(() => {
        closeOpenModal();
        setIsSubmitModalOpen(true);
      });
    }
  };

  const saveSiteAsDraft = (values: FormikValues) => {
    if (site) {
      updateSite({
        variables: {
          data: {
            siteId: site?.id as string,
            status: 'DRAFT',
            ...createSitePayload(values, 'update'),
          },
        },
      }).then((res) => {
        fetchSite({
          variables: {
            siteId: res?.data?.updateSite?.id as string,
          },
        });
      });
    } else {
      createSite({
        variables: {
          data: createSitePayload(values),
        },
      }).then((res) => {
        fetchSite({
          variables: {
            siteId: res.data?.createSite?.id as string,
          },
        });
      });
    }
  };
  const debounceSaveAsDraft = debounce(saveSiteAsDraft, 6000);
  const closeSubmitModal = () => {
    setIsSubmitModalOpen(false);
  };
  return (
    <>
      <Modal
        open={isSubmitModalOpen}
        title="Site requested"
        onClose={closeSubmitModal}
        size="xl"
        subTitle="Our team is working hard to create your new site. We'll send you an email as soon as it's ready."
        closeIcon={true}
        iconClass="inline-flex justify-center rounded-full w-10 h-10 text-white p-2 bg-green-100 mr-4"
        actions={[
          <Button displayType="primary" type="button" onClick={closeSubmitModal}>
            Confirm
          </Button>,
        ]}
      />
      <Modal
        title="Choose a site type"
        subTitle="Decide whether to build a comprehensive site or a targeted landing page. We'll create a new site for you and send you an email when it's ready."
        open={isOpen && !selectedRow}
        onClose={onClose}
        size="sm"
        actions={[
          <Button title="close" displayType="text" type="button" onClick={onClose}>
            Cancel
          </Button>,
          <Button
            title="continue"
            displayType="primary"
            type="button"
            onClick={onContinue}
            disabled={!selectedSiteType}
          >
            Continue
          </Button>,
        ]}
      >
        <div className="flex max-w-sm flex-1 mt-5 gap-5 w-full">
          {siteTypes.map((siteType) => (
            <div
              className={classNames('border w-1/2 rounded-xl border-gray-200 relative min-w-[100px]', {
                'border-primary-600 border-2': siteType.type === selectedSiteType,
              })}
              key={siteType.type}
              onClick={() => setSiteType(siteType.type)}
            >
              <div className="p-5">
                <input
                  name="type"
                  id="type"
                  type="checkbox"
                  value={selectedSiteType}
                  className={classNames('mt-1 border-gray-300 rounded-full absolute top-3 right-4', {
                    'focus:ring-primary-600 text-primary-600': siteType.type === selectedSiteType,
                  })}
                  onChange={() => setSiteType(siteType.type)}
                  checked={siteType.type === selectedSiteType}
                />
                <div className="hover:cursor-pointer flex items-center justify-center flex-col">
                  <div className="flex items-center justify-center rounded-full w-14 h-14 p-2 mb-5 bg-primary-100 text-primary-600">
                    {siteType.icon}
                  </div>
                  <div className="text-center">
                    <p className="text-gray-900 font-semibold">{siteType.title}</p>
                    <p className="text-sm text-gray-600">{siteType.description}</p>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </Modal>
      <FullScreenModal
        title={site?.id ? 'Edit Site' : 'New Site'}
        isOpen={openFullModal}
        closeIcon
        onClose={closeOpenModal}
        afterLeave={() => setSite(null)}
        actions={[
          ...(site?.id
            ? [`Last saved today at ${moment(site?.updatedAt).format(DateFormats.HOURS_MINS_WITH_AMPM)}`]
            : []),
          <Button
            title="submit"
            displayType="primary"
            type="submit"
            onClick={submitSite}
            loading={createSiteLoading || updateSiteLoading}
          >
            {site?.id ? 'Save' : 'Submit'}
          </Button>,
        ]}
      >
        {loadingFetchSite ? (
          <Spinner />
        ) : (
          <div className="container mx-auto md:px-32 lg:px-44 p-16">
            <Formik
              innerRef={formRef}
              initialValues={initalValues}
              validationSchema={addSiteSchema}
              enableReinitialize
              onSubmit={onSubmit}
              // validate={(values: FormikValues) => {
              //   if (!isEqual(values, initalValues)) debounceSaveAsDraft(values);
              // }}
              // validateOnChange={true}
            >
              <Form className="h-full flex flex-col">
                <BasicInfo />
                <BusinessOverview />
                <DesignAndContent />
              </Form>
            </Formik>
          </div>
        )}
      </FullScreenModal>
    </>
  );
};

export default React.memo(AddSiteModal);
