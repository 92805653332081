import { BadgeType } from '@portal/ui';

export const USER_STATUS = {
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  INVITED: 'invited',
};

export const badgeMappings: Record<string, BadgeType> = {
  ACTIVE: BadgeType.Success,
  INACTIVE: BadgeType.Secondary,
  INVITED: BadgeType.Primary,
};

export const USER_ROLES = {
  ADMIN: 'ADMIN',
  OPERATIONS: 'OPERATIONS',
  SALES_MANAGER: 'SALES_MANAGER',
  SALES_REPRESENTATIVE: 'SALES_REPRESENTATIVE',
  SUPPORT: 'SUPPORT',
};

export const USER_DROPDOWN_ROLES = {
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.OPERATIONS]: 'Operations',
  [USER_ROLES.SALES_MANAGER]: 'Sales',
  [USER_ROLES.SUPPORT]: 'Support',
};

export const USER_ROLES_LABELS = {
  [USER_ROLES.ADMIN]: 'Admin',
  [USER_ROLES.OPERATIONS]: 'Operations',
  [USER_ROLES.SALES_MANAGER]: 'Sales',
  [USER_ROLES.SALES_REPRESENTATIVE]: 'Sales',
  [USER_ROLES.SUPPORT]: 'Support',
};

export const USER_ROLES_OPTIONS = [
  {
    label: 'Admin',
    value: USER_ROLES.ADMIN,
    description:
      'Full access to all applications and settings in the account. Admins can add and manage users, configure settings, and manage billing.',
  },
  {
    label: 'Operations',
    value: USER_ROLES.OPERATIONS,
    description:
      'Access to all applications within the account, with limited access to settings. Cannot access or edit users, billing, or key account details.',
  },
  {
    label: 'Sales',
    value: 'Sales',
    description:
      'Access to applications essential for sales, such as contacts, scheduling, and payments, with options to control visibility to all or owned records.',
  },
  {
    label: 'Support',
    value: USER_ROLES.SUPPORT,
    description:
      'Access to select applications essential for assisting customers, including contacts, scheduling, payments, and sites.',
  },
];

export const USER_VISIBILITY = {
  VIEW_ALL: 'VIEW_ALL',
  VIEW_OWNED: 'VIEW_OWNED',
};

export const USER_VISIBILITY_LABELS = {
  [USER_VISIBILITY.VIEW_ALL]: 'View all',
  [USER_VISIBILITY.VIEW_OWNED]: 'View owned',
};

export const USER_VISIBILITY_OPTIONS = [
  {
    label: USER_VISIBILITY_LABELS[USER_VISIBILITY.VIEW_ALL],
    value: USER_VISIBILITY.VIEW_ALL,
    description: 'User can view all records on the account.',
  },
  {
    label: USER_VISIBILITY_LABELS[USER_VISIBILITY.VIEW_OWNED],
    value: USER_VISIBILITY.VIEW_OWNED,
    description: 'User can only view records that they own.',
  },
];
