import { Button, Drawer, Formik } from '@portal/ui';
import PasswordFields from 'components/password/passwordFields';
import { passwordSchema } from 'components/validation/profileSchema';
import { Action } from 'constants/action';
import { Form, FormikValues } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'redux/hooks';
import { resetAction } from 'redux/reducers/user';
import { changePassword } from 'redux/thunks/user';

type FormState = {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
};
const initialValues: FormState = {
  newPassword: '',
  currentPassword: '',
  confirmPassword: '',
};

type PasswordFormProps = {
  onClose: () => void;
};

const PasswordForm = ({ onClose }: PasswordFormProps) => {
  const dispatch = useDispatch();
  const { changePasswordLoading, action } = useSelector((state) => state.user);

  const handleOnSubmit = (values: FormikValues) => {
    dispatch(changePassword(values.currentPassword, values.newPassword));
  };
  useEffect(() => {
    if (action === Action.SUCCESS) {
      onClose();
      dispatch(resetAction());
    }
  }, [action]);
  return (
    <Formik initialValues={initialValues} onSubmit={handleOnSubmit} validationSchema={passwordSchema}>
      <Form className="h-full flex flex-col">
        <Drawer.Title title="Change password" onClose={onClose} />
        <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
          <PasswordFields />
        </div>
        <Drawer.Footer>
          <Button title="submit change password" displayType="primary" type="submit" loading={changePasswordLoading}>
            Submit
          </Button>
        </Drawer.Footer>
      </Form>
    </Formik>
  );
};

export default PasswordForm;
