import { Button, Formik } from '@portal/ui';
import FacebookIcon from 'assets/facebook.svg';
import GoogleIcon from 'assets/google.svg';
import FormField from 'components/formField';
import { onboardingSchema } from 'components/validation/onboardingSchema';
import { Form, FormikValues } from 'formik';
import _ from 'lodash';
import { useSelector } from 'redux/hooks';

interface SignupStepProps {
  handleNext: (values?: FormikValues) => void;
  error?: string;
}

const Credentials = ({ handleNext }: SignupStepProps) => {
  const status = useSelector((state) => state.user.session.status);
  return (
    <>
      <div className="flex-col w-1/2 m-auto text-secondary min-h-screen">
        <h1 className="text-black font-semibold text-3xl py-5 text-center">Welcome to Growthware</h1>
        <p className="whitespace-normal text-xl font-light text-center">
          Welcome to Growthware, your all-in-one solution for growing your business. To get started, set up a password
          or log in with your preferred tool.
        </p>

        <div className="flex flex-col gap-3 py-5 w-3/4 m-auto">
          <Button type="button" displayType="secondary" title="Sign up with Google">
            <div className="mx-auto flex items-center gap-2">
              <img src={GoogleIcon} alt="google" /> Sign up with Google
            </div>
          </Button>
          <Button type="button" displayType="secondary" title="Sign up with Facebook">
            <div className="mx-auto flex items-center gap-2">
              <img src={FacebookIcon} alt="google" /> Sign up with Facebook
            </div>
          </Button>
        </div>
        <div className="flex gap-3 items-center w-3/4 m-auto">
          <hr className="w-full" />
          <div className="text-sm">OR</div>
          <hr className="w-full" />
        </div>
        <div className="my-5">
          <Formik
            initialValues={{
              confirmPassword: '',
              password: '',
              rememberMe: false,
            }}
            validationSchema={onboardingSchema}
            onSubmit={(values) => {
              handleNext(values);
            }}
          >
            {({ setFieldValue, values }) => {
              return (
                <Form className="space-y-6 flex-col m-auto w-3/4">
                  <FormField id="password" label="Password" type="password" />
                  <FormField id="confirmPassword" label="Confirm Password" type="password" />
                  <div className="flex items-center justify-between">
                    <div className="flex items-center">
                      <input
                        id="rememberMe"
                        name="rememberMe"
                        type="checkbox"
                        onChange={(e) => setFieldValue('rememberMe', e.target.checked)}
                        className="h-4 w-4 rounded border-gray-300 bg-primary-background-200 text-purple-600 focus:ring-purple-500"
                      />
                      <label htmlFor="rememberMe" className="ml-2 block text-sm text-gray-900">
                        I agree to terms of service
                      </label>
                    </div>
                  </div>

                  <div className="onboarding-btn">
                    <Button
                      title="continue"
                      displayType="primary"
                      type="submit"
                      disabled={!values.rememberMe}
                      loading={status === 'loading'}
                    >
                      Continue
                    </Button>
                  </div>
                </Form>
              );
            }}
          </Formik>
          <div className="m-auto pt-5 pb-10 flex gap-1 w-1/2 text-center whitespace-nowrap text-sm">
            <p>Already have an account? </p> <p className="text-primary font-semibold">Log in</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Credentials;
