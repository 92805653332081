import * as Yup from 'yup';
import { hasValidTimeFormat } from 'utils/calendar';
import moment from 'moment';

const linkSchema = Yup.object().shape({
  url: Yup.string()
    .trim()
    .test('valid-protocol', 'URL must not be empty or contain only spaces', (value) => value?.trim() !== ''),
  protocol: Yup.string().required('Required'),
});
const faqSchema = Yup.object().shape({
  question: Yup.string().required('Required'),
  answer: Yup.string().required('Required'),
});
export const addSiteSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  logo: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  email: Yup.string().email('Invalid email').required('Required'),
  industry: Yup.string().required('Required'),
  languages: Yup.array().min(1, 'Language not selected').required('Required'),
  areas: Yup.array().min(1, 'Area not selected').required('Required'),
  about: Yup.string().required('Required'),
  statement: Yup.string().required('Required'),
  services: Yup.array().min(1, 'No Product or Service added').required('Required'),
  year: Yup.number()
    .integer('Year must be an integer')
    .min(1000, 'Year must be a 4-digit number')
    .max(new Date().getFullYear(), 'Year cannot be later than the current year')
    .required('Required'),
  title: Yup.string().required('Required'),
  notes: Yup.string().required('Required'),
  calenderLink: linkSchema,
  paymentLink: linkSchema,
  weeklyAvailability: Yup.array(
    Yup.object().shape({
      day: Yup.string(),
      active: Yup.boolean(),
      slots: Yup.array().when('active', {
        is: true,
        then: Yup.array().of(
          Yup.object()
            .shape({
              start: Yup.string().test('start', 'Invalid format', (value): boolean => hasValidTimeFormat(value)),
              end: Yup.string()
                .test('end', 'Invalid format', (value): boolean => hasValidTimeFormat(value))
                .when('start', {
                  is: (start: string): string => start,
                  then: Yup.string().test(
                    'end',
                    'End time should be greater than start time',
                    (value, context): boolean => {
                      const { start } = context.parent as { start: string };

                      if (start === '24:00' || value === '24:00') {
                        return true;
                      }

                      const startTime = moment(start, 'HH:mm');
                      const endTime = moment(value, 'HH:mm');

                      return endTime.isAfter(startTime);
                    }
                  ),
                }),
            })
            .test('duplicate slots', 'slot already exits', (value, context): boolean => {
              if (!value.start || !value.end) {
                return true;
              }
              const slots = context.parent as { start: string; end: string }[];
              const { start, end } = value as { start: string; end: string };

              const isSameSlot = slots
                .filter((slot) => slot !== value)
                .some((slot) => slot.start === start && slot.end === end);

              if (isSameSlot) {
                throw context.createError({
                  path: `${context.path}.slot`,
                });
              }
              return true;
            })
            .test('overlaping slots', 'slot overlaps with another slot', (value, context): boolean => {
              if (!value.start || !value.end) {
                return true;
              }
              const slots = context.parent as { start: string; end: string }[];
              const { start, end } = value as { start: string; end: string };

              const isOverlapping = slots
                .filter((slot) => slot !== value)
                .some((slot) => {
                  const startTime = moment(slot.start, 'HH:mm');
                  const endTime = moment(slot.end, 'HH:mm');
                  const newStartTime = moment(start, 'HH:mm');
                  const newEndTime = moment(end, 'HH:mm');

                  return (
                    (newStartTime.isSameOrAfter(startTime) && newStartTime.isBefore(endTime)) ||
                    (newEndTime.isAfter(startTime) && newEndTime.isSameOrBefore(endTime))
                  );
                });

              if (isOverlapping) {
                throw context.createError({
                  path: `${context.path}.slot`,
                });
              }
              return true;
            })
        ),
      }),
    })
  ).min(1, 'Required'),
  FAQs: Yup.array(faqSchema),
  promo: Yup.string(),
});
