import { DurationOption, AvailabilityOption } from '../types/BookingSettings';
import { BookingConfigurationAvailability } from 'graphql/types';

export const durationOptions: DurationOption[] = [
  { name: '15 minutes', value: 15 },
  { name: '30 minutes', value: 30 },
  { name: '45 minutes', value: 45 },
  { name: '60 minutes', value: 60 },
  { name: '90 minutes', value: 90 },
  { name: '120 minutes', value: 120 },
];

export const availabilityOptions: AvailabilityOption[] = [
  { name: '7 day into future', value: 7 },
  { name: '14 day into future', value: 14 },
  { name: '30 day into future', value: 30 },
  { name: '60 day into future', value: 60 },
  { name: '90 day into future', value: 90 },
];

export const colors: string[] = [
  '#16B364',
  '#06AED4',
  '#2E90FA',
  '#6172F3',
  '#7A5AF8',
  '#D444F1',
  '#EE46BC',
  '#F63D68',
  '#EF6820',
];

export const defaultAvailabilities: BookingConfigurationAvailability[] = [
  { id: '1', day: 'mon', startTime: '09:00', endTime: '18:00', enabled: true },
  { id: '2', day: 'tue', startTime: '09:00', endTime: '18:00', enabled: true },
  { id: '3', day: 'wed', startTime: '09:00', endTime: '18:00', enabled: true },
  { id: '4', day: 'thr', startTime: '09:00', endTime: '18:00', enabled: true },
  { id: '5', day: 'fri', startTime: '09:00', endTime: '18:00', enabled: true },
  { id: '6', day: 'sat', startTime: '09:00', endTime: '18:00', enabled: false },
  { id: '7', day: 'sun', startTime: '09:00', endTime: '18:00', enabled: false },
];
