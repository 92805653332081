import * as Yup from 'yup';
import { durations } from 'components/calendar/appointments/AppointmentForm';

export const newAppointmentSchema = Yup.object().shape({
  title: Yup.string().required('Required'),
  date: Yup.date().required('Required'),
  time: Yup.string()
    .required('Start time is required')
    .matches(/^([01]\d|2[0-3]):([0-5]\d)$/, 'Invalid time format'),
  duration: Yup.string().oneOf(
    durations.map((duration) => duration.value),
    'required'
  ),
});
