import moment from 'moment';

export const getFormattedDate = (date: Date | null, format: string) => {
  if (!date) {
    return '';
  }
  return moment(date).format(format);
};

export const formatDate = (date: string) => {
  return {
    shortFormatDate: new Date(date).toLocaleDateString('en-US', {
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions),
    longFormatDate: new Date(date).toLocaleDateString('en-US', {
      weekday: 'short',
      year: 'numeric',
      month: 'short',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions),
  };
};
