export const removePrecedingText = (link: string) => {
  const domainRegex = /^(?:https?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n]+)/im;
  const match = link.match(domainRegex);

  if (match && match.length > 1) {
    const domainName = match[1];
    const startIndex = link.indexOf(domainName);
    if (startIndex !== -1) {
      return link.substring(startIndex);
    }
  }
  return link;
};

export const getUrl = (val: string | undefined) => ({
  protocol: 'http://',
  url: val?.split('//')[1] || '',
});

export const getLink = ({ protocol, url }: { protocol: string; url: string }) => protocol + url;
