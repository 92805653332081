import { useMemo } from 'react';
import Select from 'react-select/creatable';
import { DropdownIndicatorProps, components, OptionProps } from 'react-select';
import { ActionMeta } from '../../types/CreateableSelect';

import Icons from '../../assets/icons';
import classNames from 'classnames';

export type Option = {
  value: string;
  label: string;
};
export type GroupOption = {
  label: string;
  options: Option[];
};
export type CreateableSelectProps = {
  options: Option[] | GroupOption[];
  customOptionNode?: React.FC<OptionProps>;
  noOptionNode?: React.FC;
  isMulti?: boolean;
  createOptionNode?: (value: string) => React.ReactNode;
  onCreateOption?: (value: string) => void;
  isLoading?: boolean;
  onChange: (value: Option | Option[]) => void;
  defaultValue?: Option | Option[];
  value?: Option | Option[];
  isValidNewOption?: () => boolean;
  placeholder?: string;
  menuIsOpen?: boolean;
  onInputChange?: (inputValue: string, actionMeta: ActionMeta) => void;
  isGroup?: boolean;
};

const DropdownIndicator = (props: DropdownIndicatorProps<Option>) => {
  return (
    <components.DropdownIndicator {...props}>
      <Icons.ChevronDown />
    </components.DropdownIndicator>
  );
};

const CreatableSelect = ({
  options,
  customOptionNode,
  createOptionNode,
  noOptionNode,
  menuIsOpen,
  onInputChange,
  isGroup,
  ...rest
}: CreateableSelectProps) => {
  const groupStyles = useMemo(
    () => (isGroup ? { groupHeading: () => 'text-steel-600 text-sm pb-1', group: () => 'px-2 py-1' } : {}),
    [isGroup]
  );
  return (
    <Select
      classNames={{
        multiValue: () => 'bg-gray-50 px-1 mx-1 flex gap-1 items-center',
        multiValueRemove: () => 'text-gray-500',
        valueContainer: () => 'text-sm p-1',
        input: () => '[&>input]:text-red !text-red',
        placeholder: () => 'text-black text-sm ',
        menuList: () => 'bg-white border m-1 rounded-md ',
        noOptionsMessage: () => 'text-black text-sm p-2',
        indicatorSeparator: () => 'hidden',
        control: () =>
          'border bg-white border-steel-300 focus:border-primary-500 hover:border-steel-300 shadow-sm !text-base sm:text-sm p-2 rounded-md',
        option: ({ isSelected }) =>
          classNames(
            'active:bg-gray-50 hover:text-black hover:bg-gray-50 !text-sm sm:text-sm  cursor-default rounded-md select-none py-2 active:text-black text-black !cursor-pointer',
            isSelected ? 'bg-gray-50' : 'bg-white',
            !isGroup ? 'px-3' : ''
          ),
        ...groupStyles,
      }}
      unstyled
      components={{
        IndicatorSeparator: () => null,
        DropdownIndicator: DropdownIndicator,
        ...(customOptionNode && { Option: customOptionNode }),
        ...(noOptionNode && { NoOptionsMessage: noOptionNode }),
        LoadingIndicator: () => <Icons.Spinner />,
      }}
      closeMenuOnSelect
      options={options}
      formatCreateLabel={createOptionNode}
      isClearable={false}
      hideSelectedOptions
      menuPlacement="auto"
      menuIsOpen={menuIsOpen}
      onInputChange={onInputChange}
      {...rest}
    />
  );
};

export default CreatableSelect;
