import { useState } from 'react';
import { Button, SemiCircleProgressBar, Formik } from '@portal/ui';
import { businessScanSchema } from 'components/validation/onboardingSchema';
import { Form } from 'formik';
import _ from 'lodash';
import FormField from 'components/formField';

interface ScanABusinessProps {
  handleNext: () => void;
}

const BusinessScan = ({ handleNext }: ScanABusinessProps) => {
  const [results, setResults] = useState<any>(null);
  return (
    <div className="flex-col w-1/2 m-auto pt-10 text-secondary min-h-screen">
      {!results ? (
        <>
          <h1 className="text-black font-semibold text-3xl py-5 text-center">Scan for your business</h1>
          <p className="whitespace-normal text-xl font-light text-center">
            Tell us a bit about your business and we&apos;ll do a quick scan to see what we can find. Based on the
            results, we&apos;ll make suggestions to help your business grow.
          </p>

          <div className="my-10">
            <Formik
              initialValues={{
                name: '',
                address: '',
                phone: '',
              }}
              validationSchema={businessScanSchema}
              onSubmit={(values) => {
                setResults({});
              }}
            >
              <Form className="space-y-6 flex-col m-auto w-3/4">
                <FormField id="name" label="Business Name" type="text" placeholder="Acme Solutions" />
                <FormField
                  id="address"
                  label="Business Address"
                  type="text"
                  placeholder="100 Main Street, Fort Worth, TX 76110 "
                />
                <FormField id="phone" label="Phone Number" type="tel" placeholder="+1 (555) 500-5000" />

                <div className="onboarding-btn">
                  <Button title="scan" displayType="primary" type="submit">
                    Scan
                  </Button>
                </div>
              </Form>
            </Formik>
          </div>
        </>
      ) : (
        <>
          <h1 className="text-black font-semibold text-3xl py-5 text-center whitespace-nowrap">The results are in</h1>
          <p className="whitespace-normal text-xl font-light text-center">
            Nice! You&apos;ve set yourself a strong foundation for growth with a solid online presence and accurate
            listings.
          </p>
          <div className="text-center ">
            <SemiCircleProgressBar data={40} />
          </div>
          <div className="onboarding-btn">
            <Button displayType="primary" type="submit" onClick={handleNext} title="continue">
              Continue
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default BusinessScan;
