import { Button, FieldFeedback, Icons, TextField } from '@portal/ui';
import classNames from 'classnames';
import { getIn, useFormikContext } from 'formik';
import { useState } from 'react';
import { ConnectedCalendar } from 'types/CalendarSettings';

export type CalendarFieldsProps = {
  name: string;
  path: string;
  hasError: boolean;
  showDivider: boolean;
  onRemove: (index: number) => void;
};

export const CalendarFields = ({ name, path, hasError, showDivider, onRemove }: CalendarFieldsProps) => {
  const [openForm, setOpenForm] = useState(hasError);
  const { errors, handleSubmit } = useFormikContext<{ calendars: ConnectedCalendar[] }>();
  const error = getIn(errors, path);

  const handleAddCalender = () => {
    if (!hasError) {
      setOpenForm(false);
      handleSubmit();
    }
  };

  return (
    <div className="flex flex-col">
      {openForm ? (
        <>
          <div className="flex gap-2.5 mb-3">
            <div className="w-1/2">
              <TextField id={`${path}.name`} label="Calendar Name" type="text" />
              <FieldFeedback errorCondition={hasError} errorMessage={error?.name} transitionClasses="mt-1" />
            </div>
            <div className="w-1/2">
              <TextField id={`${path}.link`} label="Calendar Link" type="url" />
              <FieldFeedback errorCondition={hasError} errorMessage={error?.link} transitionClasses="mt-1" />
            </div>
          </div>
          <div className="flex justify-end">
            <Button title="cancel" displayType="secondary" onClick={onRemove}>
              Cancel
            </Button>
            <Button title="add" displayType="primary" type="submit" onClick={() => handleAddCalender()}>
              Add
            </Button>
          </div>
        </>
      ) : (
        <div
          className={classNames('flex justify-between items-center', {
            'mb-5': !showDivider,
          })}
        >
          <span className="text-steel-900 font-medium text-sm hover:cursor-pointer" onClick={() => setOpenForm(true)}>
            {name}
          </span>
          <Button title="delete" displayType="text" iconOnly onClick={onRemove}>
            <Icons.Trash className="w-5 h-5 text-steel-600" />
          </Button>
        </div>
      )}
      {showDivider && <hr className="bg-steel-200 mt-5 mb-6" />}
    </div>
  );
};
