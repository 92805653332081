import React from 'react';
import { Button, Icons, Modal } from '@portal/ui';

type modalContentType = {
  title: string;
  content: string;
  actionLabel: string;
  titleIcon?: JSX.Element;
  iconClass?: string;
};

type props = {
  isOpen: boolean;
  action: () => void;
  actionType: string;
  onClose: () => void;
};

const actionModalContent: Record<string, modalContentType> = {
  edit: {
    title: 'Edit site',
    content: 'for help on editing a site.',
    actionLabel: 'Close',
  },
  credits: {
    title: 'Buy site credits',
    content: 'to purchase more site credits.',
    actionLabel: 'Close',
  },
  remove: {
    title: 'Remove site',
    content: 'for help on removing a site.',
    actionLabel: 'Close',
  },
  customizeDomain: {
    title: 'Customize domain',
    content: 'for help on setting a custom domain.',
    actionLabel: 'Close',
  },
  view: {
    title: 'Site Requested',
    content: "Our team is working hard to create your new site. We'll send you an email as soon as it's ready.",
    actionLabel: 'Confirm',
    titleIcon: <Icons.Featured color="#039855" />,
    iconClass: 'bg-green-100 rounded-3xl',
  },
};

const ActionModal = ({ isOpen, action, actionType, onClose }: props) => {
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      closeIcon={true}
      title={actionModalContent[actionType]?.title}
      icon={actionModalContent[actionType]?.titleIcon}
      iconClass={actionModalContent[actionType]?.iconClass}
      actions={[
        <Button title="sites" displayType="primary" type="button" onClick={action}>
          {actionModalContent[actionType]?.actionLabel}
        </Button>,
      ]}
    >
      <div className="mt-1">
        <p className="text-sm text-gray-600">
          {actionType !== 'view' && (
            <>
              Send us an email at
              <a href="mailto:support@growthware.com" className="mx-1 underline">
                support@growthware.com
              </a>
            </>
          )}
          {actionModalContent[actionType]?.content}
        </p>
      </div>
    </Modal>
  );
};

export default React.memo(ActionModal);
