import { Checkbox, checkFieldErrors, Label } from '@portal/ui';
import { useFormikContext } from 'formik';
import { AddQuestionProps } from 'types/BookingSettings';
import { Icons } from '@portal/ui';
import FormField from 'components/formField';

export const AddQuestion = ({ question, index, remove }: AddQuestionProps) => {
  const { setFieldValue, touched, errors } = useFormikContext();

  const handleOnRemove = () => {
    remove(index);
  };

  const handleOnCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(`questions.${index}.required`, event.target.checked);
  };

  return (
    <div className="flex flex-col mb-4">
      <Label htmlFor={`questions.${index}.text`} label={`Question ${index + 1}`} />
      <div className="flex items-center mb-1.5">
        <div className="w-10/12">
          <FormField
            id={`questions.${index}.text`}
            forceHasError={checkFieldErrors(touched, errors, `questions.${index}`, 'text')}
          />
        </div>
        <button type="button" onClick={handleOnRemove} className="px-2 py-2.5 border-0 shadow-0 mr-1">
          <Icons.Trash color="#4A5578" className="w-5 h-5 mr-1" />
        </button>
      </div>
      <Checkbox
        {...{
          checked: question.required,
          onChange: handleOnCheck,
          label: 'Required',
          className: 'text-primary rounded cursor-pointer',
          name: `questions.${index}.required`,
          id: `questions.${index}.required`,
        }}
      />
    </div>
  );
};
