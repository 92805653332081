import { Button, Drawer, Icons, Label, RadioButton } from '@portal/ui';
import classNames from 'classnames';
import { USER_ROLES_OPTIONS, USER_VISIBILITY, USER_VISIBILITY_OPTIONS } from 'constants/users';
import { Form, Formik } from 'formik';
import { FC, useCallback } from 'react';
import { UserEditFormProps } from 'types/User';
import { defaultInitialValues } from './UserForm';

const EditPermissionsForm: FC<UserEditFormProps> = ({ initialValues, onSubmit, loading }) => {
  const handleLearnMore = useCallback(() => {
    window.open('https://www.google.com', '_blank');
  }, []);

  return (
    <Formik initialValues={{ ...defaultInitialValues, ...initialValues }} onSubmit={onSubmit}>
      {({ values, setFieldValue }) => {
        return (
          <Form className="h-full flex flex-col">
            <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
              <div className="flex flex-col">
                <div className="flex justify-between mb-4">
                  <Label label="Role" />
                  <button onClick={handleLearnMore} className="flex items-center text-steel-600 text-sm font-semibold">
                    <Icons.HelpCircle className="w-4=5 h-5 mr-2" /> Learn more
                  </button>
                </div>
                {USER_ROLES_OPTIONS.map((role) => (
                  <div
                    className={classNames('border rounded-xl border-steel-200 mb-2.5', {
                      '!border-primary-600 border-2 bg-primary-50': values.role === role.value,
                    })}
                    key={role.value}
                    onClick={() => {
                      setFieldValue('role', role.value);
                      if (role.value !== 'sales') {
                        setFieldValue('visibility', USER_VISIBILITY.VIEW_ALL);
                      }
                    }}
                  >
                    <div className="p-4 flex items-start">
                      <input
                        name="role"
                        id="role"
                        type="radio"
                        checked={values.role === role.value}
                        onChange={() => setFieldValue('role', role.value)}
                        className={classNames('mt-1 border-steel-300', {
                          'focus:ring-primary-600 text-primary-600': values.role === role.value,
                        })}
                      />
                      <label className="hover:cursor-pointer">
                        <div className="flex items-center ml-2">
                          <div className="text-sm">
                            <span
                              className={classNames('text-steel-700 font-medium', {
                                '!text-primary-800': values.role === role.value,
                              })}
                            >
                              {role.label}
                            </span>
                            <p
                              className={classNames('text-steel-600 font-normal', {
                                '!text-primary-700': values.role === role.value,
                              })}
                            >
                              {role.description}
                            </p>
                          </div>
                        </div>
                      </label>
                    </div>
                  </div>
                ))}
                <span className="mt-1.5">
                  <Label label="Visibility" htmlFor="visibility" />
                </span>
                {USER_VISIBILITY_OPTIONS.map((visibility) => (
                  <div
                    className={classNames('mb-4 flex items-start', {
                      'opacity-50': values.role !== 'Sales',
                    })}
                    key={visibility.value}
                    onClick={() => {
                      if (values.role === 'Sales') setFieldValue('visibility', visibility.value);
                    }}
                  >
                    <RadioButton
                      handleChange={(value) => setFieldValue('visibility', value.target.value)}
                      name="visibility"
                      label={visibility.label}
                      info={visibility.description}
                      id={visibility.value}
                      value={visibility.value}
                      disabled={values.role !== 'Sales'}
                      checked={values.visibility === visibility.value}
                    />
                  </div>
                ))}
              </div>
            </div>
            <Drawer.Footer>
              <Button title="save permissions" displayType="primary" type="submit" loading={loading}>
                Save
              </Button>
            </Drawer.Footer>
          </Form>
        );
      }}
    </Formik>
  );
};

export default EditPermissionsForm;
