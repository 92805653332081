import { TextField, TextFieldProps } from '@portal/ui';

interface CurrencyInputProps extends TextFieldProps {
  currencyType?: string;
}

const CURRENCY_ICONS: Record<string, string> = {
  usd: '$',
};

const CurrencyInput = ({ currencyType = 'usd', ...props }: CurrencyInputProps) => (
  <div className="flex items-center relative ">
    <div className="absolute left-3 top-[6px] text z-10 h-fit">{CURRENCY_ICONS[currencyType]}</div>
    <div className="[&>div:first-child]:w-full w-full">
      <TextField
        {...props}
        type="number"
        step="0.01"
        min="0"
        customStyling={{
          field: 'form-field !pl-6',
        }}
      />
    </div>
  </div>
);

export default CurrencyInput;
