import { useMergeState } from '@portal/react-hooks/use-merge-state';
import { Badge, BadgeType, ConfirmationModal, DataTable, DeleteModal, EmptyState, RowActionMenu } from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { currencyFormatter } from '@portal/utils/misc';
import { capitalizeText } from '@portal/utils/string';
import { PaginationState, SortingState } from '@tanstack/react-table';
import InvoiceFormHOC from 'components/invoices/invoice_form/InvoiceFormHOC';
import { InvoiceActions } from 'constants/invoice';
import { useDeleteInvoiceMutation, useUpdateInvoiceMutation } from 'graphql/mutation.generated';
import {
  FetchAllInvoicesDocument,
  FetchAllInvoicesQuery,
  useFetchAllInvoicesLazyQuery,
  useFindInvoiceByIdLazyQuery,
} from 'graphql/query.generated';
import { FindAllUsersFilters } from 'graphql/types';
import { reduce } from 'lodash';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

const badgeMappings: Record<string, BadgeType> = {
  paid: BadgeType.Success,
  open: BadgeType.Primary,
  refunded: BadgeType.Secondary,
  draft: BadgeType.Secondary,
  overdue: BadgeType.Warning,
  cancelled: BadgeType.Cancelled,
};

export type InvoiceRow = FetchAllInvoicesQuery['findAllInvoicesByContactId']['invoices'][number];

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllUsersFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

const Invoices = () => {
  const { id } = useParams();
  const [selectedRow, setSelectedRow] = useState<InvoiceRow | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [showInvoiceModal, setShowInvoiceModal] = useState<boolean>(false);

  const [fetchInvoices, { data: invoices, loading: fetchingInvoicesLoading }] = useFetchAllInvoicesLazyQuery({
    fetchPolicy: 'network-only',
  });
  const [updateInvoice, { loading: loadingUpdateInvoice }] = useUpdateInvoiceMutation({
    refetchQueries: [FetchAllInvoicesDocument],
  });
  const [deleteInvoice, { loading: deletingInvoiceLoading }] = useDeleteInvoiceMutation({
    onCompleted: () => {
      toast.success('Invoice deleted');
      onClose();
    },
    onError: (err) => {
      toast.error(err.message ?? 'Error deleting invoice');
    },
    refetchQueries: [FetchAllInvoicesDocument],
  });
  const [fetchInvoice, { data: invoice, loading: fetchInvoiceLoading }] = useFindInvoiceByIdLazyQuery({
    fetchPolicy: 'network-only',
    onError: (err) => {
      toast.error(err.message);
      console.log(err);
    },
  });
  useEffect(() => {
    if (!selectedRow?.id) return;
    fetchInvoice({
      variables: {
        invoiceId: selectedRow?.id as string,
      },
    });
  }, [selectedRow?.id]);

  const copyPaymentLink = (row: InvoiceRow | undefined) => {
    if (row) {
      navigator.clipboard.writeText(row.id);
      toast.success('Link copied to clipboard');
    }
  };

  useEffect(() => {
    fetchInvoices({
      variables: {
        merchantContactId: id as string,
        pagination: {
          offset: tableState.pageIndex * tableState.pageSize,
          limit: tableState.pageSize,
        },
        orderBy: {
          createdAt: ternary(tableState.sorting.find((col) => col.id === 'createdAt')?.desc, 'desc', 'asc', undefined),
        },
      },
    });
  }, [id, tableState.pageIndex, tableState.pageSize, tableState.sorting]);

  const onClose = () => {
    setShowDeleteModal(false);
    setSelectedRow(null);
    setShowInvoiceModal(false);
  };

  const onCancelClose = () => {
    setShowCancelModal(false);
    setShowInvoiceModal(false);
    setSelectedRow(null);
  };

  const handleDeleteConfirmation = () => {
    deleteInvoice({
      variables: {
        invoiceId: selectedRow?.id as string,
      },
    });
  };
  const handleCancelConfirmation = () => {
    updateInvoice({
      variables: {
        data: {
          invoiceId: selectedRow?.id as string,
          status: 'CANCELLED',
        },
      },
    })
      .then(() => {
        toast.success('Invoice cancelled');
        onCancelClose();
      })
      .catch((err) => {
        toast.error(err?.message ?? 'Invoice cancellation failed');
      });
  };

  const rowActions: RowActionMenuProps<InvoiceRow>['rowActions'] = [
    {
      name: 'Edit',
      action: (row) => {
        setSelectedRow(row ?? null);
        setShowInvoiceModal(true);
      },
    },
    {
      name: 'Cancel',
      action: (row) => {
        setSelectedRow(row ?? null);
        setShowCancelModal(true);
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedRow(row ?? null);
        setShowDeleteModal(true);
      },
    },
    {
      name: 'Copy payment link',
      action: (row) => {
        copyPaymentLink(row);
      },
    },
  ];

  const columns: ColumnType<InvoiceRow>[] = useMemo(
    () => [
      {
        name: 'Amount',
        id: 'amount',
        cell: (info) => (
          <div className="font-medium text-sm text-steel-600">
            {currencyFormatter(
              reduce(info.row.original.lineItems, (amount, item) => amount + item.price * item.quantity, 0)
            )}
          </div>
        ),
      },
      {
        id: 'status',
        name: 'Status',
        cell: (info) => (
          <Badge
            text={capitalizeText(info.row.original.status)}
            type={badgeMappings[info.row.original.status.toLowerCase()]}
          />
        ),
      },
      {
        name: 'Invoice ID',
        id: 'id',
        cell: (info) => {
          return <div className="font-normal text-sm text-steel-600">{info.row.original.slug}</div>;
        },
      },
      {
        id: 'createdAt',
        name: 'Created',
        cell: (info) => moment(info.row.original.createdAt).format('MMM D, YYYY'),
      },
      {
        id: 'action',
        name: '',
        cell: ({ row: { original } }) => {
          return (
            <RowActionMenu
              row={original}
              rowActions={rowActions.filter((rowAction) => InvoiceActions[original.status].includes(rowAction.name))}
            />
          );
        },
      },
    ],
    []
  );

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = invoices?.findAllInvoicesByContactId?.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [invoices?.findAllInvoicesByContactId?.count]);

  return (
    <>
      <DeleteModal
        onClose={onClose}
        title={'Delete invoice'}
        open={showDeleteModal}
        loading={deletingInvoiceLoading}
        onConfirm={handleDeleteConfirmation}
        content={'Are you sure you want to delete this invoices? This action cannot be undone.'}
      />

      <ConfirmationModal
        open={showCancelModal}
        onClose={onCancelClose}
        isLoading={loadingUpdateInvoice}
        onConfirm={handleCancelConfirmation}
        title={'Cancel invoice'}
        content={'Are you sure you want to cancel this invoice?'}
      />
      <div className="invoice-table">
        <DataTable
          updateTableState={updateTableState}
          tableState={tableState}
          columns={columns}
          loading={fetchingInvoicesLoading}
          data={invoices?.findAllInvoicesByContactId?.invoices ?? []}
          pagination
          noDataElement={
            <EmptyState
              title={'No invoice yet'}
              subTitle={'Get started by adding a new invoice'}
              btnText={'New invoice'}
              onClick={() => setShowInvoiceModal(true)}
            />
          }
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
        />
      </div>
      <InvoiceFormHOC
        onClose={onClose}
        showInvoiceModal={showInvoiceModal}
        invoice={invoice?.findInvoiceById}
        fetchInvoiceLoading={fetchInvoiceLoading}
      />
    </>
  );
};

export default Invoices;
