import { geocodeByPlaceId } from 'react-google-places-autocomplete';

interface AddressComponent {
  long_name: string;
  short_name: string;
  types: string[];
}

export interface AddressInfo {
  postalCode: string | null;
  country: string | null;
  city: string | null;
  streetNumber: string | null;
  state: string | null;
  error: string | null;
  formattedAddress?: string | null;
}

async function getAddressInfoFromPlaceId(placeId: string): Promise<AddressInfo> {
  try {
    const results = await geocodeByPlaceId(placeId);
    if (results.length > 0) {
      const addressComponents: AddressComponent[] = results[0].address_components;

      let postalCode: string | null = null;
      let country: string | null = null;
      let state: string | null = null;
      let streetNumber: string | null = null;
      let city: string | null = '';
      let route: string | null = '';
      let county: string | null = '';
      addressComponents.forEach((component) => {
        if (component.types.includes('postal_code')) {
          postalCode = component.long_name;
        } else if (component.types.includes('country')) {
          country = component.short_name;
        } else if (component.types.includes('administrative_area_level_1')) {
          state = component.short_name;
        } else if (component.types.includes('street_number')) {
          streetNumber = component.long_name;
        } else if (component.types.includes('locality')) {
          city = component.long_name;
        } else if (component.types.includes('route')) {
          route = component.long_name;
        } else if (component.types.includes('administrative_area_level_2')) {
          county = component.long_name;
        }
      });
      return {
        postalCode,
        country,
        city,
        streetNumber,
        state,
        formattedAddress: results[0].formatted_address,
        error: null,
      };
    } else {
      return {
        postalCode: null,
        country: null,
        city: null,
        streetNumber: null,
        state: null,
        error: 'No results found for the provided placeId.',
        formattedAddress: results[0].formatted_address,
      };
    }
  } catch (error) {
    return {
      postalCode: null,
      country: null,
      city: null,
      streetNumber: null,
      state: null,
      error: 'Error retrieving address components.',
    };
  }
}

export { getAddressInfoFromPlaceId };
