import { capitalizeText } from '@portal/utils/string';
import ListBox from '@portal/ui/components/base/ListBox';
import Badge from '@portal/ui/components/display/Badge';
import { colorMappings, contactStatusBadgeMappings } from '../../utils/status';
import { Icons } from '@portal/ui';

interface BadgeDropdownProps {
  options: {
    name: string;
    value: string;
  }[];

  onChange: (value: string) => void;
  selected: string;
  disabled?: boolean;
  loading?: boolean;
}

const BadgeDropdown = ({ options, selected, onChange, disabled, loading }: BadgeDropdownProps) => {
  const generatedClass = colorMappings[contactStatusBadgeMappings[selected]];
  return (
    <div className="flex gap-2">
      <ListBox
        options={options}
        disabled={disabled}
        customStyle={`${generatedClass}  flex items-center border-0 rounded-lg w-fit p-0`}
        selectedNode={
          <div className="mr-4 mb-0.5 cursor-pointer">
            <Badge text={capitalizeText(selected)} type={contactStatusBadgeMappings[selected]} />
          </div>
        }
        onChange={onChange}
      />
      {loading && (
        <div className="text-center">
          <Icons.Spinner />
        </div>
      )}
    </div>
  );
};

export default BadgeDropdown;
