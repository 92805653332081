export const BusinessOptions = [{ name: 'Tech', value: 'technology' }];

export const EstimatedRenevueOptions = [
  { name: 'Less than $50,000', value: 'Less than $50,000' },
  { name: '$50,000-$100,000', value: '$50,000-$100,000' },
  { name: '$100,000-$250,000', value: '$100,000-$250,000' },
  { name: '$250,000-$500,000', value: '$250,000-$500,000' },
  { name: '$500,000-$1,000,000', value: '$500,000-$1,000,000' },
  { name: '$1,000,000-$5,000,000', value: '$1,000,000-$5,000,000' },
  { name: '$5,000,000-$10,000,000', value: '$5,000,000-$10,000,000' },
  { name: '$10,000,000-$25,000,000', value: '$10,000,000-$25,000,000' },
  { name: '$25,000,000+', value: '$25,000,000+' },
];

export const NumberOfEmployeesOptions = [
  { name: '1-5', value: '1-5' },
  { name: '6-10', value: '6-10' },
  { name: '11-25', value: '11-25' },
  { name: '26-50', value: '26-50' },
  { name: '51-100', value: '51-100' },
  { name: '101-250', value: '101-250' },
  { name: '251-500', value: '251-500' },
  { name: '500+', value: '500+' },
];
