import { useState, useRef } from 'react';
import cx from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import Button from '../../base/Button';

import 'react-quill/dist/quill.snow.css';
export interface EditorContentChanged {
  html: string;
  markdown: string;
}

type CustomClass = {
  btnPosition: string;
};

export type InputBoxProps = {
  id?: string;
  value?: string;
  placeholder?: string;
  showButton?: boolean;
  displayType: 'sms' | 'email';
  customClass?: CustomClass;
  onChange?: (changes: EditorContentChanged) => void;
};

const modules = {
  toolbar: [['bold', 'italic', 'underline', 'link']],
};

const InputBox = (props: InputBoxProps) => {
  const [value, setValue] = useState<string>('');
  const reactQuillRef = useRef<typeof ReactQuill>(null);

  const handleChange = (content: string) => {
    setValue(content);
    if (props.onChange) {
      props.onChange({
        html: content,
        markdown: content,
      });
    }
  };

  return (
    <>
      <div className="cursor-pointer relative inline-block w-full">
        {props.showButton && (
          <div className={cx(props.customClass?.btnPosition ?? 'absolute right-3 bottom-3 z-10')}>
            <div className="flex items-center gap-2">
              <Button displayType="primary">Send</Button>
            </div>
          </div>
        )}

        <ReactQuill
          className={cx(
            '.ql-toolbar.ql-snow .quill..ql-editor.ql-blank..ql-toolbar.ql-snow ql-stroke ql-formats hidden lg:block'
          )}
          theme="snow"
          placeholder={props.placeholder}
          ref={reactQuillRef}
          modules={modules}
          value={value}
        />
      </div>
    </>
  );
};

export default InputBox;
