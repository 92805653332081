import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type UsersState = {
  data: {
    id: string;
    name: string;
    email: string;
    avatar?: string | null;
  }[];
};

const initialState: UsersState = {
  data: [],
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setData: (state, action: PayloadAction<UsersState['data']>) => {
      state.data = action.payload;
    },
  },
});

export const usersReducer = slice.reducer;
export const { setData } = slice.actions;
export type { UsersState };
