import { useIsMobileView } from '@portal/react-hooks/use-is-mobile-view';
import { Drawer } from '@portal/ui';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { FormDataState, getFormData } from '@portal/utils/forms';
import { getFirstAndLastName } from '@portal/utils/string';
import { ContactDetails, TabsView } from 'components/contactDetails';
import { ContactForm } from 'components/contacts/ContactForm';
import { useUpdateContactAvatarMutation, useUpdateContactMutation } from 'graphql/mutation.generated';
import { useFindAllPaymentMethodsByContactIdLazyQuery, useFindContactByIdLazyQuery } from 'graphql/query.generated';
import { MerchantContactStatus } from 'graphql/types';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { RootState } from 'redux/store';
import ContactInfo from 'types/ContactInfo';

export const ContactDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const userId = useSelector<RootState>((state) => state.user.attributes?.id);
  const isMobileView = useIsMobileView();

  const [findAllPaymentsMethodByContactId, { data: paymentMethods, loading: fetchPaymentsMethodLoading }] =
    useFindAllPaymentMethodsByContactIdLazyQuery({
      fetchPolicy: 'network-only',
    });

  const [findContactById, { data, loading, refetch }] = useFindContactByIdLazyQuery({
    onCompleted: (res) => {
      setPropertyName('');
      setUploadingAvatar(false);

      if (res.findContactById.id) {
        findAllPaymentsMethodByContactId({ variables: { merchantContactId: res.findContactById.id } });
      }
    },

    onError: () => {
      return navigate('/unauthorized', { replace: true, state: { from: '/contacts' } });
    },
    fetchPolicy: 'no-cache',
  });

  const [updateContactAvatar] = useUpdateContactAvatarMutation();
  const [propertyName, setPropertyName] = useState<string>('');
  const [drawerOpened, setDrawerOpened] = useState<boolean>(false);
  const [uploadingAvatar, setUploadingAvatar] = useState<boolean>(false);
  const [updateContact, { loading: updateContactLoading }] = useUpdateContactMutation({
    onCompleted: () => {
      toast.success('Contact updated');
      setDrawerOpened(false);
      refetch();
    },
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
  });

  useEffect(() => {
    if (id) {
      findContactById({
        variables: {
          merchantContactId: id,
        },
      });
    }
  }, [id]);

  const updateAvatar = (file: File | null) => {
    setPropertyName('avatar');
    setUploadingAvatar(true);

    if (file)
      updateContactAvatar({
        variables: {
          merchantContactId: id as string,
          imgMimeType: file.type,
        },
      }).then((result) => {
        const uploadDetails = result?.data?.requestContactAvatarUpdate;
        const formData = getFormData(uploadDetails as FormDataState, file);

        fetch(uploadDetails?.formPostUrl || '', {
          method: 'POST',
          body: formData,
        }).then(async (res) => {
          updateContact({
            variables: {
              data: {
                merchantContactId: id as string,
                acknowledgeContactAvatarUpdate: true,
              },
            },
          });
        });
      });
  };

  const updateStatus = (status: string, contact: ContactInfo) => {
    setPropertyName('status');
    updateContact({
      variables: {
        data: {
          merchantContactId: id as string,
          status: status as MerchantContactStatus,
        },
      },
    });
  };

  const [firstName, lastName] = useMemo(() => {
    return getFirstAndLastName(data?.findContactById?.name || '');
  }, [data]);

  return (
    <div className="flex min-h-screen w-full">
      <ContactDetails
        updateStatus={updateStatus}
        updateContactLoading={updateContactLoading || uploadingAvatar}
        contact={data?.findContactById}
        paymentMethods={paymentMethods?.findAllPaymentMethodsByContactId?.paymentMethods}
        fetchPaymentsMethodLoading={fetchPaymentsMethodLoading}
        loading={loading}
        onAvatarChange={updateAvatar}
        propertyName={propertyName}
        onEdit={() => setDrawerOpened(true)}
      />
      {!isMobileView && <TabsView />}
      <Drawer open={drawerOpened} onClose={() => setDrawerOpened(false)}>
        <ContactForm
          initialValues={
            data
              ? {
                  ...data.findContactById,
                  avatar: null,
                  firstName: firstName,
                  lastName: lastName,
                  ...data.findContactById.address,
                  ownerId: userId as string,
                }
              : undefined
          }
          mode="edit"
        />
      </Drawer>
    </div>
  );
};
