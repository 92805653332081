import { hasValidTimeFormat } from 'utils/calendar';
import states from 'states-us';

import * as Yup from 'yup';
export const onboardingSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be at least 8 characters long')
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]+$/,
      'Password must contain at least one uppercase letter, one lowercase letter, one number, and one symbol'
    )
    .required('New password is required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Passwords must match')
    .required('Required'),
  rememberMe: Yup.boolean().required('Required').oneOf([true]),
});

export const businessScanSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  address: Yup.string().required('Required'),
  phone: Yup.string().required('Required'),
});

export const businessDetailsSchema = Yup.object().shape({
  name: Yup.string().required('Required'),
  category: Yup.string().required('Required'),
  businessPhone: Yup.string().required('Required'),
  mobilePhone: Yup.string().required('Required'),
  website: Yup.string().url('Invalid URL'),
  address1: Yup.string().required('Required'),
  address2: Yup.string().required('Required'),
  state: Yup.string().oneOf(states.map((state) => state.abbreviation)),
  zip: Yup.number()
    .typeError('Must be a number')
    .test('len', 'Must be five digits', (val) => (val ? val?.toString().length === 5 : false))
    .positive('Must be a positive number')
    .integer('')
    .required('Required'),
  yearFounded: Yup.number()
    .typeError('Must be a number')
    .test('len', 'Must be four digits', (val) => (val ? val?.toString().length === 4 : false))
    .positive('Must be a positive number')
    .integer('')
    .max(new Date().getFullYear(), 'Must be less than current year')
    .required('Required'),
  numberOfEmployees: Yup.string().required('Required'),
  updateListing: Yup.boolean(),
  enableNotifications: Yup.boolean(),
  estimatedRenevue: Yup.string().required('Required'),
  availabilities: Yup.array(
    Yup.object().shape({
      start: Yup.string().test('start', 'Start time in Valid format', (value): boolean => hasValidTimeFormat(value)),
      end: Yup.string()
        .test('end', 'End time in Valid format', (value): boolean => hasValidTimeFormat(value))
        .when('start', {
          is: (start: string): string => start,
          then: Yup.string().test('end', 'End time should be greater than start time', (value, context): boolean => {
            const { start } = context.parent as { start: string };
            const [startHour, startMinute] = start.split(':');
            const [endHour, endMinute] = (value ?? '').split(':');
            return parseInt(endHour) > parseInt(startHour) || parseInt(endMinute) > parseInt(startMinute);
          }),
        }),
    })
  ),
});
