import { Icons, NavigationLink, SideNavbar } from '@portal/ui';
import { DropdownItem } from '@portal/ui/components/base/Dropdown';
import logo from 'assets/growthware_logo.png';
import { matchPath, useLocation, useNavigate, Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/hooks';
import { SettingsNavigation, ProfileNavigation } from 'components/layout/navigations';
import { signOut } from 'redux/thunks/user';
import SubHeader from './SubHeader';

const Header = () => {
  const { pathname } = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const matchedSettingsPage = matchPath('/settings/:tab', pathname);
  const matchedProfilePage = matchPath('/profile/:tab', pathname);
  const attributes = useSelector((state) => state.user.attributes);
  const user = {
    name: attributes?.name ?? '',
    email: attributes?.email ?? '',
    imageUrl: attributes?.avatar ?? '',
  };

  const navigation = [
    // {
    //   name: 'Home',
    //   link: <NavigationLink link="Homebase" icon={<Icons.Homebase />} />,
    //   pathName: '/',
    // },
    {
      name: 'Contacts',
      link: <NavigationLink link="Contacts" icon={<Icons.Contact />} />,
      pathName: '/contacts',
      forceActive: pathname === '/',
    },
    // {
    //   name: 'Inbox',
    //   link: <NavigationLink link="Inbox" icon={<Icons.Inbox />} />,
    //   pathName: '/inbox',
    // },
    {
      name: 'Calendar',
      link: <NavigationLink link="Calendar" icon={<Icons.Calendar />} />,
      pathName: '/calendar',
    },
  ];

  const subNavigation = [
    {
      name: 'Payments',
      link: <NavigationLink link="Payments" icon={<Icons.CreditCard />} />,
      pathName: '/payments',
    },
    {
      name: 'Listings',
      link: <NavigationLink link="Listings" icon={<Icons.Listing />} />,
      pathName: '/listings',
    },

    {
      name: 'Sites',
      link: <NavigationLink link="Sites" icon={<Icons.Desktop />} />,
      pathName: '/sites',
    },
    // {
    //   name: 'Growthbooks',
    //   link: <NavigationLink link={<Link to="/growthbooks">Growthbooks</Link>} icon={<Icons.Growthwearbooks />} />,
    //   pathName: '/growthbooks',
    // },
    // {
    //   name: 'Growthbooks',
    //   link: <NavigationLink link="growthbooks" icon={<Icons.Growthwearbooks />} />,
    //   pathName: '/growthbooks',
    // },
  ];

  const signOutClicked = () => {
    dispatch(signOut());
    navigate('/login');
  };

  const userDropdownItems: DropdownItem[] = [
    {
      name: 'Account settings',
      action: () => navigate('/settings/general'),
    },
    {
      name: 'Profile',
      action: () => navigate('/profile/details'),
    },
    {
      name: 'Sign out',
      action: () => signOutClicked(),
    },
  ];
  return (
    <>
      <SideNavbar
        logo={logo}
        navigation={navigation}
        subNavigation={subNavigation}
        subNavigationTitle={'APPS'}
        userDropdownItems={userDropdownItems}
        user={user}
      />

      {matchedSettingsPage && <SubHeader navigation={SettingsNavigation} />}
      {matchedProfilePage && <SubHeader navigation={ProfileNavigation} />}
    </>
  );
};

export { Header };
