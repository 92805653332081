import { Icons } from '@portal/ui';

export enum CardType {
  Affirm = 'Affirm',
  Alipay = 'Alipay',
  Amazon = 'Amazon',
  AMEX = 'AMEX',
  ApplePay = 'ApplePay',
  Bitpay = 'Bitpay',
  Citadele = 'Citadele',
  DinersClub = 'DinersClub',
  Discover = 'Discover',
  Elo = 'Elo',
  Etherium = 'Etherium',
  Forbrugsforeningen = 'Forbrugsforeningen',
  GooglePay = 'GooglePay',
  Interac = 'Interac',
  JCB = 'JCB',
  Klarna = 'Klarna',
  Lightcoin = 'Lightcoin',
  Maestro = 'Maestro',
  Mastercard = 'Mastercard',
  Payoneer = 'Payoneer',
  PayPal = 'PayPal',
  Qiwiw = 'Qiwi',
  Paysafe = 'Paysafe',
  SEPA = 'SEPA',
  ShopPay = 'ShopPay',
  Skrill = 'Skrill',
  Sofort = 'Sofort',
  Stripe = 'Stripe',
  UnionPay = 'UnionPay',
  Verifone = 'Verifone',
  Visa = 'Visa',
  Webmoney = 'Webmoney',
  Default = 'Default',
}

export const CardTypeIcons: Record<CardType, JSX.Element> = {
  Affirm: <Icons.CardTypes.Affirm />,
  Alipay: <Icons.CardTypes.Alipay />,
  Amazon: <Icons.CardTypes.Amazon />,
  AMEX: <Icons.CardTypes.AMEX />,
  ApplePay: <Icons.CardTypes.ApplePay />,
  Bitpay: <Icons.CardTypes.Bitpay />,
  Citadele: <Icons.CardTypes.Citadele />,
  DinersClub: <Icons.CardTypes.DinersClub />,
  Discover: <Icons.CardTypes.Discover />,
  Elo: <Icons.CardTypes.Elo />,
  Etherium: <Icons.CardTypes.Etherium />,
  Forbrugsforeningen: <Icons.CardTypes.Forbrugsforeningen />,
  GooglePay: <Icons.CardTypes.GooglePay />,
  Interac: <Icons.CardTypes.Interac />,
  JCB: <Icons.CardTypes.JCB />,
  Klarna: <Icons.CardTypes.Klarna />,
  Lightcoin: <Icons.CardTypes.Lightcoin />,
  Maestro: <Icons.CardTypes.Maestro />,
  Mastercard: <Icons.CardTypes.Mastercard />,
  Payoneer: <Icons.CardTypes.Payoneer />,
  PayPal: <Icons.CardTypes.PayPal />,
  Paysafe: <Icons.CardTypes.Paysafe />,
  Qiwi: <Icons.CardTypes.Qiwi />,
  SEPA: <Icons.CardTypes.SEPA />,
  ShopPay: <Icons.CardTypes.ShopPay />,
  Skrill: <Icons.CardTypes.Skrill />,
  Sofort: <Icons.CardTypes.Sofort />,
  Stripe: <Icons.CardTypes.Stripe />,
  UnionPay: <Icons.CardTypes.UnionPay />,
  Verifone: <Icons.CardTypes.Verifone />,
  Visa: <Icons.CardTypes.Visa />,
  Webmoney: <Icons.CardTypes.Webmoney />,
  Default: <Icons.CardTypes.Default />,
};
