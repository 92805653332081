import BadgeDropdown from 'components/badgeDropdown';
import ContactInfo from 'types/ContactInfo';
import { useState } from 'react';
import { statusItems } from '@portal/ui/components/data/statusItems';
import PaymentMethodsForm from 'components/payment/paymentMethodsForm';
import ContactInfoView from './ContactInfoView';
import { useFindAllPaymentMethodsByContactIdLazyQuery } from 'graphql/query.generated';
import { useEffect, useMemo } from 'react';
import { Drawer, Spinner } from '@portal/ui';
import { CreditCardDisplay } from './CreditCardDisplay';
import { MerchantContactPaymentMethod } from 'graphql/types';

export interface DetailsProps {
  contact: ContactInfo | undefined;
  onAvatarChange: (file: File | null) => void;
  updateContactLoading: boolean;
  updateStatus: (status: string, contact: ContactInfo) => void;
  propertyName?: string;
}
export function Details({ contact, updateContactLoading, updateStatus, propertyName }: DetailsProps) {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [findAllPaymentsMethodByContactId, { data: paymentMethods, loading: fetchPaymentsMethodLoading }] =
    useFindAllPaymentMethodsByContactIdLazyQuery({
      fetchPolicy: 'network-only',
    });
  useEffect(() => {
    if (contact?.id) {
      findAllPaymentsMethodByContactId({
        variables: {
          merchantContactId: contact?.id as string,
        },
      });
    }
  }, [contact, contact?.id]);
  const primaryPaymentMethod = useMemo(() => {
    return paymentMethods?.findAllPaymentMethodsByContactId.paymentMethods.find(
      (paymentMethod) => paymentMethod.isPrimary === true
    );
  }, [paymentMethods?.findAllPaymentMethodsByContactId.paymentMethods]);

  return (
    <div className="px-6 md:px-0 sm:py-4">
      <div className="flex justify-center items-center py-8 md:py-0">
        <BadgeDropdown
          options={statusItems}
          loading={updateContactLoading && propertyName === 'status'}
          disabled={updateContactLoading && propertyName === 'status'}
          selected={contact?.status as string}
          onChange={(value: string) => updateStatus(value, contact as ContactInfo)}
        />
      </div>

      <ContactInfoView contact={contact as ContactInfo} />
      <div className="relative pt-5">
        <div className="flex flex-col">
          <span className="text-sm font-medium leading-5 text-steel-500"> Primary payment method</span>
          <div className="flex">
            {primaryPaymentMethod ? (
              <CreditCardDisplay card={primaryPaymentMethod as MerchantContactPaymentMethod} />
            ) : (
              <span className="text-steel-700 text-sm">None</span>
            )}
          </div>
        </div>

        <span
          className="text-primary-600 font-semibold text-sm cursor-pointer"
          onClick={() => {
            setOpenDrawer(true);
          }}
        >
          Manage
        </span>
      </div>

      <Drawer
        open={openDrawer}
        onClose={() => {
          setOpenDrawer(false);
        }}
      >
        <>
          <Drawer.Title
            title="Manage payment methods"
            onClose={() => {
              setOpenDrawer(false);
            }}
          />
          <PaymentMethodsForm
            loading={fetchPaymentsMethodLoading}
            paymentMethods={paymentMethods?.findAllPaymentMethodsByContactId?.paymentMethods}
            contactId={contact?.id || ''}
          />
        </>
      </Drawer>
      {fetchPaymentsMethodLoading && <Spinner />}
    </div>
  );
}
