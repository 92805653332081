import { useCallback } from 'react';
import { FieldArray, useFormikContext } from 'formik';
import { Button, TextField, FieldFeedback, checkFieldErrors, Icons } from '@portal/ui';
import { TrashIcon } from '@heroicons/react/20/solid/esm/index';
import classNames from 'classnames';
import { AddSite, Service } from 'types/Sites';

const Services = () => {
  const {
    values: { services, serviceName },
    setFieldValue,
    touched,
    errors,
  } = useFormikContext<AddSite>();

  const AddService = useCallback(
    (push: (value: Service) => void) => {
      push({ name: serviceName });
      setFieldValue('serviceName', '');
    },
    [serviceName, setFieldValue]
  );

  return (
    <FieldArray
      name="services"
      render={({ push, remove }) => (
        <>
          {services?.map((service: Service, index: number) => (
            <div className="py-6 flex items-center justify-between border-b" key={service.name}>
              <span className="font-medium text-gray-900 text-sm">{service.name}</span>

              <Button title="trash" displayType="text" iconOnly onClick={() => remove(index)}>
                <Icons.Trash />
              </Button>
            </div>
          ))}
          <div
            className={classNames(
              'py-1.5 pr-1.5 items-center w-full flex border rounded-md shadow-sm border-gray-300',
              {
                'my-5': services.length,
              }
            )}
          >
            <div className="w-full mx-3.5">
              <TextField
                id="serviceName"
                type="text"
                placeholder="Item name"
                customStyling={{
                  field: 'border-0 focus:ring-transparent p-0 sm:text-sm w-full text-gray-900 placeholder-gray-400',
                }}
              />
            </div>
            <Button disabled={!serviceName} displayType="text" title="add service" onClick={() => AddService(push)}>
              Add
            </Button>
          </div>
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'services'
            )}
            errorMessage={
              typeof errors === 'object' && typeof errors?.services === 'string' ? errors?.services : undefined
            }
            transitionClasses="mt-1"
          />
        </>
      )}
    />
  );
};

export default Services;
