import React from 'react';
import classNames from 'classnames';
import { useFormikContext, FieldArray, getIn } from 'formik';
import { Button, FieldFeedback } from '@portal/ui';
import { PlusIcon, TrashIcon } from '@heroicons/react/20/solid/esm/index';
import toUpper from 'lodash/toUpper';
import { weeklyAvailability, Slot } from '../../types/WeeklyAvailabilityType';
import { findSlotsWith24Hour } from '../../utils/helper';
import AvailabilitySlot from './AvailabilitySlot';

type AvailabilitySlotsProps = {
  basePath: string;
  slots: Slot[];
  showDivider: boolean;
  active: boolean;
  day: string;
};

const AvailabilitySlots: React.FC<AvailabilitySlotsProps> = ({
  basePath,
  slots,
  showDivider,
  active,
  day,
}: AvailabilitySlotsProps) => {
  const { handleChange, errors } = useFormikContext<{ weeklyAvailability: weeklyAvailability[] }>();
  const slotsWith24Hour = findSlotsWith24Hour(slots);
  const isFullDay = Object.values(slotsWith24Hour).includes(true);
  const slotsError = getIn(errors, `${basePath}.slots`);

  return (
    <>
      <div className="flex">
        <div className="w-3/12 mt-2">
          <div className="flex items-center">
            <input
              type="checkbox"
              className="text-primary-600 border-primary-600 bg-primary-50 rounded-md cursor-pointer mr-8 focus:ring-0 w-5 h-5"
              checked={active}
              name={`${basePath}.active`}
              id={`${basePath}.active`}
              onChange={handleChange}
            />
            <label className="text-gray-700 text-sm font-medium">{toUpper(day.substring(0, 3))}</label>
          </div>
        </div>
        <div className="w-9/12">
          <FieldArray
            name={`${basePath}.slots`}
            render={({ push, remove }) =>
              slots.map((slot: Slot, slotIndex: number) => {
                return (
                  <>
                    <div
                      className={classNames('flex items-center', {
                        'mt-5': slotIndex > 0,
                      })}
                      key={slotIndex}
                    >
                      <AvailabilitySlot
                        slotBasePath={`${basePath}.slots.${slotIndex}`}
                        start={slot.start}
                        end={slot.end}
                        isFullDay={isFullDay}
                        error={slotsError?.[slotIndex]}
                        disabled={!active || (isFullDay && !slotsWith24Hour[slotIndex])}
                      />
                      {slotIndex === 0 ? (
                        <Button
                          disabled={!active || isFullDay}
                          type="button"
                          displayType="text"
                          customClass="inline-flex px-4 py-2 items-center hover:bg-gray-50 text-sm font-medium rounded-md"
                          onClick={() => push({ start: '', end: '' })}
                        >
                          <PlusIcon className="h-5 w-5 text-gray-600" />
                        </Button>
                      ) : (
                        <Button
                          type="button"
                          displayType="text"
                          customClass="inline-flex px-4 py-2 items-center hover:bg-gray-50 text-sm font-medium rounded-md"
                          onClick={() => remove(slotIndex)}
                        >
                          <TrashIcon className="h-5 w-5 text-gray-600" />
                        </Button>
                      )}
                    </div>
                    <FieldFeedback
                      errorCondition={Boolean(slotsError?.[slotIndex]?.slot)}
                      errorMessage={slotsError?.[slotIndex]?.slot}
                      transitionClasses="mt-1"
                    />
                  </>
                );
              })
            }
          />
        </div>
      </div>
      {showDivider && <hr className="my-5 border-gray-200" />}
    </>
  );
};

export default React.memo(AvailabilitySlots);
