import { currencyFormatter } from '@portal/utils/misc';

export type PriceProps = {
  title: string;
  amount: string;
};

const Price = ({ title, amount }: PriceProps) => {
  return (
    <>
      <div className="flex flex-col justify-center items-center">
        <div className="items-start gap-3 bg-gray-50 text-sm text-gray-600 mb-1">{title}</div>
        <div className="font-semibold text-3xl text-gray-900">{currencyFormatter(Number(amount))}</div>
      </div>
    </>
  );
};

export default Price;
