import { useLocation, Navigate } from 'react-router-dom';
import { Layout } from '../layout';
import { useSelector } from 'redux/hooks';
import { onBoardingStatusPath } from 'utils/misc';
import { Spinner } from '@portal/ui';

function RequireAuth({ children }: { children: JSX.Element }) {
  const location = useLocation();

  const status = useSelector((state) => state.user.session.status);
  const onboardingStatus = useSelector((state) => state.user.attributes?.onboardingStatus);
  const loading = useSelector((state) => state.user.loading);

  if (status === 'signed-in' && onboardingStatus === 'COMPLETED') {
    return <Layout>{children}</Layout>;
  } else if (status === 'signed-in' && onboardingStatus && onboardingStatus !== 'COMPLETED') {
    return <Navigate to={onBoardingStatusPath[onboardingStatus]} replace />;
  } else if (status === 'loading' || loading) {
    return <Spinner />;
  }

  return <Navigate to="/login" state={{ from: location }} replace />;
}

export { RequireAuth };
