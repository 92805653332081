import toast from '@portal/ui/components/widgets/Toast/notify';
import { MeDocument, MeQuery } from 'graphql/query.generated';
import { Auth, client } from 'libs/cognito';
import {
  logout,
  resetAction,
  resetChangePasswordLoader,
  resetLoader,
  setAttributes,
  setChangePasswordLoader,
  setLoader,
  setStatus,
  updateSession,
} from '../../reducers/user';
import { ThunkFn } from '../definitions/types';
import { fetchUsers } from '../owners';
import { fetchTags } from '../tags';

const fetchUserAttributes: ThunkFn = () => {
  return async (dispatch) => {
    try {
      dispatch(setLoader());
      const res = await client.query<MeQuery>({ query: MeDocument, fetchPolicy: 'network-only' });
      dispatch(setAttributes(res.data.me));
      dispatch(fetchUsers(res.data.me.merchantAccount.id));
      dispatch(fetchTags(res.data.me.merchantAccount.id));
      dispatch(resetLoader());
    } catch (err) {
      dispatch(signOut());
      dispatch(resetLoader());
    }
  };
};

const signInWithTokens: ThunkFn = (accessToken: string, refreshToken: string) => {
  return async (dispatch) => {
    dispatch(updateSession({ accessToken, refreshToken }));
    dispatch(setStatus('signed-in'));
    await dispatch(fetchUserAttributes());
  };
};

const signInWithEmail: ThunkFn = (username: string, password: string) => {
  return async (dispatch) => {
    dispatch(setStatus('loading'));
    const response = await Auth.signInWithEmail(username, password);

    if (response?.newPasswordRequired) {
      return response;
    } else if (!response.isValid()) {
      throw new Error('Invalid session');
    } else dispatch(signInWithTokens(response.getAccessToken().getJwtToken(), response.getRefreshToken().getToken()));
  };
};

const setNewPassword: ThunkFn = (password: string) => {
  return async (dispatch) => {
    try {
      dispatch(setStatus('loading'));
      const response = await Auth.setNewPassword(password);
      if ('isValid' in response && !response?.isValid()) {
        throw new Error('Invalid session');
      } else dispatch(signInWithTokens(response.getAccessToken().getJwtToken(), response.getRefreshToken().getToken()));
    } catch (error: any) {
      console.log(error);
      dispatch(setStatus('signed-out'));
      throw new Error(error?.message ?? 'Something went wrong');
    }
  };
};

const signOut: ThunkFn = () => {
  return (dispatch) => {
    Auth.signOut();
    dispatch(logout());
  };
};

const changePassword: ThunkFn = (oldPassword: string, newPassword: string) => {
  return async (dispatch) => {
    try {
      dispatch(setChangePasswordLoader());
      await Auth.changePassword(oldPassword, newPassword);
      toast.success('Password changed');
      dispatch(resetChangePasswordLoader());
    } catch (err: any) {
      toast.error(err?.message || 'Something went wrong');
      dispatch(resetChangePasswordLoader());
      dispatch(resetAction());
    }
  };
};

export { changePassword, fetchUserAttributes, signInWithEmail, signInWithTokens, signOut, setNewPassword };
