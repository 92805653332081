import { client } from 'libs/cognito';
import { setData } from '../../reducers/users';
import { ThunkFn } from '../definitions/types';
import { FindAllUsersDocument, FindAllUsersQuery } from 'graphql/query.generated';

const fetchUsers: ThunkFn = (id: string) => {
  return async (dispatch) => {
    try {
      const res = await client.query<FindAllUsersQuery>({
        query: FindAllUsersDocument,
        variables: {
          merchantAccountId: id,
        },
      });
      dispatch(setData(res.data.findAllUsers.users));
    } catch (err) {
      console.error(err);
    }
  };
};

export { fetchUsers };
