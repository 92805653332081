export const industryList = [
  { name: 'Technology', value: 'technology' },
  { name: 'Finance', value: 'finance' },
  { name: 'Healthcare', value: 'healthcare' },
  { name: 'Education', value: 'education' },
  { name: 'E-commerce', value: 'ecommerce' },
  { name: 'Real Estate', value: 'realestate' },
  { name: 'Hospitality', value: 'hospitality' },
  { name: 'Travel', value: 'travel' },
  { name: 'Food and Beverage', value: 'foodbeverage' },
  { name: 'Retail', value: 'retail' },
  { name: 'Fashion', value: 'fashion' },
  { name: 'Automotive', value: 'automotive' },
  { name: 'Media and Entertainment', value: 'mediaentertainment' },
  { name: 'Sports and Fitness', value: 'sportsfitness' },
  { name: 'Art and Culture', value: 'artculture' },
  { name: 'Nonprofit', value: 'nonprofit' },
  { name: 'Government', value: 'government' },
  { name: 'Professional Services', value: 'professionalservices' },
  { name: 'Consulting', value: 'consulting' },
  { name: 'Energy', value: 'energy' },
];

export const languagesList = [
  { value: 'en', label: 'English' },
  { value: 'es', label: 'Spanish' },
  { value: 'fr', label: 'French' },
  { value: 'de', label: 'German' },
  { value: 'it', label: 'Italian' },
  { value: 'pt', label: 'Portuguese' },
  { value: 'zh', label: 'Chinese' },
  { value: 'ja', label: 'Japanese' },
  { value: 'ko', label: 'Korean' },
  { value: 'ru', label: 'Russian' },
];

export const weeklyAvailability = [
  { day: 'mon', active: true, slots: [{ start: '', end: '' }] },
  { day: 'tue', active: true, slots: [{ start: '', end: '' }] },
  { day: 'wed', active: true, slots: [{ start: '', end: '' }] },
  { day: 'thr', active: true, slots: [{ start: '', end: '' }] },
  { day: 'fri', active: true, slots: [{ start: '', end: '' }] },
  { day: 'sat', active: false, slots: [{ start: '', end: '' }] },
  { day: 'sun', active: false, slots: [{ start: '', end: '' }] },
];

export const socialProfilesOptions = [
  { name: 'Facebook', url: 'facebook.com/' },
  { name: 'Instagram', url: 'instagram.com/' },
  { name: 'Yelp', url: 'yelp.com/' },
  { name: 'LinkedIn', url: 'linkedin.com/company/' },
  { name: 'Google My Business', url: 'google.com/business/' },
  { name: 'YouTube', url: 'youtube.com/' },
  { name: 'Twitter', url: 'twitter.com/' },
  { name: 'Pinterest', url: 'pinterest.com/' },
  { name: 'TikTok', url: 'tiktok.com/' },
  { name: 'Snapchat', url: 'snapchat.com/' },
  { name: 'Houzz', url: 'houzz.com/' },
  { name: 'TripAdvisor', url: 'tripadvisor.com/' },
  { name: 'Nextdoor', url: 'nextdoor.com/' },
];

export const longToShortWeekdayMap = {
  monday: 'mon',
  sunday: 'sun',
  tuesday: 'tue',
  wednesday: 'wed',
  thursday: 'thr',
  friday: 'fri',
  saturday: 'sat',
};

export const SiteActions: { [key: string]: string[] } = {
  PENDING: ['Edit', 'Remove'],
  DRAFT: ['Edit', 'Remove'],
  ACTIVE: ['View', 'Edit', 'Publish', 'Customize domain', 'Remove'],
  INACTIVE: ['View', 'Edit', 'Unpublish', 'Customize domain', 'Remove'],
};
