import { useMergeState } from '@portal/react-hooks/use-merge-state';
import { Button, DataTable, DeleteModal, EmptyState, Icons, Modal, RowActionMenu, SubHeading } from '@portal/ui';
import { ColumnType } from '@portal/ui/components/base/Table';
import { RowActionMenuProps } from '@portal/ui/components/display/RowActionMenu';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { ternary } from '@portal/utils/conditional';
import { currencyFormatter } from '@portal/utils/misc';
import { capitalizeText } from '@portal/utils/string';
import { PaginationState, SortingState } from '@tanstack/react-table';
import { FormikValues } from 'formik';
import {
  useCreateProductMutation,
  useDeleteProductMutation,
  useUpdateProductMutation,
} from 'graphql/mutation.generated';
import { FindAllProductsQuery, useFindAllProductsLazyQuery } from 'graphql/query.generated';
import { FindAllProductsFilters } from 'graphql/types';
import moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'redux/store';
import { ProductsFormProps } from 'types/Product';
import ProductsForm from './ProductForm';

const PAGE_SIZE = 10;

type TableState = {
  pageIndex: PaginationState['pageIndex'];
  pageSize: PaginationState['pageSize'];
  sorting: SortingState;
  filters: FindAllProductsFilters | undefined;
};

const initialState: TableState = {
  pageIndex: 0,
  pageSize: PAGE_SIZE,
  sorting: [
    {
      id: 'createdAt',
      desc: true,
    },
  ],
  filters: undefined,
};

export type ProductRow = FindAllProductsQuery['findAllProducts']['products'][number];

export const ProductsPage = () => {
  const merchantAccountId = useSelector<RootState>((state) => state.user.attributes?.merchantAccount.id);
  const [productFormMode, setProductFormMode] = useState<ProductsFormProps['mode'] | null>(null);
  const [tableState, updateTableState] = useMergeState<TableState>(initialState);
  const [selectedProduct, setSelectedProduct] = useState<ProductRow | null>();

  const [selectedProductToDelete, setSelectedProductToDelete] = useState<ProductRow | null>(null);
  const closeDeleteProductModal = useCallback(() => setSelectedProductToDelete(null), []);

  const [findAllProducts, { data, loading, refetch }] = useFindAllProductsLazyQuery({
    fetchPolicy: 'network-only',
  });

  const [createProduct, { loading: createProductLoading }] = useCreateProductMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Product added');
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      setProductFormMode(null);
      setSelectedProduct(null);
    },
  });

  const [updateProduct, { loading: updateProductLoading }] = useUpdateProductMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Product updated');
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
      setProductFormMode(null);
      setSelectedProduct(null);
    },
  });

  const [deleteProductMutation, { loading: deleteProductLoading }] = useDeleteProductMutation({
    onCompleted: () => {
      toast.success('Product deleted');
      closeDeleteProductModal();
      resetTableState();
      refetch({
        pagination: {
          offset: 0,
          limit: PAGE_SIZE,
        },
        filters: undefined, // reset any filters
      });
    },
    onError: (error) => {
      console.error(error);
    },
  });

  const openProductFormModal = useCallback((mode: typeof productFormMode) => {
    setProductFormMode(mode);
  }, []);

  const closeProductFormModal = useCallback(() => {
    setSelectedProduct(null);
    setProductFormMode(null);
  }, []);

  useEffect(() => {
    if (merchantAccountId) {
      findAllProducts({
        variables: {
          merchantAccountId: merchantAccountId as string,
          pagination: {
            offset: tableState.pageIndex * tableState.pageSize,
            limit: tableState.pageSize,
          },
          filters: tableState.filters,
          orderBy: {
            name: ternary(tableState.sorting.find((col) => col.id === 'name')?.desc, 'desc', 'asc', undefined),
            createdAt: ternary(
              tableState.sorting.find((col) => col.id === 'createdAt')?.desc,
              'desc',
              'asc',
              undefined
            ),
          },
        },
        onError: (error) => {
          console.error(error);
          toast.error('Something went wrong');
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [merchantAccountId, tableState.pageIndex, tableState.pageSize, tableState.filters, tableState.sorting]);

  const rowActions: RowActionMenuProps<ProductRow>['rowActions'] = [
    {
      name: 'Edit',
      action: (row) => {
        setSelectedProduct(row);
        openProductFormModal('edit');
      },
    },
    {
      name: 'Delete',
      action: (row) => {
        setSelectedProductToDelete(row ?? null);
      },
    },
  ];

  const columns: ColumnType<ProductRow>[] = [
    {
      name: 'Name',
      id: 'name',
      cell: (info: any) => {
        const product = info.row.original;
        return (
          <div className="flex items-center">
            <div className="">
              <div className="font-medium text-steel-900">{capitalizeText(product.name)}</div>
              <div className="text-steel-500">{currencyFormatter(product.price)}</div>
            </div>
          </div>
        );
      },
    },
    {
      name: 'Created',
      id: 'createdAt',
      cell: (info: any) => {
        const product = info.row.original;
        return (
          <div className="flex items-center">
            <div className="font-medium font-normal text-steel-600">
              {moment(product.createdAt).format('MMM D, YYYY')}
            </div>
          </div>
        );
      },
    },
    {
      name: 'Updated',
      id: 'updatedAt',
      cell: (info: any) => {
        const product = info.row.original;
        return (
          <div className="flex items-center">
            <div className="font-medium font-normal text-steel-600">
              {moment(product.updatedAt).format('MMM D, YYYY')}
            </div>
          </div>
        );
      },
    },
    {
      name: '',
      id: 'actions',
      cell: ({ row: { original } }) => <RowActionMenu row={original} rowActions={rowActions} />,
    },
  ];

  const resetTableState = useCallback(() => updateTableState(initialState), [updateTableState]);

  const onGlobalFilterChange = useCallback(
    (value: string) => {
      if (value) {
        updateTableState({
          pageIndex: 0,
          sorting: [],
          filters: undefined,
        });
      } else {
        resetTableState();
      }
    },
    [updateTableState, resetTableState]
  );

  const pageCount = useMemo(() => {
    const total = data?.findAllProducts.count;
    return total ? Math.ceil(total / PAGE_SIZE) : undefined;
  }, [data?.findAllProducts.count]);

  const handleCreateProduct = (values: FormikValues) => {
    createProduct({
      variables: {
        merchantAccountId: merchantAccountId as string,
        data: values.products,
      },
    });
  };

  const handleUpdateProduct = (values: FormikValues) => {
    updateProduct({
      variables: {
        data: {
          merchantProductId: selectedProduct?.id as string,
          name: values.products[0]?.name,
          price: values?.products[0]?.price,
        },
      },
    });
  };

  const onSubmit = (values: FormikValues) => {
    if (selectedProduct) {
      handleUpdateProduct(values);
    } else {
      handleCreateProduct(values);
    }
  };

  const onProductDelete = () => {
    deleteProductMutation({
      variables: { merchantProductId: selectedProductToDelete?.id ?? '' },
    }).then((res) => {
      if (res.errors) {
        toast.error('Something went wrong');
      }
    });
  };

  return (
    <div className="px-8">
      <SubHeading
        title="Products"
        description="View and edit the products you offer to your customers"
        actions={[
          <Button title="add product" onPress={() => openProductFormModal('create')} displayType="primary">
            <Icons.Plus className="w-5 h-5" aria-hidden="true" color="white" /> Add product
          </Button>,
        ]}
        useHelmetTitle
      />
      <div className="pb-3">
        <DataTable
          columns={columns}
          data={data?.findAllProducts.products || []}
          noDataElement={
            <EmptyState
              title="No product yet"
              subTitle="Get started by adding a new product"
              btnText="Add product"
              onClick={() => openProductFormModal('create')}
              icon={<Icons.AddContact />}
            />
          }
          tableState={tableState}
          updateTableState={updateTableState}
          loading={loading}
          onGlobalFilterChange={onGlobalFilterChange}
          pageCount={pageCount}
        />
      </div>
      <Modal
        open={productFormMode != null}
        title={productFormMode === 'create' ? 'Add a product' : 'Edit product'}
        onClose={closeProductFormModal}
        closeIcon
      >
        <ProductsForm
          mode={productFormMode ?? 'create'}
          initialValues={selectedProduct ? { ...selectedProduct } : undefined}
          onSubmit={onSubmit}
          loading={productFormMode === 'create' ? createProductLoading : updateProductLoading}
        />
      </Modal>
      <DeleteModal
        onClose={closeDeleteProductModal}
        title={'Delete product'}
        open={selectedProductToDelete != null}
        onConfirm={onProductDelete}
        loading={deleteProductLoading}
        content={'Are you sure you want to delete this product? This action cannot be undone.'}
      />
    </div>
  );
};
