import { Icons, Spinner } from '@portal/ui';
import { Event } from 'types/Event';
import { formatDate } from 'utils/dates';
import { getFormattedTime } from 'utils/time';
interface AppointmentCardProps {
  event: Event;
  loading?: boolean;
  onClick: () => void;
}

const AppointmentCard = ({ event, loading, onClick }: AppointmentCardProps) => {
  const { title, startTime, endTime, location } = event;
  const { shortFormatDate, longFormatDate } = formatDate(startTime);
  const [month, day] = shortFormatDate.split(' ');

  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div
          className="w-full p-1 lg:p-3 border border-steel-200 bg-white rounded-xl shadow flex justify-between mb-6 cursor-pointer"
          onClick={onClick}
        >
          <div className="flex gap-[17px] items-center">
            <div className="hidden py-3 px-6 rounded-lg bg-steel-50 lg:flex flex-col gap-1 items-center">
              <p className="text-3xl font-semibold leading-[38px] text-steel-900">{day}</p>
              <p className="text-base font-medium leading-6 text-steel-900">{month}</p>
            </div>
            <div className="p-3 gap-2 flex flex-col">
              <p className="text-base font-medium leading-6 text-steel-900">{title}</p>
              <div className="flex flex-col lg:flex-row gap-2 lg:gap-6">
                <div className="flex items-center gap-2.5">
                  <Icons.Clock color="#7D89B0" height={16} width={16} />
                  <p className="text-sm font-normal text-steel-700">{getFormattedTime(startTime, endTime)}</p>
                </div>
                <div className="flex items-center gap-2.5">
                  <Icons.Calendar color="#7D89B0" height={16} width={16} />
                  <p className="text-sm font-normal text-steel-700">{longFormatDate}</p>
                </div>
              </div>
              {location && (
                <div className="flex items-center gap-1.5">
                  <div className="-mb-1">
                    <Icons.Location color="#7D89B0" />
                  </div>
                  <p className="text-sm font-normal text-steel-700">{location}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AppointmentCard;
