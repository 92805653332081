import React, { useEffect } from 'react';
import { useFormikContext } from 'formik';
import { FieldFeedback, checkFieldErrors } from '@portal/ui';
import { MinusIcon } from '@heroicons/react/20/solid/esm/index';
import { weeklyAvailability } from '../../types/WeeklyAvailabilityType';
import TimeInput from './TimeInput';

type AvailabilitySlotProps = {
  slotBasePath: string;
  start: string;
  end: string;
  isFullDay: boolean;
  error: string | Record<string, string>;
  disabled: boolean;
};

const AvailabilitySlot: React.FC<AvailabilitySlotProps> = ({
  slotBasePath,
  start,
  end,
  isFullDay,
  error,
  disabled,
}: AvailabilitySlotProps) => {
  const { touched, errors, setFieldValue } = useFormikContext<{ weeklyAvailability: weeklyAvailability[] }>();
  const startError = typeof error === 'object' ? error?.start : undefined;
  const endError = typeof error === 'object' ? error?.end : undefined;

  useEffect(() => {
    if (isFullDay && !disabled && (start === '24:00' || end !== '24:00')) {
      setFieldValue(`${slotBasePath}.start`, '24:00');
      setFieldValue(`${slotBasePath}.end`, '24:00');
    }
  }, [isFullDay, start, end, disabled, setFieldValue, slotBasePath]);

  return (
    <>
      <div className="flex flex-col">
        <TimeInput
          placeholder="Open"
          value={start}
          onChange={(time) => setFieldValue(`${slotBasePath}.start`, time)}
          disabled={disabled}
        />
        <FieldFeedback
          errorCondition={checkFieldErrors(
            touched as Record<string, boolean>,
            errors as Record<string, string | undefined>,
            `${slotBasePath}`,
            'start'
          )}
          errorMessage={startError}
          transitionClasses="mt-1"
        />
      </div>
      <MinusIcon className="w-5 h-5 mx-3" />
      <div className="flex flex-col mr-2.5">
        <TimeInput
          placeholder="Close"
          value={end}
          onChange={(time) => setFieldValue(`${slotBasePath}.end`, time)}
          disabled={disabled}
        />
        <FieldFeedback
          errorCondition={checkFieldErrors(
            touched as Record<string, boolean>,
            errors as Record<string, string | undefined>,
            `${slotBasePath}`,
            'end'
          )}
          errorMessage={endError}
          transitionClasses="mt-1"
        />
      </div>
    </>
  );
};

export default React.memo(AvailabilitySlot);
