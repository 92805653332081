import { defaultAvailabilities as availabilities } from 'constants/booking';
import { Button, TextField, ListBox, Checkbox, SearchableInput } from '@portal/ui';
import { AvailabilitySettings } from 'components/settings/BookingSettings/AvailabilitySettings';
import { businessDetailsSchema } from 'components/validation/onboardingSchema';
import { Formik, Form } from 'formik';
import _ from 'lodash';
import { BusinessOptions, EstimatedRenevueOptions, NumberOfEmployeesOptions } from 'constants/onboarding';
import states from 'states-us';
import { useMemo } from 'react';

const BusinessDetails = () => {
  const stateOptions = useMemo(() => {
    return states.map((state) => ({ label: state.name, value: state.abbreviation }));
  }, [states]);
  return (
    <div className="flex-col w-[54%] mx-auto pt-10 text-secondary min-h-screen">
      <div className="flex flex-col justify-start items-start w-full">
        <h1 className="text-black font-semibold text-[33px] py-5">Confirm your business details</h1>
        <p className="whitespace-normal text-xl font-light text-gray-600 inline-flex text-center">
          Enter your business information below to personalize your account. You can also choose to receive SMS
          notifications for updates about your business.
        </p>
      </div>

      <div className="my-5">
        <Formik
          initialValues={{
            name: '',
            category: '',
            businessPhone: '',
            mobilePhone: '',
            website: '',
            address: '',
            updateListing: false,
            state: '',
            city: '',
            address1: '',
            address2: '',
            enableNotifications: false,
            availability: availabilities,
            estimatedRevenue: '',
            numberOfEmployees: '',
            yearFounded: '',
          }}
          validationSchema={businessDetailsSchema}
          onSubmit={(values) => {
            console.log(values);
          }}
        >
          {({ setFieldValue, values }) => {
            return (
              <Form className="space-y-4 pb-10 flex-col w-full">
                <div className="w-full">
                  <TextField id="name" label="Business name" type="text" placeholder="Acme Solutions" />
                </div>
                <div className="flex flex-col w-full">
                  <label htmlFor="website" className="block text-sm font-medium text-steel-700 mb-2">
                    Website
                  </label>
                  <div className="flex">
                    <div className="w-1/5">
                      <TextField
                        disabled={true}
                        id="website-prefix"
                        type="text"
                        value="https://"
                        customStyling={{
                          field:
                            'text-steel-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                        }}
                      />
                    </div>
                    <div className="w-full [&>*:first-child]:w-full">
                      <TextField
                        id="website"
                        type="text"
                        prefix="https://"
                        placeholder="yoursite.com"
                        customStyling={{
                          field:
                            'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                          errorMsg: '!absolute left-0 top-[36px]',
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex space-x-5">
                  <div className="w-3/4">
                    <TextField id="address1" label="Street Address" type="text" placeholder="" />
                  </div>
                  <div>
                    <TextField id="address2" label="Street Address" type="text" placeholder="" />
                  </div>
                </div>
                <div className="">
                  <TextField id="city" label="City" type="text" placeholder="" />
                </div>
                <div>
                  <div className="flex space-x-5">
                    <div className="w-2/4">
                      <label className="block text-sm font-medium mb-2 text-steel-700" htmlFor="state">
                        State
                      </label>
                      <SearchableInput
                        defaultValue={{
                          label: '',
                          value: '',
                        }}
                        value={stateOptions.find((state) => state.value === values.state)}
                        options={stateOptions}
                        handleChange={(value) => setFieldValue('state', value)}
                        id="state"
                      />
                    </div>
                    <div className="w-2/4">
                      <TextField id="zip" type="text" label="Zip" />
                    </div>
                  </div>
                  <div className="flex space-x-5 py-4">
                    <div className="w-2/4">
                      <TextField
                        id="businessPhone"
                        label="Business phone"
                        type="text"
                        placeholder="+1 (555) 500-5000"
                      />
                    </div>
                    <div className="w-2/4">
                      <TextField id="mobilePhone" label="Mobile phone" type="text" placeholder="+1 (555) 500-5000" />
                    </div>
                  </div>
                  <div className="flex space-x-5">
                    <div className="w-2/4">
                      <TextField id="yearFounded" label="Year founded" type="text" />
                    </div>
                    <div className="flex w-2/4 flex-col gap-2">
                      <label className="block text-sm font-medium text-gray-700">Category</label>
                      <ListBox
                        customStyle="grow"
                        options={BusinessOptions}
                        onChange={(value: string) => setFieldValue('category', value)}
                      />
                    </div>
                  </div>
                </div>
                <div className="flex gap-4 items-center">
                  <div className="flex w-1/2 flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Number of employees</label>
                    <ListBox
                      customStyle="grow"
                      options={NumberOfEmployeesOptions}
                      onChange={(value: string) => setFieldValue('numberOfEmployees', value)}
                    />
                  </div>
                  <div className="flex w-1/2 flex-col gap-2">
                    <label className="block text-sm font-medium text-gray-700">Est. annual revenue</label>
                    <ListBox
                      customStyle="grow"
                      options={EstimatedRenevueOptions}
                      onChange={(value: string) => setFieldValue('estimatedRevenue', value)}
                    />
                  </div>
                </div>

                <div className="flex flex-col items-start justify-start w-full availability py-3">
                  <span className="pb-5 text-sm">Hours</span>
                  <AvailabilitySettings customFieldClass={'!w-3/4 p-0'} showLabel={false} />
                </div>
                <div className="[&>*:first-child]:px-0">
                  <Checkbox
                    id="updateListing"
                    name="businessListing"
                    checked={values.updateListing}
                    label=" Update my business listings. Save my login details for next time."
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('updateListing', e.target.checked)
                    }
                    className="text-primary-600 border-primary-600 bg-primary-50 rounded-md cursor-pointer mr-8 focus:ring-0 w-5 h-5"
                  />
                </div>
                <div className="[&>*:first-child]:px-0">
                  <Checkbox
                    id="enableNotifications"
                    name="notifications"
                    checked={values.enableNotifications}
                    label=" Enable SMS notifications"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                      setFieldValue('enableNotifications', e.target.checked)
                    }
                    className="text-primary-600 border-primary-600 bg-primary-50 rounded-md cursor-pointer mr-8 focus:ring-0 w-5 h-5"
                  />
                </div>

                <div className="onboarding-btn">
                  <Button displayType="primary" type="submit" title="Continue">
                    Continue
                  </Button>
                </div>
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default BusinessDetails;
