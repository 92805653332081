export type EmailInputProps = {
  label: string;
  value: string;
  type?: 'text' | 'email';
  id: string;
  customClass?: string;
  onChange: (value: string) => void;
};

const EmailBoxInput = ({ label, value, id, type = 'email', onChange }: EmailInputProps) => {
  return (
    <>
      <div className="pt-6 pb-4 text-base font-medium text-gray-700 border-b border-gray-200  flex w-full items-center gap-5">
        <div>{label}</div>
        <input
          id={id}
          className="w-full border-none focus:border-none active:border-none outline-none !ring-opacity-0 !ring-transparent !ring-offset-0"
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
      </div>
    </>
  );
};

export default EmailBoxInput;
