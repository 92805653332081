import { PasswordSection } from './PasswordSection';

export const Security = () => {
  return (
    <div className="overflow-y-auto w-full relative">
      <div className="px-4 sm:px-6 lg:px-8 mb-24">
        <PasswordSection />
      </div>
    </div>
  );
};
