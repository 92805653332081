import {
  Avatar,
  FileUpload,
  TimeZone,
  Button,
  HorizontalLabel,
  TextField,
  FieldFeedback,
  checkFieldErrors,
  Icons,
  SubHeading,
} from '@portal/ui';
import { FormState } from 'components/profile/Details';
import { getInitials } from '@portal/utils/string';
import { useFormikContext } from 'formik';
import { formatOffset, getLogoUrl } from '@portal/utils/misc';
import { components } from 'react-select';
import moment from 'moment';

const ProfileFields = () => {
  const { setFieldValue, values, errors, touched } = useFormikContext<FormState>();
  return (
    <>
      <SubHeading title="Personal details" description="Update your photo and personal information here." showBorder />
      <div className="py-6 border-b border-steel-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel label="Name" labelFor="name" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TextField id="name" type="text" />
        </div>
      </div>
      <div className="py-6 border-b border-steel-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel label="Email" labelFor="email" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TextField id="email" type="email" />
        </div>
      </div>
      <div className="py-6 border-b border-steel-200 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel label="Your photo" labelFor="photo" info="This will be displayed on your profile" />
        </div>
        <div className="w-9/12 pl-8 pr-16 flex">
          <div className="flex-shrink-0 mb-1 mr-16">
            <Avatar
              id="photo"
              size={'large'}
              imageUrl={
                typeof values.profileAvatar === 'object' ? getLogoUrl(values.profileAvatar) : values.profileAvatar
              }
              initials={getInitials(values.name)}
            />
            {values.profileAvatar && (
              <Button
                title="remove photo avatar"
                displayType="text"
                type="button"
                onClick={() => setFieldValue('profileAvatar', '')}
              >
                Remove
              </Button>
            )}
          </div>
          <div className="flex">
            <div className="w-96">
              <FileUpload onChange={(value) => setFieldValue('profileAvatar', value)} />
            </div>
          </div>
        </div>
      </div>
      <div className="py-6 flex gap-8">
        <div className="w-3/12">
          <HorizontalLabel labelFor="tzName" label="Time zone" />
        </div>
        <div className="w-9/12 pl-8 pr-16">
          <TimeZone
            id="tzName"
            timezone={values.tzName}
            onChange={(value) => setFieldValue('tzName', value)}
            selectedComponent={({ children, ...props }) => {
              const timezone = props.getValue();
              let value: number | null = null;
              if (timezone && timezone.length) {
                value = moment.tz(timezone[0].value).utcOffset();
              }

              return (
                <components.Control {...props} className="flex gap-2 !justify-start">
                  <Icons.Clock />
                  <div className="flex">{children}</div>
                  <p className="text-sm text-steel-500">{value ? `UTC ${formatOffset(value)}` : ''}</p>
                </components.Control>
              );
            }}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'tzName'
            )}
            errorMessage={errors?.tzName}
            transitionClasses="mt-1"
          />
        </div>
      </div>
    </>
  );
};

export default ProfileFields;
