import {
  Button,
  ListBox,
  MultiSelect,
  Avatar,
  FileUpload,
  WeeklyAvailability,
  checkFieldErrors,
  FieldFeedback,
  CreatableSelect,
  HorizontalLabel,
  SubHeading,
  TextField,
} from '@portal/ui';
import { useState } from 'react';
import { useFormikContext } from 'formik';
import { useNavigate } from 'react-router-dom';
import { industryList } from 'constants/sites';
import { AddSite } from 'types/Sites';
import SocialProfiles from './SocialProfiles';
import { subHeadingsWrapperClasses } from './AddSiteModal';
import Icons from '@portal/ui/components/assets/icons';
import { languages } from 'utils/language';
import { ActionMeta } from '@portal/ui/components/types/CreateableSelect';
import FormField from 'components/formField';

type Area = {
  value: string;
  label: string;
};

const BasicInfo = () => {
  const navigate = useNavigate();
  const [areas, setAreas] = useState<Area[]>([]);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const { values, setFieldValue, touched, errors } = useFormikContext<AddSite>();

  const isObject = (variable: any): variable is Record<string, unknown> => typeof variable === 'object';

  const createNewArea = (value: string) => {
    const newOption = { value, label: value };
    setAreas([...areas, newOption]);
    setFieldValue('areas', [...(values.areas ? values.areas : []), newOption]);
  };
  const handleInputChange = (inputValue: string, actionMeta: ActionMeta) => {
    if (inputValue.length > 0) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  };
  return (
    <>
      <div className="flex-grow pb-5 border-b border-gray-200">
        <SubHeading title="Basic information" description="Tell us the core details about your business." />
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Business address"
            labelFor="address"
            info="This is the address that will appear on your invoices."
          />
        </div>
        <div className="w-8/12">
          <FormField id="name" type="text" />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Business logo" labelFor="logo" />
        </div>
        <div className="w-8/12">
          <div className="text-gray-600 flex text-sm font-semibold cursor-pointer gap-8 w-full">
            <div className="flex-shrink-0 mb-1 items-start justify-start">
              <Avatar size={'large'} imageUrl={values.logo} />
            </div>
            <div className="w-full">
              <FileUpload
                onChange={(logo) => setFieldValue('logo', URL.createObjectURL(logo as File))}
                uploadInfoText="SVG, PNG, JPG or GIF (10MB max)"
                name="logo"
              />
              <FieldFeedback
                errorCondition={checkFieldErrors(
                  touched as Record<string, boolean>,
                  errors as Record<string, string | undefined>,
                  '',
                  'logo'
                )}
                errorMessage={isObject(errors) ? errors?.logo : undefined}
                transitionClasses="mt-1"
              />
            </div>
          </div>
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Business phone" labelFor="phone" />
        </div>
        <div className="w-8/12">
          <FormField id="phone" type="tel" />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Business address" labelFor="address" />
        </div>
        <div className="w-8/12 flex justify-between items-center">
          <div className="w-7/12">
            <FormField id="address" type="text" />
          </div>
          <div className="w-2/5">
            <FormField
              id="addressLine2"
              type="text"
              onChange={(e) => setFieldValue('address', `${values.address || ''} ${e.target.value}`)}
            />
          </div>
          {/* <FormField id="address" type="text" /> */}
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Business email address" labelFor="email" />
        </div>
        <div className="w-8/12">
          <FormField id="email" type="email" />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Business category" labelFor="category" />
        </div>
        <div className="w-8/12">
          <ListBox
            value={industryList.filter((industry) => industry.value === values.industry)[0]}
            options={industryList}
            onChange={(value) => setFieldValue('industry', value)}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'industry'
            )}
            errorMessage={isObject(errors) ? errors?.industry : undefined}
            transitionClasses="mt-1"
          />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel label="Languages spoken" labelFor="languages" />
        </div>
        <div className="w-8/12">
          <MultiSelect
            values={values.languages}
            options={languages.map((lang) => ({ label: lang, value: lang }))}
            onChange={(selectedOptions) => setFieldValue('languages', selectedOptions)}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'languages'
            )}
            errorMessage={isObject(errors) && typeof errors?.languages === 'string' ? errors?.languages : undefined}
            transitionClasses="mt-1"
          />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Areas serviced"
            info="These are the geographic areas where your business provides service or delivery. You can specify the areas using zip codes, city names, or other details."
            labelFor="areas"
          />
        </div>
        <div className="w-8/12">
          <CreatableSelect
            isMulti
            value={values?.areas}
            options={areas}
            onCreateOption={(value: string) => {
              createNewArea(value);
            }}
            onChange={(selectedOptions) => {
              setAreas(selectedOptions as Area[]);
              setFieldValue('areas', selectedOptions);
            }}
            placeholder={''}
            menuIsOpen={isMenuOpen}
            onInputChange={handleInputChange}
          />
          <FieldFeedback
            errorCondition={checkFieldErrors(
              touched as Record<string, boolean>,
              errors as Record<string, string | undefined>,
              '',
              'areas'
            )}
            errorMessage={isObject(errors) && typeof errors?.areas === 'string' ? errors?.areas : undefined}
            transitionClasses="mt-1"
          />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12">
          <HorizontalLabel
            label="Social profiles"
            info="We use these as links on the site and may pull reviews if any exist"
            labelFor="social-profiles"
          />
        </div>
        <div className="w-8/12">
          <SocialProfiles />
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12 flex flex-col gap-2">
          <HorizontalLabel
            label="Calendar link"
            info="This is the link to your online calendar where customers can book appointments."
            labelFor="calendar-link"
          />
          <div className="[&>button]:!text-primary-700 [&>button]:!pl-0">
            <Button
              title="Set up calendar"
              displayType="text"
              suffixIcon={<Icons.ArrowRight color="#7F56D9" />}
              onClick={() => navigate('/calendar')}
            >
              Set up calendar
            </Button>
          </div>
        </div>
        <div className="w-8/12">
          <div className="flex">
            <div className="w-fit">
              <TextField
                disabled={true}
                id={`calenderLink.http`}
                type="url"
                value={'https://'}
                customStyling={{
                  field:
                    'text-gray-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500',
                }}
              />
            </div>
            <div className="w-full">
              <TextField
                id="calenderLink.url"
                type="url"
                customStyling={{
                  field:
                    'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500 appearance-none !ring-opacity-0 !ring-transparent !ring-offset-0',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className={subHeadingsWrapperClasses}>
        <div className="w-4/12 flex flex-col gap-2">
          <HorizontalLabel
            label="Payment link"
            info="This is where customers can make payments for your products or services."
            labelFor="payment-link"
          />
          <div className="[&>button]:!text-primary-700 [&>button]:!pl-0">
            <Button
              displayType="text"
              title="Set up payments"
              suffixIcon={<Icons.ArrowRight color="#7F56D9" />}
              onClick={() => navigate('/payments')}
            >
              Set up payments
            </Button>
          </div>
        </div>
        <div className="w-8/12">
          <div className="flex">
            <div className="w-fit">
              <TextField
                disabled={true}
                id="paymentLink.protocol"
                type="url"
                customStyling={{
                  field:
                    'text-gray-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500',
                }}
              />
            </div>
            <div className="w-full">
              <TextField
                id="paymentLink.url"
                type="url"
                customStyling={{
                  field:
                    'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-gray-300 placeholder-gray-400 focus:ring-primary-500 focus:border-primary-500 appearance-none !ring-opacity-0 !ring-transparent !ring-offset-0',
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 border-gray-200 flex gap-8">
        <div className="w-4/12">
          <HorizontalLabel label="Hours" labelFor="hours" />
        </div>
        <div className="w-8/12">
          <WeeklyAvailability />
        </div>
      </div>
    </>
  );
};

export default BasicInfo;
