import { Button, Drawer, Formik, Label, TextField, TimeSlotPicker } from '@portal/ui';
import { Form, useFormikContext } from 'formik';

type EditBusinessDetailsFormProps = {
  onClose: () => void;
  businessDetails: any;
};

const EditBusinessDetailsForm = ({ onClose, businessDetails }: EditBusinessDetailsFormProps) => {
  const { handleSubmit } = useFormikContext<any>();

  return (
    <Form className="h-full flex flex-col">
      <Drawer.Title title="Edit business details" onClose={onClose} />
      <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
        <div className="flex flex-col">
          <div className="mb-4">
            <TextField id="name" label="Business name" />
          </div>
          <div className="flex flex-col mb-4">
            <Label customClass="block text-sm font-medium text-steel-700 mb-2" label="Website" htmlFor="website" />
            <div className="flex flex-row">
              <div className="w-1/6">
                <TextField
                  disabled={true}
                  id="website-prefix"
                  value="https://"
                  ariaLabel="Business website prefix"
                  customStyling={{
                    field:
                      'text-steel-600 appearance-none block w-full px-3 py-2 border border-r-0 rounded-md rounded-r-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                  }}
                />
              </div>
              <div className="w-5/6">
                <TextField
                  id="website"
                  type="text"
                  ariaLabel="Business website"
                  customStyling={{
                    field:
                      'appearance-none block w-full px-3 py-2 border rounded-md rounded-l-none shadow-sm focus:outline-none sm:text-sm border-steel-300 placeholder-steel-400 focus:ring-purple-500 focus:border-purple-500',
                  }}
                />
              </div>
            </div>
          </div>
          <div className="mb-4">
            <TextField id="phone" label="Business phone" type="tel" />
          </div>
          <div className="mb-4">
            <TextField id="address" label="Business address" type="text" />
          </div>
          <div className="mb-4">
            <TimeSlotPicker
              id="hours"
              label="Hours"
              onChange={(data) => {
                console.log(data);
              }}
            />
          </div>
        </div>
      </div>
      <Drawer.Footer>
        <Button title="publish business details" displayType="primary" type="submit" onClick={handleSubmit}>
          Publish
        </Button>
      </Drawer.Footer>
    </Form>
  );
};

type EditBusinessDetailsProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: any) => void;
  businessDetails?: any;
};

export const EditBusinessDetails = ({ isOpen, onClose, businessDetails, onSubmit }: EditBusinessDetailsProps) => {
  const initialValues: any = {
    name: businessDetails?.name,
    website: businessDetails?.website,
    phone: businessDetails?.phone,
    address: businessDetails?.address,
  };

  return (
    <Drawer open={isOpen} onClose={onClose}>
      <Formik
        initialValues={initialValues}
        enableReinitialize
        // validationSchema={businessDetailsSchema}
        onSubmit={(values) => {
          onSubmit(values);
          onClose();
        }}
      >
        <EditBusinessDetailsForm onClose={onClose} businessDetails={businessDetails} />
      </Formik>
    </Drawer>
  );
};
