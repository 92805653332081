import React from 'react';

const OnboardingLayout = (props: React.PropsWithChildren) => {
  return <div className="">{props.children}</div>;
};

const Overview = (props: React.PropsWithChildren) => {
  return <div className="bg-[#F9F9FB] h-screen flex flex-col justify-between">{props.children}</div>;
};

const Details = (props: React.PropsWithChildren) => {
  return <div className="col-span-2 m-auto bg-white h-screen overflow-y-auto">{props.children}</div>;
};

export default Object.assign(OnboardingLayout, { Overview, Details });
