import * as Yup from 'yup';

export const calendarSettingsSchema = Yup.object().shape({
  calendars: Yup.array(
    Yup.object().shape({
      name: Yup.string().required('Required'),
      link: Yup.string().url('Invalid Url').required('Required'),
    })
  ),
});
