import { Button, Drawer, Formik } from '@portal/ui';
import { DrawerProps } from '@portal/ui/components/display/Drawer';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { FormDataState, getFormData } from '@portal/utils/forms';
import { Form, FormikValues } from 'formik';
import {
  useCreateContactMutation,
  useUpdateContactAvatarMutation,
  useUpdateContactMutation,
} from 'graphql/mutation.generated';
import { FindAllContactsDocument, FindContactByIdDocument } from 'graphql/query.generated';
import { result, trim } from 'lodash';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'redux/hooks';
import { fetchTags } from 'redux/thunks/tags';
import { formatContactFormikData } from 'utils/misc';
import { contactSchema } from '../validation/contactSchema';
import ContactFields from './ContactFields';

type FormState = {
  firstName: string;
  lastName: string;
  email: string;
  addressLine1: string;
  addressLine2: string;
  city: string;
  countryCode: string;
  state: string;
  pincode: string;
  companyName: string;
  phone: string;
  status: string;
  avatar?: File | null;
  ownerId: string;
  tags?: string[];
  id?: string;
};

const defaultInitialValues: FormState = {
  firstName: '',
  lastName: '',
  email: '',
  addressLine1: '',
  addressLine2: '',
  city: '',
  countryCode: '',
  state: '',
  pincode: '',
  companyName: '',
  phone: '',
  status: 'LEAD',
  avatar: null,
  ownerId: '',
  tags: [],
};

interface ContactFormProps extends Partial<Pick<DrawerProps, 'onClose'>> {
  mode?: 'create' | 'edit';
  initialValues?: Partial<FormState>;
}

const ContactForm = ({ mode = 'create', initialValues, onClose }: ContactFormProps) => {
  const userId = useSelector((state) => state.user.attributes?.id);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const merchantAccountId = useSelector((state) => state.user.attributes?.merchantAccount.id);
  const [createContact, { loading: createContactLoading }] = useCreateContactMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: (data) => {
      dispatch(fetchTags(merchantAccountId));

      toast.success('Contact added');

      if (onClose) onClose();
    },
    refetchQueries: [FindAllContactsDocument],
  });

  const [updateContact, { loading: updateContactLoading }] = useUpdateContactMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      toast.success('Contact updated');
      dispatch(fetchTags(merchantAccountId));
      if (onClose) onClose();
    },
    refetchQueries: [FindAllContactsDocument, FindContactByIdDocument],
  });

  const [updateContactAvatar] = useUpdateContactAvatarMutation({
    onError: (error) => {
      console.error(error);
      toast.error('Something went wrong');
    },
    onCompleted: () => {
      if (onClose) onClose();
    },
    refetchQueries: [FindAllContactsDocument],
  });

  const handleUpdateContact = (values: FormikValues) => {
    if (initialValues && initialValues.id) {
      if (typeof values?.avatar === 'object' && values.avatar) {
        updateContactAvatar({
          variables: {
            merchantContactId: initialValues.id,
            imgMimeType: values.avatar.type,
          },
        }).then((result) => {
          const uploadDetails = result?.data?.requestContactAvatarUpdate;
          const formData = getFormData(uploadDetails as FormDataState, values.avatar as File);

          fetch(uploadDetails?.formPostUrl || '', {
            method: 'POST',
            body: formData,
          }).then(async (res) => {
            updateContact({
              variables: {
                data: {
                  ...formatContactFormikData(values),
                  merchantContactId: initialValues.id as string,
                  acknowledgeContactAvatarUpdate: true,
                },
              },
            });
          });
        });
      } else {
        const data = formatContactFormikData(values);
        updateContact({
          variables: {
            data: {
              ...data,
              merchantContactId: initialValues.id as string,
            },
          },
        });
      }
    }
  };

  const handleCreateContact = (values: FormikValues) => {
    const data = formatContactFormikData(values);

    if (typeof values?.avatar === 'object' && values?.avatar) {
      console.error(typeof values?.avatar);
      createContact({
        variables: {
          data: {
            ...data,
            ownerId: userId ?? '',
            merchantAccountId: merchantAccountId as string,
            avatarMimeType: values.avatar.type,
          },
        },
      }).then((result) => {
        const uploadDetails = result?.data?.createContact.avatarUploadForm;
        const formData = getFormData(uploadDetails as FormDataState, values.avatar as File);

        fetch(uploadDetails?.formPostUrl || '', {
          method: 'POST',
          body: formData,
        })
          .then(async () => {
            return await updateContact({
              variables: {
                data: {
                  merchantContactId: result.data?.createContact.contact.id ?? '',
                  acknowledgeContactAvatarUpdate: true,
                },
              },
            });
          })
          .then((res) => {
            if (res.data?.updateContact.id) {
              navigate(`/contacts/${res.data?.updateContact.id}`);
            }
          });
      });
    } else {
      createContact({
        variables: {
          data: {
            ...data,
            ownerId: userId ?? '',
            merchantAccountId: merchantAccountId ?? '',
          },
        },
      }).then((res) => {
        if (res.data?.createContact?.contact.id) {
          navigate(`/contacts/${res.data?.createContact?.contact.id}`);
        }
      });
    }
  };

  const handleSubmit = (values: FormikValues) => {
    if (mode === 'edit') {
      handleUpdateContact(values);
    } else {
      handleCreateContact(values);
    }
  };

  const editTitle =
    (initialValues?.firstName && result(initialValues, 'firstName') !== '') ||
    (initialValues?.lastName && result(initialValues, 'lastName') !== '')
      ? trim(`${initialValues?.firstName} ${initialValues?.lastName}`)
      : 'contact';

  const title = mode === 'edit' ? `Edit ${editTitle}` : 'Add contact';

  const submitBtn = useMemo(
    () => (
      <Button
        title="create or save contact"
        displayType="primary"
        type="submit"
        loading={createContactLoading || updateContactLoading}
      >
        {mode === 'create' ? 'Create' : 'Save'}
      </Button>
    ),
    [createContactLoading, updateContactLoading]
  );

  return (
    <Formik<FormState>
      initialValues={{ ...defaultInitialValues, ...initialValues }}
      validationSchema={contactSchema}
      onSubmit={handleSubmit}
    >
      <Form className="h-full flex flex-col">
        <Drawer.Title title={title} onClose={onClose} submitBtn={submitBtn} />
        <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
          <ContactFields />
        </div>
        <Drawer.Footer>{submitBtn}</Drawer.Footer>
      </Form>
    </Formik>
  );
};

export { ContactForm };
export type { ContactFormProps, FormState };
