import { Icons } from '@portal/ui';
import classNames from 'classnames';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { Option } from 'react-google-places-autocomplete/build/types';
import { SingleValue } from 'react-select';
import { getAddressInfoFromPlaceId, AddressInfo } from 'utils/address';

type AddressInputProps = {
  id?: string;
  onChange: (value: AddressInfo) => void;
  value: Option;
};

export const AddressInput = ({ id, onChange, value }: AddressInputProps) => {
  return (
    <GooglePlacesAutocomplete
      apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
      apiOptions={{
        libraries: ['places', 'maps', 'geocoding', 'core'],
      }}
      autocompletionRequest={{
        componentRestrictions: {
          country: ['us'],
        },
      }}
      selectProps={{
        id,
        components: {
          LoadingIndicator: () => <Icons.Spinner />,
          DropdownIndicator: () => null,
        },
        onChange: async (value: SingleValue<Option>) => {
          if (value?.value) {
            const data = await getAddressInfoFromPlaceId(value.value?.place_id);
            onChange(data);
          }
        },
        value,
        unstyled: true,
        openMenuOnClick: true,
        placeholder: 'Search',
        classNames: {
          valueContainer: () => 'text-sm',
          input: () => '[&>input]:text-red !text-red cursor-text',
          loadingMessage: () => 'text-black text-sm p-2',
          placeholder: () => 'text-black text-sm ',
          menuList: () => 'bg-white border m-1 rounded-md ',
          noOptionsMessage: () => 'text-black text-sm p-2',
          indicatorSeparator: () => 'hidden',
          control: () =>
            'border bg-white border-steel-300 focus:border-steel-300 hover:border-steel-300 shadow-sm px-2 py-2.5 !text-base sm:text-sm rounded-md',
          option: ({ isSelected }) =>
            classNames(
              'active:bg-steel-50 hover:text-black hover:bg-steel-50 !text-sm sm:text-sm  cursor-default rounded-md select-none px-3 py-2 active:text-black text-black !cursor-pointer',
              isSelected ? 'bg-steel-50' : 'bg-white'
            ),
        },
      }}
    />
  );
};
