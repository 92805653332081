import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Button from '../Button';
import Icons from '../../assets/icons';

export type FullScreenModalProps = {
  children?: React.ReactNode;
  isOpen: boolean;
  title?: string;
  subTitle?: string | React.ReactNode;
  onClose: () => void;
  afterLeave?: () => void;
  closeIcon?: boolean;
  actions?: React.ReactNode[];
};

const FullScreenModal = ({
  isOpen,
  children,
  onClose,
  closeIcon,
  title,
  subTitle,
  actions = [],
  afterLeave,
}: FullScreenModalProps) => {
  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="fixed inset-0 z-[99999]" onClose={onClose}>
        <div className="flex justify-center items-center h-screen">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 scale-95"
            enterTo="opacity-100 scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 scale-100"
            leaveTo="opacity-0 scale-95"
            afterLeave={afterLeave}
          >
            <Dialog.Panel className="inline-block w-full max-h-full min-h-full overflow-y-auto text-left align-middle transition-all transform bg-white">
              <div className="sticky top-0 bg-white z-50 py-4 px-4 border-b">
                <div className="flex items-center">
                  {closeIcon && (
                    <Button title="close modal" onClick={onClose} iconOnly displayType="text">
                      <Icons.Cross />
                    </Button>
                  )}
                  <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900">
                    {title}
                  </Dialog.Title>
                  <div className="ml-auto flex items-center">
                    {actions.map((item, idx) => (
                      <div key={idx} className="flex-none ml-2">
                        {item}
                      </div>
                    ))}
                  </div>
                </div>
                {subTitle && (
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">{subTitle}</p>
                  </div>
                )}
              </div>
              <div className="h-full">{children}</div>
            </Dialog.Panel>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default FullScreenModal;
