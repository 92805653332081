import { FC, useCallback } from 'react';
import { Formik, Form, FieldArray } from 'formik';
import { Button, checkFieldErrors } from '@portal/ui';
import ProductFields from './ProductFields';
import { Product, ProductsFormProps } from 'types/Product';
import { ProductsSchema } from 'components/validation/productSchema';
import { Icons } from '@portal/ui';

const defaultInitialValues: Product = {
  name: '',
  price: 0,
};

const ProductsForm: FC<ProductsFormProps> = ({
  mode = 'create',
  initialValues,
  onSubmit,
  loading,
}: ProductsFormProps) => {
  const addProduct = useCallback((push: (value: Product) => void) => {
    push({
      name: '',
      price: 0,
    });
  }, []);

  return (
    <Formik
      initialValues={{
        products: [{ ...defaultInitialValues, ...initialValues }],
      }}
      validationSchema={ProductsSchema}
      onSubmit={onSubmit}
    >
      {({ values: { products }, handleChange, touched, errors }) => {
        return (
          <>
            <Form className="h-full flex flex-col">
              <div className="relative mt-5 flex-1">
                <div className="flex flex-col">
                  <FieldArray
                    name="products"
                    render={({ push, remove }) => (
                      <>
                        {products.map((product, index) => (
                          <ProductFields
                            price={product.price}
                            key={product.id}
                            onChange={handleChange}
                            index={index}
                            remove={remove}
                            isInValidName={checkFieldErrors(
                              touched as Record<string, boolean>,
                              errors as Record<string, string | undefined>,
                              `products.${index}`,
                              'name'
                            )}
                            isInValidPrice={checkFieldErrors(
                              touched as Record<string, boolean>,
                              errors as Record<string, string | undefined>,
                              `products.${index}`,
                              'price'
                            )}
                          />
                        ))}
                        {mode === 'create' && (
                          <div>
                            <Button title="add another product" onClick={() => addProduct(push)} displayType="primary">
                              <Icons.Plus className="w-5 h-5" aria-hidden="true" color="white" /> Add another
                            </Button>
                          </div>
                        )}
                      </>
                    )}
                  />
                </div>
              </div>
              <div className="flex justify-end items-center">
                <div className="flex-none mx-2 mb-5">
                  <Button title="create or save product" displayType="primary" type="submit" loading={loading}>
                    {mode === 'create' ? 'Create' : 'Save'}
                  </Button>
                </div>
              </div>
            </Form>
          </>
        );
      }}
    </Formik>
  );
};

export default ProductsForm;
