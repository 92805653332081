import { useDialog, AriaDialogProps } from 'react-aria';
import React from 'react';

export interface DialogProps extends AriaDialogProps {
  children: React.ReactNode;
  title?: string;
}

function Dialog({ title, children, ...props }: DialogProps) {
  const ref = React.useRef<HTMLDivElement>(null);
  const { dialogProps } = useDialog(props, ref);

  return (
    <div {...dialogProps} ref={ref}>
      {children}
    </div>
  );
}

export default Dialog;
