import { Link } from 'react-router-dom';
import { classNames, isActivePath } from '@portal/ui';
import { Disclosure } from '@headlessui/react';

type NavigationItem = {
  name: string;
  link: React.ReactNode; // Replace this with NavigationLinkProps if NavigationLink is a component
  pathName: string;
};
interface SubHeaderProps {
  navigation: NavigationItem[];
}
const SubHeader = ({ navigation }: SubHeaderProps) => {
  return (
    <div className="static w-[12rem] z-10">
      <Disclosure as="nav" className="flex fixed">
        {({ open }) => (
          <>
            <div className="hidden md:flex flex-col justify-between h-screen p-3 w-48 bg-steel-50 border-r border-steel-200">
              <div className="space-y-3">
                <div className="flex-1">
                  <ul className="pt-2 pb-4 space-y-1 text-sm">
                    {navigation.map((item, idx) => (
                      <li className="rounded-sm" key={idx}>
                        <Link to={item?.pathName}>
                          <Disclosure.Button
                            className={classNames(
                              isActivePath(item.pathName)
                                ? 'bg-steel-100 rounded-md text-primary-700'
                                : 'text-steel-700 hover:bg-steel-100',
                              'flex items-center p-2 space-x-3  font-medium rounded-md w-full'
                            )}
                          >
                            <span>{item.link}</span>
                          </Disclosure.Button>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </>
        )}
      </Disclosure>
    </div>
  );
};

export default SubHeader;
