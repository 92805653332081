import { Icons, Modal } from '@portal/ui';
import { Event } from 'types/Event';
import { formatDate } from 'utils/dates';
import moment from 'moment';
import { getFormattedTime } from 'utils/time';
import { useState } from 'react';
import { ScheduleAppointmentModal } from 'components/calendar/appointments/ScheduleAppointmentModal';
import FullCalendar from '@fullcalendar/react';

interface ViewAppointmentModalProps {
  isModalOpen: boolean;
  closeModal: () => void;
  event?: Event;
  loading?: boolean;
  calendar?: FullCalendar | null;
}

const ViewAppointmentModal = ({
  isModalOpen,
  closeModal,
  event,
  loading = false,
  calendar,
}: ViewAppointmentModalProps) => {
  const { longFormatDate } = formatDate(event ? event?.startTime : '');
  const formattedTime = getFormattedTime(event ? event?.startTime : '', event ? event?.endTime : '');
  const [openAppointmentForm, setOpenAppointmentForm] = useState<boolean>(false);

  return openAppointmentForm && event ? (
    <ScheduleAppointmentModal
      isOpen={openAppointmentForm}
      edit={true}
      calendar={calendar}
      data={{
        ...event,
        eventId: event.id,
        locationType: event.location ? 'IN_PERSON' : 'PHONE_CALL',
        sendReminderAt: event.sendReminderAt ? moment(event.sendReminderAt).toISOString() : null,
      }}
      onToggle={() => {
        setOpenAppointmentForm(false);
        closeModal();
      }}
    />
  ) : (
    <Modal
      open={isModalOpen}
      title={event ? event.title : ''}
      onClose={closeModal}
      closeIcon={true}
      loading={loading}
      subTitle={longFormatDate && formattedTime ? `${longFormatDate} // ${formattedTime}` : ''}
    >
      {event?.location && (
        <div className="mt-5 flex gap-5 items-center">
          <div className="mb-1">
            <Icons.Location color="#7D89B0" height={20} width={20} />
          </div>
          <p className="text-sm font-normal text-steel-700">{event?.location}</p>
        </div>
      )}
      <Divider />
      <div className="flex justify-between items-start">
        <div className="flex gap-6 items-start">
          <Icons.Users color="#7D89B0" height={16} width={16} />
          <div className="flex flex-col gap-2">
            {event?.guests?.map((guest) => (
              <div className="flex gap-2 items-center">
                <p className="text-sm font-normal text-steel-700 leading-4">{guest}</p>
                <div className="rounded-full bg-steel-300 w-2 h-2" />
              </div>
            ))}
          </div>
        </div>
        <p className="text-sm font-normal text-steel-700">Owner</p>
      </div>
      {event?.description && (
        <>
          <Divider />
          <div className="flex items-start gap-5">
            <div>
              <Icons.AlignLeft color="#8e99bf" height={20} width={20} />
            </div>
            <p className="text-sm font-normal text-steel-700 leading-4">{event?.description}</p>
          </div>
        </>
      )}
      <button
        className="mt-5 p-2 gap-2 flex items-center justify-center self-stretch rounded-lg border border-solid w-full bg-[#7F56D9] text-white font-semibold text-sm"
        onClick={() => setOpenAppointmentForm(true)}
      >
        Edit event
      </button>
    </Modal>
  );
};

const Divider = () => {
  return <div className="bg-steel-200 h-px w-full my-5" />;
};

export default ViewAppointmentModal;
