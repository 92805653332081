import { Button, Drawer, Formik } from '@portal/ui';
import { ChildrenProps } from '@portal/ui/components/display/Drawer';
import toast from '@portal/ui/components/widgets/Toast/notify';
import { paymentSchema } from 'components/validation/paymentSchema';
import { PaymentMethods, PaymentStatuses } from 'constants/payments';
import { Form, FormikValues } from 'formik';
import { useCreatePaymentMutation, useUpdatePaymentMutation } from 'graphql/mutation.generated';
import {
  FetchAllPaymentsDocument,
  FindAllPaymentsByMerchantIdDocument,
  FindPaymentStatsByIdDocument,
} from 'graphql/query.generated';
import { MerchantContactPaymentStatus } from 'graphql/types';
import moment from 'moment';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { CustomerType } from 'types/Customer';
import { PaymentRow } from '../contactDetails/TabsView/Payments/index';
import LogPaymentForm from './LogPaymentForm';

export type PaymentMethod = {
  id: number;
  last4Digits: string;
  type: any;
  cardType: string;
  expiration: string;
  externalId: string;
  isPrimary: boolean;
  contact: {
    id: string;
    name: string;
  };
};

export type FormState = {
  customer: CustomerType | null;
  reference: string;
  date: Date;
  paymentMethod: string;
  amount: string;
  attachments: File[];
};

export interface LogPaymentFormProps extends ChildrenProps {
  submit?: (values: any) => void;
  data?: PaymentRow | null;
  loading?: boolean;
  closePaymentForm?: () => void;
  edit?: boolean;
}

const initialValues: FormState = {
  customer: null,
  date: new Date(),
  amount: '',
  paymentMethod: PaymentMethods[0].value,
  reference: '',
  attachments: [],
};

const LogPayment = ({ edit, data, closePaymentForm, ...props }: LogPaymentFormProps) => {
  const navigate = useNavigate();
  const [createPayment, { loading: loadingCreatePayment }] = useCreatePaymentMutation({
    onCompleted: (data) => {
      toast.success('Payment logged');
      if (closePaymentForm) {
        closePaymentForm();
      }
      navigate(`/contacts/${data.createPayment.contact.id}`);
    },
    onError: (err) => {
      toast.error(err?.message ?? 'Payment creation failed');
    },
    refetchQueries: [FetchAllPaymentsDocument, FindPaymentStatsByIdDocument, FindAllPaymentsByMerchantIdDocument],
  });
  const [updatePayment, { loading: loadingUpdatePayment }] = useUpdatePaymentMutation({
    onCompleted: (data) => {
      toast.success('Payment updated');
      if (closePaymentForm) {
        closePaymentForm();
      }
      navigate(`/contacts/${data.updatePayment.contact.id}`);
    },
    onError: (error) => {
      toast.error('something went wrong');
      console.error(error);
    },
    refetchQueries: [FetchAllPaymentsDocument],
  });
  const logPayment = (values: FormikValues) => {
    if (edit) {
      updatePayment({
        variables: {
          data: {
            contactId: values.customer.id as string,
            paymentId: values.id as string,
            amount: values.amount,
            reference: values.reference,
            paidAt: moment(values.date).format(),
            status: values.status,
          },
        },
      });
    } else {
      createPayment({
        variables: {
          data: {
            contactId: values.customer.id as string,
            amount: values.amount,
            reference: values.reference,
            paidAt: moment(values.date).format(),
            status: PaymentStatuses[0].value as MerchantContactPaymentStatus,
          },
        },
      });
    }
  };

  const submitBtn = useMemo(
    () => (
      <Button
        title="edit or log payment"
        loading={loadingCreatePayment || loadingUpdatePayment}
        displayType="primary"
        type="submit"
      >
        Save
      </Button>
    ),
    [loadingCreatePayment, loadingUpdatePayment]
  );

  return (
    <Formik
      initialValues={{
        ...initialValues,
        ...data,
        customer: data?.contact,
        date: data?.paidAt ? new Date(data?.paidAt) : new Date(),
      }}
      validationSchema={paymentSchema}
      onSubmit={logPayment}
    >
      <Form className="h-full flex flex-col">
        <Drawer.Title title={data ? 'Edit payment' : 'Log a payment'} onClose={props?.onClose} submitBtn={submitBtn} />
        <div className="relative mt-6 flex-1 px-4 sm:px-6 space-y-6">
          <LogPaymentForm />
        </div>
        <Drawer.Footer>
          <Button
            title="edit or log payment"
            loading={loadingCreatePayment || loadingUpdatePayment}
            displayType="primary"
            type="submit"
          >
            Save
          </Button>
        </Drawer.Footer>
      </Form>
    </Formik>
  );
};

export default LogPayment;
