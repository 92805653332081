import { FieldFeedback, Icons, Label } from '@portal/ui';
import CurrencyInput from 'components/currencyInput';
import FormField from 'components/formField';
import { useFormikContext } from 'formik';
import { FC } from 'react';
import { Product, ProductFieldsProps } from 'types/Product';

const ProductFields: FC<ProductFieldsProps> = ({ price, onChange, index, remove, isInValidName, isInValidPrice }) => {
  const { errors } = useFormikContext<{ products: Product[] }>();
  const error = errors?.products?.[index];
  const nameError = typeof error === 'object' ? error?.name : undefined;
  const priceError = typeof error === 'object' ? error?.price : undefined;
  const displayLabel = index == 0;

  return (
    <>
      <div className="mb-2">
        <div className="flex">
          <div className="w-8/12 mr-3">
            {displayLabel && <Label htmlFor="product" label="Item" />}
            <FormField id={`products.${index}.name`} type="text" />
          </div>
          <div className="w-4/12">
            {displayLabel && <Label htmlFor="price" label="Price" />}
            <div className="relative flex">
              <span className="absolute left-2.5 top-2/4 font-normal text-steel-600 -translate-y-1/2">$</span>
              <CurrencyInput id={`products.${index}.price`} value={price} />
              {index > 0 && (
                <button
                  type="button"
                  className="w-1/12 ml-1 mt-auto mb-2.5 cursor-pointer"
                  onClick={() => remove(index)}
                >
                  <Icons.Cross className="w-5 h-5 text-red-600" />
                </button>
              )}
            </div>
          </div>
        </div>
        <div className="flex">
          <div className="w-8/12 mr-3">
            <FieldFeedback errorCondition={isInValidName} errorMessage={nameError} transitionClasses="mb-1" />
          </div>
          <div className="w-4/12">
            <FieldFeedback errorCondition={isInValidPrice} errorMessage={priceError} transitionClasses="mb-1" />
          </div>
        </div>
      </div>
    </>
  );
};

export default ProductFields;
